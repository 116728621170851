import React from "react";
import { components } from "react-select";
import { default as ReactSelect } from "react-select";

export const SingleSelect = ({ onChange, options, value }) => {
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  return (
    <span>
      <ReactSelect
        options={options}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option,
        }}
        onChange={onChange}
        value={value}
      />
    </span>
  );
};

export const MultiSelect = ({ onChange, options, value, styles }) => {
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  return (
    <span
      data-toggle="popover"
      data-trigger="focus"
      data-content="Please select account(s)"
    >
      <ReactSelect
        options={options}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option,
        }}
        onChange={onChange}
        styles={styles}
        value={value}
      />
    </span>
  );
};
