import React from "react";
import { StyledModal, StyledDashboardContentFlex, Theme } from "../index";
import { Dialog } from "@mui/material";
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";
import { StyledLoadingContainer } from "../styles/Login.Styled";

const DeleteModal = ({
  isModalOpen,
  closeModal,
  handleDeleteActionClick,
  name,
  loading,
}) => {
  const { t } = useTranslation();

  if (!isModalOpen) return null;
  const flexContainer = {
    display: "flex",
    justifyContent: "flex-end",
    gap: "1em",
    padding: "1.5em",
  };
  const title = {
    color: Theme.colors.sysSurface2Color,
    fonts: Theme.fonts.fontMediumBold,
  };
  const confirmTest = {
    color: Theme.colors.sysSurfaceVariant,
    font: Theme.fonts.smallFont,
    letterSpacing: "0.25px",
  };
  const paragraph = {
    color: Theme.colors.blueColor,
    cursor: "pointer",
  };
  return (
    <StyledModal>
      {!loading ? (
        <Dialog
          open={isModalOpen}
          onClose={closeModal}
          PaperProps={{
            sx: {
              padding: "1.5em",
              borderRadius: Theme.radius.primaryBorderRadius,
              background: Theme.colors.inverseOnSurface,
              position: "relative",
            },
          }}
        >
          <StyledDashboardContentFlex column>
            <>
              <p style={title}>{t("workflow.table.actions.delete.title")}</p>
              <p style={confirmTest}>
                {t("workflow.table.actions.delete.question")} {name}?
              </p>
              <div style={flexContainer}>
                <p style={paragraph} onClick={closeModal}>
                  {t("workflow.table.actions.delete.no")}
                </p>
                <p style={paragraph} onClick={handleDeleteActionClick}>
                  {t("workflow.table.actions.delete.yes")}
                </p>
              </div>
            </>
          </StyledDashboardContentFlex>
        </Dialog>
      ) : (
        <StyledLoadingContainer>
          <ReactLoading
            type={"spin"}
            color={Theme.colors.blueColor}
            height={35}
            width={35}
          />
        </StyledLoadingContainer>
      )}
    </StyledModal>
  );
};

export default DeleteModal;
