/* eslint-disable no-unused-vars */
import { useTranslation } from "react-i18next";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
// import ChatIcon from "@mui/icons-material/Chat";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import BoatGPTLogo from "../../images/boat_GPT.png";
import {
  HomeSvg,
  JobsSvg,
  WorkFlow,
  DashBoardSvg,
  DataManagementSvg,
  SchemaRegistrySvg,
  ALSvg,
  SandBoxSvg,
  MlopsSvg,
  TransformationSvg,
  CatalogSvg,
  ObservabilitySvg,
  MetricsSvg,
  LogsSvg,
  LineageSvg,
  DataQualitySvg,
  DataGovernanceSvg,
  DataClassificationSvg,
  ControlPlaneSvg,
  UploadSvg,
  AnalyticsSvg,
} from "../reusableComponents/svg/menuListSvg";
export const GetMenuList = () => {
  const { t } = useTranslation();
  const menuList = [
    {
      id: 1,
      title: t("menu_list.item_1.title"),
      path: "/dataManagement",
      class: "dataManagement",
      icon: <HomeSvg />,
      newFeature: false,
    },
    // {
    //   id: 8,
    //   title: t("menu_list.item_4.title"),
    //   path: "/analytics",
    //   class: "analytics",
    //   icon: <AnalyticsSvg />,
    //   newFeature: false,
    // },
    {
      id: 2,
      title: t("menu_list.item_2.title"),
      icon: <JobsSvg />,
      options: [
        {
          id: 1,
          subMenu: t("menu_list.item_2.options.item_1.sub_menu"),
          optionLink: "/yedaflow",
          optionClass: "yedaflow",
          class: "yedaflow",
          optionIcon: <WorkFlow />,
        },
        // TODO Route from Jobs
        {
          id: 2,
          subMenu: t("menu_list.item_2.options.item_2.sub_menu"),
          optionLink: "/create-job",
          optionClass: "create-job",
          optionIcon: <JobsSvg />,
        },
        // {
        //   id: 3,
        //   subMenu: "Dashboard",
        //   optionLink: "/dashboard",
        //   optionClass: "dashboard",
        //   optionIcon: <DashBoardSvg />,
        //   newFeature: true,
        // },
      ],
    },
    {
      id: 3,
      title: t("menu_list.item_3.title"),
      icon: <DataManagementSvg />,
      options: [
        {
          id: 1,
          subMenu: t("menu_list.item_3.options.item_1.sub_menu"),
          optionLink: "/transformation",
          optionClass: "transformation",
          optionIcon: <TransformationSvg />,
        },
        {
          id: 2,
          subMenu: t("menu_list.item_3.options.item_2.sub_menu"),
          optionLink: "/queryBuilder",
          optionClass: "queryBuilder",
          optionIcon: (
            <QueryBuilderIcon
              sx={{
                fill: "black",
                fontSize: "1.35em",
              }}
            />
          ),
        },
        {
          id: 3,
          subMenu: t("menu_list.item_3.options.item_3.sub_menu"),
          optionLink: "/boatGPT",
          optionClass: "boatGPT",
          optionIcon: (
            <img
              src={BoatGPTLogo}
              alt="Boat GPT Logo"
              style={{
                width: "30px",
                height: "30px",
              }}
            />
          ),
        },
        {
          id: 4,
          subMenu: t("menu_list.item_3.options.item_4.sub_menu"),
          optionLink: "/upload",
          optionClass: "upload",
          optionIcon: <UploadSvg />,
        },
        {
          id: 5,
          subMenu: t("menu_list.item_3.options.item_5.sub_menu"),
          optionLink: "/catalog",
          optionClass: "catalog",
          optionIcon: <CatalogSvg />,
        },
      ],
    },
    // {
    //   id: 4,
    //   title: "Observability",
    //   icon: <ObservabilitySvg />,
    //   newFeature: true,
    //   options: [
    //     {
    //       id: 1,
    //       subMenu: "Metric(s)",
    //       optionLink: "/metrics",
    //       optionClass: "metrics",
    //       optionIcon: <MetricsSvg />,
    //       newFeature: true,
    //     },
    //     {
    //       id: 2,
    //       subMenu: "Logs",
    //       optionLink: "/logs",
    //       optionClass: "logs",
    //       optionIcon: <LogsSvg />,
    //       newFeature: true,
    //     },
    //     {
    //       id: 3,
    //       subMenu: "Lineage",
    //       optionLink: "/lineage",
    //       optionClass: "lineage",
    //       optionIcon: <LineageSvg />,
    //       newFeature: true,
    //     },
    //     {
    //       id: 4,
    //       subMenu: "Data Quality",
    //       optionLink: "/dataQuality",
    //       optionClass: "dataQuality",
    //       optionIcon: <DataQualitySvg />,
    //     },
    //   ],
    // },
    // {
    //   id: 5,
    //   title: "Data Governance",
    //   icon: <DataGovernanceSvg />,
    //   newFeature: true,
    //   options: [
    //     {
    //       id: 1,
    //       subMenu: "Data Classification",
    //       optionLink: "/dataClassification",
    //       optionClass: "dataClassification",
    //       optionIcon: <DataClassificationSvg />,
    //     },
    //     {
    //       id: 2,
    //       subMenu: "Schema",
    //       optionLink: "/schemaRegistry",
    //       optionClass: "schemaRegistry",
    //       optionIcon: <SchemaRegistrySvg />,
    //       newFeature: true,
    //     },
    //   ],
    // },
    // {
    //   id: 6,
    //   title: "AI/ML Sandbox",
    //   icon: <ALSvg />,
    //   newFeature: true,
    //   options: [
    //     {
    //       id: 1,
    //       subMenu: "Sandbox",
    //       optionLink: "/notebook",
    //       optionClass: "notebook",
    //       optionIcon: <SandBoxSvg />,
    //       newFeature: true,
    //     },
    //     {
    //       id: 2,
    //       subMenu: "MLOps Flow",
    //       optionLink: "/mlOps",
    //       optionClass: "mlOps",
    //       optionIcon: <MlopsSvg />,
    //       newFeature: true,
    //     },
    //   ],
    // },
    {
      id: 7,
      title: t("menu_list.item_7.title"),
      path: "/control",
      class: "control",
      icon: <ControlPlaneSvg />,
    },
    {
      id: 8,
      title: "Support",
      path: "/support",
      class: "support",
      icon: <SupportAgentIcon />,
      newFeature: false,
    },
  ];
  return menuList;
};
