import React from "react";
import {
  ScrollableTable,
  Thead,
  TableHeaderCell,
} from "../../styles/Yedaflow.Styled";

const ScrollableTableOverview = ({ headers, rows, renderRow }) => {
  return (
    <div style={{ maxHeight: "450px", overflowY: "auto" }}>
      <ScrollableTable>
        <Thead>
          <tr>
            {headers.map((header, index) => (
              <TableHeaderCell key={index}>{header}</TableHeaderCell>
            ))}
          </tr>
        </Thead>
        <tbody>
          {rows.map((item, index) => (
            <React.Fragment key={index}>
              {renderRow(item, index)}
            </React.Fragment>
          ))}
        </tbody>
      </ScrollableTable>
    </div>
  );
};

export default ScrollableTableOverview;
