import React from "react";
import {
  StyledConnect,
  StyledImage,
  DynamicGrid,
  StyledModal,
  Flex,
  ModalHeader,
} from "../../../index";
import { reversedSyncListItems } from "../IconListItems";
import CloseSvg from "../../../reusableComponents/svg/CloseSvg";
import { Dialog } from "@mui/material";
import { useTranslation } from "react-i18next";
import SelectedComponent from "./SelectedComponent";

const ReversedConnectorModal = ({
  onReversedClose,
  closeReversedModal,
  openReversedModal,
  reversedModalOpen,
  selectedText,
  isReversedConnectorOpen,
  closeReversedConnector,
  taskId,
  taskData,
  setTaskData,
  onClose,
}) => {
  const { t } = useTranslation();

  if (!isReversedConnectorOpen) return null;

  return (
    <>
      <StyledModal>
        <Dialog
          height="fit-content"
          open={isReversedConnectorOpen}
          fullWidth
          PaperProps={{ sx: { borderRadius: "28px" } }}
        >
          <div>
            <ModalHeader>
              <p>{t("jobs.form.inputs.task.modal.title")}</p>
              <div onClick={closeReversedConnector}>
                <CloseSvg style={{ float: "right" }} className="modal_icon" />
              </div>
            </ModalHeader>
            <StyledConnect>
              <DynamicGrid>
                {reversedSyncListItems.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="icon_container"
                      onClick={() =>
                        openReversedModal(data.component, data.text, taskId)
                      }
                    >
                      <Flex>
                        <StyledImage
                          src={`../../../../images/${data.image}`}
                          alt={data.text}
                          objectFit="contain"
                        />
                      </Flex>
                      <p className="text">{data.text}</p>
                    </div>
                  );
                })}
              </DynamicGrid>
            </StyledConnect>
          </div>
        </Dialog>
      </StyledModal>
      {reversedModalOpen && (
        <StyledModal>
          <Dialog
            height={"fit-content"}
            open={reversedModalOpen}
            fullWidth={true}
            maxWidth="md"
            PaperProps={{ sx: { borderRadius: "28px" } }}
          >
            <div>
              <ModalHeader>
                <p>
                  {selectedText}{" "}
                  {t("jobs.form.inputs.task.modal.connectors.server.title")}
                </p>
                <div onClick={closeReversedModal}>
                  <CloseSvg style={{ float: "right" }} className="modal_icon" />
                </div>
              </ModalHeader>
              <SelectedComponent
                selectedText={selectedText}
                closeReversedModal={closeReversedModal}
                onReversedClose={onReversedClose}
                openReversedModal={openReversedModal}
                reversedModalOpen={reversedModalOpen}
                isReversedConnectorOpen={isReversedConnectorOpen}
                closeReversedConnector={closeReversedConnector}
                taskId={taskId}
                taskData={taskData}
                setTaskData={setTaskData}
                onClose={onClose}
              />
            </div>
          </Dialog>
        </StyledModal>
      )}
    </>
  );
};

export default ReversedConnectorModal;
