import React from "react";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  StyledDocHeader,
  StyledDocContentFlex,
} from "../styles/Document.Styled";
import Button from "../reusableComponents/Button";
import Search from "../reusableComponents/Search";

import databoatLogo from "../../images/logo.png";

const DocumentHeader = () => {
  const { t } = useTranslation();

  return (
    <StyledDocHeader>
      <StyledDocContentFlex justifyContent>
        <Grid
          container
          xs={3}
          md={3}
          columnSpacing={2}
          alignItems={{
            xs: "left",
            md: "center",
          }}
          direction={{
            xs: "column",
            md: "row",
          }}
        >
          <Grid item>
            <Link to="/docs">
              <img src={databoatLogo} alt="Data boat logo" width={40} />
            </Link>
          </Grid>

          <Grid item>
            <Link to="/docs">
              <p>
                <strong>Databoat</strong> Documentation
              </p>
            </Link>
          </Grid>
        </Grid>

        <Grid
          container
          xs={9}
          md={9}
          justifyContent="space-between"
          alignItems={{
            xs: "left",
            md: "center",
          }}
          direction={{
            xs: "column",
            md: "row",
          }}
        >
          <Grid item>
            <Search className="document-search" />
          </Grid>

          <Grid item justifyContent="right">
            <Link to="/">
              <Button text={t("general.sign_in")} className="go-to-btn" />
            </Link>

            <Link to="https://databoat.nl/">
              <Button text={t("general.sign_up")} className="go-to-btn dark" />
            </Link>
          </Grid>
        </Grid>
      </StyledDocContentFlex>
    </StyledDocHeader>
  );
};

export default DocumentHeader;
