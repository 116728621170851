import React, { useState } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Stack } from "@mui/material";

const ReusableDatePicker = ({ selectedDateRange, handleDateChange }) => {
  // State to manage the visibility of the DatePicker components
  const [showDatePickers, setShowDatePickers] = useState(false);

  // Handle click event to toggle DatePicker visibility
  const handleTextFieldClick = () => {
    setShowDatePickers(true);
  };

  // Handle date change event
  const handleDateChangeInternal = (newValue) => {
    // Update the selected date range
    handleDateChange(newValue);

    // Hide the date pickers if the end date is selected
    // if (newValue[1]) {
    //   setShowDatePickers(false);
    // }
  };

  // Format the selected date range for display
  const formatDateRange = () => {
    if (selectedDateRange[0] && selectedDateRange[1]) {
      return `${selectedDateRange[0].format(
        "MMM D, YYYY",
      )} - ${selectedDateRange[1].format("MMM D, YYYY")}`;
    }
    return "Select Date Range";
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box>
        {!showDatePickers ? (
          <TextField
            value={formatDateRange()}
            onClick={handleTextFieldClick}
            label="Execution date"
            sx={{
              width: 250,
              "& .MuiOutlinedInput-root": {
                borderRadius: "50px",
              },
            }}
            readOnly
          />
        ) : (
          <Stack spacing={2} direction={"row"}>
            <DatePicker
              label="Start Date"
              value={selectedDateRange[0]}
              sx={{
                width: 250,
                "& .MuiOutlinedInput-root": {
                  borderRadius: "50px",
                },
              }}
              onChange={(date) =>
                handleDateChangeInternal([date, selectedDateRange[1]])
              }
            />
            <DatePicker
              label="End Date"
              value={selectedDateRange[1]}
              sx={{
                width: 250,
                "& .MuiOutlinedInput-root": {
                  borderRadius: "50px",
                },
              }}
              onChange={(date) =>
                handleDateChangeInternal([selectedDateRange[0], date])
              }
            />
          </Stack>
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default ReusableDatePicker;
