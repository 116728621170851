import React from "react";
import { ConnectorScreens, StyledDashboardContentFlex } from "../../../index";
import { useTranslation } from "react-i18next";

const SQLServerConnector = ({
  inputData,
  dbDataValues,
  handleSQLServerValuesChange,
  sqlError,
  setQueryDBName,
  queryDatabaseName,
  handlequeryDataBaseName,
  sqlIngestions,
  selectedOption,
  handleSelectChange,
  optionsArray,
  sqlServerdataQueries,
  handleQueriesTableNameChange,
  queries,
  taskId,
  handleQueriesChange,
  checkboxValues,
  handleCheckboxChange,
  partitonColValue,
  handleQueryPartitionChange,
  addMoreSQLServerQueries,
  tableHead,
  tableData,
  handleDatabaseNameChange,
  databaseNameLoader,
  databaseName,
  handleSchemaNameChange,
  schemas,
  handleTableNameChange,
  tables,
  handleTableIngestModeOption,
  tableOptions,
  handleDeltaColumnChange,
  deltaColumn,
  handlePartionChange,
  handlePartitioningChange,
  partitionColumn,
  addRow,
  onClose,
}) => {
  const { t } = useTranslation();

  const useSchemas = true;
  const usesql = true;
  return (
    <StyledDashboardContentFlex column>
      <ConnectorScreens
        HeaderText={t(
          "jobs.form.inputs.task.modal.connectors.server.sql.title",
        )}
        inputData={inputData}
        data={dbDataValues}
        handleChange={handleSQLServerValuesChange}
        errors={sqlError}
        setQueryDBName={setQueryDBName}
        queryDataBaseName={queryDatabaseName}
        handlequeryDataBaseName={handlequeryDataBaseName}
        ingestions={sqlIngestions}
        htmlFor={"Ingestions"}
        selectTitle={"Ingestions"}
        selectedOption={selectedOption}
        handleSelected={handleSelectChange}
        options={optionsArray}
        dataQueries={sqlServerdataQueries}
        handleQueriesTableNameChange={handleQueriesTableNameChange}
        queryValues={queries}
        taskId={taskId}
        handleQueriesChange={handleQueriesChange}
        checkboxValues={checkboxValues}
        handleCheckboxChange={handleCheckboxChange}
        queryPartitionValue={partitonColValue}
        handleQueryPartitionChange={handleQueryPartitionChange}
        handleAddMore={addMoreSQLServerQueries}
        tableHead={tableHead}
        tableData={tableData}
        handleDatabaseNameChange={handleDatabaseNameChange}
        databaseNameLoader={databaseNameLoader}
        databaseName={databaseName}
        handleSchemaNameChange={handleSchemaNameChange}
        useSchemas={useSchemas}
        schemas={schemas}
        usesql={usesql}
        handleTableNameChange={handleTableNameChange}
        tables={tables}
        handleTableIngestModeOption={handleTableIngestModeOption}
        tableOptions={tableOptions}
        handleDeltaColumnChange={handleDeltaColumnChange}
        deltaColumn={deltaColumn}
        handlePartionChange={handlePartionChange}
        handlePartitioningChange={handlePartitioningChange}
        partitionColumn={partitionColumn}
        addRow={addRow}
        btnOnClick={onClose}
        buttonText={t("jobs.form.inputs.task.modal.button")}
      />
    </StyledDashboardContentFlex>
  );
};

export default SQLServerConnector;
