import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  StyledDashboardContentFlex,
  ModalHeader,
  Flex,
  Search,
  CreateJobButton,
  FilteredBox,
  PreLoader,
} from "../../../index";
import { Dialog } from "@mui/material";
import EditDataDictionaryTable from "./EditDataDictionaryTable";
import CloseSvg from "../../../reusableComponents/svg/CloseSvg";
import { useTranslation } from "react-i18next";

const EditDataDictionaryModal = ({ open, handleClose, updateTable }) => {
  const [searchValue, setSearchValue] = useState("");

  const { metaDataColumns, metaDataColumnsLoader } = useSelector(
    (state) => state.metaData,
  );
  const { t } = useTranslation();

  const handleSortByColumnName = () => {
    //sort logic
    console.log("handleSortByColumnName");
  };

  const handleSortByDataType = () => {
    //sort logics
    console.log("handleSortByDataType");
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      PaperProps={{ sx: { borderRadius: "28px", maxWidth: "1000px" } }}
    >
      {metaDataColumnsLoader ? (
        <>
          <ModalHeader>
            <p>
              {metaDataColumns.length}
              {t("data_management.catalog.presenter.table.modals.edit.title")}
            </p>
            <div onClick={handleClose}>
              <CloseSvg />
            </div>
          </ModalHeader>
          <StyledDashboardContentFlex column style={{ padding: "1.5em" }}>
            <Flex>
              <Search
                placeholder={t(
                  "data_management.catalog.presenter.table.modals.edit.placeholder",
                )}
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
              <FilteredBox
                text={t(
                  "data_management.catalog.presenter.table.modals.edit.filters.name",
                )}
                handleSorting={handleSortByColumnName}
              />
              <FilteredBox
                text={t(
                  "data_management.catalog.presenter.table.modals.edit.filters.type",
                )}
                handleSorting={handleSortByDataType}
              />
            </Flex>
            <EditDataDictionaryTable
              rows={metaDataColumns}
              updateTable={updateTable}
            />
            <CreateJobButton
              text={t(
                "data_management.catalog.presenter.table.modals.edit.button",
              )}
              type={"button"}
              onClick={handleClose}
              style={{ width: "fit-content", float: "right" }}
            />
          </StyledDashboardContentFlex>
        </>
      ) : (
        <PreLoader />
      )}
    </Dialog>
  );
};

export default EditDataDictionaryModal;
