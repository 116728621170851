import S3Connector from "./allconnectors/S3Connector";
import MySQLConnector from "./allconnectors/MySQLConnector";
import SQLServerConnector from "./allconnectors/SQLServerConnector";
import PostgresSQL from "./allconnectors/PostgresSQL";
// import CSVConnector from "./allconnectors/CSVConnector";
import APIConnector from "./allconnectors/APIConnector";
import GSC_CONNECTOR from "./allconnectors/GSC_CONNECTOR";
import BigQueryConnector from "./allconnectors/BigQueryConnector";
import OracleConnector from "./allconnectors/OracleConnector";
import ExcatConnector from "./allconnectors/ExcatConnector";
import ActiveCampaignConnector from "./allconnectors/ActiveCampaignConnector";

import PipedriveReversedSyncConnector from "./reversedSync/PipedriveConnector";
import PipedriveConnector from "./allconnectors/PipedriveConnector";

export const iconsListItems = [
  {
    id: "1",
    image: "s3.png",
    component: <S3Connector />,
    shortText: true,
    header: "S3 Connector",
    text: "S3",
  },
  {
    id: "2",
    image: "mysql.jpeg",
    component: <MySQLConnector />,
    header: "MySQL",
    text: "MySQL",
  },
  {
    id: "3",
    image: "sql.png",
    component: <SQLServerConnector />,
    header: "SQL Server",
    text: "SQL Server",
  },
  {
    id: "4",
    image: "postgress.png",
    component: <PostgresSQL />,
    header: "Postgres SQL",
    longText: true,
    text: "Postgres SQL",
  },
  {
    id: "5",
    image: "gcs.png",
    component: <GSC_CONNECTOR />,
    shortText: true,
    header: "GCS Connector",
    text: "GCS",
  },
  {
    id: "6",
    image: "api_icon.png",
    component: <APIConnector />,
    text: "API",
    newFeature: true,
  },
  // {
  //   id: "7",
  //   image: "csv.jpeg",
  //   component: <CSVConnector />,
  //   shortText: false,
  //   text: "CSV",
  // },
  {
    id: "7",
    image: "bigquery.png",
    component: <BigQueryConnector />,
    shortText: false,
    text: "Big Query",
  },
  // {
  //   id: "8",
  //   image: "kafka.jpeg",
  //   component: "",
  //   text: "Kafka",
  // },
  {
    id: "9",
    image: "oracle.svg",
    component: <OracleConnector />,
    shortText: false,
    text: "Oracle",
  },
  {
    id: "10",
    image: "excat.png",
    component: <ExcatConnector />,
    shortText: true,
    header: "Exact Online",
    text: "Exact Online",
  },
  {
    id: "11",
    image: "pipedrive.png",
    component: <PipedriveConnector />,
    shortText: true,
    header: "PipeDrive",
    text: "PipeDrive",
  },
  {
    id: "12",
    image: "active-campaing.png",
    component: <ActiveCampaignConnector />,
    shortText: true,
    header: "ActiveCampaign",
    text: "ActiveCampaign",
  },
];

export const reversedSyncListItems = [
  {
    id: "1",
    image: "pipedrive.png",
    component: <PipedriveReversedSyncConnector />,
    shortText: true,
    header: "PipeDrive",
    text: "PipeDrive",
  }
];
