import { Button } from "@mui/material";
import styled from "styled-components";
import Theme from "../Theme";

export const StyledTrialHasEndedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff;

  h1 {
    font-weight: normal;
    margin-bottom: 20px;
  }

  p {
    font-weight: 300;
    margin-bottom: 20px;
  }

  p.questions {
    font-size: 14px;
    margin-top: 20px;
    opacity: 0.7;

    a {
      color: ${Theme.colors.blueColor};
      text-decoration: none;
    }
  }

  a {
    opacity: 1;
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin: 20px;
`;

export const StyledButton = styled(Button)(({ theme }) => ({
  "&.MuiButton-outlinedPrimary": {
    borderColor: Theme.colors.blueColor,
    color: Theme.colors.blueColor,
  },
  "&.MuiButton-containedPrimary": {
    backgroundColor: Theme.colors.blueColor,
    boxShadow: "none",
  },
}));
