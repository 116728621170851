/* eslint-disable no-unused-vars */
import { useState } from "react";
import { axiosGetTable } from "../../../../clientRequest/axiosRequest";
import { fetchDatabaseName } from "../../../redux/actions/usersActions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const useOracle = (token, taskData, setTaskData, setOracleIngestions) => {
  const dispatch = useDispatch();
  const { environmentVariables } = useSelector((state) => state.users);
  const baseURL = `${environmentVariables.REACT_APP_TABLE_API}/yeda/database-metadata/oracle`;
  const [oraclequeries, setoracleQueries] = useState([]);
  const [oraclecheckboxValues, setoracleCheckboxValues] = useState([false]);
  const [oracleParams, setoracleParamsValue] = useState([
    {
      host: "",
      port: "",
      user: "",
      password: "",
      service_name: "",
      id: 1,
    },
  ]);

  const { t } = useTranslation();

  const oracleDataValues = [
    {
      id: 1,
      name: "host",
      title: "Host",
      CheckBox: false,
      defaultValue: "",
      required: true,
    },
    {
      id: 2,
      name: "port",
      title: "Port",
      CheckBox: false,
      defaultValue: "",
      required: true,
    },
    {
      id: 3,
      name: "user",
      title: t(
        "jobs.form.inputs.task.modal.connectors.server.hook.api_data.three",
      ),
      CheckBox: false,
      defaultValue: "",
      required: true,
    },
    {
      id: 4,
      name: "password",
      title: t(
        "jobs.form.inputs.task.modal.connectors.server.hook.api_data.four",
      ),
      CheckBox: false,
      password: true,
      defaultValue: "",
      required: true,
    },
    {
      id: 5,
      name: "service_name",
      title: "Service Name",
      CheckBox: false,
      defaultValue: "",
      required: true,
    },
  ];
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const group = localStorage.getItem("group");
  const [oracleselectedOption, setoracleSelectedOption] = useState("");
  const oracleoptionsArray = [
    {
      id: 1,
      options: "",
      title: "Select",
    },
    {
      id: 2,
      options: "query",
      title: "Query",
    },
    {
      id: 3,
      options: "tables",
      title: "Table(s)",
    },
  ];
  const handleoracleServerValuesChange = (event, id) => {
    const { name, value } = event.target;
    oracleParams.map((data) => {
      if (data.id === id) {
        switch (name) {
          case "host":
            data.host = value;
            break;
          case "port":
            data.port = value;
            break;
          case "user":
            data.user = value;
            break;
          case "password":
            data.password = value;
            break;
          case "service_name":
            data.service_name = value;
            break;
          default:
            console.log("error");
        }
      }
      return data;
    });
    // Check if all values in oracleParams are filled
    const allValuesFilled = oracleParams.every(
      (data) =>
        data.host &&
        data.port &&
        data.user &&
        data.password &&
        data.service_name,
    );

    setOracleIngestions(allValuesFilled);
    const currentoracleServerInput = oracleParams.find(
      (input) => input.id === id,
    );
    taskData.map((data) => {
      if (data.id === id) {
        data.params.input_db_host = currentoracleServerInput.host;
        data.params.input_db_port = currentoracleServerInput.port;
        data.params.input_db_user = currentoracleServerInput.user;
        data.params.input_db_pass = currentoracleServerInput.password;
        data.params.query_db_name = currentoracleServerInput.service_name;
        data.params.destination_bucket = `databoat-raw-${group}`;
        data.source_type = "oracleql";
      }
      return data;
    });
  };

  const [oracleError, setoracleError] = useState("");
  const handleoracleSelectChange = async (event, taskId) => {
    const selected = event.target.value;
    const updatedData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.selected = selected;
      }
      return data;
    });

    setTaskData(updatedData);
    setoracleSelectedOption(selected);
    const currentDbParam = oracleParams.find((data) => data.id === taskId);
    const validateForm = () => {
      const errors = [];
      if (!currentDbParam.host.trim()) {
        errors.host = "Host is required";
      }
      if (!currentDbParam.user.trim()) {
        errors.user = "User is required";
      }
      if (!currentDbParam.password.trim()) {
        errors.password = "Password is required";
      }
      if (!currentDbParam.service_name.trim()) {
        errors.password = "service name is required";
      }
      setoracleError(errors);
      return Object.keys(errors).length === 0;
    };
    if (validateForm()) {
      setoracleError("");
      if (selected === "tables") {
        const formData = {
          user: currentDbParam.user,
          password: currentDbParam.password,
          server: currentDbParam.host,
          service_name: currentDbParam.service_name,
          port: currentDbParam.port,
        };
        await fetchoracleSchemas(taskId, formData);
      }
    } else {
      setoracleError("Please fill all required fields");
    }
  };
  const [oracleServerdataQueries, setoracleServerDataQueries] = useState(() => [
    {
      id: 1,
      query: null,
      table_name: null,
      partitioning: false,
      partition_columns: null,
    },
  ]);

  // Function to handle adding more rows
  const addMoreoracleServerQueries = (id) => {
    setoracleServerDataQueries((previousQueryData) => {
      return [
        ...previousQueryData,
        {
          id: previousQueryData.length + 1,
          query: null,
          table_name: null,
          partitioning: false,
          partition_columns: null,
        },
      ];
    });
  };

  const handleoracleQueriesChange = (event, id, taskId) => {
    const selected = event.target.value;
    const updatedData = oracleServerdataQueries.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          query: selected,
        };
      }
      return data;
    });

    setoracleServerDataQueries(updatedData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.queries = updatedData;
      }
      return data;
    });

    setTaskData(updatedTaskData);
  };
  const handleoracleQueriesTableNameChange = (event, id, taskId) => {
    const selected = event.target.value;
    const updatedData = oracleServerdataQueries.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          table_name: selected,
        };
      }
      return data;
    });

    setoracleServerDataQueries(updatedData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.queries.map((item) => {
          if (item.id === id) {
            item.table_name = selected;
            return item;
          }
          return item;
        });
      }
      return data;
    });

    setTaskData(updatedTaskData);
  };
  const handleoracleCheckboxChange = (event, id, taskId) => {
    let selected = event.target.value;
    if (selected === "on") {
      selected = true;
    } else {
      selected = false;
    }
    const updatedData = oracleServerdataQueries.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          partitioning: selected,
        };
      }
      return data;
    });
    setoracleServerDataQueries(updatedData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.queries.map((item) => {
          item.partitioning = selected;
          return item;
        });
      }
      return data;
    });

    setTaskData(updatedTaskData);
  };
  const handleoracleQueryPartitionChange = (event, id, taskId) => {
    const selected = event.target.value.split(",");
    const updatedQuery = oracleServerdataQueries.map((data) => {
      if (data.id === id) {
        data.partition_columns = selected;
      }
      return data;
    });
    setoracleServerDataQueries(updatedQuery);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.queries.map((item) => {
          if (item.id === id) {
            item.partition_columns = selected;
            return item;
          }
          return item;
        });
      }
      return data;
    });

    setTaskData(updatedTaskData);
  };
  //-----------------------------------------oracleSERVER TABLES
  const [oracleisChecked, setoracleIsChecked] = useState();
  const [oracleloadType, setoracleLoadType] = useState("");

  const oracletableHead = [
    { id: 1, title: "Schema" },
    { id: 2, title: "Table" },
    { id: 3, title: "Ingestion Mode" },
    oracleloadType === "delta" && { id: 4, title: "Delta_Column" },
    { id: 5, title: "Partition" },
    oracleisChecked && { id: 6, title: "Partition_Column" },
  ].filter(Boolean);
  const [oracletableData, setoracleTableData] = useState(() => [
    {
      id: 1,
      input_db_name: null,
      input_db_schema: null,
      input_table_name: null,
      load_type: null,
      partitioning: false,
      isDelta: false,
      delta_column: null,
      partition_columns: null,
      tableSchemasLoader: false,
      listTablesLoader: false,
      deltaColumnDataLoader: false,
      partitionColumnDataLoader: false,
    },
  ]);

  // Function to handle adding more rows
  const oracleaddRow = () => {
    setoracleTableData((previousTableData) => {
      return [
        ...previousTableData,
        {
          id: previousTableData.length + 1,
          input_db_name: null,
          input_db_schema: null,
          input_table_name: null,
          load_type: null,
          partitioning: false,
          isDelta: false,
          delta_column: null,
          partition_columns: null,
          tableSchemasLoader: false,
          listTablesLoader: false,
          deltaColumnDataLoader: false,
          partitionColumnDataLoader: false,
        },
      ];
    });
    setoracleSchemas((previousSchemaData) => {
      return [
        ...previousSchemaData,
        {
          id: previousSchemaData.length + 1,
          schema_names: [],
        },
      ];
    });
    setoracleTables((previousTableNameData) => {
      return [
        ...previousTableNameData,
        {
          id: previousTableNameData.length + 1,
          table_names: [],
        },
      ];
    });
    setoracleDeltaColumn((previousdeltaData) => {
      return [
        ...previousdeltaData,
        {
          id: previousdeltaData.length + 1,
          COLUMN_NAMES: [],
        },
      ];
    });
    setoraclePartitionColumn((previousPartitioneData) => {
      return [
        ...previousPartitioneData,
        {
          id: previousPartitioneData.length + 1,
          COLUMN_NAMES: [],
        },
      ];
    });
  };
  const oracletableOptions = [
    { id: 1, option: "", title: "Select" },
    { id: 2, option: "full", title: "Full" },
    { id: 3, option: "delta", title: "Delta" },
  ];

  const [oracleschemas, setoracleSchemas] = useState(() => [
    {
      id: 1,
      schema_names: [],
    },
  ]);
  const [oracletables, setoracleTables] = useState(() => [
    {
      id: 1,
      table_names: [],
    },
  ]);
  const [oracledeltaColumn, setoracleDeltaColumn] = useState(() => [
    {
      id: 1,
      COLUMN_NAMES: [],
    },
  ]);
  const [oraclepartitionColumn, setoraclePartitionColumn] = useState(() => [
    {
      id: 1,
      COLUMN_NAMES: [],
    },
  ]);

  const handleoracleSchemaNameChange = async (event, id, taskId) => {
    const selected = event.target.value;
    if (selected) {
      const currentDbParam = oracleParams.find((data) => data.id === taskId);
      await fetchoracleTableName(
        selected,
        id,
        currentDbParam.user,
        currentDbParam.password,
        currentDbParam.host,
        currentDbParam.port,
      );
    }
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        const existingTableIndex = data.params.tables.findIndex(
          (item) => item.id === id,
        );

        if (existingTableIndex !== -1) {
          // Table with the given id exists, update it
          data.params.tables[existingTableIndex].input_db_schema = selected;
        } else {
          // Table with the given id doesn't exist, create a new one
          data.params.tables.push({
            id: id,
            input_db_schema: selected,
          });
        }
      }
      return data;
    });

    setTaskData(updatedTaskData);
  };

  const handleoracleTableNameChange = (event, id, taskId) => {
    const selected = event.target.value;
    const updatedData = oracletableData.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          input_table_name: selected,
        };
      }
      return data;
    });
    setoracleTableData(updatedData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        const existingTableIndex = data.params.tables.findIndex(
          (item) => item.id === id,
        );

        if (existingTableIndex !== -1) {
          // Table with the given id exists, update it
          data.params.tables[existingTableIndex].input_table_name = selected;
        } else {
          // Table with the given id doesn't exist, create a new one
          data.params.tables.push({
            id: id,
            input_table_name: selected,
          });
        }
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };
  const handleoracleTableIngestModeOption = async (event, id, taskId) => {
    const selectedValue = event.target.value;
    if (selectedValue) {
      const currentDbParam = oracleParams.find((data) => data.id === taskId);
      await fetchoracleDeltaColumn(
        selectedValue,
        id,
        currentDbParam.user,
        currentDbParam.password,
        currentDbParam.host,
        currentDbParam.port,
      );
    }
    setoracleLoadType(selectedValue);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        const existingTableIndex = data.params.tables.findIndex(
          (item) => item.id === id,
        );

        if (existingTableIndex !== -1) {
          // Table with the given id exists, update it
          data.params.tables[existingTableIndex].load_type = selectedValue;
          data.params.tables[existingTableIndex].isDelta =
            selectedValue === "delta";
        } else {
          // Table with the given id doesn't exist, create a new one
          data.params.tables.push({
            id: id,
            load_type: selectedValue,
            isDelta: selectedValue === "delta",
          });
        }
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };
  const handleoracleDeltaColumnChange = (event, id, taskId) => {
    const updatedData = oracletableData.map((data) => {
      if (data.id === id) {
        return { ...data, delta_column: event.target.value };
      }
      return data;
    });
    setoracleTableData(updatedData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        const existingTableIndex = data.params.tables.findIndex(
          (item) => item.id === id,
        );

        if (existingTableIndex !== -1) {
          // Table with the given id exists, update it
          data.params.tables[existingTableIndex].delta_column =
            event.target.value;
        } else {
          // Table with the given id doesn't exist, create a new one
          data.params.tables.push({
            id: id,
            delta_column: event.target.value,
          });
        }
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };
  const handleoraclePartionChange = async (event, id, taskId) => {
    let selected = event.target.value;
    if (selected === "on") {
      selected = true;
    }
    if (selected) {
      const currentDbParam = oracleParams.find((data) => data.id === taskId);
      await fetchoraclePartitionColumn(
        selected,
        id,
        currentDbParam.user,
        currentDbParam.password,
        currentDbParam.host,
        currentDbParam.port,
      );
    }
    setoracleIsChecked(selected);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        const existingTableIndex = data.params.tables.findIndex(
          (item) => item.id === id,
        );

        if (existingTableIndex !== -1) {
          // Table with the given id exists, update it
          data.params.tables[existingTableIndex].partitioning = selected;
        } else {
          // Table with the given id doesn't exist, create a new one
          data.params.tables.push({
            id: id,
            partitioning: selected,
          });
        }
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };

  const handleoraclePartitioningChange = (options, id, taskId) => {
    const partion_column = options.map((value) => value.value);
    const updatedData = oracletableData.map((data) => {
      if (data.id === id) {
        return { ...data, partition_columns: partion_column };
      }
      return data;
    });
    setoracleTableData(updatedData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        const existingTableIndex = data.params.tables.findIndex(
          (item) => item.id === id,
        );

        if (existingTableIndex !== -1) {
          // Table with the given id exists, update it
          data.params.tables[existingTableIndex].partition_columns =
            partion_column;
        } else {
          // Table with the given id doesn't exist, create a new one
          data.params.tables.push({
            id: id,
            partition_columns: partion_column,
          });
        }
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };

  const fetchoracleSchemas = async (id, formData) => {
    try {
      const response = await axiosGetTable(baseURL).post(
        "/list-schemas",
        formData,
        { headers },
      );
      const updatedData = oracletableData.map((data) => {
        if (data.id === id)
          return {
            ...data,
            input_db_name: formData.service_name,
            tableSchemasLoader: true,
          };
        return data;
      });
      setoracleTableData(updatedData);

      const updatedSchema = oracleschemas.map((schemas) => {
        if (schemas.id === id)
          return { ...schemas, schema_names: response.data };
        return schemas;
      });
      setoracleSchemas(updatedSchema);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchoracleTableName = async (
    selectedSchemaName,
    id,
    user,
    password,
    host,
    port,
  ) => {
    let selectedDbName = "";

    oracletableData.map((data) => {
      if (data.id === id) {
        selectedDbName = data.input_db_name;
      }
      return data;
    });
    const formData = {
      user: user,
      password: password,
      port: port,
      server: host,
      service_name: selectedDbName,
      schema: selectedSchemaName,
    };
    try {
      const response = await axiosGetTable(baseURL).post(
        "/list-tables",
        formData,
        { headers },
      );
      const updatedData = oracletableData.map((data) => {
        if (data.id === id)
          return {
            ...data,
            input_db_schema: selectedSchemaName,
            listTablesLoader: true,
          };
        return data;
      });

      setoracleTableData(updatedData);
      const updatedTable = oracletables.map((tables) => {
        if (tables.id === id) return { ...tables, table_names: response.data };
        return tables;
      });

      setoracleTables(updatedTable);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchoracleDeltaColumn = async (
    selectedValue,
    id,
    user,
    password,
    host,
    port,
  ) => {
    let selectedDbName = "";
    let selectedSchemaName = "";
    let selectedTableName = "";

    oracletableData.map((data) => {
      if (data.id === id) {
        selectedDbName = data.input_db_name;
        selectedSchemaName = data.input_db_schema;
        selectedTableName = data.input_table_name;
      }
      return data;
    });
    const formData = {
      user: user,
      password: password,
      server: host,
      port: port,
      service_name: selectedDbName,
      schema: selectedSchemaName,
      table: selectedTableName,
    };
    try {
      const response = await axiosGetTable(baseURL).post(
        "/list-date",
        formData,
        { headers },
      );
      const updatedData = oracletableData.map((data) => {
        if (data.id === id) {
          return {
            ...data,
            load_type: selectedValue,
            isDelta: selectedValue === "delta",
            deltaColumnDataLoader: true,
          };
        }
        return data;
      });
      setoracleTableData(updatedData);
      const updatedDeltaColumn = oracledeltaColumn.map((columns) => {
        if (columns.id === id)
          return { ...columns, COLUMN_NAMES: response.data };
        return columns;
      });
      setoracleDeltaColumn(updatedDeltaColumn);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchoraclePartitionColumn = async (
    selected,
    id,
    user,
    password,
    host,
    port,
  ) => {
    let selectedDbName = "";
    let selectedSchemaName = "";
    let selectedTableName = "";

    oracletableData.map((data) => {
      if (data.id === id) {
        selectedDbName = data.input_db_name;
        selectedSchemaName = data.input_db_schema;
        selectedTableName = data.input_table_name;
      }
      return data;
    });
    const formData = {
      user: user,
      password: password,
      server: host,
      port: port,
      service_name: selectedDbName,
      schema: selectedSchemaName,
      table: selectedTableName,
    };
    try {
      const response = await axiosGetTable(baseURL).post(
        "/list-columns",
        formData,
        { headers },
      );
      const updatedData = oracletableData.map((data) => {
        if (data.id === id) {
          return {
            ...data,
            partitioning: selected,
            partitionColumnDataLoader: true,
          };
        }
        return data;
      });
      setoracleTableData(updatedData);

      const updatedPartitonColumn = oraclepartitionColumn.map((columns) => {
        if (columns.id === id)
          return { ...columns, COLUMN_NAMES: response.data };
        return columns;
      });
      setoraclePartitionColumn(updatedPartitonColumn);
    } catch (error) {
      console.log(error);
    }
  };
  const [oraclepartitonColValue, setoraclePartitonColValue] = useState("");
  //  ------------------------ends
  return [
    oracleDataValues,
    setoracleParamsValue,
    handleoracleServerValuesChange,
    oracleError,
    oracleselectedOption,
    handleoracleSelectChange,
    oracleoptionsArray,
    oracleServerdataQueries,
    handleoracleQueriesTableNameChange,
    oraclequeries,
    handleoracleQueriesChange,
    oraclecheckboxValues,
    handleoracleCheckboxChange,
    oraclepartitonColValue,
    handleoracleQueryPartitionChange,
    addMoreoracleServerQueries,
    oracletableHead,
    oracletableData,
    handleoracleSchemaNameChange,
    oracleschemas,
    handleoracleTableNameChange,
    oracletables,
    handleoracleTableIngestModeOption,
    oracletableOptions,
    handleoracleDeltaColumnChange,
    oracledeltaColumn,
    handleoraclePartionChange,
    handleoraclePartitioningChange,
    oraclepartitionColumn,
    oracleaddRow,
  ];
};

export default useOracle;
