const initialState = {
  allCustomers: null,
  allCustomersLoader: false,
  allCustomersError: null,
  addNewUser: null,
  addNewUserLoader: false,
  addNewUserError: null,
  deleteUserScript: null,
  deleteUserScriptLoader: false,
  deleteUserScriptError: null,
  deleteUser: null,
  deleteUserLoader: false,
  deleteUserError: null,
  isUserEditing: null,
  isUserEditingLoader: false,
  isUserEditingError: null,
  userScript: null,
  userScriptLoader: false,
  userScriptError: null,
  addUserScript: null,
  addUserScriptLoader: false,
  addUserScriptError: null,
};
const customersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CUSTOMERS_LOADING":
      return {
        ...state,
        allCustomersLoader: false,
      };
    case "GET_CUSTOMERS_SUCCESS":
      return {
        ...state,
        allCustomers: action.payload,
        allCustomersLoader: true,
      };
    case "GET_CUSTOMERS_ERROR":
      return {
        ...state,
        allCustomersLoader: false,
        allCustomersError: action.payload,
      };

    case "POST_USERS_LOADING":
      return {
        ...state,
        addNewUserLoader: false,
      };
    case "POST_USERS_SUCCESS":
      return {
        ...state,
        allCustomers: [...state.allCustomers, action.payload],
        addNewUser: action.payload,
        addNewUserLoader: true,
      };
    case "POST_USERS_ERROR":
      return {
        ...state,
        addNewUserLoader: false,
        addNewUserError: action.payload,
      };
    case "CLEAR_POST_USER_SUCCESS":
      return {
        addNewUser: null,
      };

    case "DELETE_USERS_LOADING":
      return {
        ...state,
        deleteUserLoader: false,
      };
    case "DELETE_USERS_SUCCESS":
      return {
        ...state,
        allCustomers: [...state.allCustomers, action.payload.updatedItem],
        deleteUser: action.payload,
        deleteUserLoader: true,
      };
    case "DELETE_USERS_ERROR":
      return {
        ...state,
        deleteUserLoader: false,
      };
    case "CLEAR_DELETE_USER_SUCCESS":
      return {
        deleteUser: null,
      };

    case "PUT_USERS_LOADING":
      return {
        ...state,
        isUserEditingLoader: false,
      };
    case "PUT_USERS_SUCCESS":
      return {
        ...state,
        allCustomers: [...state.allCustomers, action.payload],
        isUserEditing: action.payload,
        isUserEditingLoader: true,
      };
    case "PUT_USERS_ERROR":
      return {
        ...state,
        isUserEditingLoader: false,
        isUserEditingError: action.payload,
      };
    case "CLEAR_UPDATE_USER_SUCCESS":
      return {
        isUserEditing: null,
      };
    case "GET_USER_SCRIPTS_LOADING":
      return {
        ...state,
        userScriptLoader: false,
      };
    case "GET_USER_SCRIPTS_SUCCESS":
      return {
        ...state,
        userScript: action.payload,
        userScriptLoader: true,
      };
    case "GET_USER_SCRIPTS_ERROR":
      return {
        ...state,
        userScriptLoader: false,
        userScriptError: action.payload,
      };
    case "DELETE_USER_SCRIPTS_LOADING":
      return {
        ...state,
        deleteUserScriptLoader: false,
      };
    case "DELETE_USER_SCRIPTS_SUCCESS":
      return {
        ...state,
        userScript: [...state.userScript],
        deleteUserScript: action.payload,
        deleteUserScriptLoader: true,
      };
    case "DELETE_USER_SCRIPTS_ERROR":
      return {
        ...state,
        deleteUserScriptLoader: false,
      };
    case "CLEAR_DELETE_USER_SCRIPTS_SUCCESS":
      return {
        deleteUserScript: null,
      };
    case "POST_USER_SCRIPTS_LOADING":
      return {
        ...state,
        addUserScriptLoader: false,
      };
    case "POST_USER_SCRIPTS_SUCCESS":
      return {
        ...state,
        userScript: [...state.userScript],
        addUserScript: action.payload,
        addUserScriptLoader: true,
      };
    case "POST_USER_SCRIPTS_ERROR":
      return {
        ...state,
        addUserScriptLoader: false,
      };
    case "CLEAR_ADD_USER_SCRIPTS_SUCCESS":
      return {
        addUserScript: null,
      };
    default:
      return state;
  }
};

export default customersReducer;
