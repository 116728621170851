import { StyledSideBar } from "../../index";
import databoatLogo from "../../../images/mainlogo.png";

const SideBar = () => {
  const styles = {
    container: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
    },
  };

  return (
    <StyledSideBar>
      <div style={styles.container}>
        <img src={databoatLogo} alt={"databoat"} />
      </div>
    </StyledSideBar>
  );
};
export default SideBar;
