import React from "react";
import { StyledPortalContent, H3 } from "../index";

const MLOpsFlowOverview = () => {
  return (
    <StyledPortalContent>
      <H3>MLOpsFlow</H3>
    </StyledPortalContent>
  );
};

export default MLOpsFlowOverview;
