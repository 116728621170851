import React from "react";
import { DashBoardScreen, StyledPortalContent } from "../../index";

const JobDashBoard = () => {
  return (
    <DashBoardScreen
      children={
        <StyledPortalContent>
          <p>Job Dashboard</p>
        </StyledPortalContent>
      }
    />
  );
};

export default JobDashBoard;
