import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import filesize from "filesize";
import {
  DragandDrop,
  StyledBackground,
  StyledDashboardContentFlex,
  Flex,
  StyledP,
  AiOutlineClose,
} from "../../index";
import CsvConverter from "./CsvConverter";
import UploadMoreModal from "./UploadMoreModal";

const UploadContent = ({
  files,
  errMsg,
  csvData,
  handleRemove,
  fileRejections,
  getRootProps,
  isDragActive,
  getInputProps,
  errRef,
  handlePostCSVData,
  GCPSuccessMessage,
}) => {
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [isUploadingMore, setIsUploadingMore] = useState(false);

  useEffect(() => {
    if (GCPSuccessMessage !== "") {
      setModalOpen(true);
    }
  }, [GCPSuccessMessage]);

  const handleOnCloseModal = (wantMoreUploads) => {
    setModalOpen(false);
    if (!wantMoreUploads) {
      navigate("/transformation");
    } else {
      setIsUploadingMore(true);
    }
  };

  const allFiles =
    files.length !== 0 &&
    files.map((file) => (
      <StyledBackground key={file.path}>
        <StyledDashboardContentFlex justifyContent>
          <Flex column>
            <StyledP>{file.name}</StyledP>
            <p style={{ fontSize: "0.75em" }}>{file.size}</p>
          </Flex>
          <AiOutlineClose
            className="modal_icon"
            onClick={() => handleRemove(file.name)}
          />
        </StyledDashboardContentFlex>
      </StyledBackground>
    ));
  return (
    <>
      {files.length === 0 || isUploadingMore ? (
        <div style={{ maxWidth: "60%" }}>
          <DragandDrop
            fileRejections={fileRejections}
            getRootProps={getRootProps}
            isDragActive={isDragActive}
            getInputProps={getInputProps}
            errMsg={errMsg}
            errRef={errRef}
          />
        </div>
      ) : (
        <StyledDashboardContentFlex column>
          <Flex column>{allFiles}</Flex>
          <Flex column>
            <CsvConverter csvData={csvData} />
          </Flex>
          <StyledDashboardContentFlex justifyContent>
            <div>
              <button
                className={"next_btn save_btn"}
                onClick={handlePostCSVData}
              >
                Upload
              </button>
            </div>
          </StyledDashboardContentFlex>
        </StyledDashboardContentFlex>
      )}
      {modalOpen && (
        <UploadMoreModal isOpen={modalOpen} onClose={handleOnCloseModal} />
      )}
    </>
  );
};

export default UploadContent;
