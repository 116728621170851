import { useState } from "react";
import { useTranslation } from "react-i18next";
const useAPI = (taskData, setTaskData) => {
  const apiHttp = useState(true);
  const renderKeyValue = useState(true);
  const [httpvalue, setHttpValue] = useState("");
  const group = localStorage.getItem("group");
  const [apiInputValue, setAPIInputValue] = useState([
    {
      api_url: "",
      api_client_id: "",
      api_client_secret: "",
      api_header_param: "",
      api_table_name: "",
      api_oauth_flag: "",
      id: 1,
    },
  ]);

  const { t } = useTranslation();

  const ApiData = [
    {
      id: 1,
      name: "api_url",
      title: `${t(
        "jobs.form.inputs.task.modal.connectors.api.hook.api_data.one",
      )} http://example.com`,
      checkbox: false,
      defaultValue: "",
      required: true,
      placeholder: "e.g http://example.com",
    },
    {
      id: 2,
      name: "api_client_id",
      title: t("jobs.form.inputs.task.modal.connectors.api.hook.api_data.two"),
      checkbox: false,
      defaultValue: "",
      required: true,
    },
    {
      id: 3,
      name: "api_client_secret",
      title: t(
        "jobs.form.inputs.task.modal.connectors.api.hook.api_data.three",
      ),
      checkbox: false,
      password: true,
      defaultValue: "",
      required: true,
    },
    {
      id: 4,
      name: "api_table_name",
      title: t("jobs.form.inputs.task.modal.connectors.api.hook.api_data.four"),
      checkbox: false,
      password: false,
      defaultValue: "",
      required: true,
    },
    {
      id: 5,
      name: "api_oauth_flag",
      title: t("jobs.form.inputs.task.modal.connectors.api.hook.api_data.five"),
      checkbox: true,
      defaultValue: "",
      required: true,
    },
  ];
  const handleAPIChange = (event, id) => {
    const { name, value, checked } = event.target;
    apiInputValue.map((data) => {
      if (data.id === id) {
        switch (name) {
          case "api_url":
            data.api_url = value;
            break;
          case "api_client_id":
            data.api_client_id = value;
            break;
          case "api_client_secret":
            data.api_client_secret = value;
            break;
          case "api_table_name":
            data.api_table_name = value;
            break;
          case "api_oauth_flag":
            data.api_oauth_flag = checked;
            break;
          default:
            console.log("error");
        }
      }
      return data;
    });

    const currentInput = apiInputValue.find((input) => input.id === id);

    taskData.map((data) => {
      if (data.id === id) {
        data.params.api_url = currentInput.api_url;
        data.params.api_client_id = currentInput.api_client_id;
        data.params.api_client_secret = currentInput.api_client_secret;
        data.params.table_name = currentInput.api_table_name;
        data.params.destination_bucket = `databoat-raw-${group}`;
        data.params.api_oauth_flag = currentInput.api_oauth_flag.toString();
        data.source_type = "api";
      }
      return data;
    });
  };

  const httpData = [
    { id: 1, option: "", title: "Select" },
    { id: 2, title: "GET", option: "GET" },
    { id: 3, title: "POST", option: "POST" },
    { id: 4, title: "PATCH", option: "PATCH" },
    { id: 5, title: "PUT", option: "PUT" },
    { id: 6, title: "DELETE", option: "DELETE" },
  ];
  const handleHttpChange = (event, id) => {
    const selected = event.target.value;
    const updatedData = taskData.map((data) => {
      if (data.id === id) {
        data.params.api_http_method = selected;
      }
      return data;
    });
    setTaskData(updatedData);
    setHttpValue(selected);
  };

  const [apiKey, setApiKey] = useState();

  const apihandleKeyChange = (event, id) => {
    const selected = event.target.value;
    setApiKey(selected);
  };
  const apihandleValueChange = (event, id) => {
    const selected = event.target.value;
    const updatedTaskData = taskData.map((data) => {
      if (data.id === id) {
        data.params.api_header_param = { [apiKey]: selected };
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };
  return [
    ApiData,
    setAPIInputValue,
    handleAPIChange,
    apiHttp,
    httpData,
    httpvalue,
    handleHttpChange,
    renderKeyValue,
    apihandleKeyChange,
    apihandleValueChange,
  ];
};

export default useAPI;
