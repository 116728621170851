import React, { useEffect, useState } from "react";
import {
  StyledAvatarContainer,
  StyledAvatarModal,
  StyledRow,
  StyledItem,
  StyledItemText,
} from "../styles/Styled";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Theme from "../Theme";

const Avatar = ({ src, alt, name }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEngLang, setIsEngLang] = useState(true);
  const [isDutchLang, setIsDutchLang] = useState(false);

  const location = useLocation();

  const { t } = useTranslation();

  const lng = localStorage.getItem("lng");

  useEffect(() => {
    if (lng === "nl") {
      setIsEngLang(false);
      setIsDutchLang(true);
    } else {
      setIsEngLang(true);
      setIsDutchLang(false);
    }
  }, [lng]);

  const handleAvatarClick = () => {
    setIsModalOpen((state) => !state);
  };

  const handleMouseEnter = () => {
    setIsModalOpen(true);
  };

  const handleMouseLeave = () => {
    setIsModalOpen(false);
  };

  const handleEngLangSelected = () => {
    setIsEngLang(true);
    setIsDutchLang(false);
    localStorage.setItem("lng", "en");
  };

  const handleDutchLangSelected = () => {
    setIsEngLang(false);
    setIsDutchLang(true);
    localStorage.setItem("lng", "nl");
  };

  return (
    <div style={{ position: "relative" }}>
      <StyledAvatarContainer onClick={handleAvatarClick}>
        {src ? (
          <img
            src={src}
            alt={alt}
            style={{ width: "100%", height: "100%", borderRadius: "50%" }}
          />
        ) : (
          <p
            style={{
              fontFamily: Theme.fonts.primaryFont,
              fontWeight: "500",
              color: Theme.colors.primaryColor,
            }}
          >
            {name.charAt(0).toUpperCase()}
          </p>
        )}
      </StyledAvatarContainer>
      {isModalOpen && (
        <StyledAvatarModal
          visible={isModalOpen}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <StyledRow>
            <StyledItem
              href={`${location.pathname}`}
              selected={isEngLang}
              onClick={handleEngLangSelected}
            >
              <ReactCountryFlag countryCode="US" svg />
              <StyledItemText>
                {t("header.avatar_native_lang_en")}
              </StyledItemText>
            </StyledItem>
            <StyledItem
              href={`${location.pathname}`}
              selected={isDutchLang}
              onClick={handleDutchLangSelected}
            >
              <ReactCountryFlag countryCode="NL" svg title="Dutch" />
              <StyledItemText>
                {t("header.avatar_native_lang_nl")}
              </StyledItemText>
            </StyledItem>
          </StyledRow>
        </StyledAvatarModal>
      )}
    </div>
  );
};

export default Avatar;
