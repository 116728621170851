import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Skeleton from "@mui/material/Skeleton";

const MuiSelect = ({
  inputLabel,
  items,
  isLoading = false,
  disabled = false,
  selectedValue,
  handleChange,
}) => {
  if (!Array.isArray(items)) {
    items = [];
  }

  return (
    <>
      {isLoading ? (
        <Skeleton variant="rectangular" width={"auto"} height={55} />
      ) : (
        <FormControl fullWidth disabled={disabled}>
          <InputLabel id="simple-select-autowidth-label">
            {inputLabel}
          </InputLabel>
          <Select
            labelId="simple-select-autowidth-label"
            id="simple-select-autowidth"
            value={selectedValue || ""}
            onChange={handleChange}
            autoWidth
            label={inputLabel}
          >
            {items.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default MuiSelect;
