import React from "react";
import { StyledPortalContent, H3 } from "../index";

const MetricsOverview = () => {
  return (
    <StyledPortalContent>
      <H3>Metrics</H3>
    </StyledPortalContent>
  );
};

export default MetricsOverview;
