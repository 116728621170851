/* eslint-disable */
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { BsDatabase } from "react-icons/bs";
import { TiDelete, TiPlus } from "react-icons/ti";
import { useDispatch } from "react-redux";
import WhereClauseModal from "./WhereClauseModal.js";
import {
  deleteElement,
  updateElementPosition,
  updateOverElementIndex,
} from "../../redux/reducers/elementsDndReducer";
import {
  DeleteTableContainer,
  DraggableTableHeader,
  DraggableTableRow,
  WhereClauses,
  WhereClausesContainer,
} from "../Transformation/Styles/DraggableTableStyle";

const DraggableTable = ({
  index,
  setDragElement,
  attributes = [],
  setDragAndDropQuery,
  databaseName,
  tableName,
  setOverDraggableTable,
  setOverDragArea,
  join = { enabled: false },
}) => {
  const elementRef = useRef(null);
  const [selectedAttributes, setSelectedAttributes] = useState(
    (attributes || [])?.reduce((acc, cur) => {
      return { ...acc, [cur?.column_name]: true };
    }, {}),
  );
  // const [dragCoordinates, setDragCoordinates] = useState({
  //   x: null,
  //   y: null,
  //   width: null,
  //   height: null,
  // });
  const [add, setAdd] = useState(false);
  const [limit, setLimit] = useState({ enabled: false, value: 0 });
  const [count, setCount] = useState({ enabled: false });
  const [groupBy, setGroupBy] = useState({ enabled: false });
  const [where, setWhere] = useState({ enabled: false, clauses: [] });
  const [whereModal, setWhereModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [joinClause, setJoinClause] = useState({
    type: "LEFT",
    on: {
      column1: "",
      column2: "",
    },
  });
  // const [limit, setLimit] = useState({ value: 0 });
  const handleInputChange = (e) => {
    // Convert the input value to an integer
    const inputValue = e.target.value;
    const parsedValue = inputValue !== "" ? parseInt(inputValue, 10) : "";
    // Update the state with the parsed value
    setLimit({ ...limit, value: parsedValue });
  };
  const dispatch = useDispatch();
  // Example logic to determine join condition based on dragged attributes
  useEffect(() => {
    if (!join.enabled) {
      // Handle query construction when join is not enabled
      const allTrue = Object.values(selectedAttributes).every(
        (value) => value === true,
      );
      let query = allTrue
        ? `select *`
        : `select ${Object.keys(selectedAttributes)
            .filter((key) => !!selectedAttributes[key])
            .join(", ")}`;
      if (count.enabled) query += `, COUNT(${count.value})`;
      query += ` FROM ${databaseName}.${tableName}`;
      if (limit.enabled) query += ` LIMIT ${limit.value}`;
      if (groupBy.enabled) query += ` GROUP BY ${groupBy.value}`;
      if (where.enabled) {
        query += " WHERE";
        where.clauses.forEach((clause, index) => {
          if (clause.column && clause.operation && clause.value) {
            query += ` ${clause.concatType ? clause.concatType : ""} ${
              clause.column.column_name
            } ${clause.operation} ${
              ["DATE", "TIMESTAMP", "STRING"].includes(
                clause.column.column_type,
              )
                ? `${
                    clause.value.toLowerCase() === "null"
                      ? "NULL"
                      : `'${clause.value}'`
                  }`
                : clause.value
            }`;
            // if (index !== where.clauses.length - 1) {
            //   query += ' AND'
            // }
          }
        });
      }
      setDragAndDropQuery(query); // Update the query state
    }
    if (join.enabled) {
      // Handle query construction when join is enabled
      let joinQuery = `SELECT ${Object.keys(selectedAttributes)
        .filter((key) => !!selectedAttributes[key])
        .join(", ")}`;
      joinQuery += ` FROM ${tableName} ${joinClause.type} JOIN ${join.table.name} ON ${joinClause.on.column1} = ${joinClause.on.column2}`;
      setDragAndDropQuery(joinQuery); // Update the query state
    }
  }, [
    selectedAttributes,
    limit,
    count,
    groupBy,
    where,
    joinClause,
    join.enabled,
    databaseName,
    tableName,
  ]);
  useEffect(() => {
    if (join.enabled) {
      setSelectedAttributes((prev) => {
        return {
          ...Object.entries(prev).reduce((acc, [key, val]) => {
            return { ...acc, [`${tableName}.${key}`]: val };
          }, {}),
          ...join.table.attributes.reduce((acc, cur) => {
            return { ...acc, [`${join.table.name}.${cur.column_name}`]: true };
          }, {}),
        };
      });
    }
  }, [join]);
  useEffect(() => {
    if (where.clauses[0]?.concatType) {
      const updatingClause = where.clauses[0];
      delete updatingClause.concatType;
      const updatedWhere = JSON.parse(JSON.stringify(where));
      updatedWhere.clauses.splice(0, 1, updatingClause);
      setWhere(updatedWhere);
    }
  }, [where]);
  const closeModal = () => {
    setWhereModal(false);
  };
  useEffect(() => {
    if (where.enabled === true) {
      setWhereModal(true);
    }
  }, [where]);
  // const handleDragEnd = (e) => {
  //   e.stopPropagation();
  //   dispatch(
  //     updateElementPosition({
  //       index,
  //       x: dragCoordinates.x,
  //       y: dragCoordinates.y,
  //       width: dragCoordinates.width,
  //       height: dragCoordinates.height,
  //     }),
  //   );
  //   dispatch(updateOverElementIndex({ index: -1 }));
  // };
  // const handleDrag = (e) => {
  //   const rectFather = e.currentTarget.parentElement.getBoundingClientRect();
  //   const x = e.clientX - rectFather.left;
  //   const y = e.clientY - rectFather.top;
  //   if (elementRef.current) {
  //     const rect = elementRef.current.getBoundingClientRect();
  //     if (x > 0 && y > 0) {
  //       setDragCoordinates({
  //         x,
  //         y,
  //         width: rect.width,
  //         height: rect.height,
  //         top: rect.top - rectFather.top,
  //         bottom: rect.bottom - rectFather.top,
  //       });
  //     }
  //   }
  // };
  return (
    <Stack
      key={index}
      ref={elementRef}
      onDragStart={(e) => {
        setDragElement(index);
      }}
      // onDragEnd={handleDragEnd}
      // onDrag={handleDrag}
      draggable={true}
      sx={{
        boxshadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        // width: 200,
        // height: 240,
        // position: "absolute",
        // left: dragCoordinates.x,
        // top: dragCoordinates.y,
        // cursor: "pointer",
        // backgroundColor: "white",
        // border: "0px",
        // resize: "both",
        // overflow: "auto",
        // justifyContent: "space-evenly",
        // overflowX: "hidden",
      }}
      // onDragOver={(e) => {
      //   e.stopPropagation();
      //   dispatch(updateOverElementIndex({ index }));
      //   setOverDraggableTable(true);
      //   setOverDragArea(false);
      // }}
    >
      <DeleteTableContainer>
        <IconButton
          size="small"
          onClick={() => {
            dispatch(deleteElement({ index }));
          }}
          color="gray"
          sx={{
            position: "absolute",
            top: 2,
            right: 2,
          }}
        >
          <TiDelete />
        </IconButton>
      </DeleteTableContainer>
      <DraggableTableHeader>
        <BsDatabase />
        <p style={{ fontSize: "1em", fontWeight: "bold" }}>{tableName}</p>
      </DraggableTableHeader>
      <Stack
        spacing={0}
        sx={{
          p: 1,
        }}
      >
        {(attributes || []).map((attribute, index) => {
          return (
            <FormControlLabel
              key={index}
              control={<Checkbox defaultChecked sx={{ mt: 0 }} />}
              // sx={{ background: "red", marginTop: "0px"}}
              label={attribute.column_name}
              componentsProps={{ typography: { sx: { m: 0 } } }}
              onClick={() => {
                setSelectedAttributes((prev) =>
                  join.enabled
                    ? {
                        ...prev,
                        [`${tableName}.${attribute.column_name}`]:
                          !prev[`${tableName}.${attribute.column_name}`],
                      }
                    : {
                        ...prev,
                        [attribute.column_name]: !prev[attribute.column_name],
                      },
                );
              }}
            />
          );
        })}
        {add && (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Options</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Options"
              value={null}
              onChange={(e) => {
                e.target.value === "limit" &&
                  setLimit({
                    enabled: true,
                    value: 5,
                  });
                e.target.value === "count" &&
                  setCount({
                    enabled: true,
                    value: null,
                  });
                e.target.value === "groupBy" &&
                  setGroupBy({
                    enabled: true,
                    value: null,
                  });
                e.target.value === "where" &&
                  setWhere({
                    enabled: true,
                    clauses: [
                      {
                        key: crypto.randomUUID(),
                        column: null,
                        operation: null,
                        value: null,
                      },
                    ],
                  });
                setAdd(false);
              }}
            >
              <MenuItem value="limit">LIMIT</MenuItem>
              <MenuItem value="count">COUNT</MenuItem>
              <MenuItem value="groupBy">GROUP BY</MenuItem>
              <MenuItem value="where">WHERE</MenuItem>
            </Select>
          </FormControl>
        )}
        {count.enabled && (
          <DraggableTableRow>
            <FormControlLabel
              key={"count"}
              control={<Checkbox defaultChecked sx={{}} />}
              label="Count"
              onClick={() => setCount({ ...count, enabled: false })}
            />
            <FormControl fullWidth>
              {/* <InputLabel>Column</InputLabel> */}
              <Select
                // label="Column"
                value={count.value}
                onChange={(e) => {
                  setCount({ ...count, value: e.target.value });
                }}
              >
                {attributes.map((attribute) => (
                  <MenuItem value={attribute.column_name}>
                    {attribute.column_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DraggableTableRow>
        )}
        {limit.enabled && (
          <DraggableTableRow>
            <FormControlLabel
              key={"limit"}
              control={<Checkbox defaultChecked />}
              label="Limit"
              onClick={() => setLimit({ ...limit, enabled: false })}
            />
            <TextField
              type="number"
              id="outlined-basic"
              // label="Value"
              variant="outlined"
              value={limit.value}
              onChange={handleInputChange}
              sx={{ width: "100%", paddingLeft: "0.5em" }}
            />
          </DraggableTableRow>
        )}
        {groupBy.enabled && (
          <DraggableTableRow>
            <FormControlLabel
              key={"groupBy"}
              control={<Checkbox defaultChecked sx={{}} />}
              label="Group By"
              onClick={() => setGroupBy({ ...groupBy, enabled: false })}
            />
            <FormControl fullWidth>
              {/* <InputLabel>Column</InputLabel> */}
              <Select
                // label="Column"
                value={groupBy.value}
                onChange={(e) => {
                  setGroupBy({ ...groupBy, value: e.target.value });
                }}
              >
                {attributes.map((attribute) => (
                  <MenuItem value={attribute.column_name}>
                    {attribute.column_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DraggableTableRow>
        )}
        {whereModal && (
          <WhereClauseModal
            isModalOpen={whereModal}
            closeModal={closeModal}
            where={where}
            setWhere={setWhere}
            attributes={attributes}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
          />
        )}
      </Stack>
      <div
        style={{
          padding: 8,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={() => {
            setAdd((prev) => !prev);
          }}
          variant="outlined"
          color="primary"
        >
          <TiPlus size={20} />
        </Button>
      </div>
    </Stack>
  );
};
export default DraggableTable;
