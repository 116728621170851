/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CatalogPresenter from "./CatalogPresenter";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMetadataTables,
  fetchMetadataDataset,
} from "../../redux/actions/metaDataAction";
import { fetchJobs } from "../../redux/actions/usersActions";
import { fetchCustomers } from "../../redux/actions/customerAction";
import axios from "axios";
import useQuery from "../hooks/useQuery";
import useGetToken from "../../cognito/useGetToken";

const CatalogContainer = () => {
  const { token } = useGetToken();
  const dispatch = useDispatch();
  const { allCustomers } = useSelector((state) => state.customers);
  const { environmentVariables, postQuerySuccess } = useSelector(
    (state) => state.users,
  );
  const groupName = localStorage.getItem("group");
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isRunQueryActive, setIsRunQueryActive] = useState(false);
  const closeQueryModal = () => setIsRunQueryActive(false);
  const { runQuery, getQueryResult, handleExportCSV } = useQuery();

  useEffect(() => {
    if (token) {
      dispatch(
        fetchJobs(
          token,
          environmentVariables.REACT_APP_BASE_URL_API_CORE,
          groupName,
        ),
      );
      dispatch(
        fetchCustomers(
          token,
          groupName,
          environmentVariables.REACT_APP_BASE_URL_API_CUSTOMER,
        ),
      );
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (environmentVariables && token && groupName) {
      dispatch(
        fetchMetadataDataset(
          token,
          groupName,
          environmentVariables.REACT_APP_BASE_URL_API_METADATA,
        ),
      );
      dispatch(
        fetchMetadataTables(
          token,
          groupName,
          environmentVariables.REACT_APP_BASE_URL_API_METADATA,
        ),
      );
    }
  }, [token, dispatch, environmentVariables]);

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const updateTable = async (
    databaseName,
    tableName,
    columnName,
    columnDescription,
  ) => {
    const formData = {
      customer: groupName,
      dataset_name: databaseName,
      table_name: tableName,
      columns: [
        {
          column_name: columnName,
          column_description: columnDescription,
        },
      ],
    };
    if (columnDescription) {
      await axios.patch(
        `${environmentVariables.REACT_APP_BASE_URL_API_METADATA}/columnDescription?group=${groupName}`,
        formData,
        {
          headers,
        },
      );
    }
  };

  const updateTableDescription = async (
    databaseName,
    tableName,
    tabledescription,
  ) => {
    const formData = {
      customer: groupName,
      dataset_name: databaseName,
      table_name: tableName,
      table_description: tabledescription,
    };
    if (tabledescription) {
      await axios.patch(
        `${environmentVariables.REACT_APP_BASE_URL_API_METADATA}/tableDescription?group=${groupName}`,
        formData,
        {
          headers,
        },
      );
    }
  };

  const deleteTable = async (databaseName, tableName) => {
    setDeleteLoader(true);
    const formData = {
      customer: groupName,
      dataset_name: databaseName,
      table_name: tableName,
    };
    if (formData) {
      await axios.delete(
        `${environmentVariables.REACT_APP_BASE_URL_API_METADATA}/deleteTable?group=${groupName}`,
        formData,
        {
          headers,
        },
      );
    }
    setDeleteLoader(false);
  };

  const handleRunQuery = (databaseName, tableName) => {
    setIsRunQueryActive(false);
    const queryValue = `Select * from ${databaseName}.${tableName} limit 20`;

    if (allCustomers !== null) {
      runQuery(
        allCustomers[0],
        queryValue,
        token,
        environmentVariables.REACT_APP_BASE_URL_API_CORE,
      );
    }
    setIsRunQueryActive(true);
  };
  useEffect(() => {
    if (postQuerySuccess.jobId !== undefined && token) {
      if (allCustomers !== null) {
        getQueryResult(
          allCustomers[0],
          token,
          environmentVariables.REACT_APP_BASE_URL_API_CORE,
          postQuerySuccess.jobId,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allCustomers,
    environmentVariables.REACT_APP_BASE_URL_API_CORE,
    postQuerySuccess.jobId,
    token,
  ]);
  return (
    <CatalogPresenter
      token={token}
      isModalOpen={isModalOpen}
      setModalOpen={setModalOpen}
      updateTable={updateTable}
      updateTableDescription={updateTableDescription}
      deleteTable={deleteTable}
      handleRunQuery={handleRunQuery}
      isRunQueryActive={isRunQueryActive}
      closeQueryModal={closeQueryModal}
      handleExportCSV={handleExportCSV}
      deleteLoader={deleteLoader}
    />
  );
};

export default CatalogContainer;
