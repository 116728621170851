import React, { useState } from "react";
import { Flex } from "../index";
import { ReusableFieldSet, FieldSet, SelectFieldSet } from "./FieldSet";
import { useTranslation } from "react-i18next";

const Frequency = ({
  frequencyOption,
  handleFrequencyOption,
  frequencydata,
  startHourMinutes,
  handleStartHourMinutesChange,
  dailyTime,
  handleDailyTimeChange,
  selectedDays,
  handleDayToggle,
  startWeeklyTime,
  handleWeeklyStartTimeChange,
}) => {
  const [isFrequencyFocused, setIsFrequencyFocused] = useState(false);

  const { t } = useTranslation();

  const daysOfTheWeek = [
    {
      id: 1,
      title: "Monday",
      value: t("jobs.form.inputs.frequency.weekly.days.monday"),
    },
    {
      id: 2,
      title: "Tuesday",
      value: t("jobs.form.inputs.frequency.weekly.days.tuesday"),
    },
    {
      id: 3,
      title: "Wednesday",
      value: t("jobs.form.inputs.frequency.weekly.days.wednesday"),
    },
    {
      id: 4,
      title: "Thursday",
      value: t("jobs.form.inputs.frequency.weekly.days.thursday"),
    },
    {
      id: 5,
      title: "Friday",
      value: t("jobs.form.inputs.frequency.weekly.days.friday"),
    },
    {
      id: 6,
      title: "Saturday",
      value: t("jobs.form.inputs.frequency.weekly.days.saturday"),
    },
    {
      id: 7,
      title: "Sunday",
      value: t("jobs.form.inputs.frequency.weekly.days.sunday"),
    },
  ];

  const handleFrequencyFocus = () => {
    setIsFrequencyFocused(true);
  };

  const handleFrequencyBlur = () => {
    setIsFrequencyFocused(false);
  };
  const [isStartMinuteFocused, setIsStartMinuteFocused] = useState(false);
  const handleStartMinuteFocus = () => {
    setIsStartMinuteFocused(true);
  };

  const handleStartMinuteBlur = () => {
    setIsStartMinuteFocused(false);
  };
  const [isStartTimeFocused, setIsStartTimeFocused] = useState(false);
  const handleStartTimeFocus = () => {
    setIsStartTimeFocused(true);
  };

  const handleStartTimeBlur = () => {
    setIsStartTimeFocused(false);
  };
  const [isStartWeeklyTimFocused, setIsStartWeeklyTimFocused] = useState(false);
  const handleStartWeeklyTimFocus = () => {
    setIsStartWeeklyTimFocused(true);
  };

  const handleStartWeeklyTimBlur = () => {
    setIsStartWeeklyTimFocused(false);
  };
  const [isSelectDaysFocused, setIsSelectDaysFocused] = useState(false);
  const handleSelectDaysFocus = () => {
    setIsSelectDaysFocused(true);
  };

  const handleSelectDaysBlur = () => {
    setIsSelectDaysFocused(false);
  };

  return (
    <Flex column>
      <SelectFieldSet
        isSelectFocus={isFrequencyFocused}
        selectTitle={t("jobs.form.inputs.frequency.label")}
        onBlur={handleFrequencyBlur}
        onFocus={handleFrequencyFocus}
        selectValue={frequencyOption}
        handleSelectValueChange={handleFrequencyOption}
        selectValuedata={frequencydata}
      />
      {frequencyOption === "Hourly" && (
        <FieldSet
          legendTitle={t("jobs.form.inputs.frequency.hourly.label")}
          name="Start Minute"
          type="number"
          min="0"
          max="59"
          value={startHourMinutes}
          placeholder={t("jobs.form.inputs.frequency.hourly.placeholder")}
          onChange={handleStartHourMinutesChange}
          isInputFocused={isStartMinuteFocused}
          onFocus={handleStartMinuteFocus}
          onBlur={handleStartMinuteBlur}
        />
      )}
      {frequencyOption === "Daily" && (
        <FieldSet
          legendTitle={t("jobs.form.inputs.frequency.daily.label")}
          name="Start Time"
          type="time"
          value={dailyTime}
          onChange={handleDailyTimeChange}
          isInputFocused={isStartTimeFocused}
          onFocus={handleStartTimeFocus}
          onBlur={handleStartTimeBlur}
        />
      )}
      {frequencyOption === "Weekly" && (
        <Flex column>
          <ReusableFieldSet
            title={t("jobs.form.inputs.frequency.weekly.label")}
            isFocus={isSelectDaysFocused}
            children={
              <Flex className={"signin_input_focus, paddingClass"}>
                {daysOfTheWeek.map((days) => (
                  <label
                    key={days.id}
                    style={{ display: "flex", gap: "0.5em" }}
                  >
                    <input
                      type="checkbox"
                      checked={
                        selectedDays.includes(days.title) ||
                        selectedDays.includes(days.value)
                      }
                      onChange={() => handleDayToggle(days.title)}
                      onFocus={handleSelectDaysFocus}
                      onBlur={handleSelectDaysBlur}
                      className={"signin_input_focus, paddingClass"}
                    />
                    {days.value}
                  </label>
                ))}
              </Flex>
            }
          />
          <FieldSet
            legendTitle={t("jobs.form.inputs.frequency.weekly.start_time")}
            name="startWeeklyTime"
            type="time"
            value={startWeeklyTime}
            onChange={handleWeeklyStartTimeChange}
            isInputFocused={isStartWeeklyTimFocused}
            onFocus={handleStartWeeklyTimFocus}
            onBlur={handleStartWeeklyTimBlur}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default Frequency;
