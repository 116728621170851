import styled from "styled-components";

export const StyledPortalContent = styled.div`
  background-color: ${({ theme }) => theme.colors.secondaryColor};
  padding: 1.5em;
  position: relative;
  /* s */
  width: var(--app-container-size);
`;
export const H3 = styled.h3`
  font-weight: ${({ theme }) => theme.fonts.boldFont};
  font-size: ${(props) => (props.smallFont ? "2vh" : "3vh")};
`;
export const StyledPortalContainer = styled.div`
  /* display: flex; */
  /* background-color: red;
  width: 100%;
  max-width: 100%;
  height: 100%;
  flex-direction: column; */
`;
export const StyleScrollWidth = styled.div`
  width: "100%";
  height: "100%";
  overflow: "auto";
`;
export const DashBoardContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  height: 100%;
`;
export const StyledSideNavigation = styled.div`
  background-color: ${({ theme }) => theme.colors.sysSurfaceContainerLow};
  height: 100vh;
  position: sticky;
`;
export const StyledNavigationListItems = styled.div`
  display: flex;
  gap: 0.5em;
  padding: 1em;
  flex-direction: column;

  .menu-list-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .title_container {
    display: flex;
    gap: 0.5em;
    padding: 0.25em 0.75em;
  }
  .navLink {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.sysSurfaceVariant};
    font:
      normal 500 14px/20px "Poppins",
      sans-serif;
    letter-spacing: 0.1px;
    padding: 0.65em;
    &:hover {
      cursor: pointer;
    }
  }
  .icons {
    width: 24px;
    height: 24px;
    margin-top: 0.5em;
    &:hover {
      cursor: pointer;
    }
  }
  .nav_active {
    border-radius: 28px;
    align-self: stretch;
    background: ${({ theme }) => theme.colors.sysSurfaceActive};
    color: ${({ theme }) => theme.colors.sysSecondaryContainer};
  }
  .icon_active {
    background: ${({ theme }) => theme.colors.sysSurfaceActive};
  }
  .optionsContainer {
    background-color: ${({ theme }) => theme.colors.sysSurfaceContainer};
    border-radius: 16px;
  }
  .option_flex {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .option_title {
    text-decoration: none;
    margin-top: 0.75em;
    color: ${({ theme }) => theme.colors.sysSurfaceTextColor};
    font:
      normal 400 12px/16px "Poppins",
      sans-serif;
    letter-spacing: 0.4px;
    align-self: stretch;
  }
  .feature {
    font:
      normal 500 14px/20px "Poppins",
      sans-serif;
    letter-spacing: 0.1px;
    padding: 0.45em 1em;
    border-radius: 8px;
    border: 2px solid ${({ theme }) => theme.colors.borderColor};
    color: ${({ theme }) => theme.colors.sysSurfaceVariant};
  }
`;
export const StyledPortalHeader = styled.div`
  padding: 1em 1.5em;
  background-color: ${({ theme }) => theme.colors.refPrimary20};
`;

export const StyledNotification = styled.span`
  font-size: 16px;
  background: ${({ theme }) => theme.colors.primaryColor};
  color: #fff;
  padding: 0 5px;
  vertical-align: top;
  margin-left: -20px;
  border-radius: 15px;
`;
export const StyledBorder = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.primary_border_color};
  width: 100%;
  padding: ${(props) => (props.padding ? "3em" : "1em")};
`;
export const Price = styled.p`
  font-weight: ${({ theme }) => theme.fonts.boldFont};
  font-size: 3vh;
`;
export const PercentageBackground = styled.div`
  display: flex;
  background-color: ${(props) => (props.negative ? "red" : "green")};
  opacity: 0.4;
  border-radius: 25px;
  color: ${({ theme }) => theme.colors.secondaryColor};
  padding: 0.25em;
  font-size: 0.75em;
`;
export const BoldText = styled.h2`
  font-weight: bold;
`;
export const StyledLabel = styled.label`
  display: flex;
  gap: 20px;
  width: 100%;
  font-weight: bold;
  font-size: 1em;
`;
export const InputWidth = styled.div`
  display: flex;
  justify-content: space-between;
  width: 45%;
  border: 1px solid ${({ theme }) => theme.colors.primaryColor};
  padding: 0.25em;
  .input_border {
    width: 100%;
    border: none;
    &:focus {
      outline: none;
    }
  }
`;
export const StyledConnectorInput = styled.input`
  width: 100%;
  padding: 1em;
  border: 1px solid ${({ theme }) => theme.colors.primaryColor};
  color: ${({ theme }) => theme.colors.sysSurfaceVariant};
  font: ${({ theme }) => theme.fonts.fontSecondary};
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
  letter-spacing: "0.5px";
  opacity: 0.6;
  border-radius: 4px;
  &:focus {
    border: 2.5px solid ${({ theme }) => theme.colors.blueColor};
    color: ${({ theme }) => theme.colors.blueColor};
  }
`;
export const StyledTextArea = styled.textarea`
  ${StyledConnectorInput}
  width: 100%;
  padding: 1em;
  background-color: transparent;
`;
export const OptionalText = styled.p`
  color: #42474e;

  /* Poppins/body/small */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.4px;
`;
export const StyledPTag = styled.p`
  font-weight: bold;
  font-size: 1.5em;
  color: "black";
`;
export const StyledGridLabel = styled.label`
  display: grid;
  grid-template-columns: ${(props) =>
    props.isCheckbox ? "15% 80%" : "20% 75% 5%"};
  width: 100%;
  gap: 4em;
  font-weight: bold;
  font-size: 1em;
  align-items: center;
  white-space: nowrap;
  .width_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.primaryColor};
    padding: 0.25em;
  }
  .input_border {
    width: 100%;
    border: none;
    &:focus {
      outline: none;
    }
  }
`;
export const TableContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.secondaryColor};
  border-radius: 5px;
  padding: 0.75em 1.5em;
  box-shadow: ${({ theme }) => theme.boxShadow.primary_boxShadow};
  height: 450px;
  white-space: nowrap;
  overflow: auto;
`;

export const StyledDashboardContentGrid = styled.div`
  display: grid;
  width: 100%;
  height: 450px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 2.5em;
  margin-left: 76px;
  margin-top: 12px;
`;
export const StyledP = styled.p`
  text-align: center;
  word-spacing: 1px;
  color: ${({ theme }) => theme.colors.sysSurfaceVariant};
  font: ${({ theme }) => theme.fonts.fontSecondary};
  letter-spacing: ${({ theme }) => theme.fonts.secondaryletterSpacing};
`;

export const StyledFileImports = styled.div`
  border: 2px dashed ${({ theme }) => theme.colors.primaryColor};
  stroke-width: 98;
  max-width: 100%;
  min-height: 300px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  input {
    display: none;
  }
  .browse {
    color: ${({ theme }) => theme.colors.blueColor};
    &:hover {
      cursor: pointer;
    }
  }
`;
export const Progress = styled.div`
  background-color: #d8d8d8;
  border-radius: 20px;
  position: relative;
  width: 30%;
`;
export const ProgressCompleted = styled.div`
  background: ${({ theme }) => theme.colors.blueColor};
  border-radius: 20px;
  padding: 1em;
  color: ${({ theme }) => theme.colors.whiteColor};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0;
  opacity: 0.6;
  transition: 1s ease 0.3s;
  font-size: 0.75em;
`;

export const StyledAvatarContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
`;

export const StyledAvatarModal = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.whiteColor};
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  padding: 8px 16px;
  top: calc(100% + 8px);
  left: -62px;
  transform: translateX(-50%);
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
  z-index: 1000;
`;

export const StyledRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
`;

export const StyledItem = styled.a`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding: 8px;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.lightGrey : theme.colors.whiteColor};
  border-radius: 6px;
  cursor: pointer;
  text-decoration: none;

  :hover {
    background-color: ${({ theme }) => theme.colors.lightSlate};
  }
`;

export const StyledItemText = styled.p`
  font-size: 12px;
  font-weight: 500;
`;
