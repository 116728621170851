import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";

const ScrollTable = ({
  data,
  handleHeaderClick,
  handleCellClick,
  isColumnClick,
  selectedHeader,
  selectedRowIndex,
}) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#cde5ff",
      opacity: "0.8",
      color: "#001F25",
      textAlign: "left",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
      color: "#001F25",
      font: "normal 500 14px/20px 'Poppins', sans-serif",
      letterSpacing: "0.1px",
    },
  }));

  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  const headers = Object.keys(data[0]);

  return (
    <div style={{ width: "100%", overflow: "auto" }}>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 150 }}>
          <Table aria-label="sticky table">
            <TableHead>
              <StyledTableRow>
                {headers.map((header, index) => (
                  <StyledTableCell
                    key={index}
                    align="center"
                    style={{
                      minWidth: 100,
                      border:
                        isColumnClick && selectedHeader === header
                          ? "2px solid rgb(0, 51, 82)"
                          : "none",
                      cursor: "pointer",
                    }}
                    sx={{ padding: "8px", cursor: "pointer" }}
                    onClick={() => handleHeaderClick(header)}
                  >
                    {header}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {data.map((row, rowIndex) => (
                <StyledTableRow key={rowIndex}>
                  {headers.map((header, colIndex) => (
                    <StyledTableCell
                      key={colIndex}
                      sx={{
                        padding: "0 8px",
                        border:
                          isColumnClick && selectedHeader === header
                            ? "2px solid rgb(0, 51, 82)"
                            : "1px solid #dddddd",
                      }}
                      onClick={() => handleCellClick(header, rowIndex)}
                    >
                      {row[header] === null || row[header] === ""
                        ? "Nothing to show"
                        : row[header].value
                        ? row[header].value
                        : row[header]}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default ScrollTable;
