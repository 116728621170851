import React from "react";
import { TableContainer, Input, RiArrowUpDownLine } from "../../../index";

const DataDictionaryTable = ({ handleSorting, mapItems }) => {
  return (
    <TableContainer>
      <table>
        <thead>
          <tr>
            <th>
              <Input type="checkbox" />
            </th>
            <th>
              # <RiArrowUpDownLine onClick={() => handleSorting()} />
            </th>
            <th>
              Column Name
              <RiArrowUpDownLine onClick={() => handleSorting()} />
            </th>
            <th>
              Data Type <RiArrowUpDownLine onClick={() => handleSorting()} />
            </th>
            <th>
              Description
              <RiArrowUpDownLine onClick={() => handleSorting()} />
            </th>
            <th>Data Sample</th>
          </tr>
        </thead>
        <tbody>
          {mapItems.map((data) => (
            <tr key={data.id}>
              <th>
                <Input type={"checkbox"} />
              </th>
              <th style={{ fontWeight: "400" }}>{data.id}</th>
              <th style={{ fontWeight: "400" }}>{data.ColumnName}</th>
              <th style={{ fontWeight: "400" }}>{data.DataType}</th>
              <th style={{ fontWeight: "400" }}>{data.Description}</th>
              <th style={{ fontWeight: "400" }}>{data.Sample}</th>
            </tr>
          ))}
        </tbody>
      </table>
    </TableContainer>
  );
};
export default DataDictionaryTable;
