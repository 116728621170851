import React, { useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { Grid, ListItemButton, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MainGrid } from "../styles/Document.Styled";
import Theme from "../Theme";

import databoatLogo from "../../images/logo.png";

const DocumentPageLayout = ({ children, breadcrumbs }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const date = new Date();
  const year = date.getFullYear();

  useEffect(() => {
    // Scroll to the element with the ID from the fragment identifier
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ block: "end", behavior: "smooth" });
      }
    }
  }, [location.hash]);

  const displayMainContent = () => {
    return (
      <Grid item xs={9.5} md={9.5} className="content">
        {children || ""}

        <div className="content-footer">
          <Grid container>
            <Grid item xs={4} md={4}>
              <Link to="/docs/faq" className="nav-link">
                <p>{t("general.need_support")}</p>
              </Link>
            </Grid>

            <Grid item xs={4} md={4}>
              <Link to="/" className="nav-link">
                <p>{t("login.title")}</p>
              </Link>
              <Link to="/" className="nav-link">
                <p>{t("general.create_an_account")}</p>
              </Link>
            </Grid>

            <Grid item xs={4} md={4}>
              <Link to="/" className="nav-link">
                <p>{t("general.case_studies")}</p>
              </Link>
            </Grid>
          </Grid>

          <Grid container alignItems="center">
            <Grid item xs={4} md={4}>
              <Link to="/">
                <img src={databoatLogo} alt="Data boat logo" width={60} />
              </Link>
            </Grid>
            <Grid item xs={4} md={4}>
              <p className="nav-link" sx={{ p: 0 }}>
                &copy; {year} Databoat
              </p>
            </Grid>
          </Grid>
        </div>
      </Grid>
    );
  };

  const displayDocumentBreadcrumbs = () => {
    return (
      <Grid
        item
        xs={2.5}
        md={2.5}
        className="breadcrumbs"
        sx={{
          marginTop: "2rem",
        }}
      >
        <Grid
          container
          rowSpacing={2}
          justifyContent="left"
          direction={{
            xs: "column",
            md: "column",
          }}
        >
          <Grid item>
            <p>
              <strong>{t("general.on_this_page")}</strong>
            </p>
          </Grid>

          <Grid
            item
            className="breadcrumbs-list"
            sx={{
              borderLeft: `solid 2px ${Theme.colors.refPrimary20}`,
              marginTop: "16px",
              paddingTop: "0 !important",
            }}
          >
            {(breadcrumbs || []).map((crumb, idx) => (
              <ListItemButton
                key={idx}
                href={crumb.link || "#"}
                className={`breadcrumbs-list-button ${
                  location.hash === crumb.link ? "active" : ""
                }`}
              >
                <ListItemText primary={crumb.name} />
              </ListItemButton>
            ))}
          </Grid>

          <Grid item>
            <p>
              <strong>{t("general.more_from_databoat")}</strong>
            </p>
          </Grid>

          <Grid item>
            <Link to="/contact-us" className="breadcrumbs-link">
              <p>{t("general.contact_us")}</p>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <MainGrid>
      <Grid container columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        {displayMainContent()}
        {displayDocumentBreadcrumbs()}
      </Grid>
    </MainGrid>
  );
};

export default DocumentPageLayout;
