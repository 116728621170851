import React from "react";
import { StyledSideNavigation, NavigationListItems } from "../index";

const SideNavigation = ({ isActive, handleCollapseMenu }) => {
  return (
    <StyledSideNavigation>
      <NavigationListItems
        isActive={isActive}
        handleCollapseMenu={handleCollapseMenu}
      />
    </StyledSideNavigation>
  );
};
export default SideNavigation;
