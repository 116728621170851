import React from "react";
import { MuiEnhancedTable, MuiButton, PreLoader } from "../../../index";

import Typography from "@mui/material/Typography";

const ColumnNameData = ({
  selectedTableOption,
  isTableDetailsLoading,
  selectedDBOption,
  tableDetailsError,
  tableColumnsHeadCells,
  tableColumnsRows,
  selectedColumns,
  handleSelectColumns,
  handleCreateNewRule,
}) => {
  if (isTableDetailsLoading) {
    return <PreLoader marginTop={"0px"} />;
  }

  if (!selectedTableOption && !isTableDetailsLoading) {
    return (
      <Typography
        variant="h6"
        sx={{ textAlign: "center", color: "rgba(0, 0, 0, 0.6)" }}
      >
        Select Database and table to get columns ...
      </Typography>
    );
  }

  if (selectedDBOption && !isTableDetailsLoading && !tableDetailsError) {
    return (
      <>
        <MuiEnhancedTable
          headCells={tableColumnsHeadCells}
          rows={tableColumnsRows}
          showCheckbox={true}
          title={"Columns"}
          selected={selectedColumns}
          handleSelect={handleSelectColumns}
        />
        <MuiButton text={"Create Rule"} onClick={handleCreateNewRule} />
      </>
    );
  }

  return <Typography>Error to get table columns ...</Typography>;
};

export default ColumnNameData;
