import { useState } from "react";
const useExcat = (taskData, setTaskData) => {
  const group = localStorage.getItem("group");
  const [isRequiredFieldFilled, setIsRequiredFieldFilled] = useState(false);
  const [myExcatloadType, setMyExcatloadType] = useState("full");
  const [excatValue, setExcatValue] = useState([
    {
      division_var: "",
      client_id: "",
      aws_secret_access_key: "",
      aws_session_token: "",
      id: 1,
    },
  ]);

  const excatData = [
    {
      id: 1,
      name: "aws_access_key_id",
      title: "Client Id",
      checkbox: false,
      defaultValue: "",
      required: true,
    },
    {
      id: 2,
      name: "aws_secret_access_key",
      title: "Client Secret",
      checkbox: false,
      defaultValue: "",
      required: true,
    },
    {
      id: 3,
      name: "aws_session_token",
      title: "Refresh Token",
      checkbox: false,
      defaultValue: "",
      required: true,
    },
    {
      id: 4,
      name: "division_var",
      title: "Division",
      checkbox: false,
      defaultValue: "",
      required: true,
    },
  ];
  const handleExcatChange = (event, id) => {
    const { name, value } = event.target;

    // Update excatValue with the new input values
    const updatedExcatValue = excatValue.map((data) => {
      if (data.id === id) {
        switch (name) {
          case "division_var":
            data.division_var = value;
            break;
          case "aws_access_key_id":
            data.aws_access_key_id = value;
            break;
          case "aws_secret_access_key":
            data.aws_secret_access_key = value;
            break;
          case "aws_session_token":
            data.aws_session_token = value;
            break;
          default:
            console.log("error");
        }
      }
      return data;
    });

    // Check if all fields are filled for the specific entry
    const isFilled = updatedExcatValue.every((data) => {
      return (
        data.aws_access_key_id &&
        data.aws_secret_access_key &&
        data.aws_session_token &&
        data.division_var
      );
    });

    setIsRequiredFieldFilled(isFilled);

    const currentInput = updatedExcatValue.find((input) => input.id === id);

    // Update taskData with the new values
    taskData.map((data) => {
      if (data.id === id) {
        data.params.destination_bucket = `databoat-raw-${group}`;
        data.source_type = "excat";
        data.params.credentials_json = {
          aws_access_key_id: currentInput.aws_access_key_id,
          aws_secret_access_key: currentInput.aws_secret_access_key,
          aws_session_token: currentInput.aws_session_token,
        };
        // Assign division_var if needed
        data.params.division_var = currentInput.division_var;
      }
      return data;
    });
  };
  //TABLE
  const excatTableHead = [
    { id: 1, title: "Sync" },
    { id: 2, title: "Object" },
    { id: 3, title: "Sync Mode" },
    myExcatloadType === "delta" && { id: 5, title: "Primary Key" },
  ].filter(Boolean);

  const initialExcatTableData = [
    {
      id: 1,
      object: "table 1",
      selectedMode: "",
      primaryKey: "key1",
    },
    {
      id: 2,
      object: "table 2",
      selectedMode: "",
      primaryKey: "key2",
    },
    {
      id: 3,
      object: "table 3",
      selectedMode: "",
      primaryKey: "key3",
    },
    {
      id: 4,
      object: "table 4",
      selectedMode: "",
      primaryKey: "key4",
    },
  ];
  const [excatTableData, setExcatTableData] = useState(initialExcatTableData);
  const excatTableOptions = [
    { id: 1, option: "", title: "Select" },
    { id: 2, option: "full", title: "Full" },
    { id: 3, option: "delta", title: "Delta" },
  ];
  const handleSwitchChange = (event, id, taskId) => {
    const selected = event.target.value;
    console.log(
      `Switch changed for id: ${id}, taskId: ${taskId}, value: ${selected}`,
    );
  };

  const handleSyncModeChange = (event, id, taskId) => {
    const selected = event.target.value;
    const updatedTable = excatTableData.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          selectedMode: selected,
        };
      }
      return item;
    });
    setExcatTableData(updatedTable);
    setMyExcatloadType(selected);
  };
  const handlePrimaryKeyChange = (event, id, taskId) => {
    const selected = event.target.value;
    console.log(
      `Switch changed for id: ${id}, taskId: ${taskId}, value: ${selected}`,
    );
  };
  //ends here
  return [
    setExcatValue,
    excatData,
    handleExcatChange,
    isRequiredFieldFilled,
    excatTableHead,
    myExcatloadType,
    excatTableData,
    excatTableOptions,
    handleSwitchChange,
    handleSyncModeChange,
    handlePrimaryKeyChange,
  ];
};

export default useExcat;
