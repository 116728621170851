import { Stack } from "@mui/material";
import moment from "moment";
import {
  TableDataCell,
  TableOverview,
  JobName,
  UsePaginations,
  PreLoader,
} from "../index";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const SupportTable = ({ status }) => {
  const navigate = useNavigate();
  const { getSupport, getSupportOpenCase, getSupportOpenCaseLoader } =
    useSelector((state) => state.support);
  const [currentPage, setCurrentPage] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const pageSize = 10;
  const [renderData, setRenderData] = useState([]);

  useEffect(() => {
    const data =
      status === "work in Progress" ? getSupportOpenCase : getSupport;
    setRenderData(data);
  }, [status, getSupportOpenCase, getSupport]);

  const endOffset = currentPage + pageSize;
  const currentdata = renderData.slice(currentPage, endOffset);
  const pageCount = Math.ceil(renderData.length / pageSize);
  function handlePageChange(event) {
    const newOffset = (event.selected * pageSize) % renderData.length;
    setCurrentPage(newOffset);
  }
  const handleCaseDetailsPage = (data) => {
    navigate(`/details/${data.case_id}`, {
      state: { data },
    });
  };
  return (
    <Stack spacing={2}>
      {getSupportOpenCaseLoader ? (
        <PreLoader />
      ) : (
        <>
          <TableOverview
            headers={[
              "Subject",
              "Case ID",
              "Created At",
              "Open By",
              "Severity",
              "Status",
            ]}
            rows={currentdata}
            width={"150px"}
            renderRow={(data, index) => (
              <tr key={index}>
                <TableDataCell>{data.subject}</TableDataCell>
                <TableDataCell>
                  <JobName onClick={() => handleCaseDetailsPage(data)}>
                    {data.case_id}
                  </JobName>
                </TableDataCell>
                <TableDataCell>
                  {moment(data.date).format("YYYY-MM-DD HH:mm")}
                </TableDataCell>
                <TableDataCell>{data.opened_by}</TableDataCell>
                <TableDataCell>{data.severity}</TableDataCell>
                <TableDataCell
                  style={{
                    color: data.case_status === "resolved" ? "green" : "blue",
                  }}
                >
                  {data.case_status}
                </TableDataCell>
              </tr>
            )}
          />
          <UsePaginations
            pageCount={pageCount}
            endOffset={endOffset}
            databaseLength={renderData.length}
            handlePageChange={handlePageChange}
          />
        </>
      )}
    </Stack>
  );
};

export default SupportTable;
