import React from "react";
import {
  BreadcrumbsLink,
  BreadcrumbsWrapper,
} from "../../styles/Yedaflow.Styled";

function Breadcrumbs({ values }) {
  return (
    <BreadcrumbsWrapper>
      {values.map((breadcrumb, index) => (
        <React.Fragment key={breadcrumb.path}>
          {index > 0 && " > "}
          {index < values.length - 1 ? (
            <BreadcrumbsLink to={breadcrumb.path}>
              {breadcrumb.name}
            </BreadcrumbsLink>
          ) : (
            <span>{breadcrumb.name}</span>
          )}
        </React.Fragment>
      ))}
    </BreadcrumbsWrapper>
  );
}

export default Breadcrumbs;
