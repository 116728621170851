import { DashboardHome, StyledPortalContent } from "../index";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchJobsAnalytics,
  fetchCountDataSetsAnalytics,
} from "../redux/actions/usersActions";

import { useTranslation } from "react-i18next";
import useGetToken from "../cognito/useGetToken";

const DashboardOverview = () => {
  const [greeting, setGreeting] = useState("");

  const { t } = useTranslation();
  const { token, currentUser } = useGetToken();
  const group = localStorage.getItem("group");
  const dispatch = useDispatch();
  const { environmentVariables } = useSelector((state) => state.users);
  const baseURL = environmentVariables.REACT_APP_BASE_URL_API_CORE;
  useEffect(() => {
    const now = new Date();
    const hours = now.getHours();

    let newGreeting = "";

    if (hours >= 5 && hours < 12) {
      newGreeting = t("dashboard.good_morning");
    } else if (hours >= 12 && hours < 17) {
      newGreeting = t("dashboard.good_afternoon");
    } else {
      newGreeting = t("dashboard.good_evening");
    }
    setGreeting(newGreeting);
    if (token) {
      dispatch(fetchCountDataSetsAnalytics(token, group, baseURL));
      dispatch(fetchJobsAnalytics(token, group, baseURL));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, dispatch]);
  return (
    <StyledPortalContent>
      <DashboardHome greeting={greeting} currentUser={currentUser} />
    </StyledPortalContent>
  );
};

export default DashboardOverview;
