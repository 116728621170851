import React from "react";
import { StyledDashboardContentFlex, ConnectorScreens } from "../../../index";
import useCSV from "../customHook/useCSV";

const CSVConnector = () => {
  const [
    inputData,
    CSVData,
    errors,
    handleChange,
    upload,
    files,
    progress,
    handleRemove,
    fileRejections,
    getRootProps,
    isDragActive,
    getInputProps,
    errMsg,
    errRef,
    handlePostCSVData,
  ] = useCSV();
  return (
    <StyledDashboardContentFlex column>
      <ConnectorScreens
        HeaderText={"Inform the csv location to be loaded into Databoat"}
        inputData={inputData}
        data={CSVData}
        errors={errors}
        handleChange={handleChange}
        upload={upload}
        files={files}
        progress={progress}
        handleRemove={handleRemove}
        fileRejections={fileRejections}
        getRootProps={getRootProps}
        isDragActive={isDragActive}
        getInputProps={getInputProps}
        errMsg={errMsg}
        errRef={errRef}
        btnOnClick={handlePostCSVData}
        buttonText={"Upload"}
      />
    </StyledDashboardContentFlex>
  );
};

export default CSVConnector;
