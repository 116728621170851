import React, { useState } from "react";
import {
  DialogContent,
  Dialog,
  IconButton,
  Typography,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BasicSelect from "./SelectModal";
import { StyledModal, ModalHeader } from "../../index";
import { Stack } from "@mui/material";
import ScrollTable from "./ScrollTable";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { Theme } from "../../index";
import ButtonFilledIcon from "../../reusableComponents/UIComponents/ButtonFilledIcon";

const RelationshipModal = ({
  openRelationshipModel,
  handleCloseRelationshipModel,
  setJoinQuery,
  newQuery,
}) => {
  const [fromTableValue, setFromTableValue] = React.useState("");
  const [toTableAttributes, setToTableAttributes] = React.useState([]);
  const [fromTableAttributes, setFromTableAttributes] = React.useState([]);
  const [selectedFromColumn, setSelectedFromColumn] = React.useState("");
  const [selectedToColumn, setSelectedToColumn] = React.useState("");
  const [isFromTableClick, setIsFromTableClick] = React.useState(false);
  const [error, setError] = useState(null);
  const [isToTableClick, setIsToTableClick] = React.useState(false);
  const [toDatasetValue, setToDatasetValue] = React.useState("");
  const [fromDatasetValue, setFromDatasetValue] = React.useState("");
  const [toTableValue, setToTableValue] = React.useState("");
  const [cardinalityValue, setCardinalityValue] = React.useState("");
  const [crossFilterDirectionValue, setCrossFilterDirectionValue] =
    React.useState("");
  const handlefromTableValueChange = (event) => {
    const value = event.target.value;
    setFromTableValue(value);
    const selectedTable = newQuery.find((item) => item.tableName === value);
    if (selectedTable) {
      setFromDatasetValue(selectedTable.datasetName);
    }
  };

  const handletoTableValueChange = (event) => {
    const value = event.target.value;
    setToTableValue(value);
    const selectedTable = newQuery.find((item) => item.tableName === value);
    if (selectedTable) {
      setToDatasetValue(selectedTable.datasetName);
    }
  };

  const handleCardinalityValueChange = (e) => {
    setCardinalityValue(e.target.value);
  };
  const handleCrossFilterDirectionValueChange = (e) => {
    setCrossFilterDirectionValue(e.target.value);
  };
  React.useEffect(() => {
    if (fromTableValue) {
      const selectedElement = newQuery.find(
        (item) => item.tableName === fromTableValue,
      );
      if (selectedElement) {
        setFromTableAttributes(selectedElement.result);
      }
    }
  }, [fromTableValue, newQuery]);

  React.useEffect(() => {
    if (toTableValue) {
      const selectedElement = newQuery.find(
        (item) => item.tableName === toTableValue,
      );
      if (selectedElement) {
        setToTableAttributes(selectedElement.result);
      }
    }
  }, [toTableValue, newQuery]);

  React.useEffect(() => {
    if (fromTableValue === "") {
      setFromTableValue(newQuery[0].tableName);
      setFromDatasetValue(newQuery[0].datasetName);
    }
    if (toTableValue === "") {
      setToTableValue(newQuery[1].tableName);
      setToDatasetValue(newQuery[1].datasetName);
    }
    if (cardinalityValue === "") {
      setCardinalityValue(cardinalityOptions[0]);
    }
    if (crossFilterDirectionValue === "") {
      setCrossFilterDirectionValue(crossFilterDirectionOptions[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFromTableHeaderClick = (header) => {
    setSelectedFromColumn(header);
    setIsFromTableClick(true);
  };

  const handleFromTableColumnClick = (header, rowIndex) => {
    setSelectedFromColumn(header);
    setIsFromTableClick(false);
  };
  const handleToTableHeaderClick = (header) => {
    setSelectedToColumn(header);
    setIsToTableClick(true);
  };

  const handleToTableColumnClick = (header, rowIndex) => {
    setSelectedToColumn(header);
    setIsToTableClick(false);
  };
  const saveJoinQuery = () => {
    if (
      fromTableValue &&
      selectedFromColumn &&
      toTableValue &&
      selectedToColumn
    ) {
      // Determine the type of join based on cardinality
      let joinType;
      switch (cardinalityValue) {
        case "One-to-One (1:1)":
          joinType = "INNER JOIN";
          break;
        case "One-to-Many (1:N)":
        case "Many-to-One (N:1)":
          joinType = "LEFT JOIN";
          break;
        case "Many-to-Many (N:M)":
          joinType = "INNER JOIN";
          break;
        default:
          joinType = "INNER JOIN";
      }

      // Add cross-filter direction conditions
      const crossFilterCondition =
        crossFilterDirectionValue === "Both"
          ? `WHERE ft.${selectedFromColumn} IS NOT NULL AND tt.${selectedToColumn} IS NOT NULL`
          : crossFilterDirectionValue === "Single"
          ? `WHERE ft.${selectedFromColumn} IS NOT NULL`
          : "";

      // Construct the query with dataset prefix and table aliases
      const query = `SELECT 
        ft.${selectedFromColumn} AS ${fromTableValue}_${selectedFromColumn},
        tt.${selectedToColumn} AS ${toTableValue}_${selectedToColumn}
        FROM 
          ${fromDatasetValue}.${fromTableValue} AS ft
        ${joinType} 
          ${toDatasetValue}.${toTableValue} AS tt
        ON 
          ft.${selectedFromColumn} = tt.${selectedToColumn}
        ${crossFilterCondition};`;

      // Set the query and close the modal
      setJoinQuery(query);
      handleCloseRelationshipModel();
    } else {
      setError("Missing required fields for join query construction");
    }
  };

  const cardinalityOptions = [
    "One-to-One (1:1)",
    "One-to-Many (1:N)",
    "Many-to-One (N:1)",
    "Many-to-Many (N:M)",
  ];
  const crossFilterDirectionOptions = ["None", "Single", "Both"];

  return (
    <StyledModal>
      <Dialog
        onClose={handleCloseRelationshipModel}
        aria-labelledby="customized-dialog-title"
        open={openRelationshipModel}
        fullWidth
        PaperProps={{
          sx: { borderRadius: "28px", overflow: "visible", maxWidth: "900px" },
        }}
      >
        <ModalHeader>
          <Typography>Edit Relationship</Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseRelationshipModel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </ModalHeader>
        <DialogContent>
          <Typography
            sx={{
              paddingBottom: "1em",
              font: `${Theme.fonts.fontSecondary}`,
            }}
          >
            Select tables and Columns that are related.
          </Typography>
          {error !== null && (
            <Typography
              className="error"
              sx={{
                paddingBottom: "1em",
              }}
            >
              {error}
            </Typography>
          )}
          <Stack direction={"column"} spacing={3}>
            <Stack direction={"column"} spacing={1}>
              <Typography
                sx={{
                  font: `${Theme.fonts.fontSecondaryBold}`,
                }}
              >
                From Table
              </Typography>
              <BasicSelect
                width={"50%"}
                handleChange={handlefromTableValueChange}
                value={fromTableValue}
              >
                {newQuery.map((item) => (
                  <MenuItem
                    key={item.tableName}
                    value={item.tableName}
                    sx={{
                      font: `${Theme.fonts.fontPrimary}`,
                    }}
                  >
                    {item.tableName}
                  </MenuItem>
                ))}
              </BasicSelect>
              <ScrollTable
                data={fromTableAttributes}
                handleColumnClick={handleFromTableColumnClick}
                isColumnClick={isFromTableClick}
                handleHeaderClick={handleFromTableHeaderClick}
                handleCellClick={handleFromTableColumnClick}
                selectedHeader={selectedFromColumn}
              />
            </Stack>
            <Stack direction={"column"} spacing={1}>
              <Typography
                sx={{
                  font: `${Theme.fonts.fontSecondaryBold}`,
                }}
              >
                To Table
              </Typography>
              <BasicSelect
                width={"50%"}
                handleChange={handletoTableValueChange}
                value={toTableValue}
              >
                {newQuery.map((item) => (
                  <MenuItem
                    key={item.tableName}
                    value={item.tableName}
                    sx={{
                      font: `${Theme.fonts.fontPrimary}`,
                    }}
                  >
                    {item.tableName}
                  </MenuItem>
                ))}
              </BasicSelect>
              <ScrollTable
                data={toTableAttributes}
                handleColumnClick={handleToTableColumnClick}
                isColumnClick={isToTableClick}
                handleHeaderClick={handleToTableHeaderClick}
                handleCellClick={handleToTableColumnClick}
                selectedHeader={selectedToColumn}
              />
            </Stack>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "1.5em",
              }}
            >
              <div>
                <Typography
                  sx={{
                    font: `${Theme.fonts.fontSecondaryBold}`,
                  }}
                >
                  Cardinality
                </Typography>
                <BasicSelect
                  width={"100%"}
                  handleChange={handleCardinalityValueChange}
                  value={cardinalityValue}
                >
                  {cardinalityOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </BasicSelect>
              </div>
              <div>
                <Typography
                  sx={{
                    font: `${Theme.fonts.fontSecondaryBold}`,
                  }}
                >
                  Cross-filter direction
                </Typography>
                <BasicSelect
                  width={"100%"}
                  handleChange={handleCrossFilterDirectionValueChange}
                  value={crossFilterDirectionValue}
                >
                  {crossFilterDirectionOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </BasicSelect>
              </div>
            </div>
            {/* <FormGroup
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                columnGap: '1.5em'
              }}
            >
              <FormControlLabel
                control={<Checkbox />}
                sx={{
                  font: `${Theme.fonts.fontSecondaryBold}`
                }}
                label="Make this Relationship active"
              />
              <FormControlLabel
                control={<Checkbox />}
                sx={{
                  font: `${Theme.fonts.fontSecondaryBold}`
                }}
                label="Apply security filter in both directions"
              />
              <FormControlLabel
                control={<Checkbox />}
                sx={{
                  font: `${Theme.fonts.fontSecondaryBold}`
                }}
                label="Assume referential integrity"
              />
            </FormGroup> */}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "1em",
              }}
            >
              <ButtonFilledIcon
                label={"Save"}
                handleOnClick={saveJoinQuery}
                icon={
                  <SaveIcon
                    size={"18px"}
                    color={`${Theme.colors.whiteColor}`}
                  />
                }
              />
              <ButtonFilledIcon
                label={"Cancel"}
                handleOnClick={handleCloseRelationshipModel}
                style={{ backgroundColor: "transparent" }}
                icon={
                  <CancelIcon
                    size={"18px"}
                    color={`${Theme.colors.whiteColor}`}
                  />
                }
              />
            </div>
          </Stack>
        </DialogContent>
      </Dialog>
    </StyledModal>
  );
};

export default RelationshipModal;
