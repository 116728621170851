import React, { useEffect } from 'react'
import QueryResult from './QueryResult'
import { useParams } from 'react-router-dom';
import useGetToken from '../../../cognito/useGetToken';
import { useSelector } from 'react-redux';
import useQuery from '../../hooks/useQuery';

const SampleData = () => {
    const { database: databaseName, table: tableName } = useParams();
    const { token } = useGetToken();
    const { allCustomers } = useSelector((state) => state.customers);
    const { environmentVariables, postQuerySuccess } = useSelector(
        (state) => state.users,
    );
    const { runQuery, getQueryResult } = useQuery();

    useEffect(() => {
        if (postQuerySuccess.jobId !== undefined && token) {
            if (allCustomers !== null) {
                getQueryResult(
                    allCustomers[0],
                    token,
                    environmentVariables.REACT_APP_BASE_URL_API_CORE,
                    postQuerySuccess.jobId,
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        allCustomers,
        environmentVariables.REACT_APP_BASE_URL_API_CORE,
        postQuerySuccess.jobId,
        token,
    ]);

    useEffect(() => {
        if (!token) return;
        const queryValue = `Select * from ${databaseName}.${tableName} limit 20`;

        if (allCustomers !== null) {
            runQuery(
                allCustomers[0],
                queryValue,
                token,
                environmentVariables.REACT_APP_BASE_URL_API_CORE,
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allCustomers, databaseName, environmentVariables.REACT_APP_BASE_URL_API_CORE, tableName, token])

    return (
        <QueryResult />
    )
}

export default SampleData