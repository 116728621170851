import React from "react";
import { LuDownload } from "react-icons/lu";

import {
  StyledDashboardContentFlex,
  ModalHeader,
  CreateJobButton,
} from "../../../index";
import { Dialog } from "@mui/material";
import CloseSvg from "../../../reusableComponents/svg/CloseSvg";
import { useSelector } from "react-redux";
import QueryResult from "./QueryResult";
import { useTranslation } from "react-i18next";

const ViewTableQuery = ({
  isRunQueryActive,
  closeQueryModal,
  tableName,
  handleExportCSV,
}) => {
  const { getQueryResultSuccess } = useSelector((state) => state.users);

  const { t } = useTranslation();

  return (
    <Dialog
      open={isRunQueryActive}
      onClose={closeQueryModal}
      fullWidth={true}
      PaperProps={{ sx: { borderRadius: "28px", maxWidth: "1000px" } }}
    >
      <ModalHeader>
        <p>{t("data_management.catalog.presenter.table.modals.eyes.title")}</p>
        <div onClick={closeQueryModal}>
          <CloseSvg />
        </div>
      </ModalHeader>
      <StyledDashboardContentFlex
        column
        style={{ padding: "1em", overflow: "scroll", gap: "1em" }}
      >
        <StyledDashboardContentFlex justifyContent>
          <p></p>
          <CreateJobButton
            text={
              <p>
                <LuDownload size={"1em"} />{" "}
                {t(
                  "data_management.catalog.presenter.table.modals.eyes.button",
                )}
              </p>
            }
            type={"button"}
            style={{ display: "flex" }}
            onClick={() => handleExportCSV(getQueryResultSuccess, tableName)}
          />
        </StyledDashboardContentFlex>

        <QueryResult />
      </StyledDashboardContentFlex>
    </Dialog>
  );
};

export default ViewTableQuery;
