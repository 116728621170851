import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dataBases: [],
  isDataBasesLoading: false,
  dataBasesError: null,
  tables: [],
  isTablesLoading: false,
  tablesError: null,
  isTableDetailsLoading: false,
  tableDetails: {},
  tableDetailsError: null,
  isTableFiltersLoading: false,
  tableFilters: [],
  tableFiltersError: null,
  tableInfos: {},
  isTableInfosLoading: false,
  tableInfosError: null,
  catalogDataBases: [],
  isCatalogDataBasesLoading: false,
  catalogDataBasesError: null,
  catalogTablesAttributes: {},
  isCatalogTablesAttributesLoading: false,
  catalogTablesAttributesError: false,
  isLoadingMetaData: false,
  getDatabaseError: false,
  getDatabaseLoading: false,
  getDatabase: [],
};

export const glueMetadataSlice = createSlice({
  name: "glueMetadata",
  initialState,
  reducers: {
    updateDataBases: (state, action) => {
      state.dataBases = action.payload;
    },
    setIsDataBasesLoading: (state, action) => {
      state.isDataBasesLoading = action.payload;
    },
    setDataBasesError: (state, action) => {
      state.dataBasesError = action.payload;
    },
    updateTables: (state, action) => {
      state.tables = action.payload;
    },
    setIsTablesLoading: (state, action) => {
      state.isTablesLoading = action.payload;
    },
    setTablesError: (state, action) => {
      state.tablesError = action.payload;
    },
    updateTableDetails: (state, action) => {
      state.tableDetails = action.payload;
    },
    setIsTableDetailsLoading: (state, action) => {
      state.isTableDetailsLoading = action.payload;
    },
    setTableDetailsError: (state, action) => {
      state.tableDetailsError = action.payload;
    },
    updateTableFilters: (state, action) => {
      state.tableFilters = action.payload;
    },
    setIsTableFiltersLoading: (state, action) => {
      state.isTableFiltersLoading = action.payload;
    },
    setTableFiltersError: (state, action) => {
      state.tableFiltersError = action.payload;
    },
    updateTableInfos: (state, action) => {
      state.tableInfos = action.payload;
    },
    setIsTableInfosLoading: (state, action) => {
      state.isTableInfosLoading = action.payload;
    },
    setTableInfosError: (state, action) => {
      state.tableInfosError = action.payload;
    },
    updateCatalogDataBases: (state, action) => {
      state.catalogDataBases = action.payload;
    },
    setIsCatalogDataBasesLoading: (state, action) => {
      state.isCatalogDataBasesLoading = action.payload;
    },
    setIsQueryDataBasesLoading: (state, action) => {
      state.isLoadingMetaData = action.payload;
    },
    setCatalogDataBasesError: (state, action) => {
      state.catalogDataBasesError = action.payload;
    },
    updateCatalogTablesAttributes: (state, action) => {
      state.catalogTablesAttributes = action.payload;
    },
    setCatalogTablesAttributesError: (state, action) => {
      state.catalogTablesAttributesError = action.payload;
    },
    setIsCatalogTablesAttributesLoading: (state, action) => {
      state.isCatalogTablesAttributesLoading = action.payload;
    },
    //new logic for the click and get tables and attrinutes
    updateDataBase: (state, action) => {
      state.getDatabase = action.payload;
    },
    setDataBaseLoading: (state, action) => {
      state.getDatabaseLoading = action.payload;
    },
    setDataBaseError: (state, action) => {
      state.getDatabaseError = action.payload;
    },
  },
});

export const {
  updateDataBases,
  setIsDataBasesLoading,
  setDataBasesError,
  updateTables,
  setTablesError,
  setIsTablesLoading,
  updateTableDetails,
  setIsTableDetailsLoading,
  setTableDetailsError,
  updateTableFilters,
  setIsTableFiltersLoading,
  setTableFiltersError,
  updateTableInfos,
  setIsTableInfosLoading,
  setTableInfosError,
  updateCatalogDataBases,
  setIsCatalogDataBasesLoading,
  setIsQueryDataBasesLoading,
  setCatalogDataBasesError,
  updateCatalogTablesAttributes,
  setCatalogTablesAttributesError,
  setIsCatalogTablesAttributesLoading,
  setDataBaseError,
  setDataBaseLoading,
  updateDataBase,
} = glueMetadataSlice.actions;

export default glueMetadataSlice.reducer;
