import React from "react";
import { Grid } from "@mui/material";
import ReactPlayer from "react-player";
import DocumentPageLayout from "../DocumentPageLayout";

const BigQuery = () => {
  const breadcrumbs = [
    {
      name: "Ingesting your data using BigQuery",
      link: "#big-query",
    },
    {
      name: "BigQuery Connector",
      link: "#big-query-connector",
    },
  ];

  return (
    <DocumentPageLayout breadcrumbs={breadcrumbs}>
      <Grid container rowSpacing={4}>
        <Grid item>
          <h1 id="big-query">Ingesting your data using BigQuery</h1>

          <p>
            Check this step by step video - How to easily onboard data on
            DataBoat data platform:
          </p>

          <ReactPlayer
            url="https://vid.cdn-website.com/b29d841e/videos/mw0qBHAjRtS0r3sIY7yj_SQLServer+Connector-v.mp4"
            controls={true}
          />

          <h2 id="big-query-connector">BigQuery Connector</h2>

          <h4>Step 1: Configure Your Source</h4>
          <ul>
            <li>
              Click on "Configure Your Source" to begin setting up your data
              workflow.
            </li>
          </ul>
          <h4>Step 2: Define Workflow Details</h4>
          <ul>
            <li>Enter a name for the workflow.</li>
            <li>Set the start time of the workflow based on date and time.</li>
            <li>
              Determine the execution frequency of the pipeline by selecting one
              of the predefined options.
            </li>
          </ul>
          <h4>Step 3: Set Up Tasks</h4>
          <ul>
            <li>
              Add tasks to your workflow. Remember, you can include additional
              tasks based on data dependencies with other sources.
            </li>
            <li>
              For each task, define relevant parameters for the job orchestrator
              using key-value pairs in the UI or by pasting your JSON key-value
              map.
            </li>
          </ul>
          <h4>Step 4: Choose Task Type</h4>
          <ul>
            <li>Select the task type, either Ingestion or Transformation.</li>
            <li>
              If Ingestion is selected, choose an existing connector for
              BigQuery.
            </li>
          </ul>
          <h4>Step 5: Provide Credentials and Project Details</h4>
          <ul>
            <li>
              Upload the JSON file containing your service account credentials.
              This account should have the necessary permissions to access the
              tables you plan to ingest.
            </li>
            <li>
              Provide the location (e.g., eu-west1) where your BigQuery data is
              stored.
            </li>
            <li>
              Enter the Project ID of the Google Cloud project that contains the
              datasets you want to access.
            </li>
          </ul>
          <h4>Step 6: Access and Select Data</h4>
          <ul>
            <li>
              The system will use the provided credentials and project details
              to display available datasets and tables within the specified
              project and location.
            </li>
            <li>
              Browse through the list of datasets and select the one you are
              interested in.
            </li>
          </ul>
          <h4>Step 7: Configure Data Ingestion</h4>
          <ul>
            <li>
              Choose the tables you wish to ingest from the selected dataset.
            </li>
            <li>Select the ingestion mode:</li>
            <li>Full Dump: Imports all data from the selected tables.</li>
            <li>
              Delta Mode: Only imports changes since the last ingestion
              (requires proper logging and timestamping in the source tables).
            </li>
            <li>
              If you need to ingest more tables, click "Add More Tables", choose
              additional tables as needed, and save your selections.
            </li>
          </ul>
          <h4>Step 8: Finalize Workflow</h4>
          <ul>
            <li>
              Optionally, add tags to the workflow for easier identification and
              management.
            </li>
            <li>
              Configure notifications to keep you informed about the
              workflow&rsquo;s execution status.
            </li>
          </ul>
          <h4>Step 9: Create and Execute Job</h4>
          <ul>
            <li>
              After verifying all settings, save the workflow to create the job.
            </li>
            <li>
              The job is now ready to be executed and will process data
              according to the specified parameters and schedule.
            </li>
          </ul>
        </Grid>
      </Grid>
    </DocumentPageLayout>
  );
};

export default BigQuery;
