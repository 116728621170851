import React from "react";

export const FieldSet = ({
  legendTitle,
  type,
  name,
  id,
  value,
  onChange,
  min,
  max,
  placeholder,
  isInputFocused,
  onFocus,
  onBlur,
  inputStyle,
  readOnly,
  style,
}) => {
  return (
    <>
      <fieldset
        className={
          isInputFocused ? "input_fieldset" : "input_fieldset_not_focused"
        }
        style={style}
      >
        <legend
          className={
            isInputFocused
              ? "input_fieldset_legend"
              : "input_fieldset_legend_not_focused"
          }
        >
          {legendTitle}
        </legend>
        <input
          readOnly={readOnly}
          type={type}
          name={name}
          id={id}
          min={min}
          max={max}
          placeholder={placeholder}
          defaultValue={value}
          onChange={onChange}
          autoComplete="username"
          onFocus={onFocus}
          onBlur={onBlur}
          style={inputStyle}
          className={"signin_input_focus, paddingClass"}
        />
      </fieldset>
    </>
  );
};

export const SelectFieldSet = ({
  selectTitle,
  onBlur,
  onFocus,
  selectValue,
  handleSelectValueChange,
  selectValuedata,
  isSelectFocus,
}) => {
  return (
    <>
      <fieldset
        className={
          isSelectFocus ? "input_fieldset" : "input_fieldset_not_focused"
        }
      >
        <legend
          className={
            isSelectFocus
              ? "input_fieldset_legend"
              : "input_fieldset_legend_not_focused"
          }
        >
          {selectTitle}
        </legend>
        <select
          onFocus={onFocus}
          onBlur={onBlur}
          value={selectValue}
          onChange={handleSelectValueChange}
          className={"paddingClass"}
        >
          {selectValuedata.map((item) => (
            <option
              key={item.id}
              value={item.option}
              className={"signin_input_focus"}
            >
              {item.title}
            </option>
          ))}
        </select>
      </fieldset>
    </>
  );
};
export const ReusableFieldSet = ({ title, isFocus, children }) => {
  return (
    <>
      <fieldset
        className={isFocus ? "input_fieldset" : "input_fieldset_not_focused"}
      >
        <legend
          className={
            isFocus
              ? "input_fieldset_legend"
              : "input_fieldset_legend_not_focused"
          }
        >
          {title}
        </legend>
        {children}
      </fieldset>
    </>
  );
};
