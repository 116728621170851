import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import ReactPlayer from "react-player";
import DocumentPageLayout from "../DocumentPageLayout";

const DataOnboarding = () => {
  const { t } = useTranslation();

  const contentArr = [
    {
      name: t("general.data_onboarding"),
      link: "#data-onboarding",
    },
  ];

  return (
    <DocumentPageLayout
      breadcrumbs={contentArr.map(({ name, link }) => ({ name, link }))}
    >
      <Grid container>
        <Grid item gap={4}>
          <h1 id="data-onboarding"> {t("general.data_onboarding")} </h1>

          <p>
            With DataBoat, the data onboarding process is simple and intuitive.
            Our platform is designed to make incorporating new data as easy as
            possible, allowing you to start extracting valuable insights
            quickly. Follow our step-by-step guide and discover how easy it is
            to centralize your data, automate engineering tasks, and accelerate
            your BI and AI operations. Let's get started!
          </p>

          <ReactPlayer url="https://www.youtube.com/watch?v=eamf6Q9Dgow" />

          <h2>
            Check the example of how to quickly onboard data from a SQL Server
            database:
          </h2>

          <ol>
            <li>Click on "Job" to start a job creation.</li>
            <li>
              Define the name of the workflow and then choose when the workflow
              will start, based on date and time.
            </li>
            <li>
              Define how often the pipeline will be executed, choosing one of
              the options.
            </li>
            <li>
              Define the tasks of your workflow. Remember, you can add more
              tasks for your workflow based on data dependencies you might have
              with different sources.
            </li>
            <li>
              Define some parameters for the task if needed for job
              orchestrations. You can define parameters using key-value pairs in
              the AI or paste your JSON on a key-value map.
            </li>
            <li>
              Select the task type, which could be ingestion or transformation.
              Once selected ingestion, choose one of the existing connectors. In
              this example, we are going to select SQL Server, but in this
              documentation, you can find the step-by-step for all connectors by
              clicking on their names.
            </li>
            <li>
              In the next window, you have to inform the connection details like
              server host, port (define 1433 for SQL Server), user, and
              password.
            </li>
            <li>
              Once selected, there are two options you can use as ingestion
              type: table or query.
            </li>
            <ul>
              <li>
                If "table" is selected, a combo box will guide you to choose the
                objects in the database.
              </li>
              <li>
                First, select the database, then the schema for the database,
                select the table in the database.
              </li>
              <li>
                Select the ingestion mode to dump data from the source, which
                could be full dump or data mode.
              </li>
              <li>
                If your flow needs more tables, you can add them using the "add
                more tables" button to the workflow and fill in all information
                for this new table.
              </li>
              <li>And save.</li>
            </ul>
            <li>
              The last step you can choose is to add tags and notifications
              about the execution flow.
            </li>
            <li>
              Now the job is created and ready to be executed. Simple like that!
            </li>
          </ol>

          <h2>Data Storage</h2>

          <p>
            Databoat offers in storage base a data lake structure under the hood
            which is a comprehensive approach to storing and managing large
            volumes of structured, semi-structured, and unstructured data. The
            concept of data lake layers, including raw, curated, integrated, and
            consumption layers, helps organize and streamline the data pipeline
            process, from ingestion to analysis and consumption.
          </p>

          <ul>
            <li>
              Raw Layer: The raw layer is the foundational layer of the data
              lake. It contains the original, unprocessed data in its native
              format, directly ingested from various sources such as databases,
              applications, IoT devices, logs, and external feeds. The raw layer
              preserves data in its most granular and unaltered form, providing
              a comprehensive record of all data ingested into the data lake.
              This layer is ideal for data scientists and analysts who require
              access to the original data for exploratory analysis,
              experimentation, and data discovery.
            </li>

            <li>
              Curated Layer: The curated layer is where data undergoes initial
              processing and transformation to make it more structured,
              cleansed, and organized. In this layer, data engineers perform
              data quality checks, data cleansing, data enrichment, and schema
              normalization to ensure consistency and reliability. The curated
              layer serves as an intermediate stage between the raw and
              integrated layers, providing a refined version of the data that is
              easier to analyze and consume. Data in the curated layer is
              typically stored in a structured format, such as Parquet or ORC
              files, optimized for analytical queries and downstream processing.
            </li>

            <li>
              Integrated Layer: The integrated layer combines data from multiple
              sources and systems to create a unified and cohesive view of the
              data. In this layer, data engineers integrate, aggregate, and
              harmonize disparate data sets to derive meaningful insights and
              enable cross-functional analysis. Data integration techniques may
              include data blending, data consolidation, data federation, and
              master data management (MDM). The integrated layer facilitates
              advanced analytics, machine learning, and business intelligence
              (BI) applications by providing a holistic perspective of the data
              across the organization.
            </li>

            <li>
              Consumption Layer: The consumption layer is the final layer where
              data is consumed, visualized, and analyzed by end-users,
              stakeholders, and applications. This layer provides various tools,
              interfaces, and APIs for accessing and interacting with the data,
              such as data visualization tools, BI platforms, dashboards,
              reports, and APIs. Data in the consumption layer is tailored to
              meet the specific needs and preferences of different user groups,
              enabling self-service analytics, ad-hoc queries, and real-time
              insights. The consumption layer empowers decision-makers to derive
              actionable insights and drive business value from the data lake.
            </li>
          </ul>
        </Grid>
      </Grid>
    </DocumentPageLayout>
  );
};

export default DataOnboarding;
