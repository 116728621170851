import { useState } from "react";
import {
  DataBaseListButton,
  DataBaseListContainer,
} from "../Styles/TransformationStyle";
import {
  PiCaretRightLight,
  PiCaretDownLight,
  PiDatabaseLight,
  PiTableLight,
} from "react-icons/pi";
import { useSelector } from "react-redux";

const DatabaseList = ({ getColumns }) => {
  const [openDatabases, setOpenDatabases] = useState({});
  const [openTables, setOpenTables] = useState({});

  const { metaDataTables, metaDataColumns } = useSelector(
    (state) => state.metaData,
  );

  const toggleDatabase = (databaseName) => {
    setOpenDatabases((prevOpenDatabases) => ({
      ...prevOpenDatabases,
      [databaseName]: !prevOpenDatabases[databaseName],
    }));
  };

  const toggleTable = (databaseName, tableName) => {
    setOpenTables((prevOpenTables) => ({
      ...prevOpenTables,
      [`${databaseName}-${tableName}`]:
        !prevOpenTables[`${databaseName}-${tableName}`],
    }));
  };

  const groupByDataset = (data) => {
    return (
      data !== null &&
      data.reduce((acc, item) => {
        if (!acc[item.dataset_name]) {
          acc[item.dataset_name] = [];
        }
        acc[item.dataset_name].push(item.table_name);
        return acc;
      }, {})
    );
  };

  const groupedData = groupByDataset(metaDataTables);

  return (
    <DataBaseListContainer>
      {Object.keys(groupedData).map((datasetName) => (
        <div key={datasetName}>
          <div style={{ display: "flex", marginTop: "0.5em" }}>
            <DataBaseListButton
              onClick={() => toggleDatabase(datasetName)}
              style={{ cursor: "pointer", background: "transparent" }}
            >
              {openDatabases[datasetName] ? (
                <PiCaretDownLight size={"24px"} />
              ) : (
                <PiCaretRightLight size={"24px"} />
              )}
              <PiDatabaseLight size={"24px"} />
              {datasetName}
            </DataBaseListButton>
          </div>

          {openDatabases[datasetName] &&
            groupedData[datasetName].map((tableName, index) => (
              <div key={index}>
                <DataBaseListButton
                  onClick={() => toggleTable(datasetName, tableName)}
                  style={{
                    padding: "0 2.5em",
                    cursor: "grabbing",
                    background: "transparent",
                  }}
                >
                  {openTables[`${datasetName}-${tableName}`] ? (
                    <PiCaretDownLight size={"24px"} />
                  ) : (
                    <PiCaretRightLight
                      size={"24px"}
                      onClick={() => getColumns(tableName)}
                    />
                  )}
                  <PiTableLight size={"24px"} />
                  <p>{tableName}</p>
                </DataBaseListButton>
                {openTables[`${datasetName}-${tableName}`] && (
                  <div style={{ padding: "0 16px 0 4.5em" }}>
                    {metaDataColumns !== null &&
                      metaDataColumns.map((attribute, index) => (
                        <DataBaseListButton key={index}>
                          {attribute.column_name}
                        </DataBaseListButton>
                      ))}
                  </div>
                )}
              </div>
            ))}
        </div>
      ))}
    </DataBaseListContainer>
  );
};

export default DatabaseList;
