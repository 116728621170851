import React from "react";
import { ConnectorScreens, StyledDashboardContentFlex } from "../../../index";
import { useTranslation } from "react-i18next";

const MySQLConnector = ({
  inputData,
  mySQLDataValues,
  handlemySQLServerValuesChange,
  mySQLError,
  setQueryDBName,
  handlemySQLqueryDataBaseName,
  mysqlIngestions,
  mySQLselectedOption,
  taskId,
  handlemySQLSelectChange,
  mySQLoptionsArray,
  mySQLServerdataQueries,
  handlemySQLQueriesTableNameChange,
  mySQLqueries,
  handlemySQLQueriesChange,
  mySQLcheckboxValues,
  handlemySQLCheckboxChange,
  mySQLpartitonColValue,
  handlemySQLQueryPartitionChange,
  addMoremySQLServerQueries,
  mySQLtableHead,
  mySQLtableData,
  handlemySQLDatabaseNameChange,
  mySQLdatabaseNameLoader,
  mySQLdatabaseName,
  handlemySQLSchemaNameChange,
  mySQLschemas,
  handlemySQLTableNameChange,
  mySQLtables,
  handlemySQLTableIngestModeOption,
  mySQLtableOptions,
  handlemySQLDeltaColumnChange,
  mySQLdeltaColumn,
  handlemySQLPartionChange,
  handlemySQLPartitioningChange,
  mySQLpartitionColumn,
  mySQLaddRow,
  onClose,
}) => {
  const { t } = useTranslation();

  const useTableformat = true;
  return (
    <StyledDashboardContentFlex column>
      <ConnectorScreens
        HeaderText={t(
          "jobs.form.inputs.task.modal.connectors.server.mysql.title",
        )}
        inputData={inputData}
        data={mySQLDataValues}
        handleChange={handlemySQLServerValuesChange}
        errors={mySQLError}
        setQueryDBName={setQueryDBName}
        handlequeryDataBaseName={handlemySQLqueryDataBaseName}
        ingestions={mysqlIngestions}
        htmlFor={"Ingestions"}
        selectTitle={"Ingestions"}
        selectedOption={mySQLselectedOption}
        handleSelected={handlemySQLSelectChange}
        options={mySQLoptionsArray}
        dataQueries={mySQLServerdataQueries}
        handleQueriesTableNameChange={handlemySQLQueriesTableNameChange}
        queryValues={mySQLqueries}
        taskId={taskId}
        handleQueriesChange={handlemySQLQueriesChange}
        checkboxValues={mySQLcheckboxValues}
        handleCheckboxChange={handlemySQLCheckboxChange}
        queryPartitionValue={mySQLpartitonColValue}
        handleQueryPartitionChange={handlemySQLQueryPartitionChange}
        handleAddMore={addMoremySQLServerQueries}
        tableHead={mySQLtableHead}
        tableData={mySQLtableData}
        handleDatabaseNameChange={handlemySQLDatabaseNameChange}
        databaseNameLoader={mySQLdatabaseNameLoader}
        databaseName={mySQLdatabaseName}
        handleSchemaNameChange={handlemySQLSchemaNameChange}
        schemas={mySQLschemas}
        handleTableNameChange={handlemySQLTableNameChange}
        tables={mySQLtables}
        handleTableIngestModeOption={handlemySQLTableIngestModeOption}
        tableOptions={mySQLtableOptions}
        handleDeltaColumnChange={handlemySQLDeltaColumnChange}
        deltaColumn={mySQLdeltaColumn}
        handlePartionChange={handlemySQLPartionChange}
        handlePartitioningChange={handlemySQLPartitioningChange}
        partitionColumn={mySQLpartitionColumn}
        useTableformat={useTableformat}
        addRow={mySQLaddRow}
        btnOnClick={onClose}
        buttonText={t("jobs.form.inputs.task.modal.button")}
      />
    </StyledDashboardContentFlex>
  );
};

export default MySQLConnector;
