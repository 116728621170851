import React from "react";
import { useSelector } from "react-redux";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Dialog } from "@mui/material";
import CloseSvg from "../../reusableComponents/svg/CloseSvg";

import ButtonOutlineIcon from "../../reusableComponents/UIComponents/ButtonOutlineIcon";
import { StyledDashboardContentFlex } from "../../styles/Login.Styled";
import { StyledModal } from "../../styles/Modal.Styled";

import { ModalHeader } from "../../styles/Modal.Styled";
import { useTranslation } from "react-i18next";

const SchedulerModal = ({
  isScheduleModalOpen,
  setIsScheduleModalOpen,
  newJob,
  setNewJob,
  existingJob,
  proceed,
  proceedOnScheduleModal,
  setExistingJob,
}) => {
  const { getJobs, getJobLoader } = useSelector((state) => state.users);

  const { t } = useTranslation();

  return (
    <StyledModal>
      <Dialog
        open={isScheduleModalOpen}
        onClose={() => setIsScheduleModalOpen((prev) => !prev)}
        fullWidth
        PaperProps={{ sx: { borderRadius: "28px", overflow: "visible" } }}
      >
        <ModalHeader>
          <p>
            {t(
              "data_management.transformation.presenter.modal.scheduler.title",
            )}
          </p>
          <div
            onClick={() => setIsScheduleModalOpen((prev) => !prev)}
            style={{ cursor: "pointer" }}
          >
            <CloseSvg />
          </div>
        </ModalHeader>
        <StyledDashboardContentFlex column style={{ padding: "2em" }}>
          <p>
            {t("data_management.transformation.presenter.modal.scheduler.ask")}
          </p>
          <FormControl>
            <InputLabel id="demo-simple-select-label">
              {t(
                "data_management.transformation.presenter.modal.scheduler.select.label",
              )}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={newJob}
              label="Create new job?"
              onChange={(event) => setNewJob(event.target.value)}
              defaultValue={newJob}
            >
              <MenuItem value={true}>
                {t(
                  "data_management.transformation.presenter.modal.scheduler.select.options.yes",
                )}
              </MenuItem>
              <MenuItem value={false}>
                {t(
                  "data_management.transformation.presenter.modal.scheduler.select.options.no",
                )}
              </MenuItem>
            </Select>
          </FormControl>
          {newJob === false && getJobLoader && (
            <FormControl>
              <Autocomplete
                disablePortal
                value={existingJob}
                id="select-job"
                options={getJobs.map((job) => ({ label: job.job_name }))}
                onChange={(_event, newValue) => {
                  setExistingJob(newValue ? newValue.label : "");
                }}
                renderInput={(params) => <TextField {...params} label="Job" />}
              />
            </FormControl>
          )}
          <ButtonOutlineIcon
            label={t(
              "data_management.transformation.presenter.modal.scheduler.button",
            )}
            disabled={proceed}
            handleOnClick={proceedOnScheduleModal}
          ></ButtonOutlineIcon>
        </StyledDashboardContentFlex>
      </Dialog>
    </StyledModal>
  );
};

export default SchedulerModal;
