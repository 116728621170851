import React from "react";
import { PreLoader } from "../../../index";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

const tableInfosData = ({
  selectedTableOption,
  isTableInfosLoading,
  tableInfosError,
  tableInfos,
}) => {
  const formatCamelCase = (inputString) => {
    const regex = /([A-Z]?[a-z]+)/g;
    const matches = inputString.match(regex);
    if (matches) {
      const result = matches
        .map((match) => match.charAt(0).toUpperCase() + match.slice(1))
        .join(" ");
      return result;
    } else {
      return inputString;
    }
  };

  if (!selectedTableOption) {
    return (
      <Typography
        variant="h6"
        sx={{ textAlign: "center", color: "rgba(0, 0, 0, 0.6)" }}
      >
        Select Database and table to get table infos ...
      </Typography>
    );
  }

  if (isTableInfosLoading) {
    return <PreLoader marginTop={"0px"} />;
  }

  if (!tableInfosError && tableInfos) {
    return (
      <>
        <Grid
          container
          justifyContent="space-between"
          spacing={2}
          height={"100%"}
          margin={"20"}
        >
          {Object.entries(tableInfos).map(([key, value]) => (
            <Grid
              key={`${key}-${value}`}
              item
              height={"100%"}
              xl={Math.round(12 / Object.keys(tableInfos).length)}
            >
              <Paper
                sx={{
                  padding: "10px",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "16px",
                  background: " #EFFBFF",
                  boxShadow:
                    "0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: {
                      lg: 57,
                      md: 47,
                      sm: 37,
                      xs: 27,
                    },
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "64px",
                    letterSpacing: "0.25px",
                  }}
                >
                  {value}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "22px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "28px",
                  }}
                >
                  {formatCamelCase(key)}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </>
    );
  }

  return <Typography>Error to get filters ...</Typography>;
};

export default tableInfosData;
