import styled from "styled-components";

export const UploadContainer = styled.div`
  position: relative;
  width: 120px;
  height: 120px;
  overflow: hidden;
  margin: 10px;
`;

export const UploadInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;
