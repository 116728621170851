import React from "react";
import {
  MoreInfoContainer,
  PriceContainer,
  PriceLabelContainer,
  StyledBundleContainer,
  StyledBundleItemContainer,
  StyledBundleItemContent,
  StyledBundleItemLabel,
  StyledButton,
} from "./Bundle.styles";

import LightBundleLogo from "../../../images/lightBundle.png";
import StarterBundleLogo from "../../../images/starterBundle.png";
import ProBundleLogo from "../../../images/proBundle.png";
import CustomBundleLogo from "../../../images/customBundle.png";
import { useDispatch, useSelector } from "react-redux";
import { updateCustomerPlan } from "../../redux/actions/customerAction";
import useGetToken from "../../cognito/useGetToken";
import { useNavigate } from "react-router-dom";

const Bundle = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useGetToken();
  const group = localStorage.getItem("group");
  const { environmentVariables } = useSelector((state) => state.users);
  const supportLink = "https://www.dataopshouse.com/contact";

  const handleContactSupport = () => {
    window.open(supportLink, "_blank");
  };

  const bundles = [
    {
      key: "Light",
      description:
        "Light Edition with a set of started functionality to use Databoat, with environment hosted by Databoat",
      features: ["10 jobs", "5GB Storage", "Max. 2 Users"],
      price: 250,
      icon: LightBundleLogo,
    },
    {
      key: "Starter",
      description:
        "Started Edition with a set of functionality to use Databoat, with environment hosted in the customer cloud",
      features: ["20 jobs", "Max. 5 Users", "Own cloud environment"],
      price: 499,
      label: "Most Popular",
      moreInfo:
        "This plan includes all Light Edition features, plus all the max of scalability in cloud computing, since the environment is managed by the Customer",
      icon: StarterBundleLogo,
    },
    {
      key: "Pro",
      description:
        "Pro Edition with a set of functionality to use Databoat, with no limit + analytics environment",
      features: [
        "Unlimited jobs",
        "Own Cloud Environment",
        "PBI Analytics Editor and Viewer Licenses",
        "SSO Integration",
        "Max. 10 Users",
        "20 hours of DE consultancy",
      ],
      price: 799,
      moreInfo:
        "This plan includes all Light and Starter Edition features, plus Analytics PBI licenses embedded, security enhances for big corporates, expert consultants to quickly unlock your use case.",
      icon: ProBundleLogo,
    },
    {
      key: "Custom",
      description:
        "Custom configuration, environment deploy, and process capacity can be applied for this bundle",
      buttonLabel: "Talk to Sales",
      icon: CustomBundleLogo,
      onClick: handleContactSupport,
    },
  ];

  const handleUpgrade = (bundle) => {
    dispatch(
      updateCustomerPlan(
        token,
        group,
        {
          bundle,
        },
        environmentVariables.REACT_APP_BASE_URL_API_CUSTOMER,
        navigate,
      ),
    );
  };

  return (
    <StyledBundleContainer>
      {bundles.map((bundle, index) => (
        <StyledBundleItemContainer key={index}>
          <StyledBundleItemLabel className={bundle.label ? "active" : ""}>
            <p>{bundle.label}</p>
          </StyledBundleItemLabel>
          <StyledBundleItemContent className={bundle.label ? "active" : ""}>
            {bundle.icon ? (
              <img src={bundle.icon} alt={bundle.key} height={70} />
            ) : null}
            <h2>{bundle.key}</h2>
            <p className="description">{bundle.description}</p>
            <ul>
              {bundle.features?.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>

            <PriceLabelContainer>
              <PriceContainer>
                <span className="plan-price-amount">
                  {bundle.price ? `€ ${bundle.price}/` : ""}
                </span>
                <span className="plan-price-label">
                  {bundle.price ? "per month (€)" : ""}
                </span>
              </PriceContainer>
              <p>{bundle.price ? "EU West" : ""}</p>
            </PriceLabelContainer>
            <StyledButton
              variant={bundle.label ? "contained" : "outlined"}
              color="primary"
              size="large"
              onClick={() =>
                bundle.onClick ? bundle.onClick() : handleUpgrade(bundle.key)
              }
              fullWidth
            >
              {bundle.buttonLabel || "GET STARTED"}
            </StyledButton>
            {bundle.moreInfo && (
              <MoreInfoContainer>
                <p>{bundle.moreInfo}</p>
              </MoreInfoContainer>
            )}
          </StyledBundleItemContent>
        </StyledBundleItemContainer>
      ))}
    </StyledBundleContainer>
  );
};

export default Bundle;
