import React from "react";
import { useEffect, useState } from "react";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import axios from "axios";
import "./styles.css";
import ReactLoading from "react-loading";

const AnalyticsOverview = () => {
  // where will the groupId and reportId come from

  const groupId = "c165b928-431f-4029-8d7e-dc6491c14d49";
  const reportId = "2615ddad-4446-4d65-b83d-ffee414a63b6";
  // The accessToken should come from the api for now it is being generated through postman
  const accessToken =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Ik1HTHFqOThWTkxvWGFGZnBKQ0JwZ0I0SmFLcyIsImtpZCI6Ik1HTHFqOThWTkxvWGFGZnBKQ0JwZ0I0SmFLcyJ9.eyJhdWQiOiJodHRwczovL2FuYWx5c2lzLndpbmRvd3MubmV0L3Bvd2VyYmkvYXBpIiwiaXNzIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvNmFkNTE0MzQtNjFhYS00MjM0LTk4NmUtNThjNDQzMGJjMGJhLyIsImlhdCI6MTcxOTMxNDk0NiwibmJmIjoxNzE5MzE0OTQ2LCJleHAiOjE3MTkzMTg4NDYsImFpbyI6IkUyZGdZTGdyejNmM2M2UEN4dXJhRnhzVlZWZnRCZ0E9IiwiYXBwaWQiOiJmMjFlNDE4Ni04OGY4LTRmY2EtYmMyNy1iMjRhYzE5ZTg3YmYiLCJhcHBpZGFjciI6IjEiLCJpZHAiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC82YWQ1MTQzNC02MWFhLTQyMzQtOTg2ZS01OGM0NDMwYmMwYmEvIiwiaWR0eXAiOiJhcHAiLCJvaWQiOiIwOWYzOGI3ZC00NjI3LTRmMjctYWJlNC03ZGEwMjBkZjA1ODkiLCJyaCI6IjAuQVlJQU5CVFZhcXBoTkVLWWJsakVRd3ZBdWdrQUFBQUFBQUFBd0FBQUFBQUFBQUNWQUFBLiIsInN1YiI6IjA5ZjM4YjdkLTQ2MjctNGYyNy1hYmU0LTdkYTAyMGRmMDU4OSIsInRpZCI6IjZhZDUxNDM0LTYxYWEtNDIzNC05ODZlLTU4YzQ0MzBiYzBiYSIsInV0aSI6Ild5bm9DMXlRWmtLZjRYUklONzhGQUEiLCJ2ZXIiOiIxLjAiLCJ4bXNfaWRyZWwiOiIxMCA3In0.Mg-xeb6HJ__1Ih2NQVbqpNvrl1dat8BaUcMh2ByNFs5ytGT9Q201MdOJMJxQhP0etQ8xsDtYrLpbRgUHxGJUsWC443BTzA9vU-dXbCkIEYBntrlkxEKD_lf7ICIiVy7RjWND4HuS5qGG6_6aBYzjPnbH2HD5BJ3hAEft4o9XxogF1KX_ajZRVKy-kT7vuRC7UNq0IeRHXEcKd4nsi_a_6TMMMu2DGPiKv5jlEw2MqXZ2B0tSBlzAJko7YIruuP06_AQpyjEVO461x23LPGNneJXCspJ-zQFfZ5sgtKdqQofW_Ef6XxKif7udpFD2WQCGNHn0AGlf6PSz1N2C0EzELg";
  const [isLoading, setIsLoading] = useState(true);
  const [reportConfig, setReportConfig] = useState(null);

  const getEmbedReportModel = async (accessToken, groupId, reportId) => {
    const restUrl = `https://api.powerbi.com/v1.0/myorg/groups/${groupId}/reports`;

    const response = await axios.get(restUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json",
      },
    });

    const report = response.data.value.find((r) => r.id === reportId);

    return {
      id: report.id,
      embedUrl: report.embedUrl,
      name: report.name,
    };
  };

  const generateEmbedToken = async (accessToken, groupId, reportId) => {
    const apiURL = `https://api.powerbi.com/v1.0/myorg/groups/${groupId}/reports/${reportId}/GenerateToken`;
    const requestBody = {
      accessLevel: "View",
    };

    const response = await axios.post(apiURL, requestBody, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json",
      },
    });

    return response.data.token;
  };
  useEffect(() => {
    const fetchReportConfig = async () => {
      setIsLoading(true);
      try {
        // Make a request to your backend to get the tokens and embed URL
        // const { data: accessToken } = await axios.get('/api/generateAccessToken');
        const reportDetails = await getEmbedReportModel(
          accessToken,
          groupId,
          reportId,
        );
        const embedToken = await generateEmbedToken(
          accessToken,
          groupId,
          reportId,
        );

        setReportConfig({
          type: "report",
          id: reportDetails.id,
          embedUrl: reportDetails.embedUrl,
          tokenType: models.TokenType.Embed,
          accessToken: embedToken,
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: true,
              },
            },
            background: models.BackgroundType.Transparent,
          },
        });

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchReportConfig();
  }, []);

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      {!isLoading ? (
        <PowerBIEmbed
          embedConfig={reportConfig}
          cssClassName="power-bi-report-class"
          getEmbeddedComponent={(embeddedReport) => {
            window.report = embeddedReport;
          }}
          eventHandlers={
            new Map([
              ["loaded", function () {}],
              ["rendered", function () {}],
              ["error", function (event) {}],
            ])
          }
        />
      ) : (
        <div
          style={{
            flex: 1,
            width: "100%",
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#555"}
            height={40}
            width={40}
          />
          <p
            style={{
              fontSize: 20,
              fontWeight: "100",
              marginTop: 10,
              color: "#222",
            }}
          >
            Loading Data...
          </p>
        </div>
      )}
    </div>
  );
};

export default AnalyticsOverview;
