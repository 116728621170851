import React, { useState } from "react";
import { ReusableFieldSet } from "./FieldSet";
const RenderIngestion = ({
  htmlFor,
  selectTitle,
  selectedOption,
  handleSelected,
  options,
}) => {
  const [isSelectedOptionFocused, setIsSelectedOptionFocused] = useState("");
  return (
    <ReusableFieldSet
      title={selectTitle}
      isFocus={isSelectedOptionFocused}
      children={
        <select
          value={selectedOption}
          onChange={handleSelected}
          className="paddingClass"
          name="select ingestion"
          onFocus={() => setIsSelectedOptionFocused(true)}
          onBlur={() => setIsSelectedOptionFocused(false)}
        >
          {options.map((option) => (
            <option key={option.id} value={option.options}>
              {option.title}
            </option>
          ))}
        </select>
      }
    />
  );
};

export default RenderIngestion;
