import axios from "axios";
import axiosErrorHandler from "./errorHandler";

const createAxiosInstance = (baseURL, headers = {}) => {
  const instance = axios.create({
    baseURL,
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      const handledError = axiosErrorHandler(error);
      return Promise.reject(handledError);
    },
  );

  return instance;
};

export default createAxiosInstance;
