import styled from "styled-components";

export const ShapeContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.connectBackgroundcolor};
  .bottom {
    border-bottom: 2px solid ${({ theme }) => theme.colors.right_align_color};
    width: 120px;
  }
  .vline {
    border-right: 2px solid ${({ theme }) => theme.colors.right_align_color};
    height: 50px;
  }
  .top {
    border-top: 2px solid ${({ theme }) => theme.colors.right_align_color};
    width: 300px;
    background-color: white;
  }
  .full_bottom {
    border-bottom: 2px solid ${({ theme }) => theme.colors.right_align_color};
    width: 100%;
  }
  .connect {
    display: flex;
    font-weight: bold;
    padding: 1em;
    gap: 1em;
    text-align: center;
  }
`;
