import React from "react";
import { Grid } from "@mui/material";
import ReactPlayer from "react-player";
import DocumentPageLayout from "../DocumentPageLayout";

const Api = () => {
  const breadcrumbs = [
    {
      name: "Ingesting your data using API",
      link: "#api",
    },
    {
      name: "API Connector",
      link: "#api-connector",
    },
  ];

  return (
    <DocumentPageLayout breadcrumbs={breadcrumbs}>
      <Grid container rowSpacing={4}>
        <Grid item>
          <h1 id="api">Ingesting your data using API</h1>

          <p>
            Check this step by step video - How to easily onboard data on
            DataBoat data platform:
          </p>

          <ReactPlayer
            url="https://vid.cdn-website.com/b29d841e/videos/RMzpL56tShaKNJH9FcsD_API_Connector-v.mp4"
            controls={true}
          />

          <h2 id="api-connector">API Connector</h2>

          <h4>Step 1: Configure Your Source</h4>
          <ul>
            <li>
              Click on "Configure Your Source" to start defining your data
              workflow.
            </li>
          </ul>
          <h4>Step 2: Define Workflow Details</h4>
          <ul>
            <li>Enter a name for the workflow.</li>
            <li>Set the start time of the workflow based on date and time.</li>
            <li>
              Determine the execution frequency of the pipeline by selecting one
              of the predefined options.
            </li>
          </ul>
          <h4>Step 3: Set Up Tasks</h4>
          <ul>
            <li>
              Add tasks to your workflow. Note that you can include additional
              tasks based on data dependencies with different sources.
            </li>
            <li>
              For each task, define relevant parameters for the job orchestrator
              using key-value pairs in the UI or paste your JSON key-value map.
            </li>
          </ul>
          <h4>Step 4: Choose Task Type and Connector</h4>
          <ul>
            <li>Select the task type, either Ingestion or Transformation.</li>
            <li>If Ingestion is selected, choose an existing API connector.</li>
          </ul>
          <h4>Step 5: Configure API Connection Attributes</h4>
          <ul>
            <li>Enter the API URL from which data will be ingested.</li>
            <li>
              Provide the API Client ID and API Client Secret for authentication
              purposes.
            </li>
            <li>
              Specify the Table Name where the ingested data will be stored.
            </li>
            <li>
              Toggle the API OAuth Flag to On/Off based on whether OAuth is
              required for the API.
            </li>
            <li>
              Select the API HTTP Method (GET, POST, PUT, DELETE) that will be
              used to retrieve data.
            </li>
            <li>
              Optionally, add Additional Key and Values for Header Params to
              customize the API request headers as needed.
            </li>
          </ul>
          <h4>Step 6: Finalize Workflow</h4>
          <ul>
            <li>
              Optionally, add tags to the workflow for easier identification and
              management.
            </li>
            <li>
              Set up notifications to alert you about the workflow's execution
              status.
            </li>
          </ul>
          <h4>Step 7: Create and Execute Job</h4>
          <ul>
            <li>
              Review all settings, then save the workflow to create the job.
            </li>
            <li>
              The job is now ready to be executed and will process data
              according to the specified parameters and schedule.
            </li>
          </ul>
        </Grid>
      </Grid>
    </DocumentPageLayout>
  );
};

export default Api;
