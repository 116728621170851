import React from "react";
import {
  StyledDashboardContentFlex,
  ModalHeader,
  Button,
  StyledModal,
  Flex,
  StyledConnectorInput,
} from "../../index";
import { Dialog } from "@mui/material";
import CloseSvg from "../../reusableComponents/svg/CloseSvg";
import ButtonOutlineIcon from "../../reusableComponents/UIComponents/ButtonOutlineIcon";

const CreateTableModal = ({
  tableName,
  datasetName,
  setDatasetName,
  tableError,
  setTableName,
  createTableModal,
  setCreateTableModal,
  handleSaveAsTable,
}) => {
  const handleTableNameChange = (e) => {
    const value = e.target.value;
    setTableName(value);
  };
  const handleDataSetChange = (e) => {
    const value = e.target.value;
    setDatasetName(value);
  };

  return (
    <StyledModal>
      <Dialog
        open={createTableModal}
        onClose={() => setCreateTableModal(false)}
        fullWidth
        PaperProps={{ sx: { borderRadius: "28px" } }}
      >
        <ModalHeader>
          <p>Enter your Table Details</p>
          <div onClick={() => setCreateTableModal(false)}>
            <CloseSvg />
          </div>
        </ModalHeader>
        <StyledDashboardContentFlex column style={{ padding: "2em" }}>
          <Flex>
            <StyledConnectorInput
              placeholder="Enter your database name"
              name="dataset-name"
              value={datasetName}
              onChange={handleDataSetChange}
            />
            <StyledConnectorInput
              placeholder="Enter your table name"
              name="table-name"
              value={tableName}
              onChange={handleTableNameChange}
            />
            <p className="error">{tableError}</p>
          </Flex>

          <Flex style={{ flexDirection: "row-reverse" }}>
            <Button
              onClick={handleSaveAsTable}
              disabled={tableName === ""}
              text={"Save"}
              className={"next_btn save_btn"}
            />
            <div onClick={() => setCreateTableModal(false)}>
              <ButtonOutlineIcon label={"Cancel"} />
            </div>
          </Flex>
        </StyledDashboardContentFlex>
      </Dialog>
    </StyledModal>
  );
};
export default CreateTableModal;
