import React from "react";
import { StyledPortalContent, H3 } from "../styles/Styled";

const LineageOverview = () => {
  return (
    <StyledPortalContent>
      <H3>Lineage</H3>
    </StyledPortalContent>
  );
};

export default LineageOverview;
