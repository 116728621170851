import { useState, useEffect, useRef } from "react";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import { useGetPoolData } from "../../../../clientRequest/env-variable";
import { fetchUserScripts } from "../../../redux/actions/customerAction";
import {
  fetchSingleJob,
  patchJob,
  warmUpFetchDatabaseName,
} from "../../../redux/actions/usersActions";
import useAPI from "../../connectors/customHook/useAPI";
import useGCS from "../../connectors/customHook/useGCS";
import useS3 from "../../connectors/customHook/useS3";
import usePostgress from "../../connectors/customHook/usePostgress";
import useSQL from "../../connectors/customHook/useSQL";
import useMYSQL from "../../connectors/customHook/useMYSQL";
import useBigQuery from "../../connectors/customHook/useBigQuery";
import useOracle from "../../connectors/customHook/useOracle";
import useExcat from "../../connectors/customHook/useExact";
import { useTranslation } from "react-i18next";
import useGetToken from "../../../cognito/useGetToken";
import usePipedrive from "../../connectors/customHook/usePipeDrive";
import { useActiveCampaing } from "../../connectors/customHook/useActiveCampaign";

function identifyCronFrequency(cronExpression) {
  const minute = cronExpression[0];
  const hour = cronExpression[1];
  const dayOfMonth = cronExpression[2];
  const month = cronExpression[3];
  const dayOfWeek = cronExpression[4];

  if (
    minute !== "*" &&
    hour === "*" &&
    dayOfMonth === "*" &&
    month === "*" &&
    dayOfWeek === "*"
  ) {
    return "Hourly";
  }

  if (
    minute !== "*" &&
    hour !== "*" &&
    dayOfMonth === "*" &&
    month === "*" &&
    dayOfWeek === "*"
  ) {
    return "Daily";
  }

  if (
    minute !== "*" &&
    hour !== "*" &&
    dayOfMonth === "*" &&
    month === "*" &&
    dayOfWeek !== "*"
  ) {
    return "Weekly";
  }

  return "custom";
}

const useEditJobHook = () => {
  const group = localStorage.getItem("group");
  const userEmail = localStorage.getItem("created");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { name } = useParams();
  const jobNameRef = useRef(name);
  const getPoolData = useGetPoolData();
  const {
    getSingleJob,
    environmentVariables,
    getSingleJobLoader,
    scriptloading,
  } = useSelector((state) => state.users);
  const { userScript } = useSelector((state) => state.customers);
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [selectedText, setSelectedText] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [isConnectorModalOpen, setIsConnectorModalOpen] = useState(false);
  const [alertValue, setAlertValue] = useState("");
  const [tags, setTags] = useState("");
  const [emailto, setEmailto] = useState("");
  const [emailcc, setEmailcc] = useState("");
  const [emailmessage, setEmailMessage] = useState("");
  const [slackURL, setSlackURL] = useState("");
  const [teamsURL, setTeamsURL] = useState("");
  const [emailsubject, setEmailSubject] = useState("");
  const { token } = useGetToken();
  const setQueryDBName = useState(true);
  const inputData = useState(true);
  const [mysqlIngestions, setMYSQLIngestions] = useState(false);
  const [sqlIngestions, setSQLIngestions] = useState(false);
  const [PostgresSQLIngestions, setPostgresSQLIngestions] = useState(false);
  const [OracleIngestions, setOracleIngestions] = useState(false);
  const groupName = localStorage.getItem("group");
  const [reversedModalOpen, setReversedModalOpen] = useState(false);
  const [isReversedConnectorOpen, setIsReversedConnectorOpen] = useState(false);
  
  useEffect(() => {
    if (token) {
      dispatch(
        fetchSingleJob(
          token,
          environmentVariables.REACT_APP_BASE_URL_API_CORE,
          jobNameRef.current,
        ),
      );
      dispatch(
        fetchUserScripts(
          token,
          groupName,
          userEmail,
          environmentVariables.REACT_APP_BASE_URL_API_CUSTOMER,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, dispatch]);

  const [startDate, setStartDate] = useState("");
  const [frequencyOption, setFrequencyOption] = useState("");
  const [startHourMinutes, setStartHourMinutes] = useState("");
  const [dailyTime, setDailyTime] = useState("");
  const [startWeeklyTime, setStartWeeklyTime] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);
  const [taskToEdit, setTaskToEdit] = useState(null);

  const { t } = useTranslation();

  const label = t("jobs.form.inputs.frequency.select.label");
  const hourly = t("jobs.form.inputs.frequency.select.hourly");
  const daily = t("jobs.form.inputs.frequency.select.daily");
  const weekly = t("jobs.form.inputs.frequency.select.weekly");

  const frequencydata = [
    { id: 1, option: "", title: label },
    { id: 2, title: hourly, option: "Hourly" },
    { id: 3, title: daily, option: "Daily" },
    { id: 4, title: weekly, option: "Weekly" },
  ];
  //start date
  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleTimeChange = (event) => {
    const timeChange = event.target.value;
    // Check if the input value matches the time format "HH:mm"
    const timeRegex = /^(?:[01]\d|2[0-3]):[0-5]\d$/;
    if (timeRegex.test(timeChange) || timeChange === "") {
      setTime(timeChange);
    }
  };

  useEffect(() => {
    if (date && time) {
      const combinedDateTime = new Date(`${date}T${time}`);
      setStartDate(combinedDateTime.toISOString());
    } else {
      setStartDate("");
    }
  }, [date, time]);

  const handleStartHourMinutesChange = (e) => {
    setStartHourMinutes(e.target.value);
  };

  const handleDailyTimeChange = (e) => {
    setDailyTime(e.target.value);
  };

  const handleWeeklyStartTimeChange = (e) => {
    setStartWeeklyTime(e.target.value);
  };

  const handleDayToggle = (day) => {
    const isSelected = selectedDays.includes(day);
    if (isSelected) {
      setSelectedDays(
        selectedDays.filter((selectedDay) => selectedDay !== day),
      );
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };
  //------- ends here

  //frequency
  const handleFrequencyOption = (e) => {
    const selected = e.target.value;
    setFrequencyOption(selected);
  };

  let schedulerResult = useRef(null);
  if (date) {
    if (frequencyOption === "Hourly") {
      const cronExpression = format(
        new Date(`${date}T00:00`),
        `${startHourMinutes} * * * *`,
      );
      schedulerResult.current = cronExpression;
    } else if (frequencyOption === "Daily") {
      const [hours, minutes] = dailyTime ? dailyTime.split(":") : ["00", "00"];
      const cronExpression = format(
        new Date(`${date}T00:00`),
        `${minutes} ${hours} * * *`,
      );
      schedulerResult.current = cronExpression;
    } else if (frequencyOption === "Weekly") {
      const cronExpression = format(
        new Date(`${date}T${startWeeklyTime ? startWeeklyTime : "00:00"}`),
        "m H * *",
      );
      const selectedDaysExpression = selectedDays
        .map((day) => day.substring(0, 3))
        .join(",");
      schedulerResult.current = `${cronExpression} ${selectedDaysExpression}`;
    }
  }

  const handleTaskTypeChange = (event, id) => {
    const selected = event.target.value;
    const updatedData = taskData.map((data) => {
      if (data.id === id && data.task_type !== selected) {
        return {
          ...data,
          task_type: selected,
          source_type: selected === "transformation" && "sql",
          ...(selected === "ingestion" && { source: "" }),
          ...(selected === "ingestion" && { code_path: "" }),
        };
      }
      return data;
    });
    setTaskData(updatedData);
    setTaskToEdit(id)
    setIsConnectorModalOpen(selected === "ingestion");
    setIsReversedConnectorOpen(selected === "reversed_sync_pipedrive");
  };

  const handleSourceFieldChange = (event, id) => {
    const selected = event.target.value;
    const updatedData = taskData.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          source: selected,
        };
      }
      return data;
    });
    setTaskData(updatedData);
  };

  const handleCodePathChange = (fileName, id) => {
    const updatedData = taskData.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          code_path: fileName,
        };
      }
      return data;
    });
    setTaskData(updatedData);
  };

  const handleParametersTypeChange = (event, id) => {
    const selected = event.target.value;
    const updatedData = taskData.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          parameters_type: selected,
        };
      }
      return data;
    });
    setTaskData(updatedData);
  };

  //UI FORMAT
  const handleKeyChange = (event, id) => {
    const selected = event.target.value;
    const updatedTaskData = taskData.map((data) => {
      if (data.id === id) {
        data.parameters.map((item) => {
          item.key = selected;
          return item;
        });
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };
  const handleValueChange = (event, id) => {
    const selected = event.target.value;
    const updatedTaskData = taskData.map((data) => {
      if (data.id === id) {
        data.parameters.map((item) => {
          item.value = selected;
          return item;
        });
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };
  //--------ENDS HERE
  //JSON FORMAT

  const [jsonData, setJsonData] = useState({
    key: "",
    value: "",
  });

  const handleJsonDataChange = (event, id) => {
    const newText = event.target.value;
    try {
      const parsedData = JSON.parse(newText);
      const updatedTaskData = taskData.map((data) => {
        if (data.id === id) {
          data.parameters.map((item) => {
            item.key = jsonData.key;
            item.value = jsonData.value;
            return item;
          });
        }
        return data;
      });
      setJsonData(parsedData);
      setTaskData(updatedTaskData);
    } catch (error) {
      console.error("Invalid JSON:", error);
    }
  };

  const formattedJson = JSON.stringify(jsonData, undefined, 2);
  //---------ENDS HERE
  const handleTagsChange = (event) => {
    setTags(event.target.value);
  };
  const handleAlertValueChange = (event) => {
    setAlertValue(event.target.value);
  };
  const handleEmailccChange = (event) => {
    setEmailcc(event.target.value);
  };
  const handleEmailToChange = (event) => {
    setEmailto(event.target.value);
  };
  const handleEmailMessageChange = (event) => {
    setEmailMessage(event.target.value);
  };
  const handleEmailSubjectChange = (event) => {
    setEmailSubject(event.target.value);
  };
  const handleSlackURLChange = (event) => {
    setSlackURL(event.target.value);
  };
  const handleTeamsURLChange = (event) => {
    setTeamsURL(event.target.value);
  };
  // --------- ends here

  //open connector modals
  const openModal = (component, text) => {
    setSelectedText(text);
    setSelectedComponent(component);
    if (text === "MySQL" || text === "SQL Server" || text === "Postgres SQL") {
      dispatch(
        warmUpFetchDatabaseName(
          environmentVariables.REACT_APP_TABLE_API,
          token,
        ),
      );
    }
    setModalOpen(true);
  };

  const closeModal = () => {
    // setSelectedText("");
    setSelectedComponent(null);
    setModalOpen(false);
  };

  const onClose = () => {
    closeModal();
    setIsConnectorModalOpen(false);
    setIsReversedConnectorOpen(false);
    // setSelectedText("");
  };
 
  const openReversedModal = (component, text, taskId) => {
    setSelectedText(text);
    setSelectedComponent(component);
    setReversedModalOpen(true);
    localStorage.setItem("currentTaskId", taskId);
  };
  const closeReversedModal = () => {
    setSelectedComponent(null);
    setReversedModalOpen(false);
    setIsReversedConnectorOpen(false);
    localStorage.removeItem("currentTaskId");
  };

  const onReversedClose = () => {
    setIsReversedConnectorOpen(false);
    setReversedModalOpen(false);
    setSelectedComponent(null);
    localStorage.removeItem("currentTaskId");
  };

  const closeReversedConnector = () => {
    setIsReversedConnectorOpen(false);
  };
  const [taskData, setTaskData] = useState([]);
  useEffect(() => {
    if (getSingleJobLoader) {
      const dateWithTimezone = new Date(
        new Date(getSingleJob.start_date).toUTCString(),
      );

      setDate(format(dateWithTimezone, "yyyy-MM-dd"));
      setTime(format(dateWithTimezone, "HH:mm"));
      schedulerResult.current = getSingleJob.schedule_interval.split(" ");
      const minute = schedulerResult.current[0];
      const hour = schedulerResult.current[1];
      // const dayOfMonth = schedulerResult.current[2];
      // const month = schedulerResult.current[3];
      const dayOfWeek = schedulerResult.current[4];
      setStartHourMinutes(minute);

      const frequency = identifyCronFrequency(schedulerResult.current);
      if (frequency === "Daily") {
        setDailyTime(
          `${hour.length === 1 ? hour.padStart(2, "0") : hour}:${
            minute.length === 1 ? `${minute}`.padStart(2, "0") : minute
          }`,
        );
      }

      if (frequency === "Weekly") {
        setStartWeeklyTime(
          `${hour.length === 1 ? hour.padStart(2, "0") : hour}:${
            minute.length === 1 ? `${minute}`.padStart(2, "0") : minute
          }`,
        );
        setSelectedDays(dayOfWeek.split(","));
      }

      setFrequencyOption(frequency);

      const preFilledScriptData = {
        taskName: searchParams.get("taskName"),
        taskType: searchParams.get("taskType"),
        script: searchParams.get("script"),
      };
      // eslint-disable-next-line no-unused-vars
      const { taskName, taskType, script } = preFilledScriptData;
      const tasks = getSingleJob.tasks.map((task, index) => ({
        id: index + 1,
        task_name: task.task_name,
        task_execution_status: "",
        depends_on: [],
        parallel: "",
        source_type: task.source_type,
        scriptName: task.scriptName,
        script: task.script,
        parameters:
          Array.isArray(task.parameters) &&
          task.parameters.length > 0
            ? task.parameters
            : [{ key: "", value: "" }],
        parameters_type: "UI",
        task_type: task.task_type,
        engine_type: "",
        params: {
          division_var: task?.params?.division_var || "",
          input_db_host: task?.params?.input_db_host || "",
          input_db_port: task?.params?.input_db_port || "",
          input_db_user: task?.params?.input_db_user || "",
          input_db_pass: task?.params?.input_db_pass || "",
          query_db_name: task?.params?.query_db_name || "",
          selected: "",
          queries: [],
          tables: [],
          file_format: task?.params?.file_format || "",
          is_zipped: "",
          header: task?.params?.header || "",
          separator: task?.params?.separator || "",
          charset: task?.params?.charset || "",
          destination_bucket: "",
          source_bucket: task?.params?.source_bucket || "",
          api_url: task?.params?.api_url || "",
          api_client_id: task?.params?.api_client_id || "",
          api_client_secret: task?.params?.api_client_secret || "",
          api_header_param: task?.params?.api_header_param || { "": "" },
          api_oauth_flag: task?.params?.api_oauth_flag || "",
          api_oauth_server: "",
          api_http_method: task?.params?.api_http_method || "",
          table_name: task?.params?.table_name || "",
          partition_columns: task?.params?.partition_columns || null,
          credentials_json: task?.params?.credentials_json || null,
          location: task?.params?.location || "",
          bq_tables: [],
        },
      }));

      preFilledScriptData.taskName &&
        tasks.push({
          id: getSingleJob.length + 1,
          task_name: taskName,
          task_execution_status: "",
          depends_on: [],
          parallel: "",
          source_type: "sql",
          scriptName: taskName,
          script: script,
          parameters: [
            {
              key: "",
              value: "",
            },
          ],
          parameters_type: "",
          task_type: taskType,
          engine_type: "",
          params: {
            input_db_host: "",
            input_db_port: "",
            input_db_user: "",
            input_db_pass: "",
            query_db_name: "",
            selected: "",
            queries: [],
            tables: [],
            file_format: "",
            is_zipped: "",
            header: "",
            separator: "",
            charset: "",
            destination_bucket: "",
            source_bucket: "",
            api_url: "",
            api_client_id: "",
            api_client_secret: "",
            api_header_param: { "": "" },
            api_oauth_flag: "",
            api_oauth_server: "",
            api_http_method: "",
            table_name: "",
            partition_columns: null,
            credentials_json: null,
            location: "",
            bq_tables: [],
          },
        });

      setTaskData(tasks);
      setTags(getSingleJob.tags);
      setAlertValue(getSingleJob.alerts.type);
      setEmailto(getSingleJob.alerts.params.to_email_addresses);
      setEmailcc(getSingleJob.alerts.params.cc_email_addresses);
      setEmailSubject(getSingleJob.alerts.params.subject_email);
      setSlackURL(getSingleJob.alerts.params.webhook_url);
      setTeamsURL(getSingleJob.alerts.params.webhook_url);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getSingleJob.schedule_interval,
    getSingleJob.start_date,
    getSingleJob.tags,
    getSingleJob.tasks,
    getSingleJobLoader,
    scriptloading,
    searchParams,
  ]);
  //  ends here

  const handleTaskNameChange = (event, id) => {
    const selected = event.target.value;
    const updatedData = taskData.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          task_name: selected,
        };
      }
      return data;
    });
    setTaskData(updatedData);
  };
  const handleScriptNameChange = (id, newValue) => {
    if (!newValue) {
      return;
    }

    const [folderName, scriptName] = newValue.label.split("/");

    let scriptValue = null;

    userScript.forEach((item) => {
      if (item.folderName === folderName) {
        const foundScript = item.scripts.find(
          (script) => script.scriptName === scriptName,
        );
        if (foundScript) {
          scriptValue = foundScript.script;
        }
      }
    });

    const updatedData = taskData.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          scriptName: newValue.label ? `${userEmail}/${newValue.label}` : "",
          script: scriptValue,
        };
      }
      return data;
    });
    setTaskData(updatedData);
  };
  //  Function to handle adding more rows
  const handleAddMoreTask = () => {
    setTaskData((previousTaskData) => {
      return [
        ...previousTaskData,
        {
          id: previousTaskData.length + 1,
          task_name: "",
          task_execution_status: "",
          depends_on: [],
          parallel: "",
          source_type: "",
          scriptName: "",
          script: "",
          parameters: [
            {
              key: "",
              value: "",
            },
          ],
          parameters_type: "",
          task_type: "",
          engine_type: "",
          params: {
            input_db_host: "",
            input_db_port: "",
            input_db_user: "",
            input_db_pass: "",
            query_db_name: "",
            selectedOption: "",
            queries: [],
            tables: [],
            file_format: "",
            is_zipped: "",
            header: "",
            separator: "",
            charset: "",
            destination_bucket: "",
            source_bucket: "",
            api_url: "",
            api_client_id: "",
            api_client_secret: "",
            api_header_param: { "": "" },
            api_oauth_flag: "",
            api_oauth_server: "",
            api_http_method: "",
            table_name: "",
            partition_columns: null,
            credentials_json: null,
            location: "",
            bq_tables: [
              {
                project_id: null,
                dataset_id: null,
                table_id: null,
                load_type: null,
                partition_columns: null,
                delta_column: null,
              },
            ],
          },
        },
      ];
    });

    setInputValue((previousInputValue) => {
      return [
        ...previousInputValue,
        {
          sourceBucket: "",
          header: false,
          fileFormat: "",
          seperator: "",
          charset: "",
          id: previousInputValue.length + 1,
        },
      ];
    });
    setDBParamsValue((previousSQLServerValue) => {
      return [
        ...previousSQLServerValue,
        {
          host: "",
          port: "",
          user: "",
          password: "",
          id: previousSQLServerValue.length + 1,
        },
      ];
    });
    setmySQLParamsValue((previousmySQLServerValue) => {
      return [
        ...previousmySQLServerValue,
        {
          host: "",
          port: "",
          user: "",
          password: "",
          id: previousmySQLServerValue.length + 1,
        },
      ];
    });
    setAPIInputValue((previousAPIInputValue) => {
      return [
        ...previousAPIInputValue,
        {
          api_url: "",
          api_client_id: "",
          api_client_secret: "",
          api_header_param: { "": "" },
          api_oauth_flag: "",
          id: previousAPIInputValue.length + 1,
        },
      ];
    });
    setpostgresParamsValue((previouspostgresServerValue) => {
      return [
        ...previouspostgresServerValue,
        {
          host: "",
          port: "",
          user: "",
          password: "",
          id: previouspostgresServerValue.length + 1,
        },
      ];
    });
    setoracleParamsValue((previousoracleServerValue) => {
      return [
        ...previousoracleServerValue,
        {
          host: "",
          port: "",
          user: "",
          password: "",
          id: previousoracleServerValue.length + 1,
        },
      ];
    });
    setGCSValue((previousGCSValue) => {
      return [
        ...previousGCSValue,
        {
          sourceBucket: "",
          header: false,
          fileFormat: "",
          seperator: "",
          charset: "",
          id: previousGCSValue.length + 1,
        },
      ];
    });
    setBigQueryInputValue((previousValue) => {
      return [
        ...previousValue,
        {
          location: "",
          id: previousValue.length + 1,
        },
      ];
    });
    setExcatValue((previousExcatValue) => {
      return [
        ...previousExcatValue,
        {
          division_var: "",
          client_id: "",
          aws_secret_access_key: "",
          aws_session_token: "",
          id: previousExcatValue.length + 1,
        },
      ];
    });
  };
  //-----S3
  const [
    s3fileName,
    setInputValue,
    S3Data,
    handleChange,
    handleS3SourceBucketChange,
    handleS3SourceBucketBlur,
    s3fileNameError,
    handleS3TaskPartitionColumn,
  ] = useS3(taskData, setTaskData);
  //---ENDS HERE ------------------
  //-----DataBase starts-------------------------------------------------------------------

  //---------SQLSERVER
  const [
    dbDataValues,
    setDBParamsValue,
    handleSQLServerValuesChange,
    sqlError,
    handlequeryDataBaseName,
    selectedOption,
    handleSelectChange,
    optionsArray,
    sqlServerdataQueries,
    handleQueriesTableNameChange,
    queries,
    handleQueriesChange,
    checkboxValues,
    handleCheckboxChange,
    partitonColValue,
    handleQueryPartitionChange,
    addMoreSQLServerQueries,
    tableHead,
    tableData,
    handleDatabaseNameChange,
    databaseNameLoader,
    databaseName,
    handleSchemaNameChange,
    schemas,
    handleTableNameChange,
    tables,
    handleTableIngestModeOption,
    tableOptions,
    handleDeltaColumnChange,
    deltaColumn,
    handlePartionChange,
    handlePartitioningChange,
    partitionColumn,
    addRow,
  ] = useSQL(token, taskData, setTaskData, setSQLIngestions);

  ///----------------------------------------------MySQL Server
  const [
    mySQLDataValues,
    setmySQLParamsValue,
    handlemySQLServerValuesChange,
    mySQLError,
    handlemySQLqueryDataBaseName,
    mySQLselectedOption,
    handlemySQLSelectChange,
    mySQLoptionsArray,
    mySQLServerdataQueries,
    handlemySQLQueriesTableNameChange,
    mySQLqueries,
    handlemySQLQueriesChange,
    mySQLcheckboxValues,
    handlemySQLCheckboxChange,
    mySQLpartitonColValue,
    handlemySQLQueryPartitionChange,
    addMoremySQLServerQueries,
    mySQLtableHead,
    mySQLtableData,
    handlemySQLDatabaseNameChange,
    mySQLdatabaseNameLoader,
    mySQLdatabaseName,
    handlemySQLSchemaNameChange,
    mySQLschemas,
    handlemySQLTableNameChange,
    mySQLtables,
    handlemySQLTableIngestModeOption,
    mySQLtableOptions,
    handlemySQLDeltaColumnChange,
    mySQLdeltaColumn,
    handlemySQLPartionChange,
    handlemySQLPartitioningChange,
    mySQLpartitionColumn,
    mySQLaddRow,
  ] = useMYSQL(token, taskData, setTaskData, setMYSQLIngestions);
  ///----------------------------------------------POSTGRES Server
  const [
    postgresDataValues,
    setpostgresParamsValue,
    handlepostgresServerValuesChange,
    postgresError,
    handlepostgresqueryDataBaseName,
    postgresselectedOption,
    handlepostgresSelectChange,
    postgresoptionsArray,
    postgresServerdataQueries,
    handlepostgresQueriesTableNameChange,
    postgresqueries,
    handlepostgresQueriesChange,
    postgrescheckboxValues,
    handlepostgresCheckboxChange,
    postgrespartitonColValue,
    handlepostgresQueryPartitionChange,
    addMorepostgresServerQueries,
    postgrestableHead,
    postgrestableData,
    handlepostgresDatabaseNameChange,
    postgresdatabaseNameLoader,
    postgresdatabaseName,
    handlepostgresSchemaNameChange,
    postgresschemas,
    handlepostgresTableNameChange,
    postgrestables,
    handlepostgresTableIngestModeOption,
    postgrestableOptions,
    handlepostgresDeltaColumnChange,
    postgresdeltaColumn,
    handlepostgresPartionChange,
    handlepostgresPartitioningChange,
    postgrespartitionColumn,
    postgresaddRow,
  ] = usePostgress(token, taskData, setTaskData, setPostgresSQLIngestions);
  //------oracle
  const [
    oracleDataValues,
    setoracleParamsValue,
    handleoracleServerValuesChange,
    oracleError,
    oracleselectedOption,
    handleoracleSelectChange,
    oracleoptionsArray,
    oracleServerdataQueries,
    handleoracleQueriesTableNameChange,
    oraclequeries,
    handleoracleQueriesChange,
    oraclecheckboxValues,
    handleoracleCheckboxChange,
    oraclepartitonColValue,
    handleoracleQueryPartitionChange,
    addMoreoracleServerQueries,
    oracletableHead,
    oracletableData,
    handleoracleSchemaNameChange,
    oracleschemas,
    handleoracleTableNameChange,
    oracletables,
    handleoracleTableIngestModeOption,
    oracletableOptions,
    handleoracleDeltaColumnChange,
    oracledeltaColumn,
    handleoraclePartionChange,
    handleoraclePartitioningChange,
    oraclepartitionColumn,
    oracleaddRow,
  ] = useOracle(token, taskData, setTaskData, setOracleIngestions);
  //------API
  const [
    ApiData,
    setAPIInputValue,
    handleAPIChange,
    apiHttp,
    httpData,
    httpvalue,
    handleHttpChange,
    renderKeyValue,
    apihandleKeyChange,
    apihandleValueChange,
  ] = useAPI(taskData, setTaskData);
  //-----ENDS HERE-------------------------------------------------------------------------
  //GCS----------------------------------
  const [
    setGCSValue,
    GCSData,
    handleGCSChange,
    handleGCSSourceBucketChange,
    handleGCSSourceBucketBlur,
    gcsfileNameError,
    handleGCSTaskPartitionColumn,
  ] = useGCS(taskData, setTaskData);
  const [
    bigQueryData,
    setBigQueryInputValue,
    handleBigQueryValueChange,
    credential,
    credentialError,
    handleCredentialJsonChange,
    bqTables,
    bigQueryTableHead,
    addBqTablesRow,
    handleProjectIdChange,
    datasets,
    handleDataSetIdChange,
    allTables,
    handleTableIdChange,
    isTableId,
    handleBigQueryLoadTypeChange,
    allDeltaColumn,
    handleBigQueryDeltaColumnChange,
    handleBigQueryPartionChange,
    allPartitionColumn,
    handleBigQueryPartitioningChange,
    handleOutputTableName,
  ] = useBigQuery(taskData, setTaskData);
  //---excat
  const [
    setExcatValue,
    excatData,
    handleExcatChange,
    isRequiredFieldFilled,
    excatTableHead,
    myExcatloadType,
    excatTableData,
    excatTableOptions,
    handleSwitchChange,
    handleSyncModeChange,
    handlePrimaryKeyChange,
  ] = useExcat(taskData, setTaskData);
  //-----Ends here
  // -- PIPEDRIVE
  const [
    pipeDriveApiKey,
    pipeDriveSetApiKey,
    pipeDriveObjects,
    pipeDriveHandleObjectSelection,
    pipeDriveHandleAttrSelection,
    pipeDriveFetchEndpoints,
    pipeDriveConvertSelectedToApi,
  ] = usePipedrive(token, taskData);

   //-----ActiveCampaign
   const [
    activeCampaignUrl,
    activeCampaignSetUrl,
    activeCampaignApiKey,
    activeCampaignSetApiKey,
    activeCampaignData,
    activeCampaignSearch,
    activeCampaignHandleSearch,
    activeCampaignSelected,
    activeCampaignHandleAttrSelection,
    activeCampaignExpanded,
    activeCampaignHandleExpand,
    activeCampaignHandleAdd,
  ] = useActiveCampaing(token, taskData, setTaskData);
  //-----Ends Here

  const handleRemoveFromUI = (id) => {
    const updatedWords = taskData.filter((item) => item.id !== id);
    setTaskData(updatedWords);
  };
  const handlePatchJob = () => {
    taskData.forEach((item) => {
      if (item.task_type === "ingestion") {
        const curatedTask = {
          params: {
            fileset: [
              {
                source_bucket: null,
                destination_bucket: null,
                partition_columns: null,
              },
            ],
          },
          depends_on: [],
        };
        curatedTask.parallel = "false";
        curatedTask.source_type = "transformation-curated";
        curatedTask.task_type = "ingestion";
        curatedTask.depends_on.push(item.task_name);
        curatedTask.task_name = `${item.task_name}_to_curated`;

        //for gcs,s3
        if (item.params.source_bucket) {
          const uriParts = item.params.source_bucket.split("/");
          const fileNameWithExtension = uriParts[uriParts.length - 1];
          const fileName = fileNameWithExtension.split(".")[0];
          curatedTask.params.fileset[0].source_bucket = `databoat-raw-${group}/${fileName}`;
          curatedTask.params.fileset[0].destination_bucket = `databoat-curated-${group}/${fileName}`;
          curatedTask.params.fileset[0].partition_columns =
            item.params.partition_columns;
        }
        //for api connector
        if (item.params.table_name !== "") {
          curatedTask.params.fileset[0].source_bucket = `databoat-raw-${group}/${item.params.table_name}`;
          curatedTask.params.fileset[0].destination_bucket = `databoat-curated-${group}/${item.params.table_name}`;
        }
        //for db connectors
        if (!!item.params.queries[0]?.table_name) {
          item.params.queries.forEach((query, index) => {
            curatedTask.params.fileset[index] = {
              source_bucket: `databoat-raw-${group}/${query.table_name}`,
              destination_bucket: `databoat-curated-${group}/${query.table_name}`,
              partition_columns: query.partition_columns,
            };
          });
        }

        if (!!item.params.tables[0]?.input_table_name) {
          item.params.tables.forEach((row, index) => {
            curatedTask.params.fileset[index] = {
              source_bucket: `databoat-raw-${group}/${row.input_table_name}`,
              destination_bucket: `databoat-curated-${group}/${row.input_table_name}`,
              partition_columns: row.partition_columns || null,
            };
          });
        }
        if (!!item.params.bq_tables[0]?.output_table_name) {
          item.params.bq_tables.forEach((row, index) => {
            curatedTask.params.fileset[index] = {
              source_bucket: `databoat-raw-${group}/${row.output_table_name}`,
              destination_bucket: `databoat-curated-${group}/${row.output_table_name}`,
              partition_columns: row.partition_columns || null,
            };
          });
        }
        taskData.push(curatedTask);
      }
    });
    const newTaskData = taskData.map((item) => {
      const { parameters_type, selected, engine_type, id, ...rest } = item;
      return {
        ...rest,
        params: {
          ...item.params,
          queries: item.params.queries?.length > 0 ? item.params.queries : null,
          tables: item.params.tables?.length > 0 ? item.params.tables : null,
        },
      };
    });
    const webhookUrl =
      alertValue === "Slack"
        ? slackURL
        : alertValue === "Teams"
        ? teamsURL
        : "";
    const formData = {
      job_name: jobNameRef.current,
      group_name: group,
      start_date: startDate,
      schedule_interval: schedulerResult.current,
      last_execution_date: "",
      last_execution_status: "",
      created_by: userEmail,
      tags: tags,
      tasks: newTaskData,
      alerts: {
        type: alertValue,
        params: {
          to_email_addresses: emailto,
          cc_email_addresses: emailcc,
          subject_email: emailsubject,
          webhook_url: webhookUrl,
        },
      },
    };
    const UserPool = new CognitoUserPool(getPoolData);
    const cognitoUser = UserPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          throw err;
        } else {
          if (session.isValid()) {
            const token = session.getIdToken().jwtToken;
            dispatch(
              patchJob(
                jobNameRef.current,
                formData,
                token,
                environmentVariables.REACT_APP_BASE_URL_API_CORE,
              ),
            );
            navigate("/yedaflow");
          } else {
            cognitoUser.signOut();
            localStorage.clear();
            navigate("/");
          }
        }
      });
    }
  };
  return {
    jobNameRef,
    date,
    handleDateChange,
    time,
    handleTimeChange,
    frequencyOption,
    handleFrequencyOption,
    frequencydata,
    startHourMinutes,
    handleStartHourMinutesChange,
    dailyTime,
    handleDailyTimeChange,
    selectedDays,
    handleDayToggle,
    startWeeklyTime,
    handleWeeklyStartTimeChange,
    //task
    taskData,
    handleTaskNameChange,
    handleParametersTypeChange,
    handleKeyChange,
    handleValueChange,
    formattedJson,
    handleJsonDataChange,
    handleTaskTypeChange,
    handleCodePathChange,
    handleSourceFieldChange,

    //CONNECTORS
    isConnectorModalOpen,
    onClose,
    openModal,
    closeModal,
    modalOpen,
    selectedComponent,
    selectedText,
    inputData,
    //s3
    s3fileName,
    S3Data,
    handleChange,
    handleS3SourceBucketChange,
    handleS3SourceBucketBlur,
    s3fileNameError,
    handleS3TaskPartitionColumn,
    //----------SQL SERVER ----------------------------------------------------
    dbDataValues,
    handleSQLServerValuesChange,
    sqlError,
    setQueryDBName,
    handlequeryDataBaseName,
    sqlIngestions,
    selectedOption,
    handleSelectChange,
    optionsArray,
    sqlServerdataQueries,
    handleQueriesTableNameChange,
    queries,
    handleQueriesChange,
    checkboxValues,
    handleCheckboxChange,
    partitonColValue,
    handleQueryPartitionChange,
    addMoreSQLServerQueries,
    tableHead,
    tableData,
    handleDatabaseNameChange,
    databaseNameLoader,
    databaseName,
    handleSchemaNameChange,
    schemas,
    handleTableNameChange,
    tables,
    handleTableIngestModeOption,
    tableOptions,
    handleDeltaColumnChange,
    deltaColumn,
    handlePartionChange,
    handlePartitioningChange,
    partitionColumn,
    addRow,
    //-----------------MYSQL
    mySQLDataValues,
    handlemySQLServerValuesChange,
    mySQLError,
    handlemySQLqueryDataBaseName,
    mysqlIngestions,
    mySQLselectedOption,
    handlemySQLSelectChange,
    mySQLoptionsArray,
    mySQLServerdataQueries,
    handlemySQLQueriesTableNameChange,
    mySQLqueries,
    handlemySQLQueriesChange,
    mySQLcheckboxValues,
    handlemySQLCheckboxChange,
    mySQLpartitonColValue,
    handlemySQLQueryPartitionChange,
    addMoremySQLServerQueries,
    mySQLtableHead,
    mySQLtableData,
    handlemySQLDatabaseNameChange,
    mySQLdatabaseNameLoader,
    mySQLdatabaseName,
    handlemySQLSchemaNameChange,
    mySQLschemas,
    handlemySQLTableNameChange,
    mySQLtables,
    handlemySQLTableIngestModeOption,
    mySQLtableOptions,
    handlemySQLDeltaColumnChange,
    mySQLdeltaColumn,
    handlemySQLPartionChange,
    handlemySQLPartitioningChange,
    mySQLpartitionColumn,
    mySQLaddRow,
    //--------postgress
    //------------------POSTGRESS
    postgresDataValues,
    handlepostgresServerValuesChange,
    postgresError,
    handlepostgresqueryDataBaseName,
    PostgresSQLIngestions,
    postgresselectedOption,
    handlepostgresSelectChange,
    postgresoptionsArray,
    postgresServerdataQueries,
    handlepostgresQueriesTableNameChange,
    postgresqueries,
    handlepostgresQueriesChange,
    postgrescheckboxValues,
    handlepostgresCheckboxChange,
    postgrespartitonColValue,
    handlepostgresQueryPartitionChange,
    addMorepostgresServerQueries,
    postgrestableHead,
    postgrestableData,
    handlepostgresDatabaseNameChange,
    postgresdatabaseNameLoader,
    postgresdatabaseName,
    handlepostgresSchemaNameChange,
    postgresschemas,
    handlepostgresTableNameChange,
    postgrestables,
    handlepostgresTableIngestModeOption,
    postgrestableOptions,
    handlepostgresDeltaColumnChange,
    postgresdeltaColumn,
    handlepostgresPartionChange,
    handlepostgresPartitioningChange,
    postgrespartitionColumn,
    postgresaddRow,
    //oracle
    oracleDataValues,
    handleoracleServerValuesChange,
    oracleError,
    OracleIngestions,
    oracleselectedOption,
    handleoracleSelectChange,
    oracleoptionsArray,
    oracleServerdataQueries,
    handleoracleQueriesTableNameChange,
    oraclequeries,
    handleoracleQueriesChange,
    oraclecheckboxValues,
    handleoracleCheckboxChange,
    oraclepartitonColValue,
    handleoracleQueryPartitionChange,
    addMoreoracleServerQueries,
    oracletableHead,
    oracletableData,
    handleoracleSchemaNameChange,
    oracleschemas,
    handleoracleTableNameChange,
    oracletables,
    handleoracleTableIngestModeOption,
    oracletableOptions,
    handleoracleDeltaColumnChange,
    oracledeltaColumn,
    handleoraclePartionChange,
    handleoraclePartitioningChange,
    oraclepartitionColumn,
    oracleaddRow,
    //API
    ApiData,
    handleAPIChange,
    apiHttp,
    httpData,
    httpvalue,
    handleHttpChange,
    renderKeyValue,
    apihandleKeyChange,
    apihandleValueChange,
    //-------GCS
    GCSData,
    handleGCSChange,
    handleGCSSourceBucketChange,
    gcsfileNameError,
    handleGCSSourceBucketBlur,
    handleGCSTaskPartitionColumn,
    //---bigquery
    bigQueryData,
    handleBigQueryValueChange,
    credential,
    credentialError,
    handleCredentialJsonChange,
    bqTables,
    bigQueryTableHead,
    addBqTablesRow,
    handleProjectIdChange,
    datasets,
    handleDataSetIdChange,
    allTables,
    handleTableIdChange,
    isTableId,
    handleBigQueryLoadTypeChange,
    allDeltaColumn,
    handleBigQueryDeltaColumnChange,
    handleBigQueryPartionChange,
    allPartitionColumn,
    handleBigQueryPartitioningChange,
    handleOutputTableName,
    //---excat
    excatData,
    handleExcatChange,
    isRequiredFieldFilled,
    excatTableHead,
    myExcatloadType,
    excatTableData,
    excatTableOptions,
    handleSwitchChange,
    handleSyncModeChange,
    handlePrimaryKeyChange,
    //----------------ENDS ---------
    // -- PIPEDRIVE
    pipeDriveApiKey,
    pipeDriveSetApiKey,
    pipeDriveObjects,
    pipeDriveHandleObjectSelection,
    pipeDriveHandleAttrSelection,
    pipeDriveFetchEndpoints,
    pipeDriveConvertSelectedToApi,
    // ----
    // -- ACTIVE CAMPAIGN
    activeCampaignUrl,
    activeCampaignSetUrl,
    activeCampaignApiKey,
    activeCampaignSetApiKey,
    activeCampaignData,
    activeCampaignSearch,
    activeCampaignHandleSearch,
    activeCampaignSelected,
    activeCampaignHandleAttrSelection,
    activeCampaignExpanded,
    activeCampaignHandleExpand,
    activeCampaignHandleAdd,
    // --
    handleScriptNameChange,
    //reversed
    isReversedConnectorOpen,
    closeReversedConnector,
    // handleEngineType,
    handleAddMoreTask,
    handleRemoveFromUI,
    tags,
    handleTagsChange,
    alertValue,
    handleAlertValueChange,
    emailto,
    handleEmailToChange,
    emailcc,
    handleEmailccChange,
    emailsubject,
    handleEmailSubjectChange,
    emailmessage,
    handleEmailMessageChange,
    slackURL,
    handleSlackURLChange,
    teamsURL,
    handleTeamsURLChange,
    handlePatchJob,
    onReversedClose,
    closeReversedModal,
    openReversedModal,
    reversedModalOpen,
    taskToEdit,
  };
};

export default useEditJobHook;
