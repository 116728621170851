import React from "react";
import { AiFillCaretDown, AiFillCaretUp, AiOutlineCheck } from "react-icons/ai";
import styled from "styled-components";

export const StyledButton = styled.button`
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.sysSurfaceActive : theme.colors.secondaryColor};
  border-radius: 8px;
  border: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.colors.sysSurfaceActive : theme.colors.borderColor};
  display: inline-flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: ${({ theme, selected }) =>
    selected
      ? theme.colors.sysSecondaryContainer
      : theme.colors.placeholderText};
  text-align: center;
  letter-spacing: ${({ theme }) => theme.fonts.primaryLetterSpacing};
  font: ${({ theme }) => theme.fonts.fontPrimary};
  padding: 6px 8px 6px 16px;
  gap: 8px;
  min-width: 100px;
`;

const ChipLabelIcon = ({
  label = "button",
  handleOnClick,
  open = false,
  selected = false,
}) => {
  return (
    <StyledButton selected={selected} onClick={handleOnClick}>
      {selected && <AiOutlineCheck />}
      {label} {open ? <AiFillCaretUp /> : <AiFillCaretDown />}
    </StyledButton>
  );
};

export default ChipLabelIcon;
