import React from "react";
import { DataTypeButton, PreLoader } from "../../../index";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
const FiltersDataType = ({
  selectedTableOption,
  isTableFiltersLoading,
  tableFiltersError,
  dataTypeFilterButtons,
}) => {
  if (!selectedTableOption) {
    return (
      <Typography
        variant="h6"
        sx={{ textAlign: "center", color: "rgba(0, 0, 0, 0.6)" }}
      >
        Select Database and table to get filters ...
      </Typography>
    );
  }

  if (isTableFiltersLoading) {
    return <PreLoader marginTop={"0px"} />;
  }

  if (!tableFiltersError && dataTypeFilterButtons.length) {
    return (
      <>
        <Typography variant="h5">Data Type</Typography>
        <Grid
          container
          spacing={2}
          margin={"20"}
          alignItems="center"
          justifyContent="center"
          height={"100%"}
        >
          {dataTypeFilterButtons.map((item, index) => (
            <Grid
              key={`${item}-${index}`}
              item
              xl={Math.round(12 / dataTypeFilterButtons.length)}
            >
              <DataTypeButton
                key={item.label}
                selected={item.selected}
                onClick={item.onClick}
              >
                {item.label}
              </DataTypeButton>
            </Grid>
          ))}
        </Grid>
      </>
    );
  }

  return <Typography>Error to get filters ...</Typography>;
};

export default FiltersDataType;
