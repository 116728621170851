import { useState } from "react";
import { useSelector } from "react-redux";
import { axiosGetJobs } from "../../../../clientRequest/axiosRequest";
import { useTranslation } from "react-i18next";

const useBigQuery = (token, taskData, setTaskData) => {
  const { environmentVariables } = useSelector((state) => state.users);
  const baseURL = `${environmentVariables.REACT_APP_TABLE_API}/yeda/database-metadata/bigquery`;
  const group = localStorage.getItem("group");
  const [bigQueryInputValue, setBigQueryInputValue] = useState([
    {
      location: "",
      id: 1,
    },
  ]);

  const { t } = useTranslation();

  const bigQueryData = [
    {
      id: 1,
      name: "location",
      title: t(
        "jobs.form.inputs.task.modal.connectors.big_query.inputs.location",
      ),
      checkbox: false,
      defaultValue: "",
      required: true,
    },
  ];
  const handleBigQueryValueChange = (event, id) => {
    const { name, value } = event.target;
    bigQueryInputValue.map((data) => {
      if (data.id === id) {
        switch (name) {
          case "location":
            data.location = value;
            break;
          default:
            console.log("error");
        }
      }
      return data;
    });

    const currentInput = bigQueryInputValue.find((input) => input.id === id);

    taskData.map((data) => {
      if (data.id === id) {
        data.params.location = currentInput.location;
        data.params.destination_bucket = `databoat-raw-${group}`;
        data.source_type = "BQ";
      }
      return data;
    });
  };
  const [credentialError, setCredentialError] = useState("");
  const [credential, setCredential] = useState("");
  //credential json
  const handleCredentialJsonChange = (event, taskId) => {
    try {
      setCredentialError("");
      const credentials = JSON.parse(event.target.value);
      const updatedData = taskData.map((data) => {
        if (data.id === taskId) {
          data.params.credentials_json = credentials;
        }
        return data;
      });
      setCredential(credentials);
      setTaskData(updatedData);
    } catch (error) {
      setCredentialError(
        "Error: The provided string is not valid JSON, credentials must start and end with {}",
      );
    }
  };
  //tables
  const [isChecked, setIsChecked] = useState();
  const [isTableId, setIsTableId] = useState(false);
  const [loadType, setLoadType] = useState("");
  const [bqTables, setBQTables] = useState(() => [
    {
      id: 1,
      project_id: null,
      dataset_id: null,
      table_id: null,
      load_type: null,
      partitioning: false,
      partition_columns: null,
      delta_column: null,
      output_table_name: null,
    },
  ]);
  const bigQueryTableHead = [
    { id: 1, title: "Project ID" },
    { id: 2, title: "Dataset ID" },
    { id: 3, title: "Table ID" },
    { id: 4, title: "Ingestion Mode" },
    loadType === "delta" && { id: 5, title: "Delta_Column" },
    { id: 6, title: "Partition" },
    isChecked && { id: 7, title: "Partition_Column" },
    isTableId && { id: 8, title: "Output Table Name" },
  ].filter(Boolean);

  const addBqTablesRow = () => {
    setBQTables((previousTableData) => {
      return [
        ...previousTableData,
        {
          id: previousTableData.length + 1,
          project_id: null,
          dataset_id: null,
          table_id: null,
          load_type: null,
          partitioning: false,
          partition_columns: null,
          delta_column: null,
          output_table_name: null,
        },
      ];
    });
  };
  const [datasets, setDatasets] = useState([]);
  const [allTables, setTables] = useState([]);
  const [allPartitionColumn, setPartitionColumn] = useState([]);
  const [allDeltaColumn, setDeltaColumn] = useState([]);

  const handleProjectIdChange = async (event, id, taskId) => {
    const selected = event.target.value;
    if (selected && credential) {
      await fetchDatasets(credential, selected);
    } else {
      setCredentialError("field cannot be empty");
    }
    const updatedtableData = bqTables.map((data) => {
      if (data.id === id) {
        return { ...data, project_id: selected };
      }
      return data;
    });
    setBQTables(updatedtableData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        const existingTableIndex = data.params.bq_tables.findIndex(
          (item) => item.id === id,
        );
        if (existingTableIndex !== -1) {
          // Table with the given id exists, update it
          data.params.bq_tables[existingTableIndex].project_id = selected;
        } else {
          // Table with the given id doesn't exist, create a new one
          data.params.bq_tables.push({
            id: id,
            project_id: selected,
          });
        }
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };
  const handleDataSetIdChange = async (event, id, taskId) => {
    const selected = event.target.value;
    const currentDbParam = bqTables.find((data) => data.id === taskId);
    const selectedProjectId = currentDbParam.project_id;

    if (credential && selectedProjectId && selected) {
      await fetchTables(credential, selectedProjectId, selected);
    }
    const updatedtableData = bqTables.map((data) => {
      if (data.id === id) {
        return { ...data, dataset_id: selected };
      }
      return data;
    });
    setBQTables(updatedtableData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        const existingTableIndex = data.params.bq_tables.findIndex(
          (item) => item.id === id,
        );
        if (existingTableIndex !== -1) {
          // Table with the given id exists, update it
          data.params.bq_tables[existingTableIndex].dataset_id = selected;
        } else {
          // Table with the given id doesn't exist, create a new one
          data.params.bq_tables.push({
            id: id,
            dataset_id: selected,
          });
        }
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };
  const handleTableIdChange = async (event, id, taskId) => {
    const selected = event.target.value;
    const updatedtableData = bqTables.map((data) => {
      if (data.id === id) {
        return { ...data, table_id: selected };
      }
      return data;
    });
    setBQTables(updatedtableData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        const existingTableIndex = data.params.bq_tables.findIndex(
          (item) => item.id === id,
        );
        if (existingTableIndex !== -1) {
          // Table with the given id exists, update it
          data.params.bq_tables[existingTableIndex].table_id = selected;
        } else {
          // Table with the given id doesn't exist, create a new one
          data.params.bq_tables.push({
            id: id,
            table_id: selected,
          });
        }
      }
      return data;
    });
    setIsTableId(true);
    setTaskData(updatedTaskData);
  };

  const handleBigQueryLoadTypeChange = async (event, id, taskId) => {
    const selectedValue = event.target.value;
    const currentDbParam = bqTables.find((data) => data.id === taskId);
    const selectedProjectId = currentDbParam.project_id;
    const selectedDataSetId = currentDbParam.dataset_id;
    const selectedTableId = currentDbParam.table_id;
    if (
      credential &&
      selectedProjectId &&
      selectedDataSetId &&
      selectedTableId
    ) {
      await fetchDeltaColumns(
        credential,
        selectedProjectId,
        selectedDataSetId,
        selectedTableId,
      );
    }
    const updatedtableData = bqTables.map((data) => {
      if (data.id === id) {
        return { ...data, load_type: selectedValue };
      }
      return data;
    });
    setBQTables(updatedtableData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        const existingTableIndex = data.params.bq_tables.findIndex(
          (item) => item.id === id,
        );
        if (existingTableIndex !== -1) {
          // Table with the given id exists, update it
          data.params.bq_tables[existingTableIndex].load_type = selectedValue;
        } else {
          // Table with the given id doesn't exist, create a new one
          data.params.bq_tables.push({
            id: id,
            load_type: selectedValue,
          });
        }
      }
      return data;
    });
    setLoadType(selectedValue);
    setTaskData(updatedTaskData);
  };
  const handleBigQueryDeltaColumnChange = (event, id, taskId) => {
    const updatedtableData = bqTables.map((data) => {
      if (data.id === id) {
        return { ...data, delta_column: event.target.value };
      }
      return data;
    });
    setBQTables(updatedtableData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.bq_tables.map((item) => {
          item.delta_column = event.target.value;
          return item;
        });
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };
  const handleBigQueryPartionChange = async (event, id, taskId) => {
    const selected = event.target.checked;
    setIsChecked(selected);
    const currentDbParam = bqTables.find((data) => data.id === taskId);
    const selectedProjectId = currentDbParam.project_id;
    const selectedDataSetId = currentDbParam.dataset_id;
    const selectedTableId = currentDbParam.table_id;
    if (
      credential &&
      selectedProjectId &&
      selectedDataSetId &&
      selectedTableId
    ) {
      await fetchPartitionColumns(
        credential,
        selectedProjectId,
        selectedDataSetId,
        selectedTableId,
      );
    }
    const updatedtableData = bqTables.map((data) => {
      if (data.id === id) {
        return { ...data, partitioning: selected };
      }
      return data;
    });
    setBQTables(updatedtableData);
  };

  const handleBigQueryPartitioningChange = (event, id, taskId) => {
    const value = event.target.value; //list seperated by comma
    const updatedtableData = bqTables.map((data) => {
      if (data.id === id) {
        return { ...data, partition_columns: value };
      }
      return data;
    });
    setBQTables(updatedtableData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        const existingTableIndex = data.params.bq_tables.findIndex(
          (item) => item.id === id,
        );
        if (existingTableIndex !== -1) {
          // Table with the given id exists, update it
          data.params.bq_tables[existingTableIndex].partition_columns = value;
        } else {
          // Table with the given id doesn't exist, create a new one
          data.params.bq_tables.push({
            id: id,
            partition_columns: value,
          });
        }
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };
  const handleOutputTableName = async (event, id, taskId) => {
    const selected = event.target.value;
    const updatedtableData = bqTables.map((data) => {
      if (data.id === id) {
        return { ...data, output_table_name: selected };
      }
      return data;
    });
    setBQTables(updatedtableData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        const existingTableIndex = data.params.bq_tables.findIndex(
          (item) => item.id === id,
        );
        if (existingTableIndex !== -1) {
          // Table with the given id exists, update it
          data.params.bq_tables[existingTableIndex].output_table_name =
            selected;
        } else {
          // Table with the given id doesn't exist, create a new one
          data.params.bq_tables.push({
            id: id,
            output_table_name: selected,
          });
        }
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const fetchDatasets = async (credentials, project_id) => {
    const formData = {
      credentials: credentials,
      project_id: project_id,
    };
    const response = await axiosGetJobs(baseURL).post(
      "/list-datasets",
      formData,
      { headers },
    );
    setDatasets(response.data);
  };
  const fetchTables = async (credentials, project_id, dataset_id) => {
    const formData = {
      credentials: credentials,
      project_id: project_id,
      dataset_id: dataset_id,
    };
    const response = await axiosGetJobs(baseURL).post(
      "/list-tables",
      formData,
      { headers },
    );
    setTables(response.data);
  };
  const fetchPartitionColumns = async (
    credentials,
    project_id,
    dataset_id,
    table_id,
  ) => {
    const formData = {
      credentials: credentials,
      project_id: project_id,
      dataset_id: dataset_id,
      table_id: table_id,
    };
    const response = await axiosGetJobs(baseURL).post(
      "/list-columns",
      formData,
      { headers },
    );
    setPartitionColumn(response.data);
  };
  const fetchDeltaColumns = async (
    credentials,
    project_id,
    dataset_id,
    table_id,
  ) => {
    const formData = {
      credentials: credentials,
      project_id: project_id,
      dataset_id: dataset_id,
      table_id: table_id,
    };
    const response = await axiosGetJobs(baseURL).post("/list-date", formData, {
      headers,
    });
    setDeltaColumn(response.data);
  };

  return [
    bigQueryData,
    setBigQueryInputValue,
    handleBigQueryValueChange,
    credential,
    credentialError,
    handleCredentialJsonChange,
    bqTables,
    bigQueryTableHead,
    addBqTablesRow,
    handleProjectIdChange,
    datasets,
    handleDataSetIdChange,
    allTables,
    handleTableIdChange,
    isTableId,
    handleBigQueryLoadTypeChange,
    allDeltaColumn,
    handleBigQueryDeltaColumnChange,
    handleBigQueryPartionChange,
    allPartitionColumn,
    handleBigQueryPartitioningChange,
    handleOutputTableName,
  ];
};

export default useBigQuery;
