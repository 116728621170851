import React from "react";

import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import getCredentials from "./getCredentials";

const useS3Function = () => {
  const [urls, setUrls] = React.useState(null);
  const s3Client = new S3Client(getCredentials());
  const upload = async (files) => {
    try {
      // Uploading to S3 and constructing URLs
      const currentDate = new Date();
      const s3Url = await Promise.all(
        files.map(async (file, index) => {
          const params = {
            Bucket: process.env.REACT_APP_BUCKET_NAME,
            Key: `${currentDate.getFullYear()}/${file.name}`,
            Body: file,
            ContentType: file.type,
            ACL: "private",
          };
          const command = new PutObjectCommand(params);
          await s3Client.send(command);
          return `s3://${params.Bucket}/${params.Key}`;
        }),
      );
      setUrls(s3Url);
    } catch (error) {
      console.error("Error uploading files to S3:", error);
    }
  };
  return {
    upload,
    urls,
  };
};

export default useS3Function;
