/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  createTheme,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  ThemeProvider,
  Stack,
} from "@mui/material";
import PipedriveInstructionsImage from "../../../../images/pipedrive-instructions.png";
import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useGetToken from "../../../cognito/useGetToken";
import SearchBar from "./pipedrive/SearchBar";
import TabsComponent from "./pipedrive/TabsComponent";

/**
 * Custom theme for Tooltip to override default styles.
 */
const customTooltipTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxWidth: "100%",
          padding: "0.5rem",
        },
      },
    },
  },
});


const mergePreviousSaved = (objects, previousSavedObjects) => {
  return objects.map(object => {
    const prevObjArray = previousSavedObjects.find(obj => obj.endpoint === object.endpoint)
    if (!prevObjArray) return object;

    return {
      ...object,
      isSelected: true,
      attributes: object.attributes.map(attribute => ({
        ...attribute,
        active: prevObjArray.attributes.some(attr => attr === attribute.name)
      }))
    }
  })
}

/**
 * @typedef {Object} PipedriveConnectorProps
 * @property {Function} onClose - Function to close the connector.
 */

/**
 * Main component for the Pipedrive Connector.
 * @param {PipedriveConnectorProps} props
 * @returns {JSX.Element}
 */
const PipedriveConnector = ({ 
  apiKey,
  setApiKey,
  objects,
  handleObjectSelection,
  handleAttrSelection,
  fetchEndpoints,
  convertSelectedToApi,
  onClose,
  previousSavedObjects,
 }) => {
  const { t } = useTranslation();

  const { token } = useGetToken();
  const groupName = localStorage.getItem("group");
  const { environmentVariables } = useSelector(
    (state) => state.users,
  );

  const [filterObject, setFilterObject] = useState("");
  const [tab, setTab] = useState("1");
  const [filteredObjects, setFilteredObjects] = useState(objects);

  const handleClose = () => {
    convertSelectedToApi();
    onClose();
  };

  /**
   * Fetch endpoints when token is available.
   */
  useEffect(() => {
    if (token) {
      fetchEndpoints(
        token,
        groupName,
        environmentVariables.REACT_APP_BASE_URL_API_CORE,
        (objects) => mergePreviousSaved(objects, previousSavedObjects)
      );
    }
  }, [token]);

  /**
   * Filters objects based on the search input.
   */
  useEffect(() => {
    filterOnObjects();
  }, [filterObject]);

  /**
   * Updates filteredObjects when objects change.
   */
  useEffect(() => {
    filterOnObjects();
  }, [objects]);

  /**
   * Filters objects based on the search input.
   */
  const filterOnObjects = () => {
    if (!filterObject) {
      setFilteredObjects(objects);
      return;
    }

    const filteredObjs = objects.filter(o => o.endpoint.toUpperCase().includes(filterObject.toUpperCase()));

    setFilteredObjects(filteredObjs);
  };

  /**
   * Handles changes in the search input.
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  const handleSearchChange = (event) => {
    setFilterObject(event.target.value);
  };

  return (
    <Stack direction={"column"} spacing={2} style={{ padding: "2em" }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Typography variant="subtitle1">
          Inform the API Key from Personal Preferences
        </Typography>
        <ThemeProvider theme={customTooltipTheme}>
          <Tooltip
            title={
              <Box>
                <img
                  src={PipedriveInstructionsImage}
                  alt="How to find API Key"
                  style={{ width: "40rem" }}
                />
              </Box>
            }
            placement="right"
          >
            <IconButton sx={{ ml: 1 }}>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </ThemeProvider>
      </Box>

      <TextField
        fullWidth
        label="Enter your API Key"
        value={apiKey}
        onChange={(e) => setApiKey(e.target.value)}
        variant="outlined"
        sx={{ mb: 3 }}
      />

      <Typography variant="h6">Object</Typography>

      <SearchBar
        label="Search object by name"
        value={filterObject}
        onChange={handleSearchChange}
      />

      <TabsComponent
        tab={tab}
        setTab={setTab}
        filteredObjects={filteredObjects}
        handleObjectSelection={handleObjectSelection}
        handleAttrSelection={handleAttrSelection}
      />

      <div>
        <button className={"next_btn save_btn"} onClick={handleClose}>
          {t("jobs.form.inputs.task.modal.button")}
        </button>
      </div>
    </Stack>
  );
};

export default PipedriveConnector;
