const initialState = {
  postJobLoader: false,
  jobError: null,
  getJobs: [],
  getJobLoader: false,
  getJobError: null,
  getSingleJob: {},
  postScriptLoader: false,
  postScriptError: null,
  postScriptSuccess: "",
  postJobSuccess: {},
  getSingleJobLoader: false,
  getSingleJobError: null,
  getJobsAnalytics: {},
  getCountAnalytics: {},
  getCountAnalyticsLoader: false,
  getCountAnalyticsError: null,
  postManualUploadLoader: false,
  postManualUploadError: false,
  postManualUploadResponse: {},
  patchJobLoader: false,
  patchJobError: false,
  patchJobSuccess: {},
  getJobAnalyticsLoader: false,
  getJobAnalyticsError: null,
  getExecutions: [],
  getExecutionsLoader: false,
  getExecutionsError: null,
  postLacasData: {},
  getCustomers: [],
  getCustomersLoading: false,
  getCustomersError: null,
  getTransformationScript: [],
  scriptloading: false,
  scripterror: null,
  getawsRegion: [],
  postCustomers: {},
  getBundles: [],
  loading: false,
  awsLoader: false,
  postCustomerLoader: false,
  error: null,
  bundleLoader: false,
  postLacasLoader: false,
  environmentVariables: {},
  envLoader: false,
  databaseName: [],
  nullDatabaseNameReturn: null,
  databaseNameLoader: false,
  databaseNameError: null,
  warmUpdatabaseName: [],
  warmUpdatabaseNameLoader: false,
  getJWTToken: "",
  userGroup: "",
  userEmail: "",
  getLocalStorageJWTToken: localStorage.getItem("token") || null,
  cognitoRefreshToken: "",
  getnewToken: "",
  getnewIDToken: localStorage.getItem("newtoken") || null,
  isDropdownActive: null,
  isJobEditing: false,
  active: null,
  activeDropdown: null,
  userSession: {},
  verifyTokenLoading: false,
  verifyTokenPayload: null,
  verifyTokenError: null,
  patchCustomers: {},
  isLoggedIn: false,
  postQuerySuccess: "",
  postQueryLoader: false,
  postQueryError: null,
  getQueryResultSuccess: null,
  getQueryResultLoader: false,
  getQueryResultError: null,
  getQueryResultStatus: "",
  getGPTQueryResultSuccess: null,
  getGPTQueryResultLoader: false,
  getGPTQueryResultError: null,
  getGPTQueryResultStatus: "",
  postGPTQuerySuccess: undefined,
  postGPTQueryLoader: false,
  postGPTQueryError: null,
  getDRAGQueryResultSuccess: null,
  getDRAGQueryResultLoader: false,
  getDRAGQueryResultError: null,
  getDRAGQueryResultStatus: "",
  postDRAGQuerySuccess: undefined,
  postDRAGQueryLoader: false,
  postDRAGQueryError: null,
  postGPTLlmQuestion: null,
  postGPTLlmQuestionLoader: false,
  postGPTLlmResultLoader: false,
  postGPTLlmQuestionError: null,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TOKEN":
      return {
        ...state,
        getJWTToken: action.payload,
      };
    case "SET_GROUP":
      return {
        ...state,
        userGroup: action.payload,
      };
    case "SET_IS_JOB_EDITING":
      return {
        ...state,
        isJobEditing: action.payload,
      };
    case "SET_EMAIL":
      return {
        ...state,
        userEmail: action.payload,
      };
    case "SET_LOGIN":
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case "SET_USER_SESSION":
      return {
        ...state,
        userSession: action.payload,
      };
    case "GET_TOKEN":
      return {
        ...state,
        getLocalStorageJWTToken: action.payload,
      };
    case "SET_REFRESH_TOKEN":
      return {
        ...state,
        cognitoRefreshToken: action.payload,
      };
    case "NEW_TOKEN":
      return {
        ...state,
        getnewToken: action.payload,
      };
    case "NEW_ID_TOKEN":
      return {
        ...state,
        getnewIDToken: action.payload,
      };
    case "SET_NEW_DROPDOWN_LOADER":
      return {
        ...state,
        isDropdownActive: action.payload,
      };

    case "TOKEN_VALIDATION_START":
      return {
        ...state,
        verifyTokenLoading: false,
        verifyTokenPayload: null,
        verifyTokenError: null,
      };
    case "TOKEN_VALIDATION_SUCCESS":
      return {
        ...state,
        verifyTokenLoading: true,
        verifyTokenPayload: action.payload,
        verifyTokenError: null,
      };
    case "TOKEN_VALIDATION_FAILURE":
      return {
        ...state,
        verifyTokenLoading: false,
        verifyTokenPayload: null,
        verifyTokenError: action.payload,
      };
    case "TRANSFORMATION_SCRIPT_LOADING":
      return {
        ...state,
        scriptloading: false,
        scripterror: null,
      };
    case "TRANSFORMATION_SCRIPT_SUCCESS":
      return {
        ...state,
        getTransformationScript: action.payload,
        scriptloading: true,
        scripterror: null,
      };
    case "TRANSFORMATION_SCRIPT_ERROR":
      return {
        ...state,
        scriptloading: false,
        scripterror: action.payload,
      };

    case "FETCH_CUSTOMER_DATA_REQUEST_LOADING":
      return {
        ...state,
        getCustomersLoading: false,
        getCustomersError: null,
      };
    case "FETCH_CUSTOMER_DATA_REQUEST_SUCCESS":
      return {
        ...state,
        getCustomers: action.payload,
        getCustomersLoading: true,
        getCustomersError: null,
      };
    case "FETCH_CUSTOMER_DATA_REQUEST_ERROR":
      return {
        ...state,
        getCustomersLoading: false,
        getCustomersError: action.payload,
      };
    case "GET_AWS_REGION_LOADING":
      return {
        ...state,
        awsLoader: false,
        error: null,
      };
    case "GET_AWS_REGION_SUCCESS":
      return {
        ...state,
        getawsRegion: action.payload,
        awsLoader: true,
        error: null,
      };
    case "GET_AWS_REGION_ERROR":
      return {
        ...state,
        awsLoader: false,
        error: action.payload,
      };
    case "GET_JOBS_LOADING":
      return {
        ...state,
        getJobLoader: false,
        getJobError: null,
      };
    case "GET_JOBS_SUCCESS":
      return {
        ...state,
        getJobs: action.payload,
        getJobLoader: true,
        getJobError: null,
      };
    case "GET_JOBS_ERROR":
      return {
        ...state,
        getJobLoader: false,
        getJobError: action.payload,
      };
    case "GET_SINGLE_JOB_LOADING":
      return {
        ...state,
        getSingleJobLoader: false,
        getSingleJobError: null,
      };
    case "GET_SINGLE_JOB_SUCCESS":
      return {
        ...state,
        getSingleJob: action.payload,
        getSingleJobLoader: true,
        getSingleJobError: null,
      };
    case "GET_SINGLE_JOB_ERROR":
      return {
        ...state,
        getSingleJobLoader: false,
        getSingleJobError: action.payload,
      };
    case "PATCH_LOADING":
      return {
        ...state,
        patchJobLoader: false,
        patchJobError: null,
      };
    case "PATCH_SUCCESS":
      return {
        ...state,
        patchJobLoader: true,
        patchJobError: null,
        patchJobSuccess: action.payload,
      };
    case "PATCH_ERROR":
      return {
        ...state,
        patchJobLoader: false,
        patchJobError: action.payload,
      };
    case "POST_SCRIPT_LOADING":
      return {
        ...state,
        postScriptLoader: false,
        postScriptError: null,
      };
    case "POST_SCRIPT_SUCCESS":
      return {
        ...state,
        postScriptLoader: true,
        postScriptError: null,
        postScriptSuccess: action.payload,
      };
    case "POST_SCRIPT_ERROR":
      return {
        ...state,
        postScriptLoader: false,
        postScriptError: action.payload,
      };

    case "DELETE_JOBS_SUCCESS":
      return {
        ...state,
        getJobs: state.getJobs.filter((job) => job.job_name !== action.payload),
        getJobLoader: true,
        getJobError: null,
      };
    case "POST_CUSTOMER_DATA_LOADING":
      return {
        ...state,
        postCustomerLoader: false,
        error: null,
      };
    case "POST_CUSTOMER_DATA_SUCCESS":
      return {
        ...state,
        postCustomers: action.payload,
        postCustomerLoader: true,
        error: null,
      };
    case "POST_CUSTOMER_DATA_ERROR":
      return {
        ...state,
        postCustomerLoader: false,
        error: action.payload,
      };
    case "PATCH_CUSTOMER_DATA_LOADING":
      return {
        ...state,
        loading: false,
        error: null,
      };
    case "PATCH_CUSTOMER_DATA_SUCCESS":
      return {
        ...state,
        patchCustomers: action.payload,
        loading: true,
        error: null,
      };
    case "PATCH_CUSTOMER_DATA_ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "GET_BUNDLES_LOADING":
      return {
        ...state,
        bundleLoader: false,
        error: null,
      };
    case "GET_BUNDLES_SUCCESS":
      return {
        ...state,
        getBundles: action.payload,
        bundleLoader: true,
        error: null,
      };
    case "GET_BUNDLES_ERROR":
      return {
        ...state,
        bundleLoader: false,
        error: action.payload,
      };
    case "POST_LACAS_DATA_LOADING":
      return {
        ...state,
        postLacasLoader: false,
        error: null,
      };
    case "POST_LACAS_DATA_SUCCESS":
      return {
        ...state,
        postLacasData: action.payload,
        postLacasLoader: true,
        error: null,
      };
    case "POST_LACAS_DATA_ERROR":
      return {
        ...state,
        postLacasLoader: false,
        error: action.payload,
      };
    case "POST_JOBS_LOADING":
      return {
        ...state,
        postJobLoader: false,
        jobError: null,
      };
    case "POST_JOBS_SUCCESS":
      const { formData, responseData } = action.payload;
      return {
        ...state,
        getJobs: [...state.getJobs, formData],
        postJobSuccess: responseData,
        postJobLoader: true,
        jobError: null,
      };
    case "POST_JOBS_ERROR":
      return {
        ...state,
        postJobLoader: false,
        jobError: action.payload,
      };
    case "CLEAR_TOAST_SUCCESS":
      return {
        ...state,
        postJobSuccess: { message: null },
        patchJobSuccess: { message: null },
      };
    case "CLEAR_TOAST_ERROR":
      return {
        ...state,
        jobError: null,
        patchJobError: null,
      };
    case "CLEAR_MANUAL_UPLOAD_TOAST_SUCCESS":
      return {
        ...state,
        postManualUploadResponse: { message: null },
      };
    case "CLEAR_MANUAL_UPLOAD_TOAST_ERROR":
      return {
        ...state,
        postManualUploadError: null,
      };
    case "POST_QUERY_LOADING":
      return {
        ...state,
        postQueryLoader: true,
        postQueryError: null,
      };
    case "POST_QUERY_SUCCESS":
      return {
        ...state,
        postQuerySuccess: action.payload,
        postQueryLoader: false,
        postQueryError: null,
      };
    case "POST_QUERY_ERROR":
      return {
        ...state,
        postQueryLoader: false,
        postQueryError: action.payload,
      };
    case "RESET_QUERY_ERROR":
      return {
        ...state,
        postQueryError: null,
      };
    case "GET_RESULT_LOADING":
      return {
        ...state,
        getQueryResultLoader: true,
        postLlmResultLoader: true,
        getQueryResultError: null,
      };
    case "GET_RESULT_STOP_LOADING":
      return {
        ...state,
        getQueryResultLoader: false,
        postLlmResultLoader: false,
        getQueryResultError: null,
      };
    case "GET_RESULT_SUCCESS":
      return {
        ...state,
        getQueryResultSuccess: action.payload,
        getQueryResultLoader: false,
        postLlmResultLoader: false,
        getQueryResultError: null,
        getQueryResultStatus: action.payload.length > 0 ? "success" : "error",
      };
    case "GET_RESULT_ERROR":
      return {
        ...state,
        getQueryResultLoader: false,
        postLlmResultLoader: false,
        getQueryResultError: action.payload,
        getQueryResultStatus: "error",
      };
    case "POST_GPT_QUERY_LOADING":
      return {
        ...state,
        postGPTQueryLoader: true,
        postGPTQueryError: null,
      };
    case "POST_GPT_QUERY_SUCCESS":
      return {
        ...state,
        postGPTQuerySuccess: action.payload,
        postGPTQueryLoader: false,
        postGPTQueryError: null,
      };
    case "POST_GPT_QUERY_ERROR":
      return {
        ...state,
        postGPTQueryLoader: false,
        postGPTQueryError: action.payload,
      };
    case "RESET_GPT_QUERY_ERROR":
      return {
        ...state,
        postGPTQueryError: null,
      };
    case "GET_GPT_RESULT_LOADING":
      return {
        ...state,
        getGPTQueryResultLoader: true,
        postGPTLlmResultLoader: true,
        getGPTQueryResultError: null,
      };
    case "CLEAR_GET_GPT_RESULT_SUCCESS":
      return {
        ...state,
        getGPTQueryResultSuccess: null,
        getGPTQueryResultLoader: false,
        postGPTLlmResultLoader: false,
        getGPTQueryResultError: null,
        postGPTQuerySuccess: undefined,
      };
    case "GET_GPT_RESULT_SUCCESS":
      return {
        ...state,
        getGPTQueryResultSuccess: action.payload,
        getGPTQueryResultLoader: false,
        postGPTLlmResultLoader: false,
        getGPTQueryResultError: null,
        getGPTQueryResultStatus:
          action.payload.length > 0 ? "success" : "error",
      };
    case "GET_GPT_RESULT_ERROR":
      return {
        ...state,
        getGPTQueryResultLoader: false,
        postGPTLlmResultLoader: false,
        getGPTQueryResultError: action.payload,
        getGPTQueryResultStatus: "error",
      };
    case "POST_DRAG_QUERY_LOADING":
      return {
        ...state,
        postDRAGQueryLoader: true,
        postDRAGQueryError: null,
      };
    case "POST_DRAG_QUERY_SUCCESS":
      return {
        ...state,
        postDRAGQuerySuccess: action.payload,
        postDRAGQueryLoader: false,
        postDRAGQueryError: null,
      };
    case "CLEAR_QUERY_SUCCESS":
      return {
        ...state,
        getDRAGQueryResultSuccess: null,
        postDRAGQueryLoader: false,
        postDRAGQueryError: null,
      };
    case "POST_DRAG_QUERY_ERROR":
      return {
        ...state,
        postDRAGQueryLoader: false,
        postDRAGQueryError: action.payload,
      };
    case "RESET_DRAG_QUERY_ERROR":
      return {
        ...state,
        postDRAGQueryError: null,
      };
    case "GET_DRAG_RESULT_LOADING":
      return {
        ...state,
        getDRAGQueryResultLoader: true,
        postDRAGLlmResultLoader: true,
        getDRAGQueryResultError: null,
      };
    case "GET_DRAG_RESULT_SUCCESS":
      return {
        ...state,
        getDRAGQueryResultSuccess: action.payload,
        getDRAGQueryResultLoader: false,
        postDRAGLlmResultLoader: false,
        getDRAGQueryResultError: null,
        getDRAGQueryResultStatus:
          action.payload.length > 0 ? "success" : "error",
      };
    case "GET_DRAG_RESULT_ERROR":
      return {
        ...state,
        getDRAGQueryResultLoader: false,
        postDRAGLlmResultLoader: false,
        getDRAGQueryResultError: action.payload,
        getDRAGQueryResultStatus: "error",
      };

    case "START_NEW_QUERY":
      return {
        ...state,
        getQueryResultSuccess: null,
        getQueryResultLoader: true,
        getQueryResultError: null,
      };

    case "GET_SSM_PARAMETERS_LOADING":
      return {
        ...state,
        envLoader: false,
        error: null,
      };
    case "GET_SSM_PARAMETERS_SUCCESS":
      return {
        ...state,
        environmentVariables: action.payload,
        envLoader: true,
        error: null,
      };
    case "GET_SSM_PARAMETERS_ERROR":
      return {
        ...state,
        envLoader: false,
        error: action.payload,
      };

    case "GET_DATABASE_LOADING":
      return {
        ...state,
        databaseNameLoader: false,
        error: null,
      };
    case "GET_DATABASE_SUCCESS":
      return {
        ...state,
        databaseName: action.payload,
        databaseNameLoader: true,
        databaseNameError: null,
      };
    case "GET_NULL_SUCCESS":
      return {
        ...state,
        nullDatabaseNameReturn: action.payload,
        databaseNameLoader: true,
        databaseNameError: null,
      };
    case "GET_DATABASE_ERROR":
      return {
        ...state,
        databaseNameLoader: false,
        databaseNameError: action.payload,
      };
    case "WARM_DATABASE_LOADING":
      return {
        ...state,
        warmUpdatabaseNameLoader: false,
        error: null,
      };
    case "WARM_DATABASE_SUCCESS":
      return {
        ...state,
        warmUpdatabaseName: action.payload,
        warmUpdatabaseNameLoader: true,
        error: null,
      };
    case "WARM_DATABASE_ERROR":
      return {
        ...state,
        warmUpdatabaseNameLoader: false,
        error: action.payload,
      };
    case "GET_EXECUTIONS_LOADING":
      return {
        ...state,
        getExecutionsLoader: false,
        getExecutionsError: null,
      };
    case "GET_EXECUTIONS_SUCCESS":
      return {
        ...state,
        getExecutions: action.payload,
        getExecutionsLoader: true,
        getExecutionsError: null,
      };
    case "GET_EXECUTIONS_ERROR":
      return {
        ...state,
        getExecutionsLoader: false,
        getExecutionsError: action.payload,
      };
    case "POST_MANUAL_UPLOAD_DATA_LOADING":
      return {
        ...state,
        postManualUploadLoader: false,
        postManualUploadError: null,
      };
    case "POST_MANUAL_UPLOAD_DATA_SUCCESS":
      return {
        ...state,
        postManualUploadResponse: action.payload,
        postManualUploadLoader: true,
        postManualUploadError: null,
      };
    case "POST_MANUAL_UPLOAD_DATA_ERROR":
      return {
        ...state,
        postManualUploadLoader: false,
        postManualUploadError: action.payload,
      };
    case "GET_JOBS_ANALYTICS_LOADING":
      return {
        ...state,
        getJobAnalyticsLoader: false,
        getJobAnalyticsError: null,
      };
    case "GET_COUNT_ANALYTICS_LOADING":
      return {
        ...state,
        getCountAnalyticsLoader: false,
        getCountAnalyticsError: null,
      };
    case "GET_JOBS_ANALYTICS_SUCCESS":
      return {
        ...state,
        getJobsAnalytics: action.payload,
        getJobAnalyticsLoader: true,
        getJobAnalyticsError: null,
      };
    case "GET_COUNT_ANALYTICS_SUCCESS":
      return {
        ...state,
        getCountAnalytics: action.payload,
        getCountAnalyticsLoader: true,
        getCountAnalyticsError: null,
      };
    case "GET_COUNT_ANALYTICS_ERROR":
      return {
        ...state,
        getCountAnalyticsLoader: false,
        getCountAnalyticsError: action.payload,
      };
    case "GET_JOBS_ANALYTICS_ERROR":
      return {
        ...state,
        getJobAnalyticsLoader: false,
        getJobAnalyticsError: action.payload,
      };
    case "POST_GPT_LLM_LOADING":
      return {
        ...state,
        postGPTLlmQuestionLoader: true,
        postGPTLlmQuestionError: null,
      };
    case "POST_GPT_LLM_SUCCESS":
      return {
        ...state,
        postGPTLlmQuestion: action.payload,
        postGPTLlmQuestionLoader: false,
      };
    case "POST_GPT_LLM_ERROR":
      return {
        ...state,
        postGPTLlmQuestionLoader: false,
        postGPTLlmQuestionError: action.payload,
      };
    case "CLEAR_TRANSFORMATION_RESULT_SUCCESS":
      return {
        ...state,
        getQueryResultSuccess: null,
        getQueryResultLoader: false,
        postLlmResultLoader: false,
        getQueryResultError: null,
        getQueryResultStatus: undefined,
      };
    case "CLEAR_QUERY_BUILDER_RESULT_SUCCESS":
      return {
        ...state,
        getDRAGQueryResultLoader: false,
        postDRAGLlmResultLoader: false,
        getDRAGQueryResultError: null,
        getDRAGQueryResultSuccess: null,
      };
    default:
      return state;
  }
};

export default reducer;
