import React from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "@mui/material";
import { StyledCookie } from "../styles/Cookie.Styled";
import { Button } from "../../components/index";

const CookieModal = ({ openModal, setOpenModal }) => {
  const { t } = useTranslation();

  const closeModal = () => {
    // accept terms
    console.log("modal closed");
    setOpenModal(false);
  };

  return (
    <Dialog
      open={openModal}
      onClose={closeModal}
      maxWidth="sm"
      hideBackdrop
      PaperProps={{
        sx: {
          position: "absolute",
          left: 0,
          bottom: 0,
          margin: "10px 20px",
        },
      }}
    >
      <StyledCookie>
        <h2>Databoat's Use of Cookies</h2>
        <div>
          <p>
            By clicking "Accept All Cookies", you consent to the storage of
            cookies on your device for improved site navigation, analyzing site
            usage, and supporting our marketing efforts.{" "}
            <a href="/">
              Refer to our Privacy Notice section for more details.
            </a>
          </p>
          <div className="opt-in">
            <Button
              text={t("general.accept_all_cookies")}
              className="opt-in-btn"
              onClick={closeModal}
            />
            <Button text="Personalize My Choices" className="opt-in-btn" />
          </div>
        </div>
      </StyledCookie>
    </Dialog>
  );
};

export default CookieModal;
