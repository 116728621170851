import Papa from "papaparse";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  postTransformationQuery,
  getTransformationResult,
  postGPTTransformationQuery,
  getGPTTransformationResult,
  postDRAGTransformationQuery,
  getDRAGTransformationResult,
} from "../../redux/actions/usersActions";

const useQuery = () => {
  const dispatch = useDispatch();

  const runQuery = useCallback(
    (currentCustomer, queryValue, token, url) => {
      const formData = {
        ssmParameterName: currentCustomer.cloudCredential[0].ssmPathParam,
        query: queryValue,
      };
      if (queryValue) {
        dispatch(postTransformationQuery(formData, token, url));
      }
    },
    [dispatch],
  );
  const handleExportCSV = (data, name) => {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", name);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const getQueryResult = useCallback(
    (currentCustomer, token, url, jobId) => {
      const formData = {
        ssmParameterName: currentCustomer.cloudCredential[0].ssmPathParam,
      };

      dispatch(getTransformationResult(token, url, jobId, formData));
    },
    [dispatch],
  );
  const runGPTQuery = useCallback(
    (currentCustomer, queryValue, token, url) => {
      const formData = {
        ssmParameterName: currentCustomer.cloudCredential[0].ssmPathParam,
        query: queryValue,
      };
      if (queryValue) {
        dispatch(postGPTTransformationQuery(formData, token, url));
      }
    },
    [dispatch],
  );
  const getGPTQueryResult = useCallback(
    (currentCustomer, token, url, jobId) => {
      const formData = {
        ssmParameterName: currentCustomer.cloudCredential[0].ssmPathParam,
      };

      dispatch(getGPTTransformationResult(token, url, jobId, formData));
    },
    [dispatch],
  );
  const runDRAGQuery = useCallback(
    (currentCustomer, queryValue, token, url) => {
      const formData = {
        ssmParameterName: currentCustomer.cloudCredential[0].ssmPathParam,
        query: queryValue,
      };
      if (queryValue) {
        dispatch(postDRAGTransformationQuery(formData, token, url));
      }
    },
    [dispatch],
  );
  const getDRAGQueryResult = useCallback(
    (currentCustomer, token, url, jobId) => {
      const formData = {
        ssmParameterName: currentCustomer.cloudCredential[0].ssmPathParam,
      };

      dispatch(getDRAGTransformationResult(token, url, jobId, formData));
    },
    [dispatch],
  );

  return {
    runQuery,
    getQueryResult,
    handleExportCSV,
    runGPTQuery,
    getGPTQueryResult,
    runDRAGQuery,
    getDRAGQueryResult,
  };
};
export default useQuery;
