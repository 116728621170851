import React, { useState } from "react";
import { StyledDashboardContentFlex, ConnectorScreens } from "../../../index";
import { useTranslation } from "react-i18next";

const GSC_CONNECTOR = ({
  gcsFileName,
  inputData,
  GCSData,
  handleGCSChange,
  handleGCSSourceBucketChange,
  handleGCSSourceBucketBlur,
  gcsfileNameError,
  gcstaskPartitionColumn,
  handleGCSTaskPartitionColumn,
  onClose,
}) => {
  const useTaskPartition = useState(true);
  const useSourceBucket = useState(true);

  const { t } = useTranslation();

  return (
    <StyledDashboardContentFlex column>
      <ConnectorScreens
        HeaderText={t(
          "jobs.form.inputs.task.modal.connectors.bucket.gcs.header",
        )}
        inputData={inputData}
        bucketFileName={gcsFileName}
        data={GCSData}
        handleChange={handleGCSChange}
        useSourceBucket={useSourceBucket}
        formatType={"Required Format: bucket-name/file"}
        sourceBucketFormat={"gs://"}
        handleSourceBucketChange={handleGCSSourceBucketChange}
        sourceBucketPlaceholder={"e.g: bucket-name/file"}
        handleSourceBucketBlur={handleGCSSourceBucketBlur}
        sourceBucketError={gcsfileNameError}
        useTaskPartition={useTaskPartition}
        taskPartitionColumn={gcstaskPartitionColumn}
        handleTaskPartitionColumn={handleGCSTaskPartitionColumn}
        btnOnClick={onClose}
        buttonText={t("jobs.form.inputs.task.modal.button")}
      />
    </StyledDashboardContentFlex>
  );
};

export default GSC_CONNECTOR;
