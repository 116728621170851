import { useState } from "react";
import {
  DataBaseListButton,
  DataBaseListContainer,
} from "../Transformation/Styles/TransformationStyle";
import {
  PiCaretRightLight,
  PiCaretDownLight,
  PiDatabaseLight,
  PiTableLight,
} from "react-icons/pi";
import DraggableTable from "./DraggableTable";
import { useDispatch, useSelector } from "react-redux";
import {
  addElement,
  updateElement,
} from "../../redux/reducers/elementsDndReducer";

const DatabaseList = ({
  getColumns,
  draggableTableProps,
  dragAndDropQuery,
  setDragAndDropQuery,
}) => {
  const [openDatabases, setOpenDatabases] = useState({});
  const [openTables, setOpenTables] = useState({});
  const { metaDataTables, metaDataColumns } = useSelector(
    (state) => state.metaData,
  );

  const dispatch = useDispatch();
  const { overElementIndex } = useSelector((state) => state.elementsDnd);
  const toggleDatabase = (databaseName) => {
    setOpenDatabases((prevOpenDatabases) => ({
      ...prevOpenDatabases,
      [databaseName]: !prevOpenDatabases[databaseName],
    }));
  };

  const toggleTable = (databaseName, tableName) => {
    getColumns(tableName);
    setOpenTables((prevOpenTables) => ({
      ...prevOpenTables,
      [`${databaseName}-${tableName}`]:
        !prevOpenTables[`${databaseName}-${tableName}`],
    }));
  };
  const handleDragStart = (table_name) => {
    getColumns(table_name);
  };

  const handleDragEnd = (attributes, databaseName, tableName) => {
    if (draggableTableProps.overDragArea) {
      dispatch(
        addElement(
          <DraggableTable
            x={draggableTableProps.x}
            y={draggableTableProps.y}
            index={draggableTableProps.index}
            setDragElement={draggableTableProps.setDragElement}
            setElements={draggableTableProps.setElements}
            attributes={attributes}
            dragAndDropQuery={dragAndDropQuery}
            setDragAndDropQuery={setDragAndDropQuery}
            databaseName={databaseName}
            tableName={tableName}
            overDragArea={draggableTableProps.overDragArea}
            setOverDragArea={draggableTableProps.setOverDragArea}
            overDraggableTable={draggableTableProps.overDraggableTable}
            setOverDraggableTable={draggableTableProps.setOverDraggableTable}
          />,
        ),
      );
      setDragAndDropQuery(`select * from ${databaseName}.${tableName}`);
      draggableTableProps.setOverDragArea(false);
    }

    if (draggableTableProps.overDraggableTable) {
      dispatch(
        updateElement({
          elementIndex: overElementIndex,
          newValues: {
            join: {
              enabled: true,
              table: { name: tableName, database: databaseName, attributes },
            },
          },
        }),
      );
    }
  };
  const groupByDataset = (data) => {
    return (
      data !== null &&
      data.reduce((acc, item) => {
        if (!acc[item.dataset_name]) {
          acc[item.dataset_name] = [];
        }
        acc[item.dataset_name].push(item.table_name);
        return acc;
      }, {})
    );
  };

  const groupedData = groupByDataset(metaDataTables);
  return (
    <DataBaseListContainer>
      {Object.keys(groupedData).map((datasetName) => (
        <div key={datasetName}>
          <DataBaseListButton
            onClick={() => toggleDatabase(datasetName)}
            style={{ cursor: "pointer", background: "transparent" }}
          >
            {openDatabases[datasetName] ? (
              <PiCaretDownLight size={"24px"} />
            ) : (
              <PiCaretRightLight size={"24px"} />
            )}
            <PiDatabaseLight size={"24px"} />
            {datasetName}
          </DataBaseListButton>
          {openDatabases[datasetName] &&
            groupedData[datasetName].map((tableName, index) => (
              <div key={index}>
                <DataBaseListButton
                  onClick={() => toggleTable(datasetName, tableName)}
                  style={{
                    padding: "0 2.5em",
                    cursor: "grabbing",
                    background: "transparent",
                  }}
                  draggable={true}
                  onDragStart={() => handleDragStart(tableName)}
                  onDragEnd={() =>
                    handleDragEnd(metaDataColumns, datasetName, tableName)
                  }
                >
                  {openTables[`${datasetName}-${tableName}`] ? (
                    <PiCaretDownLight size={"24px"} />
                  ) : (
                    <PiCaretRightLight size={"24px"} />
                  )}
                  <PiTableLight size={"24px"} />
                  <p onClick={() => getColumns(tableName)}>{tableName}</p>
                </DataBaseListButton>
                {openTables[`${datasetName}-${tableName}`] && (
                  <div style={{ padding: "0 16px 0 4.5em" }}>
                    {metaDataColumns !== null &&
                      metaDataColumns.map((attribute, index) => (
                        <DataBaseListButton key={index}>
                          {attribute.column_name}
                        </DataBaseListButton>
                      ))}
                  </div>
                )}
              </div>
            ))}
        </div>
      ))}
    </DataBaseListContainer>
  );
};

export default DatabaseList;
