/* eslint-disable react-hooks/exhaustive-deps */
import {
  CircularProgress,
  DialogActions,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Theme from "../../Theme";
import useGetToken from "../../cognito/useGetToken";
import {
  fetchMetadataColumns,
  getSelectedMetaDataTable,
  updateMetadataTableColumnDescription,
  updateMetadataTableDescription,
} from "../../redux/actions/metaDataAction";
import PreLoader from "../../reusableComponents/PreLoader";
import TableOverview from "../../reusableComponents/TableComponent/TableOverview";
import Tabs from "../../reusableComponents/Tabs";
import {
  CatalogSvg,
  HomeSvg,
  TransformationSvg,
} from "../../reusableComponents/svg/menuListSvg";
import { StyledPortalContent } from "../../styles/Styled";
import { TableDataCell } from "../../styles/Yedaflow.Styled";
import { StyledButton } from "../../reusableComponents/UIComponents/ButtonTonalIcon";
import { FieldSet } from "../../reusableComponents/FieldSet";
import SampleData from "./Components/SampleData";

const TabsContainer = styled.div`
  margin-bottom: 24px;
`;

const DescriptionContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const TagContainer = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 16px;
  align-items: center;
`;

const PageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TablesTabs = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
  margin-top: 12px;
`;

export const CatalogDetailsOverview = () => {
  const [selectedTable, setSelectedTable] = useState("Columns");
  const { database: databaseName, table: tableName } = useParams();
  const { environmentVariables } = useSelector((state) => state.users);
  const {
    metaDataColumns,
    metaDataColumnsLoader,
    selectedMetaDataTable,
    metaDataTableIsPatching,
  } = useSelector((state) => state.metaData);
  const dispatch = useDispatch();
  const group = localStorage.getItem("group");
  const { token } = useGetToken();

  const [dataForm, setDataForm] = useState({
    table_description: selectedMetaDataTable?.table_description || "",
    tags: selectedMetaDataTable?.tags || "",
  });
  const allColumns = metaDataColumns?.filter(
    (column) => column.dataset_name === databaseName,
  );
  console.log({ metaDataColumns, allColumns });
  const [columnsFilter, setColumnsFilter] = useState("");
  const filteredColumns = columnsFilter
    ? allColumns.filter((column) => column.column_name.includes(columnsFilter))
    : allColumns;

  const handleTableDescriptionChange = (e) => {
    setDataForm((prevState) => ({
      ...prevState,
      table_description: e.target.value,
    }));
  };

  const saveTableDescription = () => {
    dispatch(
      updateMetadataTableDescription(
        {
          ...dataForm,
          table_name: tableName,
          dataset_name: databaseName,
          customer: group,
        },
        token,
        group,
        environmentVariables.REACT_APP_BASE_URL_API_METADATA,
      ),
    );
  };

  useEffect(() => {
    if (token) {
      dispatch(getSelectedMetaDataTable(tableName, databaseName));
      dispatch(
        fetchMetadataColumns(
          tableName,
          token,
          group,
          environmentVariables.REACT_APP_BASE_URL_API_METADATA,
        ),
      );
    }
  }, [token]);

  useEffect(() => {
    setDataForm((prevState) => ({
      ...prevState,
      table_description: selectedMetaDataTable?.table_description || "",
      tags: selectedMetaDataTable?.tags || selectedMetaDataTable?.tag || "",
    }));
  }, [selectedMetaDataTable]);

  const updateTableColumn = async (
    databaseName,
    tableName,
    columnName,
    columnDescription,
  ) => {
    const formData = {
      customer: group,
      dataset_name: databaseName,
      table_name: tableName,
      columns: [
        {
          column_name: columnName,
          column_description: columnDescription,
        },
      ],
    };
    if (columnDescription) {
      dispatch(
        updateMetadataTableColumnDescription(
          formData,
          token,
          group,
          environmentVariables.REACT_APP_BASE_URL_API_METADATA,
        ),
      );
    }
  };

  const fill = Theme.colors.blueColor;
  const size = 18;
  const tabData = [
    {
      to: "/dataManagement",
      icon: <HomeSvg fill={fill} width={size} height={size} />,
      label: t("data_management.catalog.header.home"),
    },
    {
      to: "/transformation",
      icon: <TransformationSvg fill={fill} width={size} height={size} />,
      label: t("data_management.catalog.header.transformation"),
    },
    {
      to: "/catalog",
      icon: <CatalogSvg fill={fill} width={size} height={size} />,
      label: t("data_management.catalog.header.catalog"),
    },
  ];

  const tables = {
    Columns: {
      title: "Columns",
      component: (
        <div
          style={{
            display: "flex",
            gap: 8,
            flexDirection: "column",
          }}
        >
          {!metaDataColumnsLoader ? (
            <PreLoader />
          ) : (
            <>
              <TextField
                sx={{
                  width: 240,
                }}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FaSearch />
                    </InputAdornment>
                  ),
                }}
                label="Filter columns"
                value={columnsFilter}
                onChange={(e) => setColumnsFilter(e.target.value)}
              />
              <div
                style={{
                  maxHeight: 400,
                  overflow: "auto",
                }}
              >
                <TableOverview
                  headers={["Column", "Type", "Description", "Tags"]}
                  rows={filteredColumns}
                  width={"150px"}
                  renderRow={(column, index) => (
                    <tr key={index}>
                      <TableDataCell>{column.column_name}</TableDataCell>
                      <TableDataCell>{column.column_type}</TableDataCell>
                      <TableDataCell>
                        <FieldSet
                          style={{ border: 0, padding: 0 }}
                          inputStyle={{
                            color: Theme.colors.sysSurfaceTextColor,
                            font: Theme.fonts.fontSecondary,
                            letterSpacing: "0.25px",
                            padding: "1em 0",
                          }}
                          type={"text"}
                          name={"TableDescription"}
                          id={`${column.dataset_name}${column.column_name}${index + 1
                            }`}
                          value={column.column_description}
                          onBlur={(e) => {
                            updateTableColumn(
                              column.dataset_name,
                              column.table_name,
                              column.column_name,
                              e.target.value,
                            );
                          }}
                          placeholder={"Enter your description"}
                        />
                      </TableDataCell>
                      <TableDataCell>{column?.column_tags}</TableDataCell>
                    </tr>
                  )}
                />
              </div>
            </>
          )}
        </div>
      ),
    },
    "Sample Data": {
      title: "Sample Data",
      component: (
        <SampleData />
      ),
    },
    Permissions: {
      title: "Permissions",
      component: (
        <div>
          <h1>Permissions</h1>
        </div>
      ),
    },
  };

  return (
    <StyledPortalContent>
      <TabsContainer>
        <Tabs tabs={tabData} />
      </TabsContainer>
      <PageContentContainer>
        <h3>{`${databaseName}.${tableName}`}</h3>
        <DescriptionContainer>
          <p>Description:</p>
          <TextField
            size="small"
            variant="outlined"
            sx={{
              width: "70%",
            }}
            onChange={handleTableDescriptionChange}
            value={dataForm.table_description}
            onBlur={saveTableDescription}
          />
        </DescriptionContainer>
        <TagContainer>
          <p>Tags:</p>
          <FormControl sx={{ minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Add</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={dataForm.tags}
              label="Add"
              onChange={(e) => setDataForm((prevState) => ({ ...prevState, tags: e.target.value }))}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"Public"}>Public</MenuItem>
              <MenuItem value={"Internal"}>Internal</MenuItem>
              <MenuItem value={"Confidential"}>Confidential</MenuItem>
              <MenuItem value={"PII"}>PII</MenuItem>
            </Select>
          </FormControl>
        </TagContainer>
        <p>Owner: email@domain.com</p>
        <TablesTabs>
          {Object.entries(tables).map(([key, value]) => {
            return (
              <h4
                style={{
                  cursor: "pointer",
                  borderBottom: "4px solid",
                  borderBottomColor:
                    key === selectedTable
                      ? Theme.colors.blueColor
                      : "transparent",
                  color:
                    key === selectedTable
                      ? Theme.colors.primaryColor
                      : Theme.colors.disabledColor,
                  "&:hover": "red",
                }}
                onClick={() => setSelectedTable(key)}
              >
                {value.title}
              </h4>
            );
          })}
        </TablesTabs>
        {tables[selectedTable].component}
        <DialogActions>
          <StyledButton
            type={"submit"}
            onClick={saveTableDescription}
            disabled={!dataForm.table_description || metaDataTableIsPatching}
            style={{
              cursor:
                !dataForm.table_description || metaDataTableIsPatching
                  ? "not-allowed"
                  : "pointer",
            }}
          >
            {metaDataTableIsPatching ? (
              <CircularProgress size={20} />
            ) : (
              t("data_management.transformation.presenter.buttons.save")
            )}
          </StyledButton>
        </DialogActions>
      </PageContentContainer>
    </StyledPortalContent>
  );
};
