/* eslint-disable no-unused-vars */
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import {
  setJWTToken,
  setRefreshToken,
  setSession,
  setUserGroup,
  setUserEmail,
  fetchCountDataSetsAnalytics,
  fetchJobsAnalytics,
} from "../redux/actions/usersActions";
import { fetchMetadataTables } from "../redux/actions/metaDataAction";
import { useGetPoolData } from "../../clientRequest/env-variable";
import { fetchCustomers } from "../redux/actions/customerAction";

const useCognitoHook = () => {
  const getPoolData = useGetPoolData();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [users, setUsers] = useState({});
  const [userAttr, setUserAttr] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [loginError, setLoginError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [sessionValidation, setSessionValidation] = useState("");

  const [passwordUpdateErrorMessage, setPasswordUpdateErrorMessage] =
    useState("");
  const { environmentVariables } = useSelector((state) => state.users);

  window.addEventListener("storage", (event) => {
    if (event.key === "signout-event") {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/"; // Redirect to home page
    }
  });

  const checkAndSignOutPreviousSession = () => {
    return new Promise((resolve, reject) => {
      const UserPool = new CognitoUserPool(getPoolData);
      const cognitoUser = UserPool.getCurrentUser();

      if (cognitoUser) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            console.error("Error fetching session:", err);
            reject(err);
          } else {
            if (session.isValid()) {
              cognitoUser.globalSignOut({
                onSuccess: () => {
                  localStorage.clear();
                  sessionStorage.clear();
                  localStorage.setItem("signout-event", Date.now()); // Trigger signout event in all tabs
                  setLoginError("Previous session signed out.");
                  resolve(true); // Previous session was valid and signed out
                },
                onFailure: (err) => {
                  console.error("Error during global sign-out:", err);
                  reject(err);
                },
              });
            } else {
              cognitoUser.signOut();
              localStorage.clear();
              sessionStorage.clear();
              setSessionValidation(
                "Session was not valid, but signed out locally.",
              );
              resolve(true); // Previous session was invalid and signed out locally
            }
          }
        });
      } else {
        setSessionValidation("No previous session found.");
        resolve(false); // No previous session to sign out
      }
    });
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      await checkAndSignOutPreviousSession();

      // Proceed with new login after sign-out
      const UserPool = new CognitoUserPool(getPoolData);
      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: UserPool,
      });

      setUsers(cognitoUser);

      const authDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      });

      cognitoUser.authenticateUser(authDetails, {
        onSuccess: (session) => {
          // Handle new session
          dispatch(setSession(session));

          const group = session.getIdToken().payload["cognito:groups"];
          const usersEmail = session.getIdToken().payload.email;
          const groups = group[0];
          localStorage.setItem("group", groups);
          localStorage.setItem("created", usersEmail);
          dispatch(setUserGroup(groups));
          dispatch(setUserEmail(usersEmail));

          const jwtToken = session.getIdToken().getJwtToken();
          dispatch(setJWTToken(jwtToken));
          dispatch(setRefreshToken(session.getRefreshToken().getToken()));

          // Fetch required data

          if (groups) {
            localStorage.setItem("isLoggedIn", "true");
            dispatch(
              fetchCustomers(
                jwtToken,
                group,
                environmentVariables.REACT_APP_BASE_URL_API_CUSTOMER,
              ),
            );
            dispatch(
              fetchMetadataTables(
                jwtToken,
                groups,
                environmentVariables.REACT_APP_BASE_URL_API_METADATA,
              ),
            );
            dispatch(
              fetchCountDataSetsAnalytics(
                jwtToken,
                groups,
                environmentVariables.REACT_APP_BASE_URL_API_CORE,
              ),
            );
            dispatch(
              fetchJobsAnalytics(
                jwtToken,
                groups,
                environmentVariables.REACT_APP_BASE_URL_API_CORE,
              ),
            );
            navigate("/dataManagement"); // Redirect to the data management page
          } else {
            setLoginError("Login Failed");
          }
        },
        onFailure: (err) => {
          setErrorMessage(err.message);
        },
        newPasswordRequired: (userAttributes) => {
          setIsFirstLogin(true);
          setUserAttr(userAttributes);
        },
      });
    } catch (error) {
      console.log(error);
      setLoginError("Failed to sign out previous session");
    }
  };
  const changePasswordListener = (event) => {
    event.preventDefault();
    users.completeNewPasswordChallenge(
      newPassword,
      {},
      {
        onSuccess: () => {
          setIsFirstLogin(false);
        },
        onFailure: (err) => {
          setPasswordUpdateErrorMessage(err.message);
        },
      },
    );
  };

  return [
    email,
    setEmail,
    password,
    setPassword,
    errorMessage,
    loginError,
    newPassword,
    setNewPassword,
    handleLogin,
    changePasswordListener,
    isFirstLogin,
    passwordUpdateErrorMessage,
  ];
};

export default useCognitoHook;
