/* eslint-disable */
import React from "react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchJobs,
  clearQueryBuilderResultSuccess,
} from "../../redux/actions/usersActions";
import { fetchCustomers } from "../../redux/actions/customerAction";
import {
  fetchMetadataDataset,
  fetchMetadataColumns,
  fetchMetadataTables,
} from "../../redux/actions/metaDataAction";
import {
  HomeSvg,
  StyledDashboardContentFlex,
  StyledPortalContent,
  Tabs,
  Theme,
  TransformationSvg,
} from "../../index";
import DragAndDropModal from "./DragAndDropModal";
import useGetToken from "../../cognito/useGetToken";
const QueryBuilderContainer = () => {
  const { t } = useTranslation();
  const groupName = localStorage.getItem("group");
  const { elements } = useSelector((state) => state.elementsDnd);
  const { token } = useGetToken();
  const dispatch = useDispatch();
  const { environmentVariables } = useSelector((state) => state.users);
  const fill = Theme.colors.blueColor;
  const size = 18;
  const tabData = [
    {
      to: "/dataManagement",
      icon: <HomeSvg fill={fill} width={size} height={size} />,
      label: t("data_management.transformation.header.home"),
    },
    {
      to: "/transformation",
      icon: <TransformationSvg fill={fill} width={size} height={size} />,
      label: t("data_management.transformation.header.transformation"),
    },
  ];

  useEffect(() => {
    if (token) {
      dispatch(
        fetchJobs(
          token,
          environmentVariables.REACT_APP_BASE_URL_API_CORE,
          groupName,
        ),
      );
      dispatch(
        fetchCustomers(
          token,
          groupName,
          environmentVariables.REACT_APP_BASE_URL_API_CUSTOMER,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, token]);

  useEffect(() => {
    if (environmentVariables && token && groupName) {
      dispatch(
        fetchMetadataDataset(
          token,
          groupName,
          environmentVariables.REACT_APP_BASE_URL_API_METADATA,
        ),
      );

      dispatch(
        fetchMetadataTables(
          token,
          groupName,
          environmentVariables.REACT_APP_BASE_URL_API_METADATA,
        ),
      );
    }
  }, [token, dispatch, environmentVariables]);
  const getColumns = (table_name) => {
    if (environmentVariables && token && groupName) {
      dispatch(
        fetchMetadataColumns(
          table_name,
          token,
          groupName,
          environmentVariables.REACT_APP_BASE_URL_API_METADATA,
        ),
      );
    }
  };

  const [overDragArea, setOverDragArea] = useState(false);
  const [overDraggableTable, setOverDraggableTable] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [_, setElements] = useState([]);
  const [dragElementIndex, setDragElementIndex] = useState(null);
  const draggableTableProps = {
    x: mousePosition.x,
    y: mousePosition.y,
    index: elements.length,
    setDragElement: setDragElementIndex,
    setElements,
    overDragArea,
    setOverDragArea,
    overDraggableTable,
    setOverDraggableTable,
  };
  useEffect(() => {
    dispatch(clearQueryBuilderResultSuccess());
  }, [dispatch]);
  return (
    <StyledPortalContent>
      <div
        style={{
          padding: "1em",
          // width: "100%",
          // overflow: "scroll",
        }}
      >
        <StyledDashboardContentFlex column>
          <StyledDashboardContentFlex justifyContent>
            <Tabs tabs={tabData} />
          </StyledDashboardContentFlex>
          <DragAndDropModal
            getColumns={getColumns}
            draggableTableProps={draggableTableProps}
            setOverDragArea={setOverDragArea}
            setOverDraggableTable={setOverDraggableTable}
            setMousePosition={setMousePosition}
          />
        </StyledDashboardContentFlex>
      </div>
    </StyledPortalContent>
  );
};
export default QueryBuilderContainer;
