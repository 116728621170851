import React from "react";
import useCognitoHook from "../../cognito/useCognitoHook";

import { Login, PasswordUpdate } from "../../index";

const SignIn = () => {
  const [
    email,
    setEmail,
    password,
    setPassword,
    errorMessage,
    loginError,
    newPassword,
    setNewPassword,
    handleLogin,
    changePasswordListener,
    isFirstLogin,
    passwordUpdateErrorMessage,
  ] = useCognitoHook();
  return (
    <>
      {isFirstLogin ? (
        <PasswordUpdate
          changePasswordListener={changePasswordListener}
          newPassword={newPassword}
          setNewPassword={setNewPassword}
          passwordUpdateErrorMessage={passwordUpdateErrorMessage}
        />
      ) : (
        <Login
          handleLogin={handleLogin}
          password={password}
          setPassword={setPassword}
          email={email}
          setEmail={setEmail}
          errorMessage={errorMessage}
          loginError={loginError}
        />
      )}
    </>
  );
};
export default SignIn;
