import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import ReactPlayer from "react-player";
import DocumentPageLayout from "../DocumentPageLayout";

const DataCatalog = () => {
  const { t } = useTranslation();
  const breadcrumbs = [
    {
      name: t("general.accessing_the_data_catalog"),
      link: "#accessing-the-data-catalog",
    },
    {
      name: t("general.viewing_data_assets"),
      link: "#viewing-data-assets",
    },
    {
      name: t("general.searching_for_data"),
      link: "#searching-for-data",
    },
    {
      name: t("general.previewing_data"),
      link: "#previewing-data",
    },
    {
      name: t("general.editing_table_information"),
      link: "#editing-table-information",
    },
    {
      name: t("general.deleting_a_table"),
      link: "#deleting-a-table",
    },
  ];

  return (
    <DocumentPageLayout breadcrumbs={breadcrumbs}>
      <Grid container rowSpacing={4} style={{ padding: "0 20px" }}>
        <Grid item>
          <h1 id="accessing-the-data-catalog" style={{ paddingBottom: "20px" }}>
            {t("general.accessing_the_data_catalog")}
          </h1>
          <p>
            Welcome to the Data Catalog section of our platform. This guide will
            assist you in navigating through the data catalog feature, which is
            a part of our Data Management module. Here, you will learn how to
            view, manage, and interact with the data assets stored across
            different database environments.
          </p>

          <ReactPlayer
            url="https://vid.cdn-website.com/b29d841e/videos/cmGePZKTRGbm5x2mVNzN_Catalog+Navigation+%281%29-v.mp4"
            controls={true}
          />

          <div id="accessing-the-data-catalog">
            <div>
              <div>
                <div data-element-type="paragraph" data-version="5">
                  <p>4.1 Accessing the Data Catalog</p>
                  <p>
                    To begin exploring your data assets, click on the Data
                    Management option in the left navigation bar and select
                    Catalog. This will take you to the main interface where you
                    can manage and view your data.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="viewing-data-assets">
            <div>
              <div>
                <div data-element-type="paragraph" data-version="5">
                  <h4>4.2 Viewing Data Assets</h4>
                  <p>
                    The Data Catalog provides a structured view of all the data
                    tables across various databases. You can easily browse
                    through different classifications of databases:
                  </p>
                  <ul>
                    <li>Manual</li>
                    <li>Raw</li>
                    <li>Curated</li>
                    <li>Integrated</li>
                    <li>Consumption</li>
                  </ul>
                  <p>
                    Each entry in the catalog will display the following
                    information in a tabular format:
                  </p>
                  <ul>
                    <li>
                      Database Name: The name of the database where the table is
                      stored.
                    </li>
                    <li>Table Name: The name of the table.</li>
                    <li>
                      Description: A brief description of what the table
                      contains.
                    </li>
                    <li>
                      Modify: A column providing options to interact with the
                      table such as preview, edit, and delete.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="searching-for-data">
            <div>
              <div>
                <div data-element-type="paragraph" data-version="5">
                  <h3>4.3 Searching for Data</h3>
                  <p>
                    You can quickly find tables by searching for them by the
                    database name using the search bar provided. This feature
                    filters the table list based on your search criteria, making
                    it easy to locate specific databases or tables.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="previewing-data">
            <div>
              <div>
                <div data-element-type="paragraph" data-version="5">
                  <h3>4.4 Previewing Data</h3>
                  <p>To get a glimpse of the data contained in a table:</p>
                  <ol>
                    <li>
                      Locate the Table: Find the table you want to preview in
                      the Data Catalog.
                    </li>
                    <li>
                      Preview Data: Click the preview icon in the Modify column
                      next to the table. This will open a window showing a
                      limited set of data from the table, allowing you to
                      quickly view the contents without running complex queries.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div id="editing-table-information">
            <div>
              <div>
                <div data-element-type="paragraph" data-version="5">
                  <h3>4.5 Editing Table Information</h3>
                  <p>
                    Modify table details to ensure accurate and updated
                    metadata:
                  </p>
                  <ol>
                    <li>Select Table: Identify the table you wish to edit.</li>
                    <li>
                      Edit Details: Click the edit icon in the Modify column.
                      This will allow you to view the table's column names, data
                      types, and descriptions of the columns.
                    </li>
                    <li>
                      Update Descriptions: You can specifically edit the
                      descriptions of each column to better reflect the data
                      they contain.
                    </li>
                    <li>
                      Save Changes: After making the necessary changes, click on
                      Save to update the information in the catalog.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div id="deleting-a-table">
            <div>
              <div>
                <div>
                  <div>
                    <div>
                      <div data-element-type="paragraph" data-version="5">
                        <h4>4.6 Deleting a Table</h4>
                        <p>To remove a table from the database:</p>
                        <ol>
                          <li>
                            Identify the Table: Navigate to the table you intend
                            to delete.
                          </li>
                          <li>
                            Delete Table: Click the delete icon in the Modify
                            column. You will be asked to confirm the deletion to
                            prevent accidental loss of data.
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </DocumentPageLayout>
  );
};

export default DataCatalog;
