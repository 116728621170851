import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { PreLoader } from "../../index";
import SQLError from "../Transformation/Components/SQLError";

const DragAndDropResultTable = ({
  queryResults,
  postDRAGQuerySuccess,
  postDRAGQueryError,
  postDRAGQueryLoader,
  getDRAGQueryResultLoader,
}) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#cde5ff",
      opacity: "0.8",
      color: "#001F25",
      textAlign: "left",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
      color: "#001F25",
      font: "normal 500 14px/20px 'Poppins', sans-serif",
      letterSpacing: "0.1px",
    },
    "td, th": {
      border: 0,
    },
  }));
  const renderTable = () => {
    if (
      Array.isArray(queryResults) &&
      postDRAGQuerySuccess.jobId !== undefined
    ) {
      if (queryResults.length > 0) {
        return (
          <div style={{ width: "100%", overflow: "auto", maxHeight: "288px" }}>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ minWidth: 700, maxHeight: "288px" }}>
                <Table aria-label="sticky table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell sx={{ padding: "8px" }}>
                        Rows
                      </StyledTableCell>
                      {Object.keys(queryResults[0]).map((header, index) => (
                        <StyledTableCell
                          key={index}
                          align="center"
                          style={{ minWidth: 100 }}
                          sx={{ padding: "8px" }}
                        >
                          {header}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {queryResults.map((row, rowIndex) => (
                      <StyledTableRow key={rowIndex}>
                        <StyledTableCell sx={{ padding: "0 8px" }}>
                          {rowIndex}
                        </StyledTableCell>
                        {Object.keys(row).map((header, colIndex) => (
                          <StyledTableCell
                            key={colIndex}
                            sx={{ padding: "0 8px" }}
                          >
                            {row[header] === null || row[header] === ""
                              ? "Nothing to show"
                              : row[header].value
                              ? row[header].value
                              : row[header]}
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        );
      } else {
        return <SQLError value={"0 rows as result for the query sent"} />;
      }
    } else if (queryResults !== null && Object.keys(queryResults).length > 0) {
      return <p>{queryResults.message}</p>;
    }
  };
  return (
    <div style={{ padding: "0 1em" }}>
      {postDRAGQueryLoader || getDRAGQueryResultLoader ? (
        <PreLoader />
      ) : postDRAGQueryError ? (
        <SQLError value={postDRAGQueryError.toString()} />
      ) : (
        renderTable()
      )}
    </div>
  );
};
export default DragAndDropResultTable;
