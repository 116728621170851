import React from "react";
import { Table, Thead, TableHeaderCell } from "../../styles/Yedaflow.Styled";
import { useTranslation } from "react-i18next";
import { CgSwapVertical } from "react-icons/cg";

function columnIsSortable(header) {
  const sortableColumns = ["workflow.table.header.run_date"];
  return sortableColumns.some((el) => el === header);
}

const TableOverview = ({ headers, rows, renderRow, width, changeOrder }) => {
  const lastIndex = headers.length - 1;
  const { t } = useTranslation();
  return (
    <Table>
      <Thead>
        <tr>
          {headers.map((header, index) => (
            <TableHeaderCell
              key={index}
              style={{
                width: index === lastIndex && width,
                cursor:
                  header === "workflow.table.header.run_date"
                    ? "pointer"
                    : "default",
              }}
              onClick={
                header === "workflow.table.header.run_date" ? changeOrder : null
              }
            >
              {columnIsSortable(header) ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {t(header)} <CgSwapVertical size={28} />
                </div>
              ) : (
                t(header)
              )}
            </TableHeaderCell>
          ))}
        </tr>
      </Thead>
      <tbody>
        {rows.map((item, index) => (
          <React.Fragment key={index}>{renderRow(item)}</React.Fragment>
        ))}
      </tbody>
    </Table>
  );
};

export default TableOverview;
