import React from 'react';
import {
  Box,
  Typography,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tab
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AttributeItem from "./AttributeItem";

/**
 * @typedef {Object} Attribute
 * @property {string} name - The name of the attribute.
 * @property {string} type - The type of the attribute.
 * @property {boolean} active - Indicates if the attribute is active.
 */

/**
 * @typedef {Object} ObjectItem
 * @property {string} endpoint - The endpoint of the object.
 * @property {boolean} isSelected - Indicates if the object is selected.
 * @property {Attribute[]} attributes - List of attributes for the object.
 */

/**
 * @typedef {Object} TabsComponentProps
 * @property {string} tab - Currently selected tab.
 * @property {(event: React.SyntheticEvent, newValue: string) => void} setTab - Function to set the selected tab.
 * @property {ObjectItem[]} filteredObjects - List of filtered objects.
 * @property {(endpoint: string) => void} handleObjectSelection - Function to toggle object selection.
 * @property {(endpoint: string, attributeName: string) => void} handleAttrSelection - Function to toggle attribute selection.
 */

/**
 * Component to render tabs and their respective panels.
 * @param {TabsComponentProps} props
 * @returns {JSX.Element}
 */
const TabsComponent = ({ tab, setTab, filteredObjects, handleObjectSelection, handleAttrSelection }) => {
  /**
   * Filters objects based on their selection status.
   * @param {boolean} isSelected - Selection status to filter by.
   * @returns {ObjectItem[]}
   */
  const getFilteredObjects = (isSelected) => {
    return filteredObjects.filter(obj => obj.isSelected === isSelected);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={(event, newVal) => setTab(newVal)}
            aria-label="Pipedrive Connector Tabs"
          >
            <Tab label="All" value="1" />
            <Tab label="Enabled Objects" value="2" />
            <Tab label="Disabled Objects" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Box sx={{ mb: 3 }}>
            {filteredObjects.map((object) => (
              <Accordion key={object.endpoint}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${object.endpoint}-content`}
                  id={`${object.endpoint}-header`}
                >
                  <Checkbox
                    checked={object.isSelected}
                    onChange={() => handleObjectSelection(object.endpoint)}
                  />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography>{object.endpoint}</Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {object.attributes?.map((attribute) => (
                    <AttributeItem
                      key={`${object.endpoint}-${attribute.name}`}
                      objectEndpoint={object.endpoint}
                      attribute={attribute}
                      isObjectSelected={object.isSelected}
                      handleAttrSelection={handleAttrSelection}
                    />
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </TabPanel>

        <TabPanel value="2">
          <Box sx={{ mb: 3 }}>
            {getFilteredObjects(true).map((object) => (
              <Accordion key={object.endpoint}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${object.endpoint}-content`}
                  id={`${object.endpoint}-header`}
                >
                  <Checkbox
                    checked={object.isSelected}
                    onChange={() => handleObjectSelection(object.endpoint)}
                  />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography>{object.endpoint}</Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {object.attributes?.map((attribute) => (
                    <AttributeItem
                      key={`${object.endpoint}-${attribute.name}`}
                      objectEndpoint={object.endpoint}
                      attribute={attribute}
                      isObjectSelected={object.isSelected}
                      handleAttrSelection={handleAttrSelection}
                    />
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </TabPanel>

        <TabPanel value="3">
          <Box sx={{ mb: 3 }}>
            {getFilteredObjects(false).map((object) => (
              <Accordion key={object.endpoint}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${object.endpoint}-content`}
                  id={`${object.endpoint}-header`}
                >
                  <Checkbox
                    checked={object.isSelected}
                    onChange={() => handleObjectSelection(object.endpoint)}
                  />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography>{object.endpoint}</Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {object.attributes?.map((attribute) => (
                    <AttributeItem
                      key={`${object.endpoint}-${attribute.name}`}
                      objectEndpoint={object.endpoint}
                      attribute={attribute}
                      isObjectSelected={object.isSelected}
                      handleAttrSelection={handleAttrSelection}
                    />
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default TabsComponent;
