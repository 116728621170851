import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import ReactPlayer from "react-player";
import DocumentPageLayout from "./DocumentPageLayout";

const Transformation = () => {
  const { t } = useTranslation();
  const contentArr = [
    {
      name: t("general.transformation"),
      link: "#transformation",
    },
    {
      name: "Querying the data loaded into DataBoat",
      link: "#querying-the-data",
    },
  ];

  return (
    <DocumentPageLayout breadcrumbs={contentArr}>
      <Grid container>
        <Grid item>
          <h1 id="transformation">{t("general.transformation")}</h1>

          <p>
            In this section, you'll learn how to query the data loaded into
            DataBoat. Follow the step-by-step guide below to navigate the Data
            Management interface, craft and execute ANSI-SQL queries, and
            efficiently manage your datasets. Let's dive in and uncover the full
            potential of your data with DataBoat!"
          </p>

          <ReactPlayer url="https://www.youtube.com/watch?v=o9koU-vuwTQ" />

          <h2 id="querying-the-data">
            Querying the data loaded into DataBoat.
          </h2>
          <p>1- Accessing Data Management:</p>
          <p>Navigate to the left menu on the DataBoat main page.</p>
          <p>2- Selecting Transformation:</p>
          <p>Under the Data Management section, click on 'Transformation.'</p>
          <p>3- Navigating Data Lake Catalog:</p>
          <p>
            You'll find the Data Lake catalog, which contains databases, tables,
            and columns.
          </p>
          <p>4- Locating the Manual Upload:</p>
          <p>
            Look for the manual upload previously performed by opening the
            'Manual' item.
          </p>
          <p>5- Querying the Data:</p>
          <p>
            To query the data, use the black code canvas located on the right
            side.
          </p>
          <p>
            Write your ANSI-SQL query, selecting all columns or filtering them
            as necessary.
          </p>
          <p>6- Executing the Query:</p>
          <p>
            Once your query is written, click the 'Run' button to execute it.
          </p>
          <p>7- Saving the Query:</p>
          <p>
            If you want to save the query for future use, click the 'Save'
            button.
          </p>
          <p>Define a name for your script to easily retrieve it later.</p>
          <p>8- Utilizing Saved Queries:</p>
          <p>
            Once saved, you can use the query immediately or schedule it as a
            transformation job for automated execution.
          </p>
          <p>
            By following these steps, you can effectively query the data loaded
            into DataBoat and manage your datasets efficiently.
          </p>
        </Grid>
      </Grid>
    </DocumentPageLayout>
  );
};

export default Transformation;
