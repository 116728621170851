import { Grid, SignIn, SideBar } from "../index";

const Home = () => {
  return (
    <Grid>
      <SignIn />
      <SideBar />
    </Grid>
  );
};

export default Home;
