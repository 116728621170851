import React from "react";
import { StyledDashboardContentFlex } from "../../../index";
import OracleConnectorScreens from "../../../reusableComponents/OracleConnectorScreens";
import { useTranslation } from "react-i18next";

const OracleConnector = ({
  inputData,
  oracleDataValues,
  handleoracleServerValuesChange,
  oracleError,
  oracleIngestions,
  oracleselectedOption,
  taskId,
  handleoracleSelectChange,
  oracleoptionsArray,
  oracleServerdataQueries,
  handleoracleQueriesTableNameChange,
  oraclequeries,
  handleoracleQueriesChange,
  oraclecheckboxValues,
  handleoracleCheckboxChange,
  oraclepartitonColValue,
  handleoracleQueryPartitionChange,
  addMoreoracleServerQueries,
  oracletableHead,
  oracletableData,
  handleoracleSchemaNameChange,
  oracleschemas,
  handleoracleTableNameChange,
  oracletables,
  handleoracleTableIngestModeOption,
  oracletableOptions,
  handleoracleDeltaColumnChange,
  oracledeltaColumn,
  handleoraclePartionChange,
  handleoraclePartitioningChange,
  oraclepartitionColumn,
  oracleaddRow,
  onClose,
}) => {
  const { t } = useTranslation();
  const useSchemas = true;
  const useTableformat = true;
  return (
    <StyledDashboardContentFlex column>
      <OracleConnectorScreens
        HeaderText={t(
          "jobs.form.inputs.task.modal.connectors.server.oracle.title",
        )}
        inputData={inputData}
        data={oracleDataValues}
        handleChange={handleoracleServerValuesChange}
        errors={oracleError}
        ingestions={oracleIngestions}
        htmlFor={"Ingestions"}
        selectTitle={"Ingestions"}
        selectedOption={oracleselectedOption}
        handleSelected={handleoracleSelectChange}
        options={oracleoptionsArray}
        dataQueries={oracleServerdataQueries}
        handleQueriesTableNameChange={handleoracleQueriesTableNameChange}
        queryValues={oraclequeries}
        taskId={taskId}
        handleQueriesChange={handleoracleQueriesChange}
        checkboxValues={oraclecheckboxValues}
        handleCheckboxChange={handleoracleCheckboxChange}
        queryPartitionValue={oraclepartitonColValue}
        handleQueryPartitionChange={handleoracleQueryPartitionChange}
        handleAddMore={addMoreoracleServerQueries}
        tableHead={oracletableHead}
        tableData={oracletableData}
        handleSchemaNameChange={handleoracleSchemaNameChange}
        useSchemas={useSchemas}
        schemas={oracleschemas}
        handleTableNameChange={handleoracleTableNameChange}
        tables={oracletables}
        handleTableIngestModeOption={handleoracleTableIngestModeOption}
        tableOptions={oracletableOptions}
        handleDeltaColumnChange={handleoracleDeltaColumnChange}
        deltaColumn={oracledeltaColumn}
        handlePartionChange={handleoraclePartionChange}
        handlePartitioningChange={handleoraclePartitioningChange}
        partitionColumn={oraclepartitionColumn}
        useTableformat={useTableformat}
        addRow={oracleaddRow}
        btnOnClick={onClose}
        buttonText={t("jobs.form.inputs.task.modal.button")}
      />
    </StyledDashboardContentFlex>
  );
};

export default OracleConnector;
