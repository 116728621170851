import React, { useState } from "react";
import { ReusableFieldSet } from "./FieldSet";

const Select = ({ selectText, handleSelectChange, selectData }) => {
  const [isSelectFocused, setIsSelectFocused] = useState("");
  return (
    <ReusableFieldSet
      title={selectText}
      isFocus={isSelectFocused}
      children={
        <select
          defaultValue={""}
          onChange={handleSelectChange}
          className="paddingClass"
          onFocus={() => setIsSelectFocused(true)}
          onBlur={() => setIsSelectFocused(false)}
        >
          {selectData.map((item) => (
            <option key={item.id} value={item.option}>
              {item.title}
            </option>
          ))}
        </select>
      }
    />
  );
};

export default Select;
