import { StyledHeader } from "../../index";
import { useTranslation } from "react-i18next";

const styles = {
  display: "flex",
  gap: "2.5em",
  flexDirection: "column",
};

const Header = () => {
  const { t } = useTranslation();

  return (
    <StyledHeader>
      <div style={styles}>
        <h4>{t("login.title")}</h4>
        <h2>{t("login.welcome")}</h2>
      </div>
    </StyledHeader>
  );
};

export default Header;
