import React from "react";
import { StyledPortalContent, H3 } from "../index";

const NotificationOverview = () => {
  return (
    <StyledPortalContent>
      <H3>Notification Portal</H3>
    </StyledPortalContent>
  );
};
export default NotificationOverview;
