import React from "react";
import { StyledInput, StyledSearchContainer } from "../styles/Search.styled";
import { useTranslation } from "react-i18next";

const Search = ({
  placeholder,
  value,
  onChange,
  onFocus,
  onKeyDown,
  onBlur,
  width,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <StyledSearchContainer width={width} className={className || ""}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M21.9268 20.4694L17.2328 15.7763C18.5933 14.1429 19.2717 12.0478 19.1269 9.92694C18.9821 7.80607 18.0253 5.82268 16.4553 4.38935C14.8854 2.95602 12.8234 2.18311 10.6981 2.23141C8.57287 2.27971 6.54805 3.1455 5.04488 4.64867C3.54171 6.15184 2.67592 8.17666 2.62762 10.3019C2.57932 12.4272 3.35223 14.4892 4.78556 16.0591C6.21889 17.629 8.20228 18.5859 10.3231 18.7307C12.444 18.8755 14.5391 18.1971 16.1725 16.8366L20.8656 21.5306C20.9353 21.6003 21.018 21.6556 21.109 21.6933C21.2001 21.731 21.2977 21.7504 21.3962 21.7504C21.4948 21.7504 21.5923 21.731 21.6834 21.6933C21.7744 21.6556 21.8572 21.6003 21.9268 21.5306C21.9965 21.4609 22.0518 21.3782 22.0895 21.2872C22.1272 21.1961 22.1466 21.0985 22.1466 21C22.1466 20.9015 22.1272 20.8039 22.0895 20.7128C22.0518 20.6218 21.9965 20.5391 21.9268 20.4694ZM4.14621 10.5C4.14621 9.16498 4.54209 7.85993 5.28379 6.7499C6.02549 5.63987 7.0797 4.77471 8.3131 4.26381C9.5465 3.75292 10.9037 3.61925 12.2131 3.8797C13.5224 4.14015 14.7252 4.78303 15.6692 5.72703C16.6132 6.67103 17.2561 7.87377 17.5165 9.18314C17.777 10.4925 17.6433 11.8497 17.1324 13.0831C16.6215 14.3165 15.7563 15.3707 14.6463 16.1124C13.5363 16.8541 12.2312 17.25 10.8962 17.25C9.10661 17.248 7.39087 16.5362 6.12543 15.2708C4.85999 14.0053 4.1482 12.2896 4.14621 10.5Z"
          fill="#42474E"
        />
      </svg>
      <StyledInput
        type="search"
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        placeholder={
          placeholder
            ? placeholder
            : t("reusable_components.search.placeholder")
        }
      />
    </StyledSearchContainer>
  );
};

export default Search;
