import React from "react";
import {
  StyledPortalContent,
  MuiSelect,
  MuiSearchInput,
  StyledDashboardContentFlex,
  Tabs,
  HomeSvg,
  ObservabilitySvg,
  DataQualitySvg,
} from "../../index";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import ColumnNameData from "./components/ColumnNameData";
import TableDetailsData from "./components/TableDetailsData";
import FiltersDataType from "./components/FiltersDataType";
import TableInfosData from "./components/TableInfosData";

const DataQualityPresenter = ({
  handleDBOptionSelect,
  dataBases,
  isDataBasesLoading,
  selectedDBOption,
  handleTableOptionSelect,
  tables,
  isTablesLoading,
  selectedTableOption,
  handleSearchTableOnChange,
  handleSearchTable,
  tableColumnsHeadCells,
  tableColumnsRows,
  selectedColumns,
  handleSelectColumns,
  dataTypeFilterButtons,
  tableDetailsRows,
  handleCreateNewRule,
  envLoader,
  tableDetailsHeadCells,
  isTableDetailsLoading,
  tableDetailsError,
  paginationTableDetails,
  handleChangeTableDetailsPageOrPageSize,
  handleChangeTableDetailsOrder,
  isTableFiltersLoading,
  tableFiltersError,
  isTableInfosLoading,
  tableInfos,
  tableInfosError,
}) => {
  const fill = "#006399";
  const size = "18";
  const tabData = [
    {
      to: "/dataManagement",
      icon: <HomeSvg fill={fill} width={size} height={size} />,
      label: "Home",
    },
    {
      to: "/dataQuality",
      icon: <ObservabilitySvg fill={fill} width={size} height={size} />,
      label: "Observability",
    },
    {
      to: "/dataQuality",
      icon: <DataQualitySvg fill={fill} width={size} height={size} />,
      label: "Data Quality",
    },
  ];

  return (
    <StyledPortalContent>
      <div style={{ padding: "1em" }}>
        <StyledDashboardContentFlex column>
          <StyledDashboardContentFlex justifyContent>
            <Tabs tabs={tabData} />
          </StyledDashboardContentFlex>
          <StyledDashboardContentFlex justifyContent>
            <Grid container height={"100%"} spacing={2}>
              <Grid item xl={3.6} height={"auto"} overflow={"hidden"}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <MuiSelect
                      inputLabel={"DataBase:"}
                      items={dataBases}
                      isLoading={isDataBasesLoading || !envLoader}
                      disabled={!dataBases.length}
                      selectedValue={selectedDBOption}
                      handleChange={handleDBOptionSelect}
                    />
                  </Grid>
                  <Grid item>
                    <MuiSelect
                      inputLabel={"Table:"}
                      items={tables}
                      isLoading={isTablesLoading}
                      disabled={!tables.length}
                      selectedValue={selectedTableOption}
                      handleChange={handleTableOptionSelect}
                    />
                  </Grid>
                  <Grid item>
                    <MuiSearchInput
                      inputLabel={"Search table:"}
                      isLoading={isTablesLoading}
                      disabled={!tables.length}
                      handleClick={handleSearchTable}
                      onChange={handleSearchTableOnChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={4} height={"auto"}>
                <Paper
                  sx={{
                    padding: "10px",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    whiteSpace: "normal",
                  }}
                >
                  <FiltersDataType
                    selectedTableOption={selectedTableOption}
                    isTableFiltersLoading={isTableFiltersLoading}
                    tableFiltersError={tableFiltersError}
                    dataTypeFilterButtons={dataTypeFilterButtons}
                  />
                </Paper>
              </Grid>
              <Grid item xs={4.4} height={"auto"}>
                <Paper
                  sx={{
                    padding: "10px",
                    height: "100%",
                    whiteSpace: "normal",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <TableInfosData
                    selectedTableOption={selectedTableOption}
                    isTableInfosLoading={isTableInfosLoading}
                    tableInfosError={tableInfosError}
                    tableInfos={tableInfos}
                  />
                </Paper>
              </Grid>
              <Grid item xl={3.4} height={"75%"}>
                <Paper
                  sx={{
                    padding: "10px",
                    height: "100%",
                    whiteSpace: "normal",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                  }}
                >
                  <ColumnNameData
                    selectedTableOption={selectedTableOption}
                    isTableDetailsLoading={isTableDetailsLoading}
                    selectedDBOption={selectedDBOption}
                    tableDetailsError={tableDetailsError}
                    tableColumnsHeadCells={tableColumnsHeadCells}
                    tableColumnsRows={tableColumnsRows}
                    selectedColumns={selectedColumns}
                    handleSelectColumns={handleSelectColumns}
                    handleCreateNewRule={handleCreateNewRule}
                  />
                </Paper>
              </Grid>
              <Grid item xl={8.6} height={"75%"}>
                <Paper
                  sx={{
                    padding: "10px",
                    height: "100%",
                    whiteSpace: "normal",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                  }}
                >
                  <TableDetailsData
                    selectedTableOption={selectedTableOption}
                    isTableDetailsLoading={isTableDetailsLoading}
                    selectedDBOption={selectedDBOption}
                    tableDetailsError={tableDetailsError}
                    tableDetailsHeadCells={tableDetailsHeadCells}
                    tableDetailsRows={tableDetailsRows}
                    paginationTableDetails={paginationTableDetails}
                    handleChangeTableDetailsPageOrPageSize={
                      handleChangeTableDetailsPageOrPageSize
                    }
                    handleChangeTableDetailsOrder={
                      handleChangeTableDetailsOrder
                    }
                  />
                </Paper>
              </Grid>
            </Grid>
          </StyledDashboardContentFlex>
        </StyledDashboardContentFlex>
      </div>
    </StyledPortalContent>
  );
};

export default DataQualityPresenter;
