import React, { useState } from "react";
import styled from "styled-components";

const AccordionContainer = styled.div`
  width: 100%;
`;

const Section = styled.div`
  border: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
`;

const Content = styled.div`
  display: ${({ open }) => (open ? "block" : "none")};
  padding: 10px;
`;

const CollapsibleBox = ({ sections }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleSectionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <AccordionContainer>
      {sections.map((section, index) => (
        <Section key={index} onClick={() => handleSectionClick(index)}>
          {section.title}
          <Content open={activeIndex === index}>{section.content}</Content>
        </Section>
      ))}
    </AccordionContainer>
  );
};

export default CollapsibleBox;
