import React from "react";
import {
  StyledDashboardContentFlex,
  RenderDataItems,
  RenderIngestion,
  RenderQuery,
} from "../index";
import { useSelector } from "react-redux";
import OracleRenderTable from "./OracleRenderTable";

const OracleConnectorScreens = ({
  HeaderText,
  inputData,
  data,
  handleChange,
  errors,
  ingestions,
  htmlFor,
  selectTitle,
  selectedOption,
  handleSelected,
  options,
  dataQueries,
  handleQueriesTableNameChange,
  queryValues,
  taskId,
  handleQueriesChange,
  checkboxValues,
  handleCheckboxChange,
  queryPartitionValue,
  handleQueryPartitionChange,
  handleAddMore,
  tableHead,
  tableData,
  handleSchemaNameChange,
  useSchemas,
  usesql,
  schemas,
  handleTableNameChange,
  tables,
  handleTableIngestModeOption,
  tableOptions,
  handleDeltaColumnChange,
  deltaColumn,
  handlePartionChange,
  handlePartitioningChange,
  partitionColumn,
  useTableformat,
  addRow,
  btnOnClick,
  buttonText,
}) => {
  const { databaseNameError } = useSelector((state) => state.users);

  return (
    <StyledDashboardContentFlex column style={{ padding: "2em" }}>
      <p>{HeaderText}</p>
      <StyledDashboardContentFlex column>
        {inputData && (
          <RenderDataItems
            data={data}
            handleChange={handleChange}
            errors={errors}
          />
        )}
        {ingestions ? (
          <>
            <RenderIngestion
              htmlFor={htmlFor}
              selectTitle={selectTitle}
              selectedOption={selectedOption}
              handleSelected={handleSelected}
              options={options}
            />
            <div>
              {databaseNameError !== null ? (
                <p className="error">{databaseNameError}</p>
              ) : selectedOption === "query" ? (
                <RenderQuery
                  dataQueries={dataQueries}
                  handleQueriesTableNameChange={handleQueriesTableNameChange}
                  queryValues={queryValues}
                  taskId={taskId}
                  handleQueriesChange={handleQueriesChange}
                  checkboxValues={checkboxValues}
                  handleCheckboxChange={handleCheckboxChange}
                  queryPartitionValue={queryPartitionValue}
                  handleQueryPartitionChange={handleQueryPartitionChange}
                  handleAddMore={handleAddMore}
                />
              ) : selectedOption === "tables" ? (
                <OracleRenderTable
                  taskId={taskId}
                  tableHead={tableHead}
                  tableData={tableData}
                  handleSchemaNameChange={handleSchemaNameChange}
                  useSchemas={useSchemas}
                  schemas={schemas}
                  usesql={usesql}
                  handleTableNameChange={handleTableNameChange}
                  tables={tables}
                  handleTableIngestModeOption={handleTableIngestModeOption}
                  tableOptions={tableOptions}
                  handleDeltaColumnChange={handleDeltaColumnChange}
                  deltaColumn={deltaColumn}
                  handlePartionChange={handlePartionChange}
                  handlePartitioningChange={handlePartitioningChange}
                  partitionColumn={partitionColumn}
                  useTableformat={useTableformat}
                  addRow={addRow}
                />
              ) : null}
            </div>
          </>
        ) : null}
        <div>
          <button className={"next_btn save_btn"} onClick={btnOnClick}>
            {buttonText}
          </button>
        </div>
      </StyledDashboardContentFlex>
    </StyledDashboardContentFlex>
  );
};
export default OracleConnectorScreens;
