import axios from "axios";
import { useEffect } from "react";
import { useState, useMemo } from "react";
import { useSelector } from "react-redux";

const createCoreApi = (url, apiKey) => {
  return axios.create({
    baseURL: url,
    headers: {
      Authorization: `Bearer ${apiKey}`,
    },
  });
};

async function getObjects(api) {
  const queryParams = new URLSearchParams();
  queryParams.append("endpoint_method", "GET");

  const response = await api.get(`connectors/active-campaign/list-endpoints`, {
    params: queryParams,
  });

  return response.data;
}

function deepSearch(objects, search) {
  if (!search) return objects;

  return objects.filter((object) => {
    if (object.name.includes(search)) {
      return true;
    }

    return false;
  });
}

function transformObject(objects) {
  return objects.map((object) => ({
    name: object.endpoint,
    attrs: object.attributes.flatMap((attr) => {
      if (typeof attr === "string") {
        return {
          name: attr,
          attrs: [],
        };
      }

      return Object.entries(attr).map(([name, attrs]) => ({
        name,
        attrs: attrs.map((attr) => ({
          name: attr,
          attrs: [],
        })),
      }));
    }),
  }));
}

export const useActiveCampaing = (token, taskData, setTaskData) => {
  const [apiKey, setApiKey] = useState("");
  const [url, setUrl] = useState("");
  const [rawObjects, setRawObjects] = useState();
  const [selected, setSelected] = useState(new Set());
  const [search, setSearch] = useState("");
  const [expanded, setExpanded] = useState(new Set());
  const { environmentVariables } = useSelector((state) => state.users);

  const objects = useMemo(() => {
    return deepSearch(rawObjects, search);
  }, [search, rawObjects]);

  useEffect(() => {
    if (!token || !environmentVariables) return;

    const api = createCoreApi(
      environmentVariables.REACT_APP_BASE_URL_API_CORE,
      token,
    );

    getObjects(api).then((data) => {
      const objects = transformObject(data);
      setRawObjects(objects);
    });
  }, [token, environmentVariables]);

  const handleSearch = (search) => {
    setSearch(search);
  };

  const handleAttrSelection = (node, parentPath = "") => {
    const nodePath = `${parentPath ? `${parentPath}.` : ""}${node.name}`;

    setSelected((prevSelected) => {
      const newSelected = new Set(prevSelected);
      const isSelected = !newSelected.has(nodePath);

      const toggleSelectionRecursive = (node, path) => {
        const fullPath = `${path ? `${path}.` : ""}${node.name}`;

        if (isSelected) {
          newSelected.add(fullPath);
        } else {
          newSelected.delete(fullPath);
        }

        if (node.attrs && node.attrs.length > 0) {
          node.attrs.forEach((child) =>
            toggleSelectionRecursive(child, fullPath),
          );
        }
      };

      toggleSelectionRecursive(node, parentPath);

      return newSelected;
    });
  };

  const handleExpand = (objectName) => {
    setExpanded((prevExpanded) => {
      const newExpanded = new Set(prevExpanded);
      if (newExpanded.has(objectName)) {
        newExpanded.delete(objectName);
      } else {
        newExpanded.add(objectName);
      }

      return newExpanded;
    });
  };

  const handleSave = () => {
    const buildParameters = (selected) => {
      const parameterMap = {};

      selected.forEach((path) => {
        const [endpoint, ...attributes] = path.split(".");

        if (!parameterMap[endpoint]) {
          parameterMap[endpoint] = { attributes: [], links: [] };
        }

        if (attributes.length > 0) {
          const attrPath = attributes.join(".");

          if (attrPath.startsWith("links")) {
            const linkName = attrPath.replace("links.", "");
            parameterMap[endpoint].links.push(linkName);
          } else {
            parameterMap[endpoint].attributes.push(attrPath);
          }
        }
      });

      return Object.entries(parameterMap).map(([endpoint, data]) => ({
        endpoint,
        attributes: [
          ...data.attributes,
          ...(data.links.length > 0 ? [{ links: data.links }] : []),
        ],
      }));
    };

    taskData.forEach((data) => {
      data.source_type = "active-campaign";
      data.parameters = buildParameters(selected);
      data.params.api_header_param = { "Api-Token": apiKey };
      data.params.api_url = url;
    });
  };

  return [
    url,
    setUrl,
    apiKey,
    setApiKey,
    objects,
    search,
    handleSearch,
    selected,
    handleAttrSelection,
    expanded,
    handleExpand,
    handleSave,
  ];
};
