import React from "react";
import { MuiEnhancedTable, PreLoader } from "../../../index";

import Typography from "@mui/material/Typography";

const TableDetailsData = ({
  selectedTableOption,
  isTableDetailsLoading,
  selectedDBOption,
  tableDetailsError,
  tableDetailsHeadCells,
  tableDetailsRows,
  paginationTableDetails,
  handleChangeTableDetailsPageOrPageSize,
  handleChangeTableDetailsOrder,
}) => {
  if (isTableDetailsLoading) {
    return <PreLoader marginTop={"0px"} />;
  }

  if (!selectedTableOption && !isTableDetailsLoading) {
    return (
      <Typography
        variant="h6"
        sx={{ textAlign: "center", color: "rgba(0, 0, 0, 0.6)" }}
      >
        Select Database and table to get details about ...
      </Typography>
    );
  }

  if (
    selectedDBOption &&
    !isTableDetailsLoading &&
    !tableDetailsError &&
    tableDetailsHeadCells &&
    tableDetailsRows
  ) {
    return (
      <MuiEnhancedTable
        headCells={tableDetailsHeadCells}
        pagination={paginationTableDetails}
        handlePagination={handleChangeTableDetailsPageOrPageSize}
        handleOrdination={handleChangeTableDetailsOrder}
        rows={tableDetailsRows}
        showCheckbox={false}
        title={"Table Details"}
        selected={[]}
        handleSelect={() => {}}
      />
    );
  }

  return <Typography>Error to get details ...</Typography>;
};

export default TableDetailsData;
