import React from "react";
import { StyledPortalContent, H3 } from "../index";

const SchemaRegistryOverview = () => {
  return (
    <StyledPortalContent>
      <H3>Schema Registry</H3>
    </StyledPortalContent>
  );
};

export default SchemaRegistryOverview;
