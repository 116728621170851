import styled from "styled-components";
import Theme from "../../Theme";
import { Button } from "@mui/material";

export const StyledBundleContainer = styled.div`
  display: flex;
  max-width: 100vw;
  overflow-x: auto;
  height: 100vh;
  background-color: #fff;
  padding: 20px;
`;

export const StyledBundleItemLabel = styled.div`
  height: 50px;

  &.active {
    background-color: ${Theme.colors.blueColor};
    color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${Theme.colors.blueColor};
  }
`;

export const StyledBundleItemContent = styled.div`
  padding: 20px;
  height: 100%;
  display: flex;
  gap: 20px;
  flex-direction: column;

  img {
    width: max-content;
  }

  &:not(.active) {
    padding: 24px;
  }

  .description {
    min-height: 75px;
  }
`;

export const StyledBundleItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 500px;
  max-width: 500px;

  ${StyledBundleItemContent} {
    border: 1px solid;

    &.active {
      border: 3px solid ${Theme.colors.blueColor};
    }
  }

  &:first-child {
    ${StyledBundleItemContent} {
      border-right: none;
    }
  }

  &:not(:first-child) {
    ${StyledBundleItemContent}:not(.active) {
      border-left: none;
    }
  }

  ul {
    min-height: 160px;
  }

  li {
    margin-left: 15px;
  }
`;

export const PriceLabelContainer = styled.div`
  min-height: 75px;
`;

export const PriceContainer = styled.div`
  display: flex;
  gap: 3px;
  align-items: baseline;

  .plan-price-amount {
    color: ${Theme.colors.blueColor};
    font-size: 32px;
    font-weight: 500;
  }
`;

export const MoreInfoContainer = styled.div`
  border-top: 1px solid;
  padding: 20px 0;
  margin-top: 20px;
`;

export const StyledButton = styled(Button)(({ theme }) => ({
  "&.MuiButtonBase-root": {
    fontWeight: 600,
    borderRadius: "30px",
    padding: 20,
    marginTop: 20,
  },
  "&.MuiButton-outlinedPrimary": {
    borderColor: Theme.colors.blueColor,
    color: Theme.colors.blueColor,
  },
  "&.MuiButton-containedPrimary": {
    backgroundColor: Theme.colors.blueColor,
    border: "1px solid",
    borderColor: Theme.colors.blueColor,
    boxShadow: "none",
  },
}));
