import styled from "styled-components";

export const StyledSearchContainer = styled.div`
  display: flex;
  width: ${({ width }) => (width ? width : "300px")};
  height: 40px;
  padding: 0.25em 0.75em;
  gap: 0.5em;
  border-radius: ${({ theme }) => theme.radius.primaryBorderRadius};
  background: ${({ theme }) => theme.colors.inverseOnSurface};

  svg {
    margin-top: 0.25em;
  }
`;
export const StyledInput = styled.input`
  background: transparent;
  border: none;
  width: 100%;

  /* clears the ‘X’ from Internet Explorer */
  &::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  /* clears the ‘X’ from Chrome */
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
  &:focus {
    outline: none;
  }
  &::placeholder {
    font:
      normal 400 1em/24px "Poppins",
      sans-serif;
    letter-spacing: 0.5px;
  }
  color: ${({ theme }) => theme.colors.placeholderText};
`;
