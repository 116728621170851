import React, { useState } from "react";
import { SideNavigation, PortalHeader } from "../index";
import { Grid } from "@mui/material";

const DashBoardScreen = ({ children }) => {
  const [isActive, setIsActive] = useState(true);

  const handleCollapseMenu = () => {
    setIsActive(!isActive);
  };
  return (
    <div style={{ overflow: "hidden", maxHeight: "100vh" }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <PortalHeader />
        </Grid>
        <Grid item xs={isActive ? 2.5 : 0.5} md={isActive ? 2.5 : 0.5}>
          <SideNavigation
            isActive={isActive}
            handleCollapseMenu={handleCollapseMenu}
          />
        </Grid>
        <Grid item xs={isActive ? 9.5 : 11.5} md={isActive ? 9.5 : 11.5}>
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

export default DashBoardScreen;
