import React from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import {
  StyledDashboardContentFlex,
  Flex,
  MultiSelect,
  AiOutlineInfoCircle,
} from "../index";
const OracleRenderTable = ({
  tableHead,
  tableData,
  taskId,
  handleSchemaNameChange,
  useSchemas,
  schemas,
  usesql,
  handleTableNameChange,
  tables,
  handleTableIngestModeOption,
  tableOptions,
  handleDeltaColumnChange,
  deltaColumn,
  handlePartionChange,
  handlePartitioningChange,
  partitionColumn,
  useTableformat,
  addRow,
}) => {
  const tooltips = [
    () => <></>,
    () => (
      <>
        <AiOutlineInfoCircle
          className="ingestion-mode info-icon"
          size={"1.5em"}
          style={{ float: "right" }}
        />
        <Tooltip
          anchorSelect=".schema"
          place="top"
          multiline={true}
          className="hover-info-container"
        >
          <Flex column>
            <span>Database schema,</span>
            <span> where tbe table is stored.</span>
          </Flex>
        </Tooltip>
      </>
    ),
  ];
  const { nullDatabaseNameReturn } = useSelector((state) => state.users);
  return (
    <StyledDashboardContentFlex column>
      {nullDatabaseNameReturn !== null ? (
        <p>{nullDatabaseNameReturn}</p>
      ) : (
        <div className="tableContainer">
          <table>
            <thead>
              <tr>
                {tableHead.map((column, index) => (
                  <th key={index} style={{ fontSize: "0.75em" }}>
                    <span>{column.title}</span>
                    {tooltips[index] && tooltips[index]()}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((data) => (
                <tr key={data.id}>
                  {useSchemas ? (
                    <td
                      style={{
                        width: "200px",
                      }}
                    >
                      {data.tableSchemasLoader ? (
                        <select
                          defaultValue={""}
                          onChange={(event) =>
                            handleSchemaNameChange(event, data.id, taskId)
                          }
                          style={{ width: "100%", padding: "0.75em" }}
                          name="select ingestion Mode"
                        >
                          <option value="">Select</option>
                          {schemas[0].schema_names.length > 0 ? (
                            schemas[0].schema_names.map((data, index) => (
                              <option key={index} value={data}>
                                {data}
                              </option>
                            ))
                          ) : (
                            <option>No schemas available</option>
                          )}
                        </select>
                      ) : (
                        <p>Enter your schema name</p>
                      )}
                    </td>
                  ) : null}
                  <td
                    style={{
                      width: "200px",
                    }}
                  >
                    {data.listTablesLoader ? (
                      <select
                        defaultValue={""}
                        onChange={(event) =>
                          handleTableNameChange(event, data.id, taskId)
                        }
                        style={{ width: "100%", padding: "0.75em" }}
                        name="select ingestion Mode"
                      >
                        <option value="">Select</option>
                        {tables[0].table_names.length > 0 ? (
                          tables[0].table_names.map((data, index) => (
                            <option key={index} value={data}>
                              {data}
                            </option>
                          ))
                        ) : (
                          <option>No table available</option>
                        )}
                      </select>
                    ) : (
                      <p>Enter your table name</p>
                    )}
                  </td>
                  <td
                    style={{
                      width: "200px",
                    }}
                  >
                    <select
                      defaultValue={""}
                      onChange={(event) =>
                        handleTableIngestModeOption(event, data.id, taskId)
                      }
                      style={{ width: "100%", padding: "0.75em" }}
                      name="select ingestion Mode"
                    >
                      {tableOptions.map((item) => (
                        <option key={item.id} value={item.option}>
                          {item.title}
                        </option>
                      ))}
                    </select>
                  </td>
                  {data.load_type === "delta" && (
                    <td
                      style={{
                        width: "200px",
                      }}
                    >
                      {data.deltaColumnDataLoader ? (
                        <select
                          defaultValue={""}
                          onChange={(event) =>
                            handleDeltaColumnChange(event, data.id, taskId)
                          }
                          style={{ width: "100%", padding: "0.75em" }}
                          name="select ingestion Mode"
                        >
                          <option value="">Select</option>
                          {useSchemas ? (
                            deltaColumn
                              .find((column) => column.id === data.id)
                              ?.COLUMN_NAMES?.map((delta_object, index) => (
                                <option key={index} value={delta_object.name}>
                                  {delta_object.name}
                                </option>
                              ))
                          ) : (
                            <option disabled>No delta</option>
                          )}
                        </select>
                      ) : (
                        <p>Enter the column name</p>
                      )}
                    </td>
                  )}
                  <td
                    style={{
                      width: "200px",
                    }}
                  >
                    <input
                      type="checkbox"
                      defaultChecked={""}
                      onChange={(event) =>
                        handlePartionChange(event, data.id, taskId)
                      }
                    />
                  </td>
                  {data.partitioning && (
                    <td
                      style={{
                        width: "200px",
                      }}
                    >
                      {data.partitionColumnDataLoader ? (
                        <MultiSelect
                          onChange={(event) =>
                            handlePartitioningChange(event, data.id, taskId)
                          }
                          options={
                            useSchemas
                              ? partitionColumn
                                  .find((column) => column.id === data.id)
                                  ?.COLUMN_NAMES?.map((partition_object) => ({
                                    value: partition_object.name,
                                    label: partition_object.name,
                                  })) || []
                              : [
                                  {
                                    value: "",
                                    label: "No column",
                                    isDisabled: true,
                                  },
                                ]
                          }
                        />
                      ) : (
                        <p>Enter your table name</p>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          <div>
            <button onClick={addRow} className={"next_btn save_btn"}>
              Add more Tables
            </button>
          </div>
        </div>
      )}
    </StyledDashboardContentFlex>
  );
};
export default OracleRenderTable;
