import React from "react";
import { StyledPortalContent, H3 } from "../index";

const NoteBookOverview = () => {
  return (
    <StyledPortalContent>
      <H3>Note Book</H3>
    </StyledPortalContent>
  );
};

export default NoteBookOverview;
