import { combineReducers } from "redux";
import usersReducer from "./usersReducer";
import glueMetadataReducer from "./glueMetadataReducer";
import elementsDndSlice from "./elementsDndReducer";
import supportReducer from "./supportReducer";
import metadataReducer from "./metadataReducer";
import customersReducer from "./customersReducer";

export default combineReducers({
  users: usersReducer,
  glueMetadata: glueMetadataReducer,
  elementsDnd: elementsDndSlice,
  support: supportReducer,
  metaData: metadataReducer,
  customers: customersReducer,
});
