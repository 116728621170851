import styled from "styled-components";

export const StyledImage = styled.img`
  width: 60px;
  height: 60px;
  object-fit: ${(props) => props.objectFit ?? "cover"};
  margin: 0 auto;
`;
export const DynamicGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2em;
  @media (max-width: ${({ theme }) => theme.responsive.ipad}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
export const StyledConnectContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  padding-top: 1.5em;
`;
export const StyledConnect = styled.div`
  width: 100%;
  height: 100%;
  padding: 2em 0;
  position: relative;
`;
