import styled from "styled-components";
import Theme from "../../../Theme";

export const ErrorContainer = styled.div`
  background-color: #fbe9e6;
  display: flex;
  width: 100%;
  gap: 1em;
  padding: 1em;
  .error_icon {
    color: ${Theme.colors.errorColor};
    font: ${Theme.fonts.smallFont};
  }
`;
