import React from 'react';
import { Checkbox, Typography } from "@mui/material";

/**
 * @typedef {Object} Attribute
 * @property {string} name - The name of the attribute.
 * @property {string} type - The type of the attribute.
 * @property {boolean} active - Indicates if the attribute is active.
 */

/**
 * @typedef {Object} AttributeItemProps
 * @property {string} objectEndpoint - The endpoint of the parent object.
 * @property {Attribute} attribute - The attribute to render.
 * @property {boolean} isObjectSelected - Indicates if the parent object is selected.
 * @property {(endpoint: string, attributeName: string) => void} handleAttrSelection - Function to toggle attribute selection.
 */

/**
 * Component to render an individual attribute.
 * @param {AttributeItemProps} props
 * @returns {JSX.Element}
 */
const AttributeItem = ({ objectEndpoint, attribute, isObjectSelected, handleAttrSelection }) => {
  return (
    <div
      style={{ display: "flex", alignItems: "center", marginBottom: "0.5rem" }}
      key={`${objectEndpoint}-${attribute.name}`}
    >
      <Checkbox
        disabled={!isObjectSelected}
        checked={attribute.active}
        onChange={() => handleAttrSelection(objectEndpoint, attribute.name)}
      />
      <Typography>{attribute.name}</Typography>
      <Typography
        style={{ marginLeft: "2rem" }}
        variant="caption"
      >
        {attribute.type}
      </Typography>
    </div>
  );
};

export default AttributeItem;
