import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledFieldset = styled.fieldset`
  width: 100%;
`;
export const Legend = styled.legend`
  margin: 0 auto 0 1em;
  color: black;
  padding: 6px;
  font-weight: bold;
  font-size: 1em;
`;
export const StyledTaskContainer = styled.div`
  padding: 1em;
`;
export const StyledTask = styled.div`
  width: 100%;
  border-radius: 8px;
  padding: 1em;
  border: 1px solid ${({ theme }) => theme.colors.transparentBlack};
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
export const StyledTaskTitle = styled.p`
  color: ${({ theme }) => theme.colors.sysSurfaceTextColor};
  font: ${({ theme }) => theme.fonts.fontMedium};
`;
export const StyledIconContainer = styled.div`
  display: flex;
  gap: 0.5em;
  justify-content: flex-end;
  .add_remove {
    font-size: 1.5em;
  }
`;
export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primaryColor};
  font-weight: ${({ theme }) => theme.fonts.boldFont};
  text-decoration: none;
  &:hover {
    opacity: 0.6;
  }
`;
export const StyledJobContainer = styled.div`
  width: 80%;
  max-width: 100%;
  margin: auto 0;
  padding: 1em 2em;
  box-sizing: content-box;
  background-color: ${({ theme }) => theme.colors.secondaryColor};
  height: 80vh;
  overflow-y: scroll;
`;
export const StyledScroll = styled.div`
  position: absolute;
  overflow: scroll;
  width: 100%;
`;
