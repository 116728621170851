import React, { useState, useEffect } from "react";
import {
  StyledDashboardContentFlex,
  ModalHeader,
  Button,
  StyledModal,
  Flex,
  StyledConnectorInput,
} from "../../index";

import {
  fetchUserScripts,
  postUserScripts,
} from "../../redux/actions/customerAction";
import { Autocomplete, TextField, Dialog, FormControl } from "@mui/material";
import CloseSvg from "../../reusableComponents/svg/CloseSvg";
import ButtonOutlineIcon from "../../reusableComponents/UIComponents/ButtonOutlineIcon";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useGetToken from "../../cognito/useGetToken";

const GPTModal = ({
  scriptName,
  handleScriptNameChange,
  bundleRef,
  postLlmQuestion,
  handleClickClosed,
  openModal,
}) => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const { environmentVariables } = useSelector((state) => state.users);
  const [existingFolder, setExistingFolder] = useState(null);
  const { t } = useTranslation();
  const { token } = useGetToken();
  const groupName = localStorage.getItem("group");
  const { userScript } = useSelector((state) => state.customers);
  const user = localStorage.getItem("created");
  useEffect(() => {
    if (token) {
      dispatch(
        fetchUserScripts(
          token,
          groupName,
          user,
          environmentVariables.REACT_APP_BASE_URL_API_CUSTOMER,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, dispatch]);
  const postSQLTabScripts = async () => {
    const formData = {
      scriptName: scriptName,
      script: postLlmQuestion,
    };
    if (scriptName === null || existingFolder === null) {
      setError("Field cannot be empty");
      return;
    }
    if (token) {
      dispatch(
        postUserScripts(
          token,
          groupName,
          bundleRef.current,
          user,
          existingFolder,
          formData,
          environmentVariables.REACT_APP_BASE_URL_API_CUSTOMER,
        ),
      );
    }
    handleClickClosed();
  };
  return (
    <StyledModal>
      <Dialog
        open={openModal}
        onClose={handleClickClosed}
        fullWidth
        PaperProps={{ sx: { borderRadius: "28px" } }}
      >
        <ModalHeader>
          <p>
            {t("data_management.transformation.presenter.modal.save.title")}
          </p>
          <div onClick={handleClickClosed}>
            <CloseSvg />
          </div>
        </ModalHeader>
        <StyledDashboardContentFlex column style={{ padding: "2em" }}>
          <Flex>
            <StyledConnectorInput
              placeholder="Enter your script name"
              name="script-name"
              value={scriptName}
              onChange={handleScriptNameChange}
            />
            <p className="error">{error}</p>
          </Flex>

          <FormControl>
            <Autocomplete
              disablePortal
              value={existingFolder}
              id="select-folder"
              freeSolo
              // change edit script to only edit per user
              options={
                userScript !== null
                  ? userScript.map((script) => ({
                      label: script.folderName,
                    }))
                  : []
              }
              onChange={(_event, newValue) => {
                setExistingFolder(newValue ? newValue.label : "");
              }}
              onInputChange={(_event, newInputValue) => {
                setExistingFolder(newInputValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Folder name" />
              )}
            />
          </FormControl>
          <Flex style={{ flexDirection: "row-reverse" }}>
            <Button
              onClick={postSQLTabScripts}
              disabled={scriptName === "" || existingFolder === null}
              text={"Save"}
              className={"next_btn save_btn"}
            />
            <div onClick={handleClickClosed}>
              <ButtonOutlineIcon
                label={t(
                  "data_management.transformation.presenter.modal.save.buttons.cancel",
                )}
              />
            </div>
          </Flex>
        </StyledDashboardContentFlex>
      </Dialog>
    </StyledModal>
  );
};
export default GPTModal;
