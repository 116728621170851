import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import DocumentPageLayout from "./DocumentPageLayout";

const AboutDataboat = () => {
  const { t } = useTranslation();
  const contentArr = [
    {
      name: t("general.about_databoat"),
      link: "#about-databoat",
    },
  ];

  return (
    <DocumentPageLayout breadcrumbs={contentArr}>
      <Grid container>
        <Grid item>
          <h1 id="about-databoat">{t("general.about_databoat")}</h1>
          <p>
            Our data platform is build on a winning combination of open source
            tooling, all selected for the right purpose. A Modular Framework
            which combine the best of development application for data ingestion
            with Ops capabilities to automate the end-to-end solution in a
            parameterizable and scalable way.
          </p>
        </Grid>
      </Grid>
    </DocumentPageLayout>
  );
};

export default AboutDataboat;
