import React, { useState, useMemo } from "react";
import { Outlet } from "react-router-dom";
import {
  StyledDashboardContentFlex,
  Flex,
  Tabs,
  HomeSvg,
  CatalogSvg,
  TransformationSvg,
  StyledPortalContent,
  PreLoader,
} from "../../index";
import { Autocomplete, TextField } from "@mui/material";
import DatabaseTable from "./Components/DatabaseTable";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const CatalogPresenter = ({
  token,
  isModalOpen,
  setModalOpen,
  updateTable,
  updateTableDescription,
  deleteTable,
  handleRunQuery,
  isRunQueryActive,
  closeQueryModal,
  handleExportCSV,
  deleteLoader,
}) => {
  const { t } = useTranslation();
  const {
    metaDataTables,
    metaDataTablesLoader,
    metaDataDataset,
    metaDataDatasetLoader,
  } = useSelector((state) => state.metaData);

  const fill = "#006399";
  const size = "18";
  const tabData = [
    {
      to: "/dataManagement",
      icon: <HomeSvg fill={fill} width={size} height={size} />,
      label: t("data_management.catalog.header.home"),
    },
    {
      to: "/transformation",
      icon: <TransformationSvg fill={fill} width={size} height={size} />,
      label: t("data_management.catalog.header.transformation"),
    },
    {
      to: "/catalog",
      icon: <CatalogSvg fill={fill} width={size} height={size} />,
      label: t("data_management.catalog.header.catalog"),
    },
  ];
  const [newDatabaseName, setNewDatabaseName] = useState(null);

  const filteredData = useMemo(() => {
    return (
      metaDataTables !== null &&
      metaDataTables.filter((item) => {
        const matchedItem = newDatabaseName
          ? item.dataset_name === newDatabaseName
          : true;
        return matchedItem;
      })
    );
  }, [metaDataTables, newDatabaseName]);
  return (
    <StyledPortalContent>
      <div style={{ padding: "1em", height: "90vh", overflow: "scroll" }}>
        <StyledDashboardContentFlex column>
          <Tabs tabs={tabData} />
          {metaDataTablesLoader && metaDataDatasetLoader ? (
            <>
              <StyledDashboardContentFlex>
                <Autocomplete
                  disablePortal
                  value={newDatabaseName}
                  id="select-folder"
                  freeSolo
                  options={metaDataDataset.map((data) => ({
                    label: data,
                  }))}
                  onChange={(_event, newValue) => {
                    setNewDatabaseName(newValue ? newValue.label : "");
                  }}
                  onInputChange={(_event, newInputValue) => {
                    setNewDatabaseName(newInputValue);
                  }}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("data_management.catalog.presenter.placeholder")}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "50px",
                        },
                      }}
                    />
                  )}
                />
              </StyledDashboardContentFlex>
              <Flex column style={{ gap: "2em" }}>
                {/* tables  */}
                <DatabaseTable
                  token={token}
                  dbSchemaRepresentation={filteredData}
                  isModalOpen={isModalOpen}
                  setModalOpen={setModalOpen}
                  updateTable={updateTable}
                  updateTableDescription={updateTableDescription}
                  deleteTable={deleteTable}
                  handleRunQuery={handleRunQuery}
                  isRunQueryActive={isRunQueryActive}
                  closeQueryModal={closeQueryModal}
                  handleExportCSV={handleExportCSV}
                  deleteLoader={deleteLoader}
                />
              </Flex>
            </>
          ) : (
            <PreLoader />
          )}

          <Outlet />
        </StyledDashboardContentFlex>
      </div>
    </StyledPortalContent>
  );
};

export default CatalogPresenter;
