import React from "react";
import styled from "styled-components";
import Theme from "../../Theme";

export const StyledButton = styled.button`
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
  background-color: ${Theme.colors.secondaryColor};
  color: ${Theme.colors.blueColor};
  text-align: center;
  letter-spacing: ${Theme.colors.primaryLetterSpacing};
  font: ${Theme.fonts.fontPrimary};
  width: auto;
  padding: 10px 24px 10px 16px;
  border-radius: 100px;
  border: 1px solid ${Theme.colors.borderColor};
  &:hover {
    background: ${Theme.colors.stateLayerPrimaryOpacity008};
    transition: 0.2s;
  }
  &:disabled {
    border: 1px solid ${Theme.colors.stateLayersOnSurfaceOpacity012};
    background-color: ${Theme.colors.secondaryColor};
    color: ${Theme.colors.sysSurfaceTextColor};
    cursor: not-allowed;
    opacity: 0.38;
  }
  cursor: pointer;
`;

const ButtonOutlineIcon = ({
  label = "button",
  handleOnClick,
  disabled = false,
  icon,
}) => {
  return (
    <StyledButton onClick={handleOnClick} disabled={disabled}>
      {icon}
      {label}
    </StyledButton>
  );
};

export default ButtonOutlineIcon;
