import React, { useState } from "react";
import { RenderDataItems, Theme } from "../../../index";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTranslation } from "react-i18next";
import TableRow from "@mui/material/TableRow";
import {
  Stack,
  FormControlLabel,
  Switch,
  TextField,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";

const ExcatConnector = ({
  excatData,
  handleExcatChange,
  isRequiredFieldFilled,
  excatTableHead,
  myExcatloadType,
  excatTableData,
  excatTableOptions,
  handleSwitchChange,
  handleSyncModeChange,
  handlePrimaryKeyChange,
  taskId,
  onClose,
}) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#CDE5FF",
      opacity: "0.8",
      color: "#001F25",
      textAlign: "left",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
      color: "#001F25",
      font: "normal 500 14px/20px 'Poppins', sans-serif",
      letterSpacing: "0.1px",
    },
    "td, th": {
      border: 0,
    },
  }));

  const { t } = useTranslation();
  const [value, setValue] = useState("");
  return (
    <Stack direction={"column"} spacing={2} style={{ padding: "2em" }}>
      <p>Inform the Excat connection details to be loaded into Databoat </p>
      <RenderDataItems data={excatData} handleChange={handleExcatChange} />
      {isRequiredFieldFilled && (
        <div style={{ width: "100%", overflow: "auto", maxHeight: "288px" }}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <Stack direction={"column"} spacing={1.5}>
              <p>Objects </p>
              <TextField
                placeholder="Search"
                type="text"
                variant="outlined"
                fullWidth
                size="small"
                onChange={(e) => setValue(e.target.value)}
                value={value}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),

                  endAdornment: value && (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setValue("")}
                    >
                      <CancelIcon />
                    </IconButton>
                  ),
                }}
              />
              <TableContainer sx={{ minWidth: 300, maxHeight: "288px" }}>
                <Table aria-label="sticky table">
                  <TableHead>
                    <StyledTableRow>
                      {excatTableHead.map((column) => (
                        <StyledTableCell
                          key={column.id}
                          style={{ minWidth: 100 }}
                          sx={{ padding: "10px" }}
                        >
                          {column.title}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {excatTableData.map((data, rowIndex) => (
                      <StyledTableRow key={rowIndex}>
                        <StyledTableCell sx={{ padding: "0 10px" }}>
                          <FormControlLabel
                            control={
                              <Switch
                                defaultChecked={false}
                                onChange={(event) =>
                                  handleSwitchChange(event, data.id, taskId)
                                }
                              />
                            }
                          />
                        </StyledTableCell>
                        <StyledTableCell sx={{ padding: "0 8px" }}>
                          {data.object}
                        </StyledTableCell>
                        <StyledTableCell sx={{ padding: "0 8px" }}>
                          <select
                            value={data.selectedMode}
                            onChange={(event) =>
                              handleSyncModeChange(event, data.id, taskId)
                            }
                            style={{ width: "100%", padding: "0.75em" }}
                            name="syncMode"
                          >
                            {excatTableOptions.map((mode) => (
                              <option key={mode.id} value={mode.option}>
                                {mode.title}
                              </option>
                            ))}
                          </select>
                        </StyledTableCell>
                        {data.selectedMode === "delta" && (
                          <StyledTableCell sx={{ padding: "0 8px" }}>
                            <input
                              type="text"
                              defaultValue={data.primaryKey}
                              onChange={(event) =>
                                handlePrimaryKeyChange(event, data.id, taskId)
                              }
                              style={{ border: "none", outline: "none" }}
                            />
                          </StyledTableCell>
                        )}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Paper>
          <Stack justifyContent={"flex-end"} direction={"row"} spacing={3}>
            <button
              className={"login_btn"}
              onClick={onClose}
              style={{ border: `2px solid ${Theme.colors.blueColor}` }}
            >
              cancel
            </button>
            <button className={"next_btn save_btn"} onClick={onClose}>
              save objects
            </button>
          </Stack>
        </div>
      )}

      <div>
        <button className={"next_btn save_btn"} onClick={onClose}>
          {t("jobs.form.inputs.task.modal.button")}
        </button>
      </div>
    </Stack>
  );
};

export default ExcatConnector;
