import React from "react";
import { Flex } from "../index";
import { StyledTabLink, StyledSlash } from "../styles/Yedaflow.Styled";

const Tab = ({ to, icon, label, onClick }) => {
  return (
    <Flex>
      <StyledTabLink to={to} onClick={onClick}>
        {icon}
        {label}
      </StyledTabLink>
      <StyledSlash>/</StyledSlash>
    </Flex>
  );
};

const Tabs = ({ tabs }) => {
  return (
    <Flex>
      {tabs.map((tab, index) => (
        <Tab key={index} {...tab} />
      ))}
    </Flex>
  );
};

export default Tabs;
