import PropTypes from "prop-types";

const Button = ({ text, className, onClick, type, style, disabled }) => {
  return (
    <button
      className={className}
      onClick={onClick}
      data-testid="testBtn"
      type={type}
      style={style}
      disabled={disabled}
    >
      {text}
    </button>
  );
};
Button.defaultProps = {
  color: "#222",
  width: "100%",
  padding: "0.25em 1em",
  disabled: false,
};
Button.proptype = {
  className: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.string,
  disabled: PropTypes.bool,
};
export default Button;
