import {
  DataBaseSideBar,
  HeaderTabButton,
} from "../Transformation/Styles/TransformationStyle";
import {
  PiDatabaseLight,
  PiCaretRightLight,
  PiCaretLeftLight,
} from "react-icons/pi";
import DatabaseList from "./DatabaseList";
import IconTonalButton from "../../reusableComponents/UIComponents/IconTonalButton";
import { PreLoader } from "../../index";
import { useTranslation } from "react-i18next";

const SideBar = ({
  dbLoader,
  getColumns,
  draggableTableProps,
  dragAndDropQuery,
  setDragAndDropQuery,
  isDbSideBarOpen,
  setIsDbSideBarOpen,
}) => {
  const { t } = useTranslation();
  return (
    <DataBaseSideBar isOpen={isDbSideBarOpen}>
      {isDbSideBarOpen ? (
        <>
          <aside className="dataBaseOpenSideBarHeader">
            <HeaderTabButton>
              <PiDatabaseLight size={24} />
              {t("data_management.transformation.sidebar.header.catalog")}
            </HeaderTabButton>
            <HeaderTabButton selected={false}>
              <PiCaretLeftLight
                size={24}
                onClick={() => {
                  setIsDbSideBarOpen(!isDbSideBarOpen);
                }}
              />
            </HeaderTabButton>
          </aside>
          <div
            style={{
              width: "100%",
              height: "1px",
              background: "#C2C7CF",
            }}
          ></div>
          <div className="dataBaseListContainer">
            {dbLoader ? (
              <DatabaseList
                getColumns={getColumns}
                draggableTableProps={draggableTableProps}
                dragAndDropQuery={dragAndDropQuery}
                setDragAndDropQuery={setDragAndDropQuery}
              />
            ) : (
              <PreLoader />
            )}
          </div>
        </>
      ) : (
        <>
          <div className="dataBaseCloseSideBarContainer">
            <HeaderTabButton selected={false}>
              <PiCaretRightLight
                size={24}
                onClick={() => {
                  setIsDbSideBarOpen(!isDbSideBarOpen);
                }}
              />
            </HeaderTabButton>
            <div className="dataBaseCloseSideBarOptions">
              <IconTonalButton
                icon={<PiDatabaseLight size={24} />}
                handleOnClick={() => {
                  setIsDbSideBarOpen(true);
                }}
              />
              Catalog
            </div>
          </div>
        </>
      )}
    </DataBaseSideBar>
  );
};

export default SideBar;
