import React, { useEffect, useState, Suspense, lazy } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { useIdleTimer } from "react-idle-timer";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
// import ExpiredSessionModal from "./components/ExpiredSessionModal";
import {
  Home,
  MainContainer,
  Theme,
  GlobalStyles,
  DpacManagement,
  Notification,
  Catalog,
  Lineage,
  JobsNestedRoute,
  DataClassification,
  SchemaRegistry,
  DataGovernanceNestedRoute,
  Control,
  ObservabilityNestedRoute,
  DataManagementNestedRoute,
  MLOpsFlow,
  NoteBook,
  Application,
  Metrics,
  DataScienceNestedRoute,
  JobDashBoard,
  EditDataDictionaryModal,
  ErrorPage,
  IsNotLoggedIn,
  DataSetDictionaryModal,
  Yedaflow,
  ForgetPassword,
  DataQuality,
  Logs,
  JobDetails,
  Upload,
  Transformation,
  Analytics,
  QueryBuilder,
  BoatGPT,
  SignUp,
  Documentation,
  DocumentationHome,
  AboutDataboat,
  OverviewDoc,
  DataOnboarding,
  DataOnboardingConnectors,
  ManualUpload,
  SqlServer,
  Oracle,
  BigQuery,
  DataOnBoardingApi,
  Gcs,
  PostgreSql,
  MySql,
  WorkflowManagement,
  WorkflowManagementBoatGpt,
  DataCatalog,
  TransformationDoc,
  Upgrade,
  Bundle,
} from "./components";

import {
  getAWSSSMParameters,
  setLogin,
} from "./components/redux/actions/usersActions";
import Support from "./components/routerComponents/Support";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import { useGetPoolData } from "./clientRequest/env-variable";
import CreateJobScreen from "./components/routerComponents/jobs/CreateJobScreen";
import EditJobComponent from "./components/routerComponents/jobs/EditJobComponent";
import CaseDetails from "./components/support/CaseDetails";
import CreateCase from "./components/support/CreateCase";
import CatalogDetails from "./components/routerComponents/DataManagement/CatalogDetails";
const SessionModal = lazy(() => import("./components/ExpiredSessionModal"));
function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const background = location.state && location.state.background;
  const timeout = 3600000;
  // eslint-disable-next-line no-unused-vars
  const [remaining, setRemaining] = useState(timeout);
  const [modalOpen, setModalOpen] = useState(false);
  const getPoolData = useGetPoolData();

  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const handleOnIdle = () => {
    // Show the toast notification
    toast.info("You have been logged out.", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      pauseOnFocusLoss: false,
    });
    localStorage.clear();
    navigate("/");
  };

  const { getRemainingTime } = useIdleTimer({
    timeout,
    onIdle: handleOnIdle,
  });

  useEffect(() => {
    dispatch(getAWSSSMParameters());
    setRemaining(getRemainingTime());

    setInterval(() => {
      setRemaining(getRemainingTime());
    }, 600000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = () => {
    setModalOpen(false);
    dispatch(setLogin(false));
    navigate("/");
    localStorage.clear();
  };
  const handleSignOut = () => {
    const UserPool = new CognitoUserPool(getPoolData);
    const cognitoUser = UserPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
      localStorage.clear();
      navigate("/");
    }
  };
  useEffect(() => {
    // Function to generate or retrieve the device identifier
    const getOrSetDeviceIdentifier = () => {
      let deviceIdentifier = localStorage.getItem("deviceIdentifier");

      // If no identifier exists, generate a new one
      if (!deviceIdentifier) {
        deviceIdentifier = `device-${Math.random().toString(36).substr(2, 9)}`;
        localStorage.setItem("deviceIdentifier", deviceIdentifier);
      }

      return deviceIdentifier; // Return the identifier for use in the app
    };

    // Set or retrieve the device identifier when the app loads
    getOrSetDeviceIdentifier();
  }, []);
  return (
    <MuiThemeProvider theme={Theme}>
      <ThemeProvider theme={Theme}>
        <GlobalStyles />
        <ToastContainer />
        <MainContainer>
          <Suspense fallback={<div>Loading...</div>}>
            {modalOpen && (
              <SessionModal isOpen={modalOpen} onClose={closeModal} />
            )}
          </Suspense>
          <Routes location={background || location}>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/signup" element={<SignUp />} />
            <Route exact path="/resetPassword" element={<ForgetPassword />} />

            {isLoggedIn && (
              <>
                <Route exact path="/upgrade" element={<Upgrade />} />
                <Route exact path="/bundle" element={<Bundle />} />
                <Route
                  exact
                  path="/dataManagement"
                  element={<DpacManagement />}
                />
                <Route path="/notification" element={<Notification />} />
                <Route path="/control" element={<Control />} />
                <Route path="/analytics" element={<Analytics />} />

                <Route path="/support" element={<Support />} />
                <Route path="/details/:id" element={<CaseDetails />} />
                <Route path="/create-case" element={<CreateCase />} />
                <Route element={<JobsNestedRoute />}>
                  <Route path="/dashboard" element={<JobDashBoard />} />
                  <Route path="/yedaflow" element={<Yedaflow />} />
                  <Route path="/create-job" element={<CreateJobScreen />} />
                  <Route
                    path="/edit-job/:name"
                    element={<EditJobComponent />}
                  />
                  <Route
                    path="/yedaflow/details/:jobName"
                    element={<JobDetails />}
                  />
                </Route>
                <Route element={<DataManagementNestedRoute />}>
                  <Route
                    exact
                    path="/transformation"
                    element={<Transformation />}
                  />
                  <Route path="/catalog" element={<Catalog />} />
                  <Route
                    path="/catalog/:database/:table"
                    element={<CatalogDetails></CatalogDetails>}
                  />
                  <Route path="/queryBuilder" element={<QueryBuilder />} />
                  <Route path="/boatGPT" element={<BoatGPT />} />
                  <Route path="/upload" element={<Upload />} />
                  <Route
                    path="/schema/:Dataset"
                    element={<DataSetDictionaryModal />}
                  />
                  <Route
                    path="/edit/:Dataset"
                    element={<EditDataDictionaryModal />}
                  />
                </Route>
                <Route element={<DataGovernanceNestedRoute />}>
                  <Route
                    path="/dataClassification"
                    element={<DataClassification />}
                  />
                  <Route path="/schemaRegistry" element={<SchemaRegistry />} />
                </Route>
                <Route element={<ObservabilityNestedRoute />}>
                  <Route path="/lineage" element={<Lineage />} />
                  <Route path="/application" element={<Application />} />
                  <Route path="/metrics" element={<Metrics />} />
                  <Route path="/logs" element={<Logs />} />
                  <Route exact path="/dataQuality" element={<DataQuality />} />
                </Route>
                <Route element={<DataScienceNestedRoute />}>
                  <Route path="/notebook" element={<NoteBook />} />
                  <Route path="/mlOps" element={<MLOpsFlow />} />
                </Route>
              </>
            )}

            <Route
              path="*"
              element={<ErrorPage handleSignout={handleSignOut} />}
            />
            <Route path="/docs" element={<Documentation />}>
              <Route index element={<DocumentationHome />} />
              <Route path="about-databoat" element={<AboutDataboat />} />
              <Route path="overview" element={<OverviewDoc />} />
              <Route index element={<DocumentationHome />} />
              <Route path="about-databoat" element={<AboutDataboat />} />
              <Route path="overview" element={<OverviewDoc />} />
              <Route path="data-onboarding">
                <Route index element={<DataOnboarding />} />
                <Route
                  path="connectors"
                  element={<DataOnboardingConnectors />}
                />
                <Route path="manual-upload" element={<ManualUpload />} />
                <Route path="sql-server" element={<SqlServer />} />
                <Route path="oracle" element={<Oracle />} />
                <Route path="big-query" element={<BigQuery />} />
                <Route path="api" element={<DataOnBoardingApi />} />
                <Route path="gcs" element={<Gcs />} />
                <Route path="postgre-sql" element={<PostgreSql />} />
                <Route path="my-sql" element={<MySql />} />
                <Route index element={<AboutDataboat />} />
                <Route path="manual-upload" element={<ManualUpload />} />
                <Route path="sql-server" element={<SqlServer />} />
              </Route>
              <Route path="workflow-management">
                <Route index element={<WorkflowManagement />} />
                <Route
                  path="boat-gpt"
                  element={<WorkflowManagementBoatGpt />}
                />
              </Route>
              <Route path="data-catalog">
                <Route index element={<DataCatalog />} />
              </Route>
              <Route path="transformation" element={<TransformationDoc />} />
            </Route>

            {!isLoggedIn && (
              <Route path="/dataManagement" element={<IsNotLoggedIn />} />
            )}
          </Routes>

          {background && (
            <Routes>
              <Route
                path="/schema/:Dataset"
                element={<DataSetDictionaryModal />}
              />
              <Route
                path="/edit/:Dataset"
                element={<EditDataDictionaryModal />}
              />
            </Routes>
          )}
        </MainContainer>
      </ThemeProvider>
    </MuiThemeProvider>
  );
}

export default App;
