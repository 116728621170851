import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledCardContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.secondaryColor};
  box-shadow:
    rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  padding: 2em;
  max-width: 100%;
`;

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.secondaryColor};
  box-shadow:
    rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  height: fit-content;
  border-color: #ddd;
  min-height: 310px;
  max-width: 100%;
  display: flex;
  gap: 1em;
  flex-direction: column;
`;
export const CardMedia = styled.img`
  max-width: 100%;
`;
export const CardContent = styled.div`
  color: ${({ theme }) => theme.colors.sysSurfaceVariant};
  padding: 0 1em;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  height: 112px;
`;
export const CardHeader = styled.p`
  font: ${({ theme }) => theme.fonts.fontSecondaryBold};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;
export const StyledTitle = styled.p`
  font: ${({ theme }) => theme.fonts.fontMediumBold};
`;
export const CardActions = styled(Link)`
  color: ${({ theme }) => theme.colors.sysSurfaceVariant};
  letter-spacing: 0.1px;
  text-decoration: none;
  float: right;
  padding: 0.25em 1em;
  border-radius: ${({ theme }) => theme.radius.primaryBorderRadius};
  border: 1px solid ${({ theme }) => theme.colors.blueColor};
  font: ${({ theme }) => theme.fonts.fontPrimary};
`;
export const CardBody = styled.p`
  color: ${({ theme }) => theme.colors.sysSurfaceTextColor};
  font: ${({ theme }) => theme.fonts.smallFont};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
`;
export const StyledCardWithoutButtonContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.secondaryColor};
  padding: 1.5em;
  color: ${({ theme }) => theme.colors.sysSurfaceVariant};
  box-shadow:
    rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border: none;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2em;
  .header {
    font: ${({ theme }) => theme.fonts.fontSecondaryBold};
  }
  .amount {
    font: ${({ theme }) => theme.fonts.fontMediumBold};
    font-size: 3em;
    text-align: center;
  }
  .title {
    font: ${({ theme }) => theme.fonts.smallFont};
    text-align: center;
    color: ${({ theme }) => theme.colors.blueColor};
  }
  .date {
    font: ${({ theme }) => theme.fonts.smallFont};
    text-align: center;
    font-size: 10px;
  }
`;
