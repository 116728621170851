import styled from "styled-components";

export const StyledCookie = styled.div`
  padding: 1.5rem 1.5rem 2rem;
  display: flex;
  gap: 20px;
  max-width: 400px;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.blueColor};
  color: ${({ theme }) => theme.colors.whiteColor};

  h2 {
    font:
      normal 500 16px/20px "Poppins",
      sans-serif;
  }

  p,
  a {
    font:
      normal 400 14px/18px "Poppins",
      sans-serif;
  }

  a {
    text-decoration: underline;
    cursor: pointer;
  }

  .opt-in {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &-btn {
      background-color: ${({ theme }) => theme.colors.whiteColor};
      color: ${({ theme }) => theme.colors.blueColor};
      border-radius: 4px;
      padding: 1rem 2rem;
      border: 0;
      cursor: pointer;
      font:
        normal 500 14px/18px "Poppins",
        sans-serif;
    }
  }
`;
