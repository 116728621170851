import React, { useState, useEffect, Suspense, lazy } from "react";
import moment from "moment";
import DashBoardScreen from "../reusableComponents/DashBoardScreen";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InfoIcon from "@mui/icons-material/Info";
import { Divider, Stack, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  putSupportStatus,
  clearputSupportStatusToast,
  clearputSupportStatusToastError,
} from "../redux/actions/usersActions";
import {
  CreateJobButton,
  PreLoader,
  StyledDashboardContentFlex,
  StyledPortalContent,
  Tabs,
  Theme,
  Flex,
} from "../index";
import { useDispatch, useSelector } from "react-redux";
import { StyledTabParagraph, StyledSlash } from "../styles/Yedaflow.Styled";
import useGetToken from "../cognito/useGetToken";
const ImagePreview = lazy(() => import("./ImageModal"));
const ReplyPreview = lazy(() => import("./ReplyModal"));

const CaseDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { token } = useGetToken();
  const { environmentVariables } = useSelector((state) => state.users);
  const {
    supportStatus,
    supportStatusLoader,
    supportStatusError,
    putSupport,
    putSupportError,
  } = useSelector((state) => state.support);

  const created_by = localStorage.getItem("group");
  const tabData = [
    {
      to: "/support",
      icon: (
        <SupportAgentIcon
          sx={{ fill: "#006399", width: "18px", height: "18px" }}
        />
      ),
      label: "Support",
    },
    {
      to: "/create-case",
      icon: (
        <AddCircleIcon
          sx={{ fill: "#006399", width: "18px", height: "18px" }}
        />
      ),
      label: "create-case",
    },
  ];
  const { data } = location.state;
  const style = {
    background: "#fff",
    padding: "1.5em",
  };
  const headingStyle = {
    font: Theme.fonts.fontBasic,
    color: "grey",
  };
  const [isReplyClicked, setIsReplyClicked] = useState(false);
  const [isUrlClicked, setIsUrlClicked] = useState(false);
  const handleCloseReplyModalOpen = () => {
    setIsReplyClicked(false);
  };
  const handleCloseS3urlModal = () => {
    setIsUrlClicked(false);
  };
  const updateCaseStatus = (case_id) => {
    const case_status = "resolved";
    if ((created_by, case_id, case_status)) {
      dispatch(
        putSupportStatus(
          created_by,
          case_id,
          case_status,
          token,
          environmentVariables.REACT_APP_BASE_URL_API_CORE,
        ),
      );
    }
  };

  useEffect(() => {
    if (supportStatus !== null) {
      toast.success(supportStatus.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnFocusLoss: false,
      });
      dispatch(clearputSupportStatusToast());
    }
    if (supportStatusError) {
      toast.warning("Update failed, try again.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnFocusLoss: false,
      });

      dispatch(clearputSupportStatusToastError());
    }
  }, [supportStatusError, supportStatus, dispatch]);
  useEffect(() => {
    if (putSupport !== null) {
      toast.success(putSupport.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnFocusLoss: false,
      });
      dispatch(clearputSupportStatusToast());
    }
    if (putSupportError) {
      toast.warning("Update failed, try again.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnFocusLoss: false,
      });

      dispatch(clearputSupportStatusToastError());
    }
  }, [putSupportError, putSupport, dispatch]);
  function extractFileNames(urls) {
    return urls.map((url) => {
      if (typeof url !== "string" || url.trim() === "") {
        return "";
      }

      // Remove the s3://bucket-name/ part to get the path
      const path = url.split("/").slice(2).join("/");

      // Get the file name with extension
      const fileNameWithExtension = path.split("/").pop();

      // Split by '.' and get the name before the last dot
      const fileName = fileNameWithExtension.split(".").slice(0, -1).join(".");

      return fileName;
    });
  }

  return (
    <DashBoardScreen
      children={
        <StyledPortalContent>
          <div style={{ padding: "1em", height: "90vh", overflow: "scroll" }}>
            <ToastContainer />
            <StyledDashboardContentFlex column>
              <StyledDashboardContentFlex>
                <Tabs tabs={tabData} />
                <Flex>
                  <StyledTabParagraph>
                    <InfoIcon
                      sx={{ fill: "#006399", width: "18px", height: "18px" }}
                    />
                    Details
                  </StyledTabParagraph>
                  <StyledSlash>/</StyledSlash>
                </Flex>
              </StyledDashboardContentFlex>
              {supportStatusLoader ? (
                <PreLoader />
              ) : (
                <Stack direction={"column"} spacing={2}>
                  <Stack justifyContent={"space-between"} direction={"row"}>
                    <Typography sx={{ font: Theme.fonts.fontMediumBold }}>
                      Case ID: {data.case_id}
                    </Typography>
                    <CreateJobButton
                      text="Resolve case"
                      type={"button"}
                      onClick={() => updateCaseStatus(data.case_id)}
                    />
                  </Stack>
                  <Stack sx={style} spacing={5}>
                    <Stack direction={"column"} spacing={2}>
                      <Typography sx={{ font: Theme.fonts.fontSecondaryBold }}>
                        Case details
                      </Typography>
                      <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        sx={{ width: "60%" }}
                      >
                        <Stack spacing={2}>
                          <Stack direction={"column"} spacing={1}>
                            <p style={headingStyle}>Subject</p>
                            <p>{data.subject}</p>
                          </Stack>
                          <Stack direction={"column"} spacing={1}>
                            <p style={headingStyle}>Case Id</p>
                            <p>{data.case_id}</p>
                          </Stack>
                          <Stack direction={"column"} spacing={1}>
                            <p style={headingStyle}>Created At</p>
                            <p>
                              {moment(data.date).format("YYYY-MM-DD HH:mm")}
                            </p>
                          </Stack>
                          <Stack direction={"column"} spacing={1}>
                            <p style={headingStyle}>Case Type</p>
                            <p>{data.case_type}</p>
                          </Stack>
                          <Stack direction={"column"} spacing={1}>
                            <p style={headingStyle}>Opened by</p>
                            <p>{data.opened_by}</p>
                          </Stack>
                        </Stack>
                        <Stack direction={"row"} spacing={2}>
                          <Divider sx={{ border: "1px solid grey" }} />
                          <Stack direction={"column"} spacing={2}>
                            <Stack direction={"column"} spacing={1}>
                              <p style={headingStyle}>Status</p>
                              <p>
                                {supportStatus !== null
                                  ? supportStatus.updatedItem.case_status
                                  : data.case_status}
                              </p>
                            </Stack>
                            <Stack direction={"column"} spacing={1}>
                              <p style={headingStyle}>Severity</p>
                              <p>{data.severity}</p>
                            </Stack>
                            <Stack direction={"column"} spacing={1}>
                              <p style={headingStyle}>Category</p>
                              <p>{Array.isArray(data.category) ? data.category.join(', ') : data.category}</p>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack direction={"column"} spacing={4}>
                      <div>
                        <Stack
                          justifyContent={"space-between"}
                          direction={"row"}
                        >
                          <Typography
                            sx={{ font: Theme.fonts.fontSecondaryBold }}
                          >
                            Description
                          </Typography>
                          <CreateJobButton
                            text="Reply"
                            type={"button"}
                            style={{
                              background: Theme.colors.sysSurfaceActive,
                              color: Theme.colors.sysSecondaryContainer,
                            }}
                            onClick={() => setIsReplyClicked(!isReplyClicked)}
                          />
                        </Stack>
                        {/* next stack */}
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                          sx={{ width: "60%" }}
                        >
                          <Stack spacing={2}>
                            <Stack direction={"column"} spacing={1}>
                              <p style={{ font: Theme.fonts.fontSecondary }}>
                                {data.description}
                              </p>
                              {/* extractFileName(url) */}
                            </Stack>
                          </Stack>
                        </Stack>
                      </div>
                      <Stack direction={"column"}>
                        <Typography
                          sx={{ font: Theme.fonts.fontSecondaryBold }}
                        >
                          Attachments
                        </Typography>
                        <Stack
                          spacing={1}
                          style={{
                            font: Theme.fonts.fontSecondary,
                            cursor: "pointer",
                            color: Theme.colors.blueColor,
                          }}
                          onClick={() => setIsUrlClicked(!isUrlClicked)}
                        >
                          {extractFileNames(data.s3url).map((name, index) => (
                            <div key={index}>{name}</div>
                          ))}
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              )}
              {/* isReplyClicked */}

              <Suspense fallback={<div>Loading...</div>}>
                {isReplyClicked && (
                  <ReplyPreview
                    isReplyClicked={isReplyClicked}
                    handleCloseReplyModalOpen={handleCloseReplyModalOpen}
                    headingStyle={headingStyle}
                    token={token}
                    data={data}
                  />
                )}
              </Suspense>
              <Suspense fallback={<div>Loading...</div>}>
                {isUrlClicked && (
                  <ImagePreview
                    isUrlClicked={isUrlClicked}
                    handleCloseS3urlModal={handleCloseS3urlModal}
                    data={data.s3url}
                  />
                )}
              </Suspense>
            </StyledDashboardContentFlex>
          </div>
        </StyledPortalContent>
      }
    />
  );
};

export default CaseDetails;
