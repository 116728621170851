import React from "react";
import { MdErrorOutline } from "react-icons/md";
import { ErrorContainer } from "../Styles/ErrorStyle";
import { StyledDashboardContentFlex } from "../../../styles/Login.Styled";

const SQLError = ({ value, value2, value3 }) => {
  return (
    <ErrorContainer>
      <MdErrorOutline className="error_icon" size={"1.5em"} />
      <StyledDashboardContentFlex
        column
        style={{ gap: "1em", justifyContent: "center" }}
      >
        <p>{value}</p>
        {value2 ? <p>{value2}</p> : null}
        {value3 ? <p>{value3}</p> : null}
      </StyledDashboardContentFlex>
    </ErrorContainer>
  );
};

export default SQLError;
