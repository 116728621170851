import React from "react";
import { Grid } from "@mui/material";
import ReactPlayer from "react-player";
import DocumentPageLayout from "../DocumentPageLayout";

const SqlServer = () => {
  const breadcrumbs = [
    {
      name: "Ingesting your data using BigQuery",
      link: "#sql-server",
    },
    {
      name: "SQL Server Connector",
      link: "#sql-server-connector",
    },
  ];

  return (
    <DocumentPageLayout breadcrumbs={breadcrumbs}>
      <Grid container rowSpacing={4}>
        <Grid item>
          <h1 id="sql-server">Ingesting your data using BigQuery</h1>

          <p>
            Check this step by step video - How to easily onboard data on
            DataBoat data platform:
          </p>

          <ReactPlayer
            url="https://vid.cdn-website.com/b29d841e/videos/mw0qBHAjRtS0r3sIY7yj_SQLServer+Connector-v.mp4"
            controls={true}
          />

          <h2 id="sql-server-connector">SQL Server Connector</h2>

          <p>
            <strong>Step 1: Configure Your Source</strong>
          </p>
          <ul>
            <li>
              <p>
                Click on "Configure Your Source" to start defining your data
                workflow.
              </p>
            </li>
          </ul>
          <p>
            <strong>Step 2: Define Workflow Details</strong>
          </p>
          <ul>
            <li>
              <p>Enter a name for the workflow.</p>
            </li>
            <li>
              <p>Set the start time of the workflow based on date and time.</p>
            </li>
            <li>
              <p>
                Determine the execution frequency of the pipeline by selecting
                one of the predefined options.
              </p>
            </li>
          </ul>
          <p>
            <strong>Step 3: Set Up Tasks</strong>
          </p>
          <ul>
            <li>
              <p>
                Add tasks to your workflow. Note that you can include additional
                tasks based on data dependencies with different sources.
              </p>
            </li>
            <li>
              <p>
                For each task, define relevant parameters for the job
                orchestrator. Use key-value pairs in the UI or paste your JSON
                key-value map.
              </p>
            </li>
          </ul>
          <p>
            <strong>Step 4: Choose Task Type</strong>
          </p>
          <ul>
            <li>
              <p>Select the task type, either Ingestion or Transformation.</p>
            </li>
            <li>
              <p>
                If Ingestion is selected, choose an existing connector for SQL
                Server.
              </p>
            </li>
            <li>
              <p>
                Provide connection details such as server, host, and port
                (default port for SQL Server is 1433).
              </p>
            </li>
            <li>
              <p>Enter your database credentials.</p>
            </li>
          </ul>
          <p>
            <strong>Step 5: Select Ingestion Type</strong>
          </p>
          <ul>
            <li>
              <p>Table Ingestion:</p>
            </li>
            <li>
              <p>Select the database and then the schema.</p>
            </li>
            <li>
              <p>Choose the tables you wish to ingest.</p>
            </li>
            <li>
              <p>Choose the ingestion mode: Full Dump or Delta Mode.</p>
            </li>
            <li>
              <p>
                To ingest additional tables, click "Add More Tables" and save
                your choices.
              </p>
            </li>
            <li>
              <p>Query Ingestion:</p>
            </li>
            <li>
              <p>
                Directly write the SQL query for the data you want to ingest.
              </p>
            </li>
            <li>
              <p>Optionally, name the output table.</p>
            </li>
          </ul>
          <p>
            <strong>Step 6: Finalize Workflow</strong>
          </p>
          <ul>
            <li>
              <p>
                Optionally, add tags to the workflow for easier identification.
              </p>
            </li>
            <li>
              <p>
                Set up notifications to alert you about the workflow's execution
                status.
              </p>
            </li>
          </ul>
          <p>
            <strong>Step 7: Create and Execute Job</strong>
          </p>
          <ul>
            <li>
              <p>
                Review all settings, then save the workflow to create the job.
              </p>
            </li>
            <li>
              <p>The job is now ready to be executed.</p>
            </li>
          </ul>
        </Grid>
      </Grid>
    </DocumentPageLayout>
  );
};

export default SqlServer;
