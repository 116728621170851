import { useSelector } from "react-redux";

export const useGetPoolData = () => {
  const envFile = useSelector((state) => state.users.environmentVariables);
  const poolData = {
    UserPoolId: envFile.REACT_APP_USERPOOL_ID,
    ClientId: envFile.REACT_APP_CLIENT_ID,
  };

  return poolData;
};

export const useEnvironmentVariables = () => {
  const envFile = useSelector((state) => state.users.environmentVariables);
  return envFile;
};
