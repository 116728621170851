import React from "react";
import { Dialog } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  StyledModal,
  StyledDashboardContentFlex,
  Flex,
  Button,
  Theme,
} from "../../index";

const UploadMoreModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  if (!isOpen) {
    return null;
  }

  const handleOnCloseModal = (wantMoreUploads) => {
    onClose(wantMoreUploads);
  };

  return (
    <StyledModal>
      <Dialog
        open={isOpen}
        onClose={onClose}
        PaperProps={{
          sx: {
            padding: "1.5em",
            borderRadius: Theme.radius.primaryBorderRadius,
            position: "relative",
          },
        }}
      >
        <StyledDashboardContentFlex column>
          <p
            style={{
              fontWeight: Theme.fonts.boldFont,
              fontSize: "25px",
            }}
          >
            {t("data_management.upload.upload_more_files")}
          </p>
          <div>
            <Flex style={{ flexDirection: "row-reverse" }} gap>
              <Button
                onClick={() => handleOnCloseModal(true)}
                text={t("general.yes")}
                className={"next_btn save_btn"}
              />
              <Button
                onClick={() => handleOnCloseModal(false)}
                text={t("general.no")}
                style={{ backgroundColor: "red" }}
                className={"next_btn save_btn"}
              />
            </Flex>
          </div>
        </StyledDashboardContentFlex>
      </Dialog>
    </StyledModal>
  );
};

export default UploadMoreModal;
