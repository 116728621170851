import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { AiOutlineSearch } from "../index";

const MuiSearchInput = ({
  inputLabel,
  isLoading = false,
  disabled = false,
  onChange,
  handleClick,
}) => {
  return (
    <>
      {isLoading ? (
        <Skeleton variant="rectangular" width={"auto"} height={55} />
      ) : (
        <FormControl fullWidth variant="outlined" disabled={disabled}>
          <InputLabel htmlFor="outlined-adornment-password">
            {inputLabel}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={"text"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle search"
                  onClick={handleClick}
                  edge="end"
                >
                  <AiOutlineSearch />
                </IconButton>
              </InputAdornment>
            }
            label="Password"
            onChange={onChange}
          />
        </FormControl>
      )}
    </>
  );
};

export default MuiSearchInput;
