import React from "react";
import {
  TableDataCell,
  ScrollableTableOverview,
  FieldSet,
  Theme,
} from "../../../index";
import { useTranslation } from "react-i18next";

const EditDataDictionaryTable = ({ rows, updateTable }) => {
  const { t } = useTranslation();
  return (
    <ScrollableTableOverview
      headers={[
        "#",
        t(
          "data_management.catalog.presenter.table.modals.edit.table.headers.name",
        ),
        t(
          "data_management.catalog.presenter.table.modals.edit.table.headers.type",
        ),
        t(
          "data_management.catalog.presenter.table.modals.edit.table.headers.description",
        ),
      ]}
      rows={rows}
      renderRow={(item, index) => (
        <tr key={index}>
          <TableDataCell>{index + 1}</TableDataCell>
          <TableDataCell>{item.column_name}</TableDataCell>
          <TableDataCell>{item.column_type}</TableDataCell>
          <TableDataCell style={{ display: "flex", gap: "0.75em" }}>
            <FieldSet
              style={{ border: 0 }}
              inputStyle={{
                color: Theme.colors.sysSurfaceTextColor,
                font: Theme.fonts.fontSecondary,
                letterSpacing: "0.25px",
              }}
              type={"text"}
              name={"Description"}
              id={`description-${index}`}
              value={item.column_description}
              onBlur={(e) => {
                updateTable(
                  item.dataset_name,
                  item.table_name,
                  item.column_name,
                  e.target.value,
                );
              }}
              placeholder={t(
                "data_management.catalog.presenter.table.modals.edit.table.placeholder",
              )}
            />
          </TableDataCell>
        </tr>
      )}
    />
  );
};
export default EditDataDictionaryTable;
