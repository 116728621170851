import React, { useState } from "react";
import { useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { Flex, PreLoader } from "../../../index";

const QueryResult = () => {
  const { getQueryResultSuccess, getQueryResultLoader } = useSelector(
    (state) => state.users,
  );
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#cde5ff",
      opacity: "0.8",
      color: "#001F25",
      textAlign: "left",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
    minWidth: "min-content", // Smallest size to fit content without breaking
    maxWidth: 300, // Maximum width to prevent too much whitespace
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
      color: "#001F25",
      font: "normal 500 14px/20px 'Poppins', sans-serif",
      letterSpacing: "0.1px",
    },
    "td, th": {
      border: 0,
    },
  }));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const slicedData =
    Array.isArray(getQueryResultSuccess) && getQueryResultSuccess.length > 0
      ? getQueryResultSuccess.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage,
        )
      : [];

  return (
    <Flex column style={{ width: "100%" }}>
      {!getQueryResultLoader && Array.isArray(getQueryResultSuccess) ? (
        <>
          <div style={{ width: "100%", overflow: "auto", maxHeight: "288px" }}>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              {/* ADD SCROLL BEHAVIOUR */}
              <TableContainer sx={{ minWidth: 700, maxHeight: "288px" }}>
                <Table aria-label="sticky table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell sx={{ padding: "8px" }}>
                        Rows
                      </StyledTableCell>
                      {Object.keys(getQueryResultSuccess[0]).map(
                        (header, index) => (
                          <StyledTableCell
                            key={index}
                            sx={{ padding: "8px", align: "right" }}
                          >
                            {header}
                          </StyledTableCell>
                        ),
                      )}
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {slicedData.map((row, rowIndex) => (
                      <StyledTableRow key={rowIndex}>
                        <StyledTableCell sx={{ padding: "0 8px" }}>
                          {rowIndex}
                        </StyledTableCell>
                        {Object.keys(row).map((header, colIndex) => (
                          <StyledTableCell
                            key={colIndex}
                            sx={{ padding: "0 8px" }}
                          >
                            {row[header] === null || row[header] === ""
                              ? "Nothing to show"
                              : row[header].value
                              ? row[header].value
                              : row[header]}
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={getQueryResultSuccess.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <PreLoader />
      )}
    </Flex>
  );
};

export default QueryResult;
