import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { fetchMetadataColumns } from "../../../redux/actions/metaDataAction";
import {
  MdEdit,
  MdOutlineDeleteOutline,
  MdRemoveRedEye,
  Theme,
  Flex,
  DeleteModal,
  Table,
  TableHeaderCell,
  Thead,
  TableDataCell,
  FieldSet,
} from "../../../index";
import { TableContainer, Paper, TablePagination } from "@mui/material";
import { styled } from "@mui/material/styles";
import RunQuery from "./ViewTableQuery";
import EditDataDictionaryModal from "./EditDataDictionaryModal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const DatabaseTable = ({
  token,
  dbSchemaRepresentation,
  isModalOpen,
  setModalOpen,
  deleteTable,
  updateTable,
  updateTableDescription,
  isRunQueryActive,
  handleRunQuery,
  closeQueryModal,
  handleExportCSV,
  deleteLoader,
}) => {
  const databaseNameRef = useRef();
  const tableNameRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const group = localStorage.getItem("group");

  // eslint-disable-next-line no-unused-vars
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = useState(false);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const { environmentVariables } = useSelector((state) => state.users);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleIsEditingOpen = (table_name) => {
    setOpen(true);
    tableNameRef.current = table_name;

    dispatch(
      fetchMetadataColumns(
        table_name,
        token,
        group,
        environmentVariables.REACT_APP_BASE_URL_API_METADATA,
      ),
    );
  };

  const StyledTablePagination = styled(TablePagination)({
    // You can customize the styles here
    "& p": {
      marginBottom: 0,
    },
  });
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenQuery = (databaseName, tableName) => {
    handleRunQuery(databaseName, tableName);
    tableNameRef.current = tableName;
  };

  const openDeleteModal = (database, table) => {
    databaseNameRef.current = database;
    tableNameRef.current = table;
    setModalOpen(true);
  };
  const closeModal = () => setModalOpen(false);

  const paginatedData = dbSchemaRepresentation.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );
  const navigate = useNavigate();
  return (
    <>
      <Flex column style={{ gap: "2em", width: "100%" }}>
        <div style={{ width: "100%" }}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 740 }}>
              <Table>
                <Thead>
                  <tr>
                    <TableHeaderCell>
                      {t(
                        "data_management.catalog.presenter.table.header.data_name",
                      )}
                    </TableHeaderCell>
                    <TableHeaderCell>
                      {t(
                        "data_management.catalog.presenter.table.header.table_name",
                      )}
                    </TableHeaderCell>
                    <TableHeaderCell>
                      {t(
                        "data_management.catalog.presenter.table.header.description",
                      )}
                    </TableHeaderCell>
                    <TableHeaderCell style={{ textAlign: "center " }}>
                      {t(
                        "data_management.catalog.presenter.table.header.modify",
                      )}
                    </TableHeaderCell>
                  </tr>
                </Thead>
                <tbody>
                  {paginatedData.map((metaData, index) => (
                    <tr key={index}>
                      <TableDataCell>{metaData.dataset_name}</TableDataCell>
                      <TableDataCell>
                        <div
                          key={metaData.table_name}
                          style={{
                            display: "flex",
                            gap: "0.75em",
                            justifyContent: "space-between",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigate(
                              `/catalog/${metaData.dataset_name}/${metaData.table_name}`,
                            );
                          }}
                        >
                          {metaData.table_name}
                        </div>
                      </TableDataCell>
                      <TableDataCell style={{ cursor: "pointer" }}>
                        <FieldSet
                          style={{ border: 0, padding: 0 }}
                          inputStyle={{
                            color: Theme.colors.sysSurfaceTextColor,
                            font: Theme.fonts.fontSecondary,
                            letterSpacing: "0.25px",
                            padding: "1em 0",
                          }}
                          type={"text"}
                          name={"TableDescription"}
                          id={`${metaData.dataset_name}${index + 1}`}
                          value={metaData.table_description}
                          onBlur={(e) => {
                            updateTableDescription(
                              metaData.dataset_name,
                              metaData.table_name,
                              e.target.value,
                            );
                          }}
                          placeholder={"Enter your description"}
                        />
                      </TableDataCell>
                      <TableDataCell style={{ textAlign: "center " }}>
                        <Link
                          className="modify_link"
                          onClick={() =>
                            handleOpenQuery(
                              metaData.dataset_name,
                              metaData.table_name,
                            )
                          }
                        >
                          <MdRemoveRedEye
                            fontSize={"1.25em"}
                            style={{
                              color: Theme.colors.blueColor,
                              marginLeft: "1em",
                            }}
                          />
                        </Link>
                        <Link
                          key={metaData.table_name}
                          onClick={() =>
                            handleIsEditingOpen(metaData.table_name)
                          }
                          className="modify_link"
                        >
                          <MdEdit
                            fontSize={"1.25em"}
                            style={{
                              color: Theme.colors.blueColor,
                              marginLeft: "1em",
                            }}
                          />
                        </Link>
                        <Link
                          className="modify_link delete"
                          onClick={() =>
                            openDeleteModal(
                              metaData.dataset_name,
                              metaData.table_name,
                            )
                          }
                        >
                          <MdOutlineDeleteOutline
                            fontSize={"1.25em"}
                            style={{ color: "red", marginLeft: "1em" }}
                          />
                        </Link>
                      </TableDataCell>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </TableContainer>
            <StyledTablePagination
              rowsPerPageOptions={[15, 25, 50]}
              component="div"
              count={dbSchemaRepresentation.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
        {open && (
          <EditDataDictionaryModal
            open={open}
            handleClose={handleClose}
            updateTable={updateTable}
          />
        )}
        {isModalOpen && (
          <DeleteModal
            name={"table"}
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            loading={deleteLoader}
            handleDeleteActionClick={() => {
              deleteTable(databaseNameRef.current, tableNameRef.current);
            }}
          />
        )}
        {isRunQueryActive && (
          <RunQuery
            tableName={tableNameRef.current}
            isRunQueryActive={isRunQueryActive}
            closeQueryModal={closeQueryModal}
            handleExportCSV={handleExportCSV}
          />
        )}
      </Flex>
    </>
  );
};

export default DatabaseTable;
