import React from "react";
import { Grid } from "@mui/material";
import ReactPlayer from "react-player";
import DocumentPageLayout from "../DocumentPageLayout";

const MySql = () => {
  const breadcrumbs = [
    {
      name: "Ingesting your data using MySQL",
      link: "#my-sql",
    },
    {
      name: "MySQL Connector",
      link: "#my-sql-connector",
    },
  ];

  return (
    <DocumentPageLayout breadcrumbs={breadcrumbs}>
      <Grid container rowSpacing={4}>
        <Grid item>
          <h1 id="my-sql">Ingesting your data using MySQL</h1>

          <p>
            Check this step by step video - How to easily onboard data on
            DataBoat data platform:
          </p>

          <ReactPlayer
            url="https://vid.cdn-website.com/b29d841e/videos/zOFhrqkfSnC0bKZUOeP5_MySQL+Connector-v.mp4"
            controls={true}
          />

          <h2 id="my-sql-connector">MySQL Connector</h2>

          <h4>Step 1: Configure Your Source</h4>
          <ul>
            <li>
              Click on "Configure Your Source" to start defining your data
              workflow.
            </li>
          </ul>
          <h4>Step 2: Define Workflow Details</h4>
          <ul>
            <li>Enter a name for the workflow.</li>
            <li>Set the start time of the workflow based on date and time.</li>
            <li>
              Determine the execution frequency of the pipeline by selecting one
              of the predefined options.
            </li>
          </ul>
          <h4>Step 3: Set Up Tasks</h4>
          <ul>
            <li>
              Add tasks to your workflow. Note that you can include additional
              tasks based on data dependencies with different sources.
            </li>
            <li>
              For each task, define relevant parameters for the job
              orchestrator. Use key-value pairs in the UI or paste your JSON
              key-value map.
            </li>
          </ul>
          <h4>Step 4: Choose Task Type</h4>
          <ul>
            <li>Select the task type, either Ingestion or Transformation.</li>
            <li>
              If Ingestion is selected, choose an existing connector for MySQL.
            </li>
            <li>
              Provide connection details such as server, host, and port (default
              port for MySQL is 3306).
            </li>
            <li>Enter your database credentials.</li>
          </ul>
          <h4>Step 5: Select Ingestion Type</h4>
          <ul>
            <li>Table Ingestion:</li>
            <li>Select the database.</li>
            <li>Choose the tables you wish to ingest.</li>
            <li>Choose the ingestion mode: Full Dump or Delta Mode.</li>
            <li>
              To ingest additional tables, click "Add More Tables" and save your
              choices.
            </li>
            <li>Query Ingestion:</li>
            <li>
              Directly write the SQL query for the data you want to ingest.
            </li>
            <li>Optionally, name the output table.</li>
          </ul>
          <h4>Step 6: Finalize Workflow</h4>
          <ul>
            <li>
              Optionally, add tags to the workflow for easier identification.
            </li>
            <li>
              Set up notifications to alert you about the workflow's execution
              status.
            </li>
          </ul>
          <h4>Step 7: Create and Execute Job</h4>
          <ul>
            <li>
              Review all settings, then save the workflow to create the job.
            </li>
            <li>The job is now ready to be executed.</li>
          </ul>
        </Grid>
      </Grid>
    </DocumentPageLayout>
  );
};

export default MySql;
