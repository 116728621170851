import React from "react";
import { Link } from "react-router-dom";
import { Flex, H3, PortalHeader, StyledPortalContainer } from "./index";

const ErrorPage = ({ handleSignout }) => {
  const styles = {
    flex: {
      display: "flex",
      justifyContent: "center",
      alignItem: "center",
      flexDirection: "column",
      width: "50%",
      margin: "auto",
      gap: "1em",
    },
    text: {
      textAlign: "center",
    },
    buttonLink: {
      padding: "0.75em 1em",
      borderRadius: "8px",
      backgroundColor: "#222",
      color: "#fff",
      textAlign: "center",
      textDecoration: "none",
      width: "50%",
      margin: "auto",
    },
  };
  return (
    <StyledPortalContainer column>
      <PortalHeader />
      <div style={{ height: "100vh", display: "flex" }}>
        <div style={styles.flex}>
          <Flex column style={styles.text}>
            <H3>Page not found</H3>
            <p>The page you are looking for doesn't exist or has been moved</p>
          </Flex>
          <Link to={"/"} style={styles.buttonLink} onClick={handleSignout}>
            Log in
          </Link>
        </div>
      </div>
    </StyledPortalContainer>
  );
};

export default ErrorPage;
