import React, { useEffect, useState } from "react";
import { getNames } from "country-list";
import {
  Button,
  Flex,
  StyledDashboardContentFlex,
  StyledDiv,
  StyledSignupContainer,
  SignUpHeader,
} from "../../index";

import {
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Typography,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

const SignUpForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [country, setCountry] = useState("");
  const [consent, setConsent] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const countries = getNames();

  const { t } = useTranslation();

  useEffect(() => {
    setCountry("Netherlands");
  }, []);

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleConsentChange = () => {
    setConsent(!consent);
  };

  const validateFields = () => {
    let tempErrors = {};

    if (!firstName) tempErrors.firstName = "First Name is required";
    if (!lastName) tempErrors.lastName = "Last Name is required";
    if (!companyEmail) tempErrors.companyEmail = "Company Email is required";
    if (!companyName) tempErrors.companyName = "Company Name is required";
    if (!country) tempErrors.country = "Country is required";

    setErrors(tempErrors);

    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateFields()) {
      console.log("Form is valid. Making request...");
    } else {
      console.log("Form has errors. Please correct them.");
    }
  };

  return (
    <>
      <StyledDiv>
        <StyledSignupContainer>
          <SignUpHeader />
          <form onSubmit={handleSubmit}>
            <StyledDashboardContentFlex column style={{ gap: "1em" }}>
              <Flex column>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    maxWidth: 400,
                    margin: "0 auto",
                  }}
                >
                  <TextField
                    label="First Name"
                    variant="outlined"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    error={!!errors.firstName}
                    helperText={errors.firstName}
                    required
                  />
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    error={!!errors.lastName}
                    helperText={errors.lastName}
                    required
                  />
                  <TextField
                    label="Company Email"
                    variant="outlined"
                    type="email"
                    value={companyEmail}
                    onChange={(e) => setCompanyEmail(e.target.value)}
                    error={!!errors.companyEmail}
                    helperText={errors.companyEmail}
                    required
                  />
                  <TextField
                    label="Company Name"
                    variant="outlined"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    error={!!errors.companyName}
                    helperText={errors.companyName}
                    required
                  />
                  {countries.length && (
                    <FormControl variant="outlined" error={!!errors.country}>
                      <InputLabel>Country</InputLabel>
                      <Select
                        value={country}
                        onChange={handleCountryChange}
                        label="Country"
                        required
                      >
                        {countries.map((name, index) => (
                          <MenuItem key={index} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.country && (
                        <Typography variant="caption" color="error">
                          {errors.country}
                        </Typography>
                      )}
                    </FormControl>
                  )}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={consent}
                        onChange={handleConsentChange}
                      />
                    }
                    label={t("signup.deny_services")}
                  />
                  <Typography variant="caption" sx={{ marginBottom: 2 }}>
                    {t("signup.check_advice")}{" "}
                    <Link
                      href="https://www.dataopshouse.com/PrivacyPolicy"
                      about="_blank"
                      underline="always"
                    >
                      {t("signup.privacy_notice")}
                    </Link>
                  </Typography>
                </Box>
              </Flex>

              <Flex column style={{ gap: "2em" }}>
                <Button
                  type="submit"
                  text={t("signup.button_label")}
                  className={"filled-background"}
                />
                <div style={{ textAlign: "center" }}>
                  <span>{t("signup.or")} </span>
                  <span
                    type="button"
                    onClick={() => {
                      navigate("/");
                    }}
                    className="forget_password"
                  >
                    {t("signup.signin")}
                  </span>
                </div>
              </Flex>
            </StyledDashboardContentFlex>
          </form>
        </StyledSignupContainer>
      </StyledDiv>
    </>
  );
};

export default SignUpForm;
