import React from 'react';
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

/**
 * @typedef {Object} SearchBarProps
 * @property {string} value - Current value of the search input.
 * @property {(event: React.ChangeEvent<HTMLInputElement>) => void} onChange - Function to handle input changes.
 * @property {string} label - Label for the search input.
 */

/**
 * Component for the search bar with a search icon.
 * @param {SearchBarProps} props
 * @returns {JSX.Element}
 */
const SearchBar = ({ value, onChange, label }) => {
  return (
    <TextField
      fullWidth
      label={label}
      value={value}
      onChange={onChange}
      variant="outlined"
      sx={{ mb: 3 }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchBar;
