import React from "react";
import { StyledModal, StyledDashboardContentFlex, Theme } from "../../index";
import { Dialog } from "@mui/material";
import ReactLoading from "react-loading";
import { StyledLoadingContainer } from "../../styles/Login.Styled";
import { TiDelete, TiPlus } from "react-icons/ti";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  WhereClauses,
  WhereClausesContainer,
} from "../Transformation/Styles/DraggableTableStyle";
const WhereClauseModal = ({
  isModalOpen,
  closeModal,
  loading,
  where,
  setWhere,
  attributes,
  anchorEl,
  setAnchorEl,
}) => {
  if (!isModalOpen) return null;
  const flexContainer = {
    display: "flex",
    justifyContent: "flex-end",
    gap: "1em",
    padding: "1.5em",
  };
  const title = {
    color: Theme.colors.sysSurface2Color,
    fonts: Theme.fonts.fontMediumBold,
  };
  const paragraph = {
    color: Theme.colors.blueColor,
    cursor: "pointer",
  };
  const SqlOperations = {
    STRING: ["=", "!=", "IS", "IS NOT", "LIKE"],
    BOOL: ["=", "!=", "IS", "IS NOT"],
    INT64: ["=", "!=", "IS", "IS NOT", ">", ">=", "<", "<="],
    TIMESTAMP: ["=", "!=", "IS", "IS NOT", ">", ">=", "<", "<="],
    DOUBLE: ["=", "!=", "IS", "IS NOT", ">", ">=", "<", "<="],
    DATE: ["=", "!=", "IS", "IS NOT", ">", ">=", "<", "<="],
    INTEGER: ["=", "!=", "IS", "IS NOT", ">", ">=", "<", "<="],
  };
  return (
    <StyledModal>
      {!loading ? (
        <Dialog
          open={isModalOpen}
          onClose={closeModal}
          PaperProps={{
            sx: {
              padding: "1.5em",
              borderRadius: Theme.radius.primaryBorderRadius,
              position: "relative",
            },
          }}
        >
          <StyledDashboardContentFlex column>
            <>
              <p style={title}>Where Clause</p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FormControlLabel
                  key={"where"}
                  control={<Checkbox defaultChecked />}
                  label="Where"
                  onClick={() => setWhere({ ...where, enabled: false })}
                />
                <WhereClausesContainer>
                  {where.clauses.map((clause) => {
                    return (
                      <WhereClauses>
                        {clause.concatType && <p>{clause.concatType}</p>}
                        <FormControl
                          sx={{
                            minWidth: 104,
                          }}
                        >
                          <InputLabel>Column</InputLabel>
                          <Select
                            autoWidth
                            value={clause.column}
                            onChange={(e) => {
                              const clauseIndex = where.clauses.findIndex(
                                (option) => option.key === clause.key,
                              );
                              const updatingClause = where.clauses[clauseIndex];
                              const updatedClauses = where.clauses;
                              updatedClauses[clauseIndex] = {
                                key: crypto.randomUUID(),
                                column: e.target.value,
                                operation: null,
                                value: null,
                                ...(updatingClause.concatType && {
                                  concatType: updatingClause.concatType,
                                }),
                              };
                              setWhere((prev) => ({
                                ...prev,
                                clauses: [...updatedClauses],
                              }));
                            }}
                          >
                            {attributes.map((attribute, index) => (
                              <MenuItem key={index} value={attribute}>
                                {attribute.column_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {clause.column && (
                          <FormControl
                            sx={{
                              minWidth: 108,
                            }}
                          >
                            <InputLabel>Operator</InputLabel>
                            <Select
                              autoWidth
                              label="Operator"
                              value={clause.operation}
                              onChange={(e) => {
                                const clauseIndex = where.clauses.findIndex(
                                  (option) => option.key === clause.key,
                                );
                                const updatingClause =
                                  where.clauses[clauseIndex];
                                const updatedClauses = where.clauses;
                                updatedClauses[clauseIndex] = {
                                  key: updatingClause.key,
                                  column: updatingClause.column,
                                  operation: e.target.value,
                                  ...(updatingClause.concatType && {
                                    concatType: updatingClause.concatType,
                                  }),
                                  value: null,
                                };
                                setWhere((prev) => ({
                                  ...prev,
                                  clauses: [...updatedClauses],
                                }));
                              }}
                            >
                              {clause.column?.column_type &&
                                (
                                  SqlOperations[clause.column.column_type] || []
                                ).map((option) => (
                                  <MenuItem key={option} value={option}>
                                    {option}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        )}
                        {clause.operation && (
                          <FormControl>
                            <TextField
                              value={clause.value}
                              onChange={(e) => {
                                const filteredClauses = where.clauses.filter(
                                  (option) => option.key !== clause.key,
                                );
                                setWhere({
                                  ...where,
                                  clauses: [
                                    ...filteredClauses,
                                    {
                                      ...clause,
                                      value: e.target.value,
                                    },
                                  ],
                                });
                              }}
                            ></TextField>
                          </FormControl>
                        )}
                        <IconButton
                          onClick={() => {
                            setWhere((prev) => ({
                              ...prev,
                              clauses: [
                                ...prev.clauses.filter(
                                  (el) => el.key !== clause.key,
                                ),
                              ],
                            }));
                          }}
                          variant="outlined"
                          color="error"
                        >
                          <TiDelete />
                        </IconButton>
                      </WhereClauses>
                    );
                  })}
                </WhereClausesContainer>
                <div>
                  <IconButton
                    onClick={(e) => {
                      setAnchorEl(e.currentTarget);
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    <TiPlus />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        setWhere((prev) => ({
                          ...prev,
                          clauses: [
                            ...prev.clauses,
                            {
                              key: crypto.randomUUID(),
                              column: null,
                              operation: null,
                              value: null,
                              concatType: "AND",
                            },
                          ],
                        }));
                        setAnchorEl(null);
                      }}
                    >
                      AND
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setWhere((prev) => ({
                          ...prev,
                          clauses: [
                            ...prev.clauses,
                            {
                              key: crypto.randomUUID(),
                              column: null,
                              operation: null,
                              value: null,
                              concatType: "OR",
                            },
                          ],
                        }));
                        setAnchorEl(null);
                      }}
                    >
                      OR
                    </MenuItem>
                  </Menu>
                </div>
              </div>
              <div style={flexContainer}>
                <p style={paragraph} onClick={closeModal}>
                  Cancel
                </p>
                <p style={paragraph} onClick={closeModal}>
                  Save
                </p>
              </div>
            </>
          </StyledDashboardContentFlex>
        </Dialog>
      ) : (
        <StyledLoadingContainer>
          <ReactLoading
            type={"spin"}
            color={Theme.colors.blueColor}
            height={35}
            width={35}
          />
        </StyledLoadingContainer>
      )}
    </StyledModal>
  );
};
export default WhereClauseModal;
