import React from "react";
import { ConnectorScreens } from "../../../index";
import { useTranslation } from "react-i18next";

const APIConnector = ({
  inputData,
  ApiData,
  handleAPIChange,
  apiHttp,
  httpData,
  httpvalue,
  handleHttpChange,
  renderKeyValue,
  apiparamKey,
  apihandleKeyChange,
  apiparamValue,
  apihandleValueChange,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <ConnectorScreens
      HeaderText={t("jobs.form.inputs.task.modal.connectors.api.title")}
      inputData={inputData}
      data={ApiData}
      error={"error"}
      handleChange={handleAPIChange}
      http={t("jobs.form.inputs.task.modal.connectors.api.inputs.http.label")}
      apiPartitionText={"API Partition Columns"}
      apiHttp={apiHttp}
      handleHttpChange={handleHttpChange}
      httpData={httpData}
      httpvalue={httpvalue}
      renderKeyValue={renderKeyValue}
      paramKey={apiparamKey}
      keyPlaceholder={t(
        "jobs.form.inputs.task.modal.connectors.api.inputs.placeholder.key",
      )}
      apiHeaderText={"Headers"}
      handleKeyChange={apihandleKeyChange}
      paramValue={apiparamValue}
      valuePlaceholder={t(
        "jobs.form.inputs.task.modal.connectors.api.inputs.placeholder.value",
      )}
      handleValueChange={apihandleValueChange}
      buttonText={t("jobs.form.inputs.task.modal.button")}
      btnOnClick={onClose}
    />
  );
};

export default APIConnector;
