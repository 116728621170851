import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import ReactPlayer from "react-player";
import DocumentPageLayout from "../DocumentPageLayout";

const ManualUpload = () => {
  const { t } = useTranslation();
  const contentArr = [
    {
      name: t("general.manual_upload"),
      link: "#manual-upload",
    },
  ];

  return (
    <DocumentPageLayout breadcrumbs={contentArr}>
      <Grid container>
        <Grid item>
          <h1 id="manual-upload">{t("general.manual_upload")}</h1>

          <ReactPlayer url="https://www.youtube.com/watch?v=SdtP5ILqAD4" />

          <p></p>
        </Grid>
      </Grid>
    </DocumentPageLayout>
  );
};

export default ManualUpload;
