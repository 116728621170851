import styled from "styled-components";

export const StyledBox = styled.div`
  border: 1px solid ${(props) => (props.clickable ? "black" : "white")};
  box-shadow: ${({ theme }) => theme.boxShadow.primary_boxShadow};
  border-radius: 10px;
  height: ${(props) => (props.fullHeight ? "fit-content" : "250px")};
  width: ${(props) => (props.fullWidth ? "100%" : "fit-content")};
  padding: 1.5em 1.75em;
  text-align: start;
  &:hover {
    cursor: ${(props) => (props.pointer ? "pointer" : "default")};
  }
  p {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.secondaryElementColor};
    font-weight: ${({ theme }) => theme.fonts.regularFont};
  }
`;
export const StyledWidth = styled.div`
  width: 80%;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.secondaryColor};
  margin: auto;
  padding: 2em;
`;
