import React from "react";
import ReactPaginate from "react-paginate";
import { StyledDashboardContentFlex } from "../styles/Login.Styled";
import { StyledJobPaginationResult } from "../styles/Yedaflow.Styled";
import { useTranslation } from "react-i18next";

const UsePaginations = ({
  pageCount,
  endOffset,
  databaseLength,
  handlePageChange,
}) => {
  const { t } = useTranslation();
  return (
    <StyledDashboardContentFlex justifyContent>
      <div style={{ marginBottom: "2em" }}>
        <StyledJobPaginationResult>
          {t("workflow.table.pagination.showing")} {pageCount}{" "}
          {t("workflow.table.pagination.to")} {endOffset}{" "}
          {t("workflow.table.pagination.of")} {databaseLength}{" "}
          {t("workflow.table.pagination.records")}{" "}
        </StyledJobPaginationResult>
      </div>
      <ReactPaginate
        previousLabel={t("workflow.table.pagination.previous")}
        nextLabel={t("workflow.table.pagination.next")}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={30}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        activeClassName={"active"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        nextClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextLinkClassName={"page-link"}
        renderOnZeroPageCount={null}
      />
    </StyledDashboardContentFlex>
  );
};

export default UsePaginations;
