import React from "react";
import styled from "styled-components";
import Theme from "../../Theme";

export const StyledButton = styled.button`
  display: flex;
  width: 48px;
  height: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: ${Theme.colors.sysSurfaceActive};
  color: ${Theme.colors.sysSecondaryContainer};
  padding: 8px;
  border-radius: 100px;
  border: none;
  &:hover {
    background-color: ${Theme.colors.stateLayerOnSecondaryContainerOpacity008};
  }
  &:disabled {
    background-color: ${Theme.colors.stateLayersOnSurfaceOpacity012};
    color: ${Theme.colors.sysSurfaceTextColor};
  }
  cursor: pointer;
`;

const IconTonalButton = ({ handleOnClick, disabled = false, icon }) => {
  return (
    <StyledButton onClick={handleOnClick} disabled={disabled}>
      {icon}
    </StyledButton>
  );
};

export default IconTonalButton;
