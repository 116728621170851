import React, { useEffect } from "react";
import useUpload from "./useUpload";
import {
  StyledDashboardContentFlex,
  HomeSvg,
  TransformationSvg,
  Tabs,
  StyledPortalContent,
  UploadContent,
  UploadSvg,
} from "../../index";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomers } from "../../redux/actions/customerAction";
import ProgressBar from "../Upload/ProgressBar";
import { toast, ToastContainer } from "react-toastify";
import {
  clearManualUploadToastError,
  clearManualUploadToastSuccess,
} from "../../redux/actions/usersActions";
import { useTranslation } from "react-i18next";
import useGetToken from "../../cognito/useGetToken";

const UploadOverview = () => {
  const dispatch = useDispatch();
  const { token } = useGetToken();
  const navigate = useNavigate();
  const {
    postManualUploadError,
    postManualUploadResponse,
    environmentVariables,
  } = useSelector((state) => state.users);
  const groupName = localStorage.getItem("group");
  const { t } = useTranslation();
  const {
    files,
    errMsg,
    csvData,
    handleRemove,
    fileRejections,
    getRootProps,
    isDragActive,
    getInputProps,
    errRef,
    handlePostCSVData,
    GCPErrorMessage,
    GCPSuccessMessage,
  } = useUpload();
  useEffect(() => {
    if (token) {
      dispatch(
        fetchCustomers(
          token,
          groupName,
          environmentVariables.REACT_APP_BASE_URL_API_CUSTOMER
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, token]);
  const fill = "#006399";
  const size = "18";
  const tabData = [
    {
      to: "/dataManagement",
      icon: <HomeSvg fill={fill} width={size} height={size} />,
      label: t("data_management.upload.header.home"),
    },
    {
      to: "/transformation",
      icon: <TransformationSvg fill={fill} width={size} height={size} />,
      label: t("data_management.upload.header.transformation"),
    },
    {
      to: "/upload",
      icon: <UploadSvg fill={fill} width={size} height={size} />,
      label: t("data_management.upload.header.upload"),
    },
  ];

  useEffect(() => {
    if (postManualUploadResponse.message || GCPSuccessMessage !== "") {
      toast.success(<ProgressBar />, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 8200,
        pauseOnFocusLoss: false,
        onClose: () => {
          dispatch(clearManualUploadToastSuccess());
          // navigate("/transformation");
        },
      });
    }
    if (postManualUploadError || GCPErrorMessage !== "") {
      toast.warning("Upload failed, try again.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnFocusLoss: false,
        onClose: () => {
          dispatch(clearManualUploadToastError());
        },
      });
    }
  }, [
    dispatch,
    postManualUploadError,
    postManualUploadResponse.message,
    GCPErrorMessage,
    GCPSuccessMessage,
    navigate,
  ]);
  return (
    <StyledPortalContent>
      <div
        style={{
          padding: "1em",
          height: "90vh",
          overflow: "hidden",
        }}
      >
        <ToastContainer />
        <div
          style={{
            height: "100%",
            overflowY: "scroll",
            paddingRight: "1em",
            boxSizing: "content-box",
            marginRight: "-2em",
          }}
        >
          <StyledDashboardContentFlex column>
            <Tabs tabs={tabData} />
            <UploadContent
              files={files}
              errMsg={errMsg}
              csvData={csvData}
              handleRemove={handleRemove}
              fileRejections={fileRejections}
              getRootProps={getRootProps}
              isDragActive={isDragActive}
              getInputProps={getInputProps}
              errRef={errRef}
              handlePostCSVData={handlePostCSVData}
              GCPSuccessMessage={GCPSuccessMessage}
            />
          </StyledDashboardContentFlex>
        </div>
      </div>
    </StyledPortalContent>
  );
};

export default UploadOverview;
