import styled from "styled-components";
import Theme from "../../../Theme";

export const SuccessContainer = styled.div`
  background-color: ${Theme.colors.successLightColor};
  display: flex;
  width: 100%;
  gap: 1em;
  padding: 1em;

  .error_icon {
    color: ${Theme.colors.successColor};
    font: ${Theme.fonts.smallFont};
  }
`;
