import styled from "styled-components";

export const StyledModal = styled.div`
  background-color: ${({ theme }) => theme.colors.modalOpacityOverlay};
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Centered = styled.div`
  position: absolute;
  width: ${(props) => (props.width ? props.width : "45%")};
  height: ${(props) => (props.height ? props.height : "45%")};
  top: 20%;
  background-color: ${({ theme }) => theme.colors.sysSurfaceContainerLow};
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: ${({ theme }) => theme.radius.primaryBorderRadius};
`;
export const ModalHeader = styled.div`
  background: ${({ theme }) => theme.colors.modalHeaderBgColor};
  display: flex;
  justify-content: space-between;
  padding: 1em 1.5em;
  border-radius: 28px 28px 0 0;
  p {
    color: ${({ theme }) => theme.colors.secondaryColor};
    font: ${({ theme }) => theme.fonts.fontMediumBold};
  }
`;
export const StyledBackground = styled.div`
  background-color: ${({ theme }) => theme.colors.tertiaryColor};
  padding: 0.5em;
  border-radius: 10px;
  max-width: 50%;
`;
export const StyledCenteredModal = styled.div`
  width: 70%;
  height: fit-content;
  position: absolute;
  top: 50%;
  /* left: 55%; */
  margin: -25px 0 0 -25px;
  padding: 1.5em;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.colors.secondaryColor};
  border-radius: 10px;
  .full_width {
    width: 100%;
    display: flex;
    gap: 1em;
    background-color: transparent;
    padding: 0.5em 1em;
    border: 1px solid black;
  }
  .input_schema {
    width: 100%;
    border-radius: 5px;
    border: none;
    &:focus {
      outline: none;
    }
    &::placeholder {
      font-style: italic;
    }
  }
  .btn_schema {
    padding: 0.75em 1em;
    border-radius: 10px;
    border: none;
    outline: none;
    background-color: ${({ theme }) => theme.colors.errorColor};
    color: ${({ theme }) => theme.colors.secondaryColor};
    &:hover {
      cursor: pointer;
      opacity: 0.64;
    }
  }
`;
export const ModalOverLay = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: transparent;
  opacity: 0.96;
  font-family: "Montserrat", sans-serif;
`;
export const ModalContainer = styled.div`
  text-align: center;
  background-color: ${({ theme }) => theme.colors.primaryColor};
  color: ${({ theme }) => theme.colors.secondaryColor};
  opacity: 1;
  position: absolute;
  min-height: 250px;
  max-width: 800px;
  margin-top: 15%;
  padding: 1.5em;
  border-radius: 10px;
  h4 {
    font:
      bold 22px "Montserrat",
      sans-serif;
  }
  .cta_btn {
    background-color: ${({ theme }) => theme.colors.errorColor};
  }
  .no_btn {
    background-color: ${({ theme }) => theme.colors.secondaryColor};
    color: ${({ theme }) => theme.colors.primaryColor};
  }
`;
