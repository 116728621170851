import styled from "styled-components";

export const StyledSignupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.25em;
  padding: 2.5em;
  margin-inline: auto;
  height: 100%;
  width: 64%;
  @media (max-width: ${({ theme }) => theme.responsive.ipad}) {
    width: 70%;
  }
  @media (max-width: ${({ theme }) => theme.responsive.desktop}) {
    width: 80%;
  }
`;
