import React, { useState, useEffect } from "react";
import DataQualityPresenter from "./DataQualityPresenter";
import { useDispatch, useSelector } from "react-redux";
import {
  getDataBases,
  getDataBaseTables,
  getTableDetails,
  getTableFilters,
  getTableInfos,
} from "../../redux/actions/glueMetadataActions";
import { useTheme } from "@mui/material/styles";

const DataQualityContainer = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { environmentVariables, envLoader } = useSelector(
    (state) => state.users,
  );
  const baseURL = environmentVariables.REACT_APP_ATHENA_DATA_TRANSFORMATION;
  const appEnvironment = environmentVariables.REACT_APP_ENVIRONMENT;

  const {
    isDataBasesLoading,
    dataBases,
    isTablesLoading,
    tables,
    isTableDetailsLoading,
    tableDetails,
    tableDetailsError,
    isTableFiltersLoading,
    tableFilters,
    tableFiltersError,
    isTableInfosLoading,
    tableInfos,
    tableInfosError,
  } = useSelector((state) => state.glueMetadata);

  const tableColumnsHeadCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
  ];

  const [selectedDBOption, setSelectedDBOption] = useState(null);
  const [selectedTableOption, setSelectedTableOption] = useState(null);
  const [searchTableValue, setSearchTableValue] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [tableColumnsRows, setTableColumnsRows] = useState([]);
  const [tableDetailsHeadCells, setTableDetailsHeadCells] = useState([]);
  const [tableDetailsRows, setDetailedTableRows] = useState([]);
  const [dataTypeFilters, setDataTypeFilters] = useState({});
  const [selectedDataTypeFilters, setSelectedDataTypeFilters] = useState([]);
  const [paginationTableDetails, setPaginationTableDetails] = useState({
    currentPage: 0,
    pageSize: 10,
    totalItems: 0,
    totalPages: 0,
  });

  useEffect(() => {
    if (envLoader && environmentVariables) {
      dispatch(getDataBases(baseURL, appEnvironment));
    }
  }, [environmentVariables, envLoader, baseURL, appEnvironment, dispatch]);

  useEffect(() => {}, [selectedColumns]);

  useEffect(() => {
    setDataTypeFilters({});
    setSelectedDataTypeFilters([]);
  }, [selectedTableOption]);

  useEffect(() => {
    if (selectedDBOption) {
      setSelectedTableOption(null);
      setSearchTableValue(null);
      setSelectedColumns([]);
    }
  }, [selectedDBOption]);

  useEffect(() => {
    if (selectedDBOption && !selectedTableOption) {
      dispatch(getDataBaseTables(selectedDBOption, baseURL, appEnvironment));
    }

    if (selectedTableOption && selectedDBOption) {
      dispatch(
        getTableDetails(
          selectedDBOption,
          selectedTableOption,
          baseURL,
          appEnvironment,
        ),
      );
      dispatch(
        getTableFilters(
          selectedDBOption,
          selectedTableOption,
          baseURL,
          appEnvironment,
        ),
      );
      dispatch(
        getTableInfos(
          selectedDBOption,
          selectedTableOption,
          baseURL,
          appEnvironment,
        ),
      );
    }
  }, [
    selectedTableOption,
    selectedDBOption,
    baseURL,
    appEnvironment,
    dispatch,
  ]);

  useEffect(() => {
    const handleDataTypeFilterSelection = (filter) => {
      setSelectedDataTypeFilters((prevFilters) => {
        const updatedFilters = prevFilters.includes(filter)
          ? prevFilters.filter((selected) => selected !== filter)
          : [...prevFilters, filter];
        return updatedFilters;
      });
    };

    if (tableFilters) {
      setDataTypeFilters(
        Array.from(tableFilters).map((item) => ({
          label: item,
          selected: selectedDataTypeFilters.includes(item),
          onClick: () => handleDataTypeFilterSelection(item),
        })),
      );
    }

    dispatch(
      getTableDetails(
        selectedDBOption,
        selectedTableOption,
        baseURL,
        appEnvironment,
        null,
        null,
        null,
        null,
        selectedDataTypeFilters.length > 0 ? selectedDataTypeFilters : null,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilters, selectedDataTypeFilters]);

  useEffect(() => {
    if (tableDetails && tableDetails.data && tableDetails.data.length > 0) {
      const handleBackgroundColor = (type) => {
        switch (type) {
          case "very bad":
            return "#FFDAD6";
          case "bad":
            return "#FFEFD2";
          case "ok":
            return "#ECF1F9";
          case "good":
            return "#E8F2FF";
          case "very good":
            return "#D6F6FF";
          default:
            return "none";
        }
      };

      const formatCamelCase = (inputString) => {
        const regex = /([A-Z]?[a-z]+)/g;
        const matches = inputString.match(regex);
        if (matches) {
          const result = matches
            .map((match) => match.charAt(0).toUpperCase() + match.slice(1))
            .join(" ");
          return result;
        } else {
          return inputString;
        }
      };

      const mapColumnNames = (item) => ({ name: item["columnName"] });
      const mapHeadCells = (key) => ({
        id: key,
        numeric: false,
        disablePadding: false,
        label: formatCamelCase(key),
      });

      const modifiedData = tableDetails.data.map((item) => {
        const modifiedMaxNullCount = { ...item.maxNullCount };
        if (modifiedMaxNullCount.type) {
          modifiedMaxNullCount.type = handleBackgroundColor(
            modifiedMaxNullCount.type,
          );
        }
        return {
          ...item,
          maxNullCount: modifiedMaxNullCount,
        };
      });

      setTableColumnsRows(tableDetails.data.map(mapColumnNames));
      setTableDetailsHeadCells(
        Object.keys(tableDetails.data[0]).map(mapHeadCells),
      );
      setDetailedTableRows(modifiedData);
      setPaginationTableDetails({
        currentPage: tableDetails.currentPage,
        pageSize: tableDetails.pageSize,
        totalItems: tableDetails.totalItems,
        totalPages: tableDetails.totalPages,
      });
    }
  }, [tableDetails, theme]);

  const handleDBOptionSelect = (event) => {
    setSelectedDBOption(event.target.value);
  };

  const handleTableOptionSelect = (event) => {
    setSelectedTableOption(event.target.value);
  };

  const handleSearchTableOnChange = (event) => {
    setSearchTableValue(event.target.value);
  };

  const handleSearchTable = () => {
    dispatch(
      getTableDetails(
        selectedDBOption,
        searchTableValue,
        baseURL,
        appEnvironment,
      ),
    );
  };

  const handleSelectColumns = (newSelected) => {
    setSelectedColumns(newSelected);
  };

  const handleChangeTableDetailsPageOrPageSize = (page, pageSize) => {
    dispatch(
      getTableDetails(
        selectedDBOption,
        selectedTableOption,
        baseURL,
        appEnvironment,
        page,
        pageSize,
        null,
        null,
        selectedDataTypeFilters.length > 0 ? selectedDataTypeFilters : null,
      ),
    );
  };

  const handleChangeTableDetailsOrder = (orderBy, orderDirection) => {
    dispatch(
      getTableDetails(
        selectedDBOption,
        selectedTableOption,
        baseURL,
        appEnvironment,
        null,
        null,
        orderBy,
        orderDirection,
        selectedDataTypeFilters.length > 0 ? selectedDataTypeFilters : null,
      ),
    );
  };

  const handleCreateNewRule = () => {
    // to do
    ("new rule");
  };
  return (
    <DataQualityPresenter
      handleDBOptionSelect={handleDBOptionSelect}
      dataBases={dataBases}
      isDataBasesLoading={isDataBasesLoading}
      selectedDBOption={selectedDBOption}
      handleTableOptionSelect={handleTableOptionSelect}
      tables={tables}
      isTablesLoading={isTablesLoading}
      selectedTableOption={selectedTableOption}
      searchTableValue={searchTableValue}
      handleSearchTableOnChange={handleSearchTableOnChange}
      handleSearchTable={handleSearchTable}
      tableColumnsHeadCells={tableColumnsHeadCells}
      tableColumnsRows={tableColumnsRows}
      selectedColumns={selectedColumns}
      handleSelectColumns={handleSelectColumns}
      dataTypeFilterButtons={dataTypeFilters}
      tableDetailsRows={tableDetailsRows}
      handleCreateNewRule={handleCreateNewRule}
      envLoader={envLoader}
      tableDetailsHeadCells={tableDetailsHeadCells}
      isTableDetailsLoading={isTableDetailsLoading}
      tableDetailsError={tableDetailsError}
      paginationTableDetails={paginationTableDetails}
      handleChangeTableDetailsPageOrPageSize={
        handleChangeTableDetailsPageOrPageSize
      }
      isTableFiltersLoading={isTableFiltersLoading}
      tableFiltersError={tableFiltersError}
      handleChangeTableDetailsOrder={handleChangeTableDetailsOrder}
      isTableInfosLoading={isTableInfosLoading}
      tableInfos={tableInfos}
      tableInfosError={tableInfosError}
    />
  );
};

export default DataQualityContainer;
