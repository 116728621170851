import { useState } from "react";
import { useTranslation } from "react-i18next";
const useS3 = (taskData, setTaskData) => {
  const group = localStorage.getItem("group");
  //s3 starts
  const [inputValue, setInputValue] = useState([
    {
      sourceBucket: "",
      header: false,
      fileFormat: "",
      seperator: "",
      charset: "",
      aws_access_key_id: "",
      aws_secret_access_key: "",
      aws_session_token: "",
      id: 1,
    },
  ]);

  const { t } = useTranslation();

  const S3Data = [
    {
      id: 1,
      name: "fileFormat",
      title: t(
        "jobs.form.inputs.task.modal.connectors.bucket.gcs.hook.api_data.one",
      ),
      checkbox: false,
      defaultValue: "",
      required: true,
    },
    {
      id: 2,
      name: "header",
      title: t(
        "jobs.form.inputs.task.modal.connectors.bucket.gcs.hook.api_data.two",
      ),
      checkbox: true,
      defaultValue: "",
      required: true,
    },
    {
      id: 3,
      name: "seperator",
      title: t(
        "jobs.form.inputs.task.modal.connectors.bucket.gcs.hook.api_data.three",
      ),
      checkbox: false,
      defaultValue: "",
      required: true,
    },
    {
      id: 4,
      name: "charset",
      title: "Charset",
      checkbox: false,
      defaultValue: "",
      required: false,
    },
    {
      id: 5,
      name: "aws_access_key_id",
      title: "Aws_access_key_id",
      checkbox: false,
      defaultValue: "",
      required: true,
    },
    {
      id: 6,
      name: "aws_secret_access_key",
      title: "Aws_secret_access_key",
      checkbox: false,
      defaultValue: "",
      required: true,
    },
    {
      id: 7,
      name: "aws_session_token",
      title: "Aws_session_token",
      checkbox: false,
      defaultValue: "",
      required: true,
    },
  ];
  const handleChange = (event, id) => {
    const { name, value, checked } = event.target;

    inputValue.map((data) => {
      if (data.id === id) {
        switch (name) {
          case "header":
            data.header = checked;
            break;
          case "fileFormat":
            data.fileFormat = value;
            break;
          case "seperator":
            data.seperator = value;
            break;
          case "charset":
            data.charset = value;
            break;
          case "aws_access_key_id":
            data.aws_access_key_id = value;
            break;
          case "aws_secret_access_key":
            data.aws_secret_access_key = value;
            break;
          case "aws_session_token":
            data.aws_session_token = value;
            break;
          default:
            console.log("error");
        }
      }
      return data;
    });

    const currentInput = inputValue.find((input) => input.id === id);
    taskData.map((data) => {
      if (data.id === id) {
        data.params.file_format = currentInput.fileFormat;
        data.params.header = currentInput.header.toString();
        data.params.separator = currentInput.seperator;
        data.params.charset = currentInput.charset;
        data.params.destination_bucket = `databoat-raw-${group}/${s3fileValue}`;
        data.source_type = "csv";
        data.params.credentials_json = {
          aws_access_key_id: currentInput.aws_access_key_id,
          aws_secret_access_key: currentInput.aws_secret_access_key,
          aws_session_token: currentInput.aws_session_token,
        };
      }
      return data;
    });
  };

  const [s3fileName, setS3FileName] = useState("");
  const [s3fileValue, setS3FileValue] = useState("");
  const [s3fileNameError, setS3FileNameError] = useState("");

  const handleS3SourceBucketChange = (e, id) => {
    const s3Value = e.target.value;
    const updatedData = taskData.map((data) => {
      if (data.id === id) {
        data.params.source_bucket = `s3://${s3Value}`;
      }
      return data;
    });
    setS3FileName(s3Value);
    setS3FileNameError("");
    setTaskData(updatedData);
  };
  function isValidGCSFormat(input) {
    const regex = /^[a-zA-Z0-9-]+\/[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+$/;
    return regex.test(input);
  }
  const handleS3SourceBucketBlur = (e, id) => {
    if (s3fileName === "") {
      setS3FileNameError("Source Bucket cannot be empty");
    } else {
      const isValid = isValidGCSFormat(s3fileName);
      if (isValid) {
        const getfileValue = s3fileName.split("/");
        const extractedName = getfileValue[1].replace(/\.[^.]+$/, "");
        setS3FileValue(extractedName);
      } else {
        setS3FileNameError("Required Format: bucket-name/file");
      }
    }
  };
  const [s3PartitionColumnValue, setS3PartitionColumnValue] = useState("");
  const handleS3TaskPartitionColumn = (event, taskId) => {
    const selected = event.target.value.split(",");
    const updatedData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.partition_columns = selected;
      }
      return data;
    });
    setS3PartitionColumnValue(selected);
    setTaskData(updatedData);
  };

  //ends here
  return [
    s3fileName,
    setInputValue,
    S3Data,
    handleChange,
    handleS3SourceBucketChange,
    handleS3SourceBucketBlur,
    s3fileNameError,
    handleS3TaskPartitionColumn,
    s3fileValue,
    s3PartitionColumnValue,
  ];
};

export default useS3;
