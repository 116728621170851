import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BsChevronDown, BsChevronRight } from "react-icons/bs";
import {
  Flex,
  StyledSideNavigation,
  StyledNavigationListItems,
} from "../styles/Document.Styled";

const DocumentNavigation = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const splitLocation = pathname.split("/");

  const [activeDropdown, setActiveDropdown] = useState([]);

  // replace with value from store
  const checkIfActiveDropdown = (menuItemId) => {
    const newArr = [...activeDropdown];
    return (newArr || []).includes(menuItemId);
  };

  const getClassNameIfActive = (itemClass) =>
    splitLocation[splitLocation.length - 1] === itemClass ? "nav-active" : "";

  const menuList = [
    {
      id: 1,
      title: t("general.home"),
      path: "/docs",
      class: "docs",
    },
    {
      id: 2,
      title: t("general.about_databoat"),
      path: "/docs/about-databoat",
      class: "about-databoat",
    },
    {
      id: 3,
      title: t("general.overview"),
      path: "/docs/overview",
      class: "overview",
    },
    {
      id: 4,
      title: t("general.data_onboarding"),
      path: "/docs/data-onboarding",
      class: "data-onboarding",
      options: [
        {
          id: 41,
          subMenu: t("general.connectors"),
          optionLink: "/docs/data-onboarding/connectors",
          optionClass: "connectors",
        },
        {
          id: 42,
          subMenu: t("general.manual_upload"),
          optionLink: "/docs/data-onboarding/manual-upload",
          optionClass: "manual-upload",
        },
        {
          id: 43,
          subMenu: t("general.sql_server"),
          optionLink: "/docs/data-onboarding/sql-server",
          optionClass: "sql-server",
        },
        {
          id: 44,
          subMenu: t("general.oracle"),
          optionLink: "/docs/data-onboarding/oracle",
          optionClass: "oracle",
        },
        {
          id: 45,
          subMenu: t("general.big_query"),
          optionLink: "/docs/data-onboarding/big-query",
          optionClass: "big-query",
        },
        {
          id: 46,
          subMenu: t("general.api"),
          optionLink: "/docs/data-onboarding/api",
          optionClass: "api",
        },
        {
          id: 47,
          subMenu: t("general.gcs"),
          optionLink: "/docs/data-onboarding/gcs",
          optionClass: "gcs",
        },
        {
          id: 48,
          subMenu: t("general.postgres_sql"),
          optionLink: "/docs/data-onboarding/postgres-sql",
          optionClass: "postgres-sql",
        },
        {
          id: 49,
          subMenu: t("general.my_sql"),
          optionLink: "/docs/data-onboarding/my-sql",
          optionClass: "my-sql",
        },
      ],
    },
    {
      id: 5,
      title: t("general.workflow_management"),
      path: "/docs/workflow-management",
      class: "workflow-management",
      options: [
        {
          id: 51,
          subMenu: t("general.creating_a_job"),
          optionLink: "/docs/workflow-management#creating-a-job",
          optionClass: "creating-a-job",
        },
        {
          id: 52,
          subMenu: t("general.viewing_jobs"),
          optionLink: "/docs/workflow-management#viewing-jobs",
          optionClass: "viewing-jobs",
        },
        {
          id: 53,
          subMenu: t("general.searching_for_jobs"),
          optionLink: "/docs/workflow-management#searching-for-jobs",
          optionClass: "searching-for-jobs",
        },
        {
          id: 54,
          subMenu: t("general.editing_a_job"),
          optionLink: "/docs/workflow-management#editing-a-job",
          optionClass: "editing-a-job",
        },
        {
          id: 55,
          subMenu: t("general.running_a_job_manually"),
          optionLink: "/docs/workflow-management#running-a-job-manually",
          optionClass: "running-a-job-manually",
        },
        {
          id: 56,
          subMenu: t("general.deleting_a_job"),
          optionLink: "/docs/workflow-management#deleting-a-job",
          optionClass: "deleting-a-job",
        },
        {
          id: 57,
          subMenu: t("general.tracking_and_analyzing_job_executions"),
          optionLink:
            "/docs/workflow-management#tracking-and-analyzing-job-executions",
          optionClass: "tracking-and-analyzing-job-executions",
        },
        {
          id: 58,
          subMenu: t("general.boat_gpt"),
          optionLink: "/docs/workflow-management/boat-gpt",
          optionClass: "boat-gpt",
        },
      ],
    },
    {
      id: 6,
      title: t("general.data_catalog"),
      path: "/docs/data-catalog",
      class: "data-catalog",
      options: [
        {
          id: 61,
          subMenu: t("general.accessing_the_data_catalog"),
          optionLink: "/docs/data-catalog#accessing-the-data-catalog",
          optionClass: "accessing-the-data-catalog",
        },
        {
          id: 62,
          subMenu: t("general.viewing_data_assets"),
          optionLink: "/docs/data-catalog#viewing-data-assets",
          optionClass: "viewing-data-assets",
        },
        {
          id: 63,
          subMenu: t("general.searching_for_data"),
          optionLink: "/docs/data-catalog#searching-for-data",
          optionClass: "searching-for-data",
        },
        {
          id: 64,
          subMenu: t("general.previewing_data"),
          optionLink: "/docs/data-catalog#previewing-data",
          optionClass: "previewing-data",
        },
        {
          id: 65,
          subMenu: t("general.editing_table_information"),
          optionLink: "/docs/data-catalog#editing-table-information",
          optionClass: "editing-table-information",
        },
        {
          id: 66,
          subMenu: t("general.deleting_a_table"),
          optionLink: "/docs/data-catalog#deleting-a-table",
          optionClass: "deleting-a-table",
        },
      ],
    },
    {
      id: 7,
      title: t("general.transformation"),
      path: "/docs/transformation",
      class: "transformation",
    },
    {
      id: 8,
      title: t("general.faq"),
      path: "/docs#faq",
      class: "faq",
    },
  ];

  return (
    <StyledSideNavigation>
      <StyledNavigationListItems>
        {menuList.map((item) => (
          <div key={item.id}>
            <div
              className={`menu-list-container 
                  ${getClassNameIfActive(item.class)}
                  ${!item.hasOwnProperty("options") ? "no-dropdown" : ""}
                  ${checkIfActiveDropdown(item.id) ? "active" : ""}
                `}
            >
              <div>
                {item.hasOwnProperty("options") && (
                  <div>
                    {checkIfActiveDropdown(item.id) ? (
                      <BsChevronRight
                        onClick={() => {
                          const newArr = [...activeDropdown];
                          setActiveDropdown(
                            newArr.filter((current) => current !== item.id),
                          );
                        }}
                        className="icons up"
                      />
                    ) : (
                      <BsChevronDown
                        onClick={() => {
                          if (!activeDropdown.includes(item.id)) {
                            const newArr = [...activeDropdown];
                            newArr.push(item.id);
                            setActiveDropdown(newArr);
                          }
                        }}
                        className="icons"
                      />
                    )}
                  </div>
                )}
              </div>

              <div className="title-container">
                {item.path ? (
                  <Link to={item.path} className="nav-link">
                    {item.title}
                  </Link>
                ) : (
                  <div
                    className="nav-link"
                    onClick={() => {
                      if (!activeDropdown.includes(item.id)) {
                        const newArr = [...activeDropdown];
                        newArr.push(item.id);
                        setActiveDropdown(newArr);
                      }
                    }}
                  >
                    {item.title}
                  </div>
                )}
              </div>
            </div>

            {item.hasOwnProperty("options") ? (
              <div className="options-container" style={{ marginLeft: "1em" }}>
                {checkIfActiveDropdown(item.id)
                  ? item.options.map((option) => (
                      <div
                        className={getClassNameIfActive(option.optionClass)}
                        key={option.id}
                      >
                        <Flex
                          column
                          gap
                          style={{ padding: "0.5em 0.75em", gap: "0.5em" }}
                        >
                          <div className={"option-flex"}>
                            <Flex>
                              <Link
                                to={option.optionLink}
                                className="option-title"
                                style={{ marginTop: 0 }}
                              >
                                {option.subMenu}
                              </Link>
                            </Flex>
                          </div>
                        </Flex>
                      </div>
                    ))
                  : null}
              </div>
            ) : null}
          </div>
        ))}
      </StyledNavigationListItems>
    </StyledSideNavigation>
  );
};
export default DocumentNavigation;
