import { axiosGetJobs } from "../../../clientRequest/axiosRequest";

//get
export const fetchCustomers = (token, group, url) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_CUSTOMERS_LOADING",
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const res = await axiosGetJobs(url).get(`/customer?group=${group}`, {
      headers,
    });
    dispatch({
      type: "GET_CUSTOMERS_SUCCESS",
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: "GET_CUSTOMERS_ERROR",
      payload: error,
    });
  }
};
export const fetchUsers = (token, group, url) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_USERS_LOADING",
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const res = await axiosGetJobs(url).get(`/customer/user?group=${group}`, {
      headers,
    });
    dispatch({
      type: "GET_USERS_SUCCESS",
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: "GET_USERS_ERROR",
      payload: error,
    });
  }
};
export const fetchUserScripts =
  (token, group, user, url) => async (dispatch) => {
    try {
      dispatch({
        type: "GET_USER_SCRIPTS_LOADING",
      });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const res = await axiosGetJobs(url).get(
        `/customer/user/folder/script?group=${group}&user=${user}`,
        {
          headers,
        },
      );
      dispatch({
        type: "GET_USER_SCRIPTS_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "GET_USER_SCRIPTS_ERROR",
        payload: error,
      });
    }
  };

//post
export const postCustomer =
  (token, group, formData, url) => async (dispatch) => {
    try {
      dispatch({
        type: "POST_CUSTOMERS_LOADING",
      });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const res = await axiosGetJobs(url).post(
        `/customer?group=${group}`,
        formData,
        {
          headers,
        },
      );
      dispatch({
        type: "POST_CUSTOMERS_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "POST_CUSTOMERS_ERROR",
        payload: error,
      });
    }
  };
export const postUser =
  (token, group, bundle, formData, url) => async (dispatch) => {
    try {
      dispatch({
        type: "POST_USERS_LOADING",
      });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const res = await axiosGetJobs(url).post(
        `/customer/user?group=${group}&bundle=${bundle}`,
        formData,
        {
          headers,
        },
      );
      dispatch({
        type: "POST_USERS_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "POST_USERS_ERROR",
        payload: error,
      });
    }
  };
export const postUserFolder =
  (token, group, bundle, user, folder, url) => async (dispatch) => {
    try {
      dispatch({
        type: "POST_USERS_FOLDER_LOADING",
      });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const res = await axiosGetJobs(url).post(
        `/customer/user/folder?group=${group}&bundle=${bundle}&user=${user}`,
        { folderName: folder },
        {
          headers,
        },
      );
      dispatch({
        type: "POST_USERS_FOLDER_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "POST_USERS_FOLDER_ERROR",
        payload: error,
      });
    }
  };
export const postUserScripts =
  (token, group, bundle, user, folder, formData, url) => async (dispatch) => {
    try {
      dispatch({
        type: "POST_USER_SCRIPTS_LOADING",
      });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const res = await axiosGetJobs(url).post(
        `/customer/user/folder/script?group=${group}&bundle=${bundle}&user=${user}&folder=${folder}`,
        formData,
        {
          headers,
        },
      );
      const folderList = res.data.updatedItem.users.filter(
        (item) => item.user === user,
      );
      dispatch({
        type: "POST_USER_SCRIPTS_SUCCESS",
        payload: folderList,
      });
    } catch (error) {
      dispatch({
        type: "POST_USER_SCRIPTS_ERROR",
        payload: error,
      });
    }
  };
export const clearpostStatusToast = () => ({
  type: "CLEAR_POST_USER_SUCCESS",
});
//put
export const putCustomer =
  (token, group, formData, url) => async (dispatch) => {
    try {
      dispatch({
        type: "PUT_CUSTOMERS_LOADING",
      });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const res = await axiosGetJobs(url).put(
        `/customer?group=${group}`,
        formData,
        {
          headers,
        },
      );
      dispatch({
        type: "PUT_CUSTOMERS_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "PUT_CUSTOMERS_ERROR",
        payload: error,
      });
    }
  };
export const updateUser =
  (token, group, bundle, formData, url) => async (dispatch) => {
    try {
      dispatch({
        type: "PUT_USERS_LOADING",
      });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const res = await axiosGetJobs(url).put(
        `/customer/user?group=${group}&bundle=${bundle}`,
        formData,
        {
          headers,
        },
      );
      dispatch({
        type: "PUT_USERS_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "PUT_USERS_ERROR",
        payload: error,
      });
    }
  };
export const putUserScripts =
  (token, group, bundle, user, folder, scriptName, formData, url) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "PUT_USER_SCRIPTS_LOADING",
      });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const res = await axiosGetJobs(url).put(
        `/customer/user/folder/script?group=${group}&bundle=${bundle}&user=${user}&folder=${folder}&scriptName=${scriptName}`,
        formData,
        {
          headers,
        },
      );
      dispatch({
        type: "PUT_USER_SCRIPTS_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "PUT_USER_SCRIPTS_ERROR",
        payload: error,
      });
    }
  };
export const clearUpdateStatusToast = () => ({
  type: "CLEAR_UPDATE_USER_SUCCESS",
});
//delete
export const deleteUserFunction =
  (token, group, bundle, user, url) => async (dispatch) => {
    try {
      dispatch({
        type: "DELETE_USERS_LOADING",
      });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const res = await axiosGetJobs(url).delete(
        `/customer/user?group=${group}&bundle=${bundle}&user=${user}`,
        {
          headers,
        },
      );
      dispatch({
        type: "DELETE_USERS_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "DELETE_USERS_ERROR",
        payload: error,
      });
    }
  };
export const deleteUserScripts =
  (token, group, bundle, user, folder, scriptName, url) => async (dispatch) => {
    try {
      dispatch({
        type: "DELETE_USER_SCRIPTS_LOADING",
      });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const res = await axiosGetJobs(url).delete(
        `/customer/user/folder/script?group=${group}&bundle=${bundle}&user=${user}&folder=${folder}&scriptName=${scriptName}`,
        {
          headers,
        },
      );
      dispatch({
        type: "DELETE_USER_SCRIPTS_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "DELETE_USER_SCRIPTS_ERROR",
        payload: error,
      });
    }
  };
export const clearDeleteUserStatusToast = () => ({
  type: "CLEAR_DELETE_USER_SUCCESS",
});
export const clearDeleteUserScriptStatusToast = () => ({
  type: "CLEAR_DELETE_USER_SCRIPTS_SUCCESS",
});
export const clearAddUserScriptStatusToast = () => ({
  type: "CLEAR_ADD_USER_SCRIPTS_SUCCESS",
});
export const updateCustomerPlan =
  (token, group, formData, url, navigate) => async (dispatch) => {
    try {
      dispatch({
        type: "PUT_CUSTOMERS_LOADING",
      });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const res = await axiosGetJobs(url).put(
        `/customer?group=${group}&bundle=${formData.bundle}`,
        {
          headers,
        },
      );
      dispatch({
        type: "PUT_CUSTOMERS_SUCCESS",
        payload: res.data,
      });
      navigate("/dataManagement");
    } catch (error) {
      dispatch({
        type: "PUT_CUSTOMERS_ERROR",
        payload: error,
      });
    }
  };
