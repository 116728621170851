import { useState, useEffect } from "react";
import { axiosGetEndpoints } from "../../../../clientRequest/axiosRequest";

/**
 * @typedef {import('../types/pipedrive.type').ConnectorConfig} ConnectorConfig
 * @typedef {import('../types/pipedrive.type').SelectableEndpoint} SelectableEndpoint
 * @typedef {import('../types/pipedrive.type').PipeDriveParameters} PipeDriveParameters
 */

/**
 * Custom hook for managing Pipedrive connector functionality.
 *
 * @param {string} token - Authentication token.
 * @param {Object[]} taskData - Task-related data.
 * @param {string} taskData.groupName - Name of the group.
 * @param {string} taskData.baseURL - Base URL for the API.
 * @returns {[string, Function, SelectableEndpoint[], Function, Function, Function, Function]}
 * An array containing:
 * - `apiKey`: The current API key.
 * - `setApiKey`: Function to update the API key.
 * - `objects`: Array of selectable objects.
 * - `handleObjectSelection`: Function to toggle object selection.
 * - `handleAttrSelection`: Function to toggle attribute selection.
 * - `fetchEndpoints`: Function to fetch endpoints from the API.
 * - `convertSelectedToApi`: Function to convert selected objects and attributes for API usage.
 */
const usePipedrive = (token, taskData) => {
  const [apiKey, setApiKey] = useState("");
  const [objects, setObjects] = useState([]);

  /**
   * Fetches endpoints from the Pipedrive API.
   *
   * @param {string} token - Authentication token.
   * @param {string} group - Group name.
   * @param {string} baseURL - Base URL for the API.
   * @returns {Promise<void>}
   */
  const fetchEndpoints = async (token, group, baseURL, filterFunc) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await axiosGetEndpoints(baseURL).get(
        `/connectors/pipedrive/list-endpoints?group=${group}&endpoint_method=GET`,
        { headers }
      );

      /** @type {ConnectorConfig[]} */
      const endpoints = response.data;

      /** @type {SelectableEndpoint[]} */
      const selectableEndpoints = endpoints.map((endpoint) => ({
        attributes: endpoint.attributes
          .filter((attr) => typeof attr === "string")
          .map((attribute) => ({
            name: attribute,
            active: false,
            type: "String",
          })),
        connectorEndpoint: endpoint.connectorEndpoint,
        endpoint: endpoint.endpoint,
        isSelected: false,
      }));

      if (filterFunc) {
        setObjects(filterFunc(selectableEndpoints));
      } else {
        setObjects(selectableEndpoints);
      }
    } catch (error) {
      console.error("Error fetching endpoints:", error);
    }
  };

  const convertSelectedToApi = () => {
    const selectedObjects = objects.filter((obj) => obj.isSelected);

    /** @type {PipeDriveParameters[]} */
    const apiObjects = selectedObjects.map((obj) => ({
      endpoint: obj.endpoint,
      attributes: obj.attributes
        .filter((attr) => attr.active)
        .map((attr) => attr.name),
    }));

    taskData.forEach((data) => {
      data.source_type = 'pipedrive'
      data.parameters = apiObjects
      data.params.api_header_param = {
        "Api-Token": apiKey,
      }
    })
  };

  /**
   * Toggles the selection state of a specific object.
   *
   * @param {string} objectName - The endpoint name of the object to toggle.
   */
  const handleObjectSelection = (objectName) => {
    const updatedObjects = objects.map((obj) => {
      if (obj.endpoint === objectName) {
        const newSelectedState = !obj.isSelected;
        return {
          ...obj,
          isSelected: newSelectedState,
          attributes: obj.attributes.map((attribute) => ({
            ...attribute,
            active: newSelectedState,
          })),
        };
      }
      return obj;
    });

    setObjects(updatedObjects);
  };

  /**
   * Toggles the active state of a specific attribute within an object.
   *
   * @param {string} objectName - The endpoint name of the parent object.
   * @param {string} attributeName - The name of the attribute to toggle.
   */
  const handleAttrSelection = (objectName, attributeName) => {
    const updatedObjects = objects.map((obj) => {
      if (obj.endpoint === objectName) {
        return {
          ...obj,
          attributes: obj.attributes.map((attribute) => {
            if (attribute.name === attributeName) {
              return {
                ...attribute,
                active: !attribute.active,
              };
            }
            return attribute;
          }),
        };
      }
      return obj;
    });

    setObjects(updatedObjects);
  };

  /**
   * Effect to fetch endpoints when the token or taskData changes.
   */
  useEffect(() => {
    if (token && taskData.groupName && taskData.baseURL) {
      fetchEndpoints(token, taskData.groupName, taskData.baseURL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, taskData.groupName, taskData.baseURL]);

  /**
   * Effect to filter objects when the `objects` state changes.
   */
  useEffect(() => {
    // Placeholder for any additional effects when objects change
    // For example, logging or side effects
    // console.log("Objects updated:", objects);
  }, [objects]);

  return [
    apiKey,
    setApiKey,
    objects,
    handleObjectSelection,
    handleAttrSelection,
    fetchEndpoints,
    convertSelectedToApi,
  ];
};

export default usePipedrive;
