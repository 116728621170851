import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import DocumentPageLayout from "./DocumentPageLayout";

const Overview = () => {
  const { t } = useTranslation();
  const breadcrumbs = [
    {
      name: t("general.overview"),
      link: "#overview",
    },
  ];

  return (
    <DocumentPageLayout breadcrumbs={breadcrumbs}>
      <Grid container>
        <Grid item>
          <h1 id="overview">{t("general.overview")}</h1>
          <p>
            Welcome to a general introduction to the DataBoat Main page. Explore
            our features, learn how to maximize your data's potential, and
            embark on a journey towards data-driven success with DataBoat.
          </p>

          <div data-anchor="Overview">
            <div>
              <div>
                <div data-element-type="paragraph" data-version="5">
                  <h3>1.1 -login page</h3>
                  <p>
                    First, you have to login using your credentials to start
                    using Databoat.
                  </p>
                </div>
                <div data-element-type="image" data-widget-type="image">
                  <img
                    src="https://lirp.cdn-website.com/b29d841e/dms3rep/multi/opt/image--282-29-1920w.png"
                    alt=""
                    width="400"
                    data-dm-image-path="https://irp.cdn-website.com/b29d841e/dms3rep/multi/image--282-29.png"
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div>
                <div data-element-type="paragraph" data-version="5">
                  <h3>1.2- Main page</h3>
                  <p>
                    On the main page, you will find useful shortcuts to assist
                    you in your daily activities.
                  </p>
                  <p>
                    Ingest data: It can quickly guide you in creating a job
                    workflow.
                  </p>
                  <p>
                    Define Schema &amp; Ownership: It will connect you to your
                    data catalog, where you can find more details about your
                    data.
                  </p>
                  <p>
                    Define quality rules: You can define and verify rules for
                    your pipeline.
                  </p>
                  <p>
                    Predict your next insight: It will connect you to a machine
                    learning model.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div>
                <div data-element-type="image" data-widget-type="image">
                  <img
                    src="https://lirp.cdn-website.com/b29d841e/dms3rep/multi/opt/Captura+de+Tela+2024-04-05+a%C3%8C-s+15.40.11-1920w.png"
                    alt=""
                    width="400"
                    data-dm-image-path="https://irp.cdn-website.com/b29d841e/dms3rep/multi/Captura+de+Tela+2024-04-05+a%C3%8C-s+15.40.11.png"
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div>
                <div data-element-type="paragraph" data-version="5">
                  <h3>1.2.1 - Reporting numbers</h3>
                  <p>
                    At the bottom of the page, you will find some metrics about
                    Databoat usage:
                  </p>
                  <ul>
                    <li>The first panel provides insights on datasets.</li>
                    <li>
                      The second panel showcases the current number of jobs.
                    </li>
                    <li>
                      In the third panel, you can track the amount of space
                      you're utilizing in your cloud storage.
                    </li>
                    <li>
                      Lastly, the fourth panel displays the number of quality
                      rules you've implemented.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </DocumentPageLayout>
  );
};

export default Overview;
