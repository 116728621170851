import React from "react";
import { StyledBox } from "../styles/Box.Styled";

const Box = ({ fullWidth, fullHeight, children }) => {
  return (
    <StyledBox fullWidth={fullWidth} fullHeight={fullHeight}>
      {children}
    </StyledBox>
  );
};

Box.defaultProps = {
  fullWidth: false,
  fullHeight: false,
};

export default Box;
