import React from "react";
import {
  StyledDashboardContentFlex,
  StyledCenteredModal,
  AiOutlineClose,
  H3,
  BiSearch,
  Flex,
  Input,
  StyledModal,
} from "../../../index";
import { useNavigate } from "react-router-dom";
import DataDictionaryTable from "./DataDictionaryTable";

const DataSetDictionaryModal = () => {
  const navigate = useNavigate();
  const mapItems = [
    {
      id: "1",
      ColumnName: "store_id",
      DataType: "char_4",
      Description: "",
      VarCharValue: "",
      Sample: "",
    },
    {
      id: "2",
      ColumnName: "store_id",
      DataType: "char_4",
      Description: "",
      VarCharValue: "",
      Sample: "",
    },
    {
      id: "3",
      ColumnName: "store_id",
      DataType: "char_4",
      Description: "",
      VarCharValue: "",
      Sample: "",
    },
    {
      id: "4",
      ColumnName: "store_id",
      DataType: "char_4",
      Description: "",
      VarCharValue: "",
      Sample: "",
    },
  ];
  const handleSorting = () => {
    const copyArray = [...mapItems].map((item) => {
      return item.schema.sort((a, b) => {
        return a.ColumnName > b.ColumnName ? 1 : -1;
      });
    });
    [...mapItems].push(copyArray);
  };
  return (
    <StyledModal>
      <StyledCenteredModal>
        <StyledDashboardContentFlex column>
          <StyledDashboardContentFlex justifyContent>
            <H3>Data Dictionary - $ DataSet Selected</H3>
            <AiOutlineClose onClick={() => navigate(-1)} />
          </StyledDashboardContentFlex>
          <Flex column>
            <Flex column gap>
              <div>
                <h2>Schema({mapItems.length})</h2>
              </div>
              <p>View and manage the data schema.</p>
            </Flex>
            <StyledDashboardContentFlex justifyContent>
              <div className="full_width">
                <BiSearch />
                <Input
                  type={"text"}
                  placeholder="filter schema"
                  className={"input_schema"}
                />
              </div>
            </StyledDashboardContentFlex>
          </Flex>
          <DataDictionaryTable
            handleSorting={handleSorting}
            mapItems={mapItems}
          />
        </StyledDashboardContentFlex>
      </StyledCenteredModal>
    </StyledModal>
  );
};
export default DataSetDictionaryModal;
