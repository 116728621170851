import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid"; //for id generation
import DashBoardScreen from "../reusableComponents/DashBoardScreen";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Stack, Typography, TextField, Autocomplete, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText } from "@mui/material";
import { StyledTextArea } from "../styles/Styled";

import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSupportCategory,
  fetchSupportType,
  postSupportRequestData,
  clearputSupportStatusToast,
  clearputSupportStatusToastError,
} from "../redux/actions/usersActions";

import {
  StyledDashboardContentFlex,
  StyledPortalContent,
  Tabs,
  Flex,
  StyledConnectorInput,
  Theme,
  Button,
} from "../index";
import useS3Function from "./useS3Function";
import { useNavigate } from "react-router-dom";
import useGetToken from "../cognito/useGetToken";
const CreateCase = () => {
  const { environmentVariables } = useSelector((state) => state.users);
  const { postSupport, postSupportError } = useSelector(
    (state) => state.support,
  );
  const { token } = useGetToken();
  const { upload, urls } = useS3Function();
  const dispatch = useDispatch();
  const { getSupportCategory, getSupportType } = useSelector(
    (state) => state.support,
  );

  useEffect(() => {
    if (token) {
      dispatch(
        fetchSupportCategory(
          token,
          environmentVariables.REACT_APP_BASE_URL_API_CORE,
        ),
      );
      dispatch(
        fetchSupportType(
          token,
          environmentVariables.REACT_APP_BASE_URL_API_CORE,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, dispatch]);

  const tabData = [
    {
      to: "/support",
      icon: (
        <SupportAgentIcon
          sx={{ fill: "#006399", width: "18px", height: "18px" }}
        />
      ),
      label: "Support",
    },
    {
      to: "",
      icon: (
        <AddCircleIcon
          sx={{ fill: "#006399", width: "18px", height: "18px" }}
        />
      ),
      label: "create-case",
    },
  ];
  const headingStyle = {
    font: Theme.fonts.fontBasic,
    color: "grey",
  };
  const opened_by = localStorage.getItem("created");
  const created_by = localStorage.getItem("group");

  const [formData, setFormData] = useState({
    subject: "",
    description: "",
    created_by: created_by,
    case_id: uuidv4(),
    opened_by: opened_by,
    severity: "",
    case_status: "work in progress",
    case_type: "",
    s3url: [],
    category: [],
    date: new Date().toISOString(),
  });
  const [s3Error, setS3Error] = useState("");
  const navigate = useNavigate();
  // Handler for subject change
  const handleSubjectChange = (e) => {
    setFormData({ ...formData, subject: e.target.value });
  };

  // Handler for description change
  const handleDescriptionChange = (e) => {
    setFormData({ ...formData, description: e.target.value });
  };
  const resetError = () => {
    setS3Error("");
  };
  // Handler for file input change
  const handleS3URLChange = (e) => {
    const files = e.target.files;
    const maxFiles = 3;
    const maxSize = 5 * 1024 * 1024; // 5MB

    // Check if the number of selected files exceeds the limit
    if (files.length > maxFiles) {
      setS3Error("You can only upload up to 3 files.");
      e.target.value = ""; // Clear the selected files
      return; // Stop further processing
    }

    // Check each file size
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > maxSize) {
        setS3Error(`File ${files[i].name} exceeds the 5MB limit.`);
        e.target.value = ""; // Clear the selected files
        return; // Stop further processing
      }
    }

    // If no errors, update the form data with the selected files
    setFormData({ ...formData, s3url: Array.from(files) });
    setS3Error(""); // Clear any previous errors
  };

  const handleCaseTypeChange = (event, newValue) => {
    const value = newValue ? newValue : "";
    setFormData({ ...formData, case_type: value });
  };
  const handleSeverityChange = (event, newValue) => {
    const value = newValue ? newValue.props.value : "";
    setFormData({ ...formData, severity: value });
  };
  const handleCategoriesChange = (e) => {
    setFormData({ ...formData, category: e.target.value });
  };

  const isFormValid = () => {
    // Check if all required fields meet the validation criteria
    return (
      formData.subject !== "" &&
      formData.description !== "" &&
      formData.created_by !== "" &&
      formData.case_id !== "" &&
      formData.opened_by !== "" &&
      formData.severity !== "" &&
      formData.case_status !== "" &&
      formData.case_type !== "" &&
      formData.category.length &&
      formData.date !== ""
      // formData.s3url.length > 0
    );
  };
  const handleSaveAsTable = async () => {
    if (isFormValid()) {
      if (formData.s3url.length !== 0) {
        await upload(formData.s3url);
      } else {
        dispatch(
          postSupportRequestData(
            formData,
            token,
            environmentVariables.REACT_APP_BASE_URL_API_CORE,
          ),
        );
      }
    } else {
      setS3Error("Fields cannot be empty");
    }
  };

  useEffect(() => {
    if (isFormValid() && urls !== null && token) {
      const newFormData = {
        subject: formData.subject,
        description: formData.description,
        created_by: formData.created_by,
        case_id: formData.case_id,
        opened_by: formData.opened_by,
        severity: formData.severity,
        case_status: formData.case_status,
        case_type: formData.case_type,
        category: formData.category,
        date: formData.date,
        s3url: urls,
      };
      // Proceed with dispatching action if required
      if (newFormData) {
        dispatch(
          postSupportRequestData(
            newFormData,
            token,
            environmentVariables.REACT_APP_BASE_URL_API_CORE,
          ),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urls, token]);
  useEffect(() => {
    if (postSupport !== null) {
      toast.success("Case Added", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnFocusLoss: false,
      });
      dispatch(clearputSupportStatusToast());
      navigate("/support");
    }
    if (postSupportError) {
      toast.warning("Failed, try again.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnFocusLoss: false,
      });
      dispatch(clearputSupportStatusToastError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postSupportError, postSupport, dispatch]);

  return (
    <DashBoardScreen
      children={
        <StyledPortalContent>
          <div style={{ padding: "1em", height: "90vh", overflow: "scroll" }}>
            <StyledDashboardContentFlex column>
              <StyledDashboardContentFlex justifyContent>
                <Tabs tabs={tabData} />
              </StyledDashboardContentFlex>
              <Stack spacing={2}>
                <ToastContainer />
                <Typography sx={{ font: Theme.fonts.fontMedium }}>
                  Case Classification
                </Typography>
                <Stack spacing={1}>
                  <p style={{ font: Theme.fonts.fontSecondaryBold }}>Type</p>
                  <Autocomplete
                    disablePortal
                    id="Type"
                    options={getSupportType.type}
                    onChange={handleCaseTypeChange}
                    onFocus={resetError}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select or search" />
                    )}
                  />
                </Stack>
                <Stack spacing={1}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Severity
                </Typography>
                <FormControl fullWidth>
                  <InputLabel id="severity-label">Severity</InputLabel>
                  <Select
                    labelId="severity-label"
                    id="Severity"
                    value={formData.severity}
                    label="Severity"
                    onChange={handleSeverityChange}
                    onFocus={resetError}
                  >
                    <MenuItem value="High">High</MenuItem>
                    <MenuItem value="Medium">Medium</MenuItem>
                    <MenuItem value="Low">Low</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
                <Stack spacing={2}>
                  <Typography sx={{ font: Theme.fonts.fontSecondaryBold }}>
                    Case description
                  </Typography>
                  <Flex gap column>
                    <p style={{ font: Theme.fonts.fontSecondaryBold }}>
                      Subject
                    </p>
                    <StyledConnectorInput
                      placeholder="Subject"
                      name="Subject"
                      value={formData.subject}
                      onChange={handleSubjectChange}
                      onFocus={resetError}
                    />
                    <p style={headingStyle}>
                      Maximum 250 characters(250 remaining)
                    </p>
                  </Flex>
                  <Flex gap column>
                    <Typography style={{ font: Theme.fonts.fontSecondaryBold }}>
                      Category
                    </Typography>
                    <FormControl>
                      <Select
                        multiple
                        value={formData.category}
                        onChange={handleCategoriesChange}
                        onFocus={resetError}
                        renderValue={(selected) => selected.join(', ')}
                      >
                        {getSupportCategory.categories?.map((option) => (
                          <MenuItem key={option} value={option}>
                            <Checkbox checked={formData.category.indexOf(option) > -1} />
                            <ListItemText primary={option} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Flex>
                  <Flex column gap>
                    <p style={{ font: Theme.fonts.fontSecondaryBold }}>
                      Description
                    </p>
                    <StyledTextArea
                      placeholder="Detailed account of the question or issue"
                      name="description"
                      rows="10"
                      cols="50"
                      value={formData.description}
                      onChange={handleDescriptionChange}
                      onFocus={resetError}
                    />
                    <p style={headingStyle}>
                      Maximum 250 characters(250 remaining)
                    </p>
                  </Flex>

                  <Flex column gap>
                    <p style={{ font: Theme.fonts.fontSecondaryBold }}>
                      Attachments
                    </p>
                    <input
                      type="file"
                      id="Attachments"
                      name="Attachments"
                      accept="image/*, video/*,"
                      multiple
                      onChange={handleS3URLChange}
                      onFocus={resetError}
                    />
                    <p style={headingStyle}>
                      Up to 3 attachments, each less than 5MB
                    </p>
                    {s3Error && <p className="error">{s3Error}</p>}
                  </Flex>
                </Stack>

                <Flex style={{ flexDirection: "row-reverse" }} gap>
                  <Button
                    onClick={handleSaveAsTable}
                    text={"submit"}
                    className={"next_btn save_btn"}
                  />
                </Flex>
              </Stack>
            </StyledDashboardContentFlex>
          </div>
        </StyledPortalContent>
      }
    />
  );
};

export default CreateCase;
