const useOnLoad = () => {
  const ace = require("ace-builds/src-noconflict/ace");
  const load = (dbSchemaRepresentation, attributes) => {
    const languageTools = ace.require("ace/ext/language_tools");
    languageTools.addCompleter({
      getCompletions: function (editor, session, pos, prefix, callback) {
        const line = editor.session.getLine(pos.row);
        const lastWord = line.substring(0, pos.column).split(" ").pop();
        const completions = [];

        // Iterate over the dbSchemaRepresentation array
        dbSchemaRepresentation &&
          dbSchemaRepresentation.length > 0 &&
          dbSchemaRepresentation.forEach((schema) => {
            const { dataset_name, table_name } = schema;

            // Match for dataset_name (database)
            if (lastWord.startsWith(`${dataset_name}.`)) {
              // Add table completions
              completions.push(
                ...dbSchemaRepresentation
                  .filter(
                    (schemaItem) => schemaItem.dataset_name === dataset_name,
                  )
                  .map((schemaItem) => ({
                    name: `${schemaItem.dataset_name}.${schemaItem.table_name}`,
                    value: schemaItem.table_name,
                    score: 1,
                    meta: "table",
                  })),
              );

              // Match for table_name
              if (lastWord.startsWith(`${dataset_name}.${table_name}.`)) {
                // Add attribute completions
                completions.push(
                  ...(attributes || []).map((attribute) => ({
                    name: `${dataset_name}.${table_name}.${attribute.column_name}`,
                    value: attribute.column_name,
                    score: 1,
                    meta: "attribute",
                  })),
                );
              }
            }
          });

        // Provide database-level completion
        dbSchemaRepresentation &&
          dbSchemaRepresentation.length > 0 &&
          completions.push(
            ...dbSchemaRepresentation.map((schema) => ({
              name: schema.dataset_name,
              value: schema.dataset_name,
              score: 1,
              meta: "database",
            })),
          );

        callback(null, completions);
      },
    });
  };

  return { load };
};

export default useOnLoad;
