import { StyledHeader } from "../../index";
import { useTranslation } from "react-i18next";

const styles = {
  display: "flex",
  gap: "2.5em",
  flexDirection: "column",
};

const SignUpHeader = () => {
  const { t } = useTranslation();

  return (
    <StyledHeader>
      <div style={styles}>
        <h2>{t("signup.title")}</h2>
      </div>
    </StyledHeader>
  );
};

export default SignUpHeader;
