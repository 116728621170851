import React from "react";
import { StyledPortalContent, H3 } from "../index";

const DataClassificationOverview = () => {
  return (
    <StyledPortalContent>
      <H3>Data Classification</H3>
    </StyledPortalContent>
  );
};

export default DataClassificationOverview;
