const initialState = {
  postSupport: null,
  postSupportLoader: false,
  postSupportError: null,
  getSupport: [],
  getSupportLoader: false,
  getSupportError: null,
  getSupportType: [],
  getSupportTypeLoader: false,
  getSupportTypeError: null,
  getSupportCategory: [],
  getSupportCategoryLoader: false,
  getSupportCategoryError: null,
  getSupportOpenCase: [],
  getSupportOpenCaseLoader: false,
  getSupportOpenCaseError: null,
  putSupport: null,
  putSupportLoader: false,
  putSupportError: null,
  supportStatus: null,
  supportStatusLoader: false,
  supportStatusError: null,
};
const supportReducer = (state = initialState, action) => {
  switch (action.type) {
    case "POST_SUPPORT_LOADING":
      return {
        ...state,
        postSupportLoader: true,
      };
    case "POST_SUPPORT_SUCCESS":
      return {
        ...state,
        postSupport: action.payload,
        postSupportLoader: false,
      };
    case "POST_SUPPORT_ERROR":
      return {
        ...state,
        postSupportLoader: false,
        postSupportError: action.payload,
      };

    case "GET_SUPPORT_LOADING":
      return {
        ...state,
        getSupportLoader: true,
      };
    case "GET_SUPPORT_SUCCESS":
      return {
        ...state,
        getSupport: action.payload,
        getSupportLoader: false,
      };
    case "GET_SUPPORT_ERROR":
      return {
        ...state,
        getSupportLoader: false,
        getSupportError: action.payload,
      };

    case "GET_SUPPORT_TYPE_LOADING":
      return {
        ...state,
        getSupportTypeLoader: true,
      };
    case "GET_SUPPORT_TYPE_SUCCESS":
      return {
        ...state,
        getSupportType: action.payload,
        getSupportTypeLoader: false,
      };
    case "GET_SUPPORT_TYPE_ERROR":
      return {
        ...state,
        getSupportTypeLoader: false,
        getSupportTypeError: action.payload,
      };

    case "GET_SUPPORT_CATEGORY_LOADING":
      return {
        ...state,
        getSupportCategoryLoader: true,
      };
    case "GET_SUPPORT_CATEGORY_SUCCESS":
      return {
        ...state,
        getSupportCategory: action.payload,
        getSupportCategoryLoader: false,
      };
    case "GET_SUPPORT_CATEGORY_ERROR":
      return {
        ...state,
        getSupportCategoryLoader: false,
        getSupportCategoryError: action.payload,
      };

    case "PUT_SUPPORT_LOADING":
      return {
        ...state,
        putSupportLoader: true,
      };
    case "PUT_SUPPORT_SUCCESS":
      return {
        ...state,
        putSupport: action.payload,
        putSupportLoader: false,
      };
    case "PUT_SUPPORT_ERROR":
      return {
        ...state,
        putSupportLoader: false,
        putSupportError: action.payload,
      };

    case "PUT_SUPPORT_STATUS_LOADING":
      return {
        ...state,
        supportStatusLoader: true,
      };
    case "PUT_SUPPORT_STATUS_SUCCESS":
      return {
        ...state,
        supportStatus: action.payload,
        supportStatusLoader: false,
      };
    case "PUT_SUPPORT_STATUS_ERROR":
      return {
        ...state,
        supportStatusLoader: false,
        supportStatusError: action.payload,
      };
    case "CLEAR_SUPPORT_TOAST_SUCCESS":
      return {
        ...state,
        supportStatus: null,
        putSupport: null,
        postSupport: null,
      };
    case "CLEAR_SUPPORT_TOAST_SUCCESS_ERROR":
      return {
        ...state,
        supportStatusError: null,
        putSupportError: null,
        postSupportError: null,
      };
    case "GET_SUPPORT_OPEN_CASE_LOADING":
      return {
        ...state,
        getSupportOpenCaseLoader: true,
      };
    case "GET_SUPPORT_OPEN_CASE_SUCCESS":
      return {
        ...state,
        getSupportOpenCase: action.payload,
        getSupportOpenCaseLoader: false,
      };
    case "GET_SUPPORT_OPEN_CASE_ERROR":
      return {
        ...state,
        getSupportOpenCaseLoader: false,
        getSupportOpenCaseError: action.payload,
      };

    default:
      return state;
  }
};

export default supportReducer;
