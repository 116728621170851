import { createTheme } from "@mui/material/styles";

const Theme = createTheme({
  colors: {
    stateLayersOnSurfaceOpacity012: "rgba(0, 31, 37, 0.12)",
    stateLayersOnSurfaceOpacity008: "rgba(0, 31, 37, 0.08)",
    stateLayerPrimaryOpacity008: "rgba(0, 99, 153, 0.08)",
    stateLayerOnSecondaryContainerOpacity008: "rgba(37, 26, 0, 0.08)",
    whiteColor: "#fff",
    inverseOnSurface: "#D6F6FF",
    refPrimary20: "#003352",
    placeholderText: "#42474E",
    sysSurfaceContainer: "#EFFBFF",
    sysSurfaceContainerLow: "#F8FDFF",
    sysSecondaryContainer: "#251A00",
    sysSurfaceVariant: "#42474E",
    sysSurfaceActive: "#FFDF99",
    sysSurface2Color: "#001F25",
    sysSurfaceTextColor: "#001F25",
    blueColor: "#006399",
    refPrimary30: "#004A75",
    borderColor: "#72777f",
    transparentBlack: " #c2c7cf",
    lightSlate: "#f1f5f9",
    lightGrey: "#dee3eb",
    lightBlue: "#cde5ff",
    primaryColor: "#000",
    secondaryColor: "#FCFCFF",
    modalHeaderBgColor: "#003352",
    tertiaryColor: "#F4F3F5",
    selectColor: "#2980B9",
    tertiaryBackgroundColor: "#F8F9FD",
    bg_color: "#211F21",
    primary_bg_color: "#F29C00",
    teritaryTextColor: "#C3C3C3",
    btn_color: "#303030",
    border_color: "#8E8E8E",
    primary_border_color: "#F5F5F5",
    signInHeaderTextColor: "#818B9F",
    sideNavigation: "#FAFAFA",
    secondaryElementColor: "#4a4b4c",
    right_align_color: "#E7E7E7",
    connectBackgroundcolor: "#f2f2f2",
    disabledColor: "#b1b1b1",
    errorLightColor: "#FFDAD6",
    errorColor: "#BA1A1A",
    successLightColor: "#E6F4EA",
    successColor: "#0D8A0D",
    lighterGrey: "#F7F8FA",
    mutedGrey: "#D0D3D9",
  },
  fonts: {
    primaryFont: "'Poppins', sans-serif",
    fontBasic: "normal 500 12px/16px 'Poppins', sans-serif",
    fontPrimary: "normal 500 14px/20px 'Poppins', sans-serif",
    fontSecondary: "normal 400 16px/24px 'Poppins',sans-serif",
    fontSecondaryBold: "normal 500 16px/24px 'Poppins',sans-serif",
    fontMedium: "normal 400 22px/28px 'Poppins',sans-serif",
    fontMediumBold: "normal 400 24px/32px 'Poppins',sans-serif",
    smallFont: "normal 400 14px/20px 'Poppins',sans-serif",
    headlineLarge: "normal 400 32px/40px 'Poppins', sans-serif",
    headlineMedium: "normal 400 28px/36px 'Poppins', sans-serif",
    secondaryLetterSpacing: "0.5px",
    primaryLetterSpacing: "0.1px",
    regularFont: 400,
    mediumFont: 500,
    semiBoldFont: 600,
    boldFont: 700,
  },
  boxShadow: {
    primary_boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    secondary_boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 2px",
    bg_shadow: "rgba(0, 0, 0, 0.25)",
  },
  radius: {
    primaryBorderRadius: "28px",
    secondaryBorderRadius: "6.25em",
  },
  responsive: {
    desktop: "1200px",
    mobile: "768px",
    ipad: "990px",
  },
});
// breakpoints for xs, sm, md, lg and xl are 576px, 768px, 992px and 1200px.

export default Theme;
