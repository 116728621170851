import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { useEffect } from "react";

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    showCheckbox,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {showCheckbox && (
          <TableCell
            padding="checkbox"
            sx={{ border: "none", borderBottom: "1px solid #dddddd" }}
          >
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all",
              }}
            />
          </TableCell>
        )}

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ border: "none", borderBottom: "1px solid #dddddd" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array.isRequired,
  showCheckbox: PropTypes.bool.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, title, showCheckbox } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(showCheckbox &&
          numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity,
              ),
          }),
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h5"
        id="tableTitle"
        component="div"
      >
        {title}
      </Typography>

      {showCheckbox && numSelected > 0 && (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  showCheckbox: PropTypes.bool.isRequired,
};

const MuiEnhancedTable = ({
  headCells,
  pagination = {},
  handlePagination = (page, pageSize) => {},
  handleOrdination = (orderBy, orderDirection) => {},
  rows,
  showCheckbox = false,
  title,
  selected,
  handleSelect,
}) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
    if (Object.keys(pagination).length !== 0) {
      setRowsPerPage(pagination.pageSize);
      setPage(pagination.currentPage);
    }
  }, [pagination]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    handleOrdination(isAsc ? "desc" : "asc", property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      handleSelect(newSelected);
      return;
    }
    handleSelect([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    handleSelect(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    handlePagination(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    handlePagination(0, parseInt(event.target.value, 10));
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Box sx={{ width: "100%", overflow: "scroll" }}>
      <Paper overflow="scroll" sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={showCheckbox ? selected.length : 0}
          title={title}
          showCheckbox={showCheckbox}
        />
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={"medium"}>
            <EnhancedTableHead
              numSelected={showCheckbox ? selected.length : 0}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
              showCheckbox={showCheckbox}
            />
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    onClick={
                      showCheckbox
                        ? (event) => handleClick(event, row.name)
                        : null
                    }
                    role={showCheckbox ? "checkbox" : "row"}
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.name}
                    selected={isItemSelected}
                    sx={{ cursor: showCheckbox ? "pointer" : "default" }}
                  >
                    {showCheckbox && (
                      <TableCell
                        padding="checkbox"
                        sx={{
                          border: "none",
                          borderBottom: "1px solid #dddddd",
                        }}
                      >
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-label": labelId,
                          }}
                        />
                      </TableCell>
                    )}
                    {Object.values(row).map((cellValue, cellIndex) => {
                      if (typeof cellValue === "object") {
                        return (
                          <TableCell
                            key={cellIndex}
                            align="left"
                            sx={{
                              border: "none",
                              borderBottom: "1px solid #dddddd",
                              backgroundColor: cellValue.type,
                            }}
                          >
                            {cellValue.amount}
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell
                            key={cellIndex}
                            align="left"
                            sx={{
                              border: "none",
                              borderBottom: "1px solid #dddddd",
                            }}
                          >
                            {cellValue}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={
            Object.keys(pagination).length !== 0
              ? pagination.totalItems
              : rows.length
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ overflow: "scroll" }}
        />
      </Paper>
    </Box>
  );
};

export default MuiEnhancedTable;
