import React from "react";
import { Grid } from "@mui/material";
import ReactPlayer from "react-player";
import DocumentPageLayout from "../DocumentPageLayout";

const Gcs = () => {
  const breadcrumbs = [
    {
      name: "Ingesting your data using GCS",
      link: "#gcs",
    },
    {
      name: "Google Cloud Storage Connector",
      link: "#gcs-connector",
    },
  ];

  return (
    <DocumentPageLayout breadcrumbs={breadcrumbs}>
      <Grid container rowSpacing={4}>
        <Grid item>
          <h1 id="gcs">Ingesting your data using GCS</h1>

          <p>
            Check this step by step video - How to easily onboard data on
            DataBoat data platform:
          </p>

          <ReactPlayer
            url="https://vid.cdn-website.com/b29d841e/videos/ATpQVKKJRIakCMz7KAFQ_GCS+Connector-v.mp4"
            controls={true}
          />

          <h2 id="gcs-connector">Google Cloud Storage Connector</h2>

          <h4>Step 1: Configure Your Source</h4>
          <ul>
            <li>
              Click on "Configure Your Source" to begin the process of defining
              your data workflow.
            </li>
          </ul>
          <h4>Step 2: Define Workflow Details</h4>
          <ul>
            <li>Enter a name for the workflow.</li>
            <li>Set the start time of the workflow based on date and time.</li>
            <li>
              Determine the execution frequency of the pipeline by selecting one
              of the predefined options.
            </li>
          </ul>
          <h4>Step 3: Set Up Tasks</h4>
          <ul>
            <li>
              Add tasks to your workflow. You can include additional tasks based
              on data dependencies with different sources.
            </li>
            <li>
              For each task, define relevant parameters for the job
              orchestrator. Use key-value pairs in the UI or paste your JSON
              key-value map.
            </li>
          </ul>
          <h4>Step 4: Choose Task Type and Connector</h4>
          <ul>
            <li>Select the task type, either Ingestion or Transformation.</li>
            <li>
              If Ingestion is selected, choose an existing connector for Google
              Cloud Storage (GCS).
            </li>
          </ul>
          <h4>Step 5: Configure GCS Connection Attributes</h4>
          <ul>
            <li>
              Enter the Source Bucket Name/File path from which data will be
              ingested.
            </li>
            <li>Select the File Format (e.g., csv, xlsx).</li>
            <li>
              Indicate whether a header is present in the file by selecting
              Header On/Off.
            </li>
            <li>
              Choose the Separator character used in the file (e.g., comma,
              tab).
            </li>
            <li>
              Specify the Charset if required; this is optional but can be
              necessary for non-standard text encodings.
            </li>
            <li>
              Define Partition Columns if applicable. List the columns separated
              by commas that should be used to partition the data.
            </li>
          </ul>
          <h4>Step 6: Finalize Workflow</h4>
          <ul>
            <li>
              Optionally, add tags to the workflow for easier identification and
              management.
            </li>
            <li>
              Set up notifications to keep you informed about the
              workflow&rsquo;s execution status.
            </li>
          </ul>
          <h4>Step 7: Create and Execute Job</h4>
          <ul>
            <li>
              Review all settings, then save the workflow to create the job.
            </li>
            <li>
              The job is now ready to be executed and will process data
              according to the specified parameters and schedule.
            </li>
          </ul>
        </Grid>
      </Grid>
    </DocumentPageLayout>
  );
};

export default Gcs;
