import { axiosGlueMetadata } from "../../../clientRequest/axiosRequest";
import {
  updateDataBases,
  setIsDataBasesLoading,
  setDataBasesError,
  updateTables,
  setTablesError,
  setIsTablesLoading,
  setIsTableDetailsLoading,
  updateTableDetails,
  setTableDetailsError,
  setIsTableFiltersLoading,
  updateTableFilters,
  setTableFiltersError,
  setIsTableInfosLoading,
  updateTableInfos,
  setTableInfosError,
} from "../reducers/glueMetadataReducer";
import {
  mockTableDetailsDataWithPaginationAndOrder,
  mockTableFilters,
  mockTableInfos,
} from "./mocks/mockTableDetails";

export const getDataBases = (baseURL, appEnvironment) => async (dispatch) => {
  try {
    dispatch(setIsDataBasesLoading(true));
    dispatch(setDataBasesError(null));
    const res = await axiosGlueMetadata(baseURL).post(
      `/${appEnvironment}/list-databases`,
    );
    dispatch(updateDataBases(res.data));
  } catch (error) {
    dispatch(setDataBasesError(error.message));
  } finally {
    dispatch(setIsDataBasesLoading(false));
  }
};

export const getDataBaseTables =
  (databaseName, baseURL, appEnvironment) => async (dispatch) => {
    try {
      dispatch(setIsTablesLoading(true));
      dispatch(setTablesError(null));
      const res = await axiosGlueMetadata(baseURL).post(
        `/${appEnvironment}/list-tables/${databaseName}`,
      );
      dispatch(updateTables(res.data));
    } catch (error) {
      dispatch(setTablesError(error.message));
    } finally {
      dispatch(setIsTablesLoading(false));
    }
  };

export const getTableDetails =
  (
    databaseName,
    tableName,
    baseURL,
    appEnvironment,
    page = 0,
    pageSize = 10,
    orderBy = null,
    orderDirection = null,
    filters = [],
  ) =>
  async (dispatch) => {
    try {
      dispatch(setIsTableDetailsLoading(true));
      dispatch(setTableDetailsError(null));
      dispatch(
        updateTableDetails(mockTableDetailsDataWithPaginationAndOrder.res),
      );
    } catch (error) {
      dispatch(updateTableDetails({}));
      dispatch(setTableDetailsError(error.message));
    } finally {
      dispatch(setIsTableDetailsLoading(false));
    }
  };

export const getTableFilters =
  (databaseName, tableName, baseURL, appEnvironment) => async (dispatch) => {
    try {
      dispatch(setIsTableFiltersLoading(true));
      dispatch(setTableFiltersError(null));

      //let url = `/${appEnvironment}/list-table-filters/${databaseName}/${tableName}/`;
      //const res = await axiosGlueMetadata(baseURL).post(url);
      dispatch(updateTableFilters(mockTableFilters.res));
    } catch (error) {
      dispatch(updateTableFilters([]));
      dispatch(setTableFiltersError(error.message));
    } finally {
      dispatch(setIsTableFiltersLoading(false));
    }
  };

export const getTableInfos =
  (databaseName, tableName, baseURL, appEnvironment) => async (dispatch) => {
    try {
      dispatch(setIsTableInfosLoading(true));
      dispatch(setTableInfosError(null));

      //let url = `/${appEnvironment}/list-table-filters/${databaseName}/${tableName}/`;
      //const res = await axiosGlueMetadata(baseURL).post(url);
      dispatch(updateTableInfos(mockTableInfos.res));
    } catch (error) {
      dispatch(updateTableInfos({}));
      dispatch(setTableInfosError(error.message));
    } finally {
      dispatch(setIsTableInfosLoading(false));
    }
  };
