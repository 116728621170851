import styled from "styled-components";

export const StyledDocHeader = styled.div`
  padding: 1em 1.5em;
  background-color: ${({ theme }) => theme.colors.whiteColor};
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};

  p,
  a {
    text-decoration: none;
    font: ${({ theme }) => theme.fonts.fontPrimary};
    font-weight: ${({ theme }) => theme.fonts.mediumFont};
    color: ${({ theme }) => theme.colors.primaryColor};
  }

  strong {
    font-weight: ${({ theme }) => theme.fonts.semiBoldFont};
  }

  .document-search {
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.lighterGrey};
    border: 1px solid ${({ theme }) => theme.colors.mutedGrey};
    padding: 0.25em 0.5em;
    height: 36px;

    svg {
      margin-top: 1px;
    }
  }

  .go-to-btn {
    background-color: ${({ theme }) => theme.colors.whiteColor};
    color: ${({ theme }) => theme.colors.primaryColor};
    font-family: ${({ theme }) => theme.fonts.primaryFont};
    font-weight: ${({ theme }) => theme.fonts.boldFont};
    border-radius: 4px;
    padding: 0.5em 1.75em;
    text-transform: capitalize;
    border: 0;

    &:hover {
      cursor: pointer;
      color: ${({ theme }) => theme.colors.blueColor};
    }

    &:disabled {
      background-color: ${({ theme }) => theme.colors.disabledColor};
    }

    &.dark {
      background-color: ${({ theme }) => theme.colors.blueColor};
      color: ${({ theme }) => theme.colors.whiteColor};
      font-weight: ${({ theme }) => theme.fonts.mediumFont};
      opacity: 0.9;

      &:hover {
        opacity: 1;
      }
    }
  }
`;

export const StyledDocContentFlex = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  gap: ${(props) => (props.gap ? "3em" : "1.5em")};
  justify-content: ${(props) =>
    props.justifyContent ? "space-between" : null};
`;

export const MainGrid = styled.div`  
  flexGrow: 1;
  display: flex;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.whiteColor};

  /* reference on transparent scroll */
  #no-scrollbg::-webkit-scrollbar {
    width: auto;
  }
  #no-scrollbg::-webkit-scrollbar-track, #no-scrollbg::-webkit-scrollbar-thumb {
    background: transparent;
  }

  .breadcrumbs {
    margin-top: 3rem;

    &-list {
      border-left: 2px solid ${({ theme }) => theme.colors.refPrimary20},
      paddingTop: 0 !important;
      marginTop: 16px;
    }

    &-list-button {
      padding: .25rem 1rem;
    }

    &-list-button span, p {
      font:
        normal 400 14px/20px "Poppins",
        sans-serif;
    }

    &-link {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.sysSurfaceVariant};
      cursor: pointer;
    }

    .active span {
      font-weight: 500;
    }
  }

  .content {
    max-height: calc(100vh - 70px);
    padding-top: 2rem;
    overflow-y: scroll;

    &::-webkit-scrollbar { 
      width: auto;
    }

    &::-webkit-scrollbar-track { 
      background: transparent;
    }

    &::-webkit-scrollbar-thumb { 
      background: transparent;
    }

    a {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.primaryColor}
    }

    h1, 
    h2,
    h4,
    p, 
    iframe, 
    ol, 
    ul, 
    video {
      padding-bottom: 20px;
    }

    ol, ul {
      padding-right: 2rem;
      padding-left: 2rem;
    }

    &-image-grid {
      p {
        padding: 0;
      }
    }

    &-icon-grid {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      text-align: center;
      word-break: break-word;
      width: 100%;
      margin: 3rem 0;

      > div {
        padding-right: 10px;
        max-width: 300px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .content-footer{
    display: flex;
    flex-direction: column;
    margin: 50px 0 20px;
    padding: 2.5rem 0 1rem;
    gap: 50px;
    border-top: 1px solid ${({ theme }) => theme.colors.lightGrey};
    
    p {
      padding-bottom: 0
    }
  }

  .nav-link {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.sysSurfaceVariant};
    font:
      normal 500 14px/20px "Poppins",
      sans-serif;
    letter-spacing: 0.1px;
    &:hover {
      cursor: pointer;
    }
  }
`;

// databoat site nav styling
export const Flex = styled.div`
  display: flex;
  gap: ${(props) => (props.gap ? "0.25em" : "1em")};
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  flex: ${(props) => (props.equalWidth ? "1" : null)};
  justify-content: ${(props) => (props.center ? "center" : null)};
  align-items: ${(props) => (props.centerAlign ? "center" : null)};
  background-color: ${(props) => (props.bg ? "#fff" : null)};
  flex-wrap: wrap;
`;

export const StyledSideNavigation = styled.div`
  background-color: ${({ theme }) => theme.colors.whiteColor};
  border-right: 1px solid ${({ theme }) => theme.colors.lightGrey};
  height: 100vh;
  position: sticky;
`;

export const StyledNavigationListItems = styled.div`
  display: flex;
  gap: 0.5em;
  padding: 3rem 1rem 2rem;
  flex-direction: column;
  transition: all 1s ease-in-out;

  .menu-list-container {
    display: flex;
    flex-wrap: wrap;

    > div {
      padding: 0 2px;
    }

    .icons {
      width: 16px;
      height: 16px;
    }

    &.active,
    &.active a {
      color: ${({ theme }) => theme.colors.blueColor};
    }
  }

  /* coincides with icons size*/
  .no-dropdown {
    padding-left: 16px;
  }

  .title-container {
    display: flex;
    gap: 0.5em;
    padding: 0.25em;
  }

  .nav-link {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primaryColor};
    font:
      normal 500 14px/20px "Poppins",
      sans-serif;
    letter-spacing: 0.1px;
    padding: 0.25em;

    &:hover {
      cursor: pointer;
    }

    &:active {
      color: ${({ theme }) => theme.colors.primaryColor};
    }
  }

  .icons {
    width: 24px;
    height: 24px;
    margin-top: 0.5em;

    &:hover {
      cursor: pointer;
    }
  }

  .nav-active {
    border-radius: 4px;
    align-self: stretch;
    background: ${({ theme }) => theme.colors.lightBlue};

    a,
    svg {
      color: ${({ theme }) => theme.colors.blueColor};
    }
  }

  .options-container {
    border-radius: 16px;
  }

  .option-flex {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .option-title {
    text-decoration: none;
    margin-top: 0.75em;
    color: ${({ theme }) => theme.colors.sysSurfaceTextColor};
    font:
      normal 500 12px/16px "Poppins",
      sans-serif;
    letter-spacing: 0.4px;
    align-self: stretch;
  }
`;
