import React from "react";
import { Tooltip } from "react-tooltip";
import { StyledConnectorInput, Flex, AiOutlineInfoCircle } from "../index";

const RenderQuery = ({
  dataQueries,
  queryValues,
  handleQueriesTableNameChange,
  handleQueriesChange,
  checkboxValues,
  handleCheckboxChange,
  queryPartitionValue,
  taskId,
  handleQueryPartitionChange,
  handleAddMore,
}) => {
  return (
    <>
      <Flex column>
        {dataQueries.map((data) => (
          <Flex key={data.id} column>
            <div style={{ display: "flex", gap: "1em" }}>
              <p>#{data.id}</p>
              <AiOutlineInfoCircle
                className="my-anchor-element info-icon"
                size={"1.5em"}
              />
              <Tooltip
                anchorSelect=".my-anchor-element"
                place="top"
                className="hover-info-container"
              >
                <Flex>
                  <span>Query can be only be</span>
                  <span>executed from 1 database...!</span>
                </Flex>
              </Tooltip>
              <StyledConnectorInput
                style={{ padding: "0.5em", width: "50%" }}
                type="text"
                className="input_border"
                defaultValue={""}
                placeholder={"Enter your table name"}
                onChange={(event) =>
                  handleQueriesTableNameChange(event, data.id, taskId)
                }
              />
              <StyledConnectorInput
                style={{ padding: "0.5em", width: "50%" }}
                type="text"
                className="input_border"
                defaultValue={""}
                placeholder={"Enter your Query"}
                onChange={(event) =>
                  handleQueriesChange(event, data.id, taskId)
                }
              />
              <div style={{ display: "flex", gap: "1em" }}>
                <input
                  type="checkbox"
                  defaultChecked={""}
                  onChange={(event) =>
                    handleCheckboxChange(event, data.id, taskId)
                  }
                />
                <p style={{ marginTop: "10px" }}>Partition</p>
              </div>
              {data.partitioning && (
                <StyledConnectorInput
                  style={{ padding: "0.5em", width: "50%" }}
                  type="text"
                  defaultValue={""}
                  placeholder={"Enter your Partion Value"}
                  onChange={(event) =>
                    handleQueryPartitionChange(event, data.id, taskId)
                  }
                />
              )}
            </div>
          </Flex>
        ))}
        <div>
          <button onClick={handleAddMore} className={"next_btn save_btn"}>
            Add Queries
          </button>
        </div>
      </Flex>
    </>
  );
};

export default RenderQuery;
