import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  elements: [],
  mousePosition: { x: 0, y: 0, index: 0 },
  overDragArea: false,
  overDraggableTable: false,
  overElementIndex: -1,
};

const checkOverlap = (rect1, rect2) => {
  return !(
    rect1.right < rect2.left ||
    rect1.left > rect2.right ||
    rect1.bottom < rect2.top ||
    rect1.top > rect2.bottom
  );
};

const elementsSlice = createSlice({
  name: "elementsDnd",
  initialState,
  reducers: {
    addElement: (state, action) => {
      state.elements.push(action.payload);
    },
    clearElements: (state) => {
      state.elements = []; // Reset elements array to empty
    },
    updateElementPosition: (state, action) => {
      const { index, x, y, width, height } = action.payload;
      if (state.elements[index]) {
        state.elements[index].props.x = x;
        state.elements[index].props.y = y;
        state.elements[index].props.width = width;
        state.elements[index].props.height = height;

        state.elements[index].isSobreposto = false;

        // Verificar sobreposição com outros elementos
        state.elements.forEach((element, i) => {
          if (i !== index) {
            const rect1 = {
              left: x,
              top: y,
              right: x + width,
              bottom: y + height,
              width,
              height,
            };
            const rect2 = {
              left: element.props.x,
              top: element.props.y,
              right: element.props.x + element.props.width,
              bottom: element.props.y + element.props.height,
              width: element.props.width,
              height: element.props.height,
            };
            const isOverlapping = checkOverlap(rect1, rect2);
            if (isOverlapping) {
              // Combinar atributos e atualizar estado
              const combinedAttributes = [
                ...element.props.attributes,
                ...state.elements[index].props.attributes,
              ];
              element.props.attributes = combinedAttributes.filter(
                (attr, index, self) =>
                  index === self.findIndex((a) => a.column === attr.column),
              );

              // Remover o elemento duplicado
              state.elements.splice(index, 1);
            }
          }
        });
      }
    },
    deleteElement: (state, action) => {
      const { index } = action.payload;
      state.elements = state.elements.filter((el) => el.props.index !== index);
    },
    addMousePosition: (state, action) => {
      const { x, y, index } = action.payload;
      state.mousePosition.x = x;
      state.mousePosition.y = y;
      state.mousePosition.index = index;
    },
    updateOverElementIndex: (state, action) => {
      const { index, x, y, width, height } = action.payload;
      if (index !== state.overElementIndex) state.overElementIndex = index;
      if (state.elements[index]) {
        state.elements[index].props.x = x;
        state.elements[index].props.y = y;
        state.elements[index].props.width = width;
        state.elements[index].props.height = height;

        state.elements[index].isSobreposto = false;

        // Verificar sobreposição com outros elementos
        state.elements.forEach((element, i) => {
          if (i !== index) {
            const rect1 = {
              left: x,
              top: y,
              right: x + width,
              bottom: y + height,
              width,
              height,
            };
            const rect2 = {
              left: element.props.x,
              top: element.props.y,
              right: element.props.x + element.props.width,
              bottom: element.props.y + element.props.height,
              width: element.props.width,
              height: element.props.height,
            };
            const isOverlapping = checkOverlap(rect1, rect2);
            if (isOverlapping) {
              // Combinar atributos e atualizar estado
              const combinedAttributes = [
                ...element.props.attributes,
                ...state.elements[index].props.attributes,
              ];
              element.props.attributes = combinedAttributes.filter(
                (attr, index, self) =>
                  index === self.findIndex((a) => a.column === attr.column),
              );

              // Remover o elemento duplicado
              state.elements.splice(index, 1);
            }
          }
        });
      }
    },
    updateElement: (state, action) => {
      const { elementIndex, newValues } = action.payload;
      const elementToUpdate = state.elements[elementIndex];
      elementToUpdate.props = { ...elementToUpdate.props, ...newValues };
      state.elements[elementIndex] = elementToUpdate;
    },
  },
});

export const {
  addElement,
  clearElements,
  updateElementPosition,
  deleteElement,
  addMousePosition,
  updateOverElementIndex,
  updateElement,
} = elementsSlice.actions;
export default elementsSlice.reducer;
