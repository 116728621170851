import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import ReactPlayer from "react-player";
import DocumentPageLayout from "../DocumentPageLayout";

const BoatGPT = () => {
  const { t } = useTranslation();
  const contentArr = [
    {
      name: t("general.boat_gpt"),
      link: "#boat-gpt",
    },
  ];

  return (
    <DocumentPageLayout breadcrumbs={contentArr}>
      <Grid container>
        <Grid item>
          <h1 id="boat-gpt">BoatGPT</h1>

          <p>
            Welcome to BoatGPT! With our new feature, you can easily query your
            data using a powerful LLM (Large Language Model) approach.
          </p>

          <p>
            Step 1: To get started, head over to the left menu and click on the
            BoatGPT icon.
          </p>
          <p>
            Step 2: In the BoatGPT interface, simply type your question about
            the data into the text field and press enter.
          </p>
          <p>
            Step 3: The result will instantly appear in a table format, listing
            the data based on your query.
          </p>
          <p>
            Step 4: If you want to further analyze the data, you can easily
            export the result to a text file for additional review.
          </p>
          <p>
            Step 5: If the insight you've gained can benefit a department or
            support an existing report, you can save it and even schedule it to
            run automatically.
          </p>
          <p>
            Step 6: Just give your script a name, select a folder that
            represents the data layer where it should be saved, and choose a
            frequency, such as daily, if needed.
          </p>
          <p>
            Step 7: Once the job is defined, save it, and your insight will be
            ready to use in your data lake.
          </p>
          <p>
            With BoatGPT, turning data into actionable insights has never been
            easier. Start exploring your data today!
          </p>

          <ReactPlayer
            url="https://vid.cdn-website.com/b29d841e/videos/xxsbTgvBQ7yYoBZqQUXt_Screen+Recording+2024-08-02+at+17.05.42-v.mp4"
            controls={true}
          />
        </Grid>
      </Grid>
    </DocumentPageLayout>
  );
};

export default BoatGPT;
