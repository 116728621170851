import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const DataTypeButton = styled(Button)(({ selected, theme }) => {
  return {
    backgroundColor: selected ? "rgba(0, 99, 153, 0.12)" : theme.colors.white,
    color: theme.colors.blueColor,
    flex: 1,
    width: "100%",
    border: `1px solid ${theme.colors.borderColor}`,
    borderRadius: "50px",

    "&:active": {
      backgroundColor: selected
        ? theme.colors.stateLayersOnSurfaceOpacity012
        : theme.colors.whiteColor,
      color: selected ? theme.colors.blueColor : theme.colors.whiteColor,
    },
    "&:hover": {
      backgroundColor: selected
        ? theme.colors.blueColor
        : theme.colors.stateLayersOnSurfaceOpacity012,
      color: selected ? theme.colors.whiteColor : theme.colors.blueColor,
    },
  };
});

const ColorButton = styled(Button)(({ theme }) => {
  return {
    backgroundColor: theme.colors.blueColor,
    width: "100%",
    padding: "10px 24px 10px 24px",
    borderRadius: "100px",
    "&:hover": {
      backgroundColor: "theme.colors.blueColor",
      color: "#222",
      boxShadow:
        "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
    },
  };
});

const MuiButton = ({ text, onClick, type }) => {
  return (
    <ColorButton
      variant="contained"
      data-testid="mui_test_button"
      onClick={onClick}
      type={type}
    >
      {text}
    </ColorButton>
  );
};

export { DataTypeButton, MuiButton };
