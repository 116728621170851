import React, { useState } from "react";
import { StyledDashboardContentFlex, FieldSet } from "../index";
const UIFormat = ({
  paramKey,
  keyPlaceholder,
  handleKeyChange,
  paramValue,
  valuePlaceholder,
  handleValueChange,
}) => {
  const [isparamValueFocused, setIsparamValueFocused] = useState(false);
  const [isparamkeyFocused, setIsparamkeyFocused] = useState(false);
  return (
    <StyledDashboardContentFlex>
      <div style={{ width: "100%" }}>
        <FieldSet
          legendTitle={"Key*"}
          type={"text"}
          value={paramKey}
          onChange={handleKeyChange}
          placeholder={keyPlaceholder}
          isInputFocused={isparamkeyFocused}
          onFocus={() => setIsparamkeyFocused(true)}
          onBlur={() => setIsparamkeyFocused(false)}
        />
      </div>
      <div style={{ width: "100%" }}>
        <FieldSet
          legendTitle={"Value*"}
          type={"text"}
          value={paramValue}
          onChange={handleValueChange}
          placeholder={valuePlaceholder}
          isInputFocused={isparamValueFocused}
          onFocus={() => setIsparamValueFocused(true)}
          onBlur={() => setIsparamValueFocused(false)}
        />
      </div>
    </StyledDashboardContentFlex>
  );
};

export default UIFormat;
