import React from "react";
import { ConnectorScreens, StyledDashboardContentFlex } from "../../../index";
import { useTranslation } from "react-i18next";

const PostgressSQLConnector = ({
  inputData,
  postgresDataValues,
  handlepostgresServerValuesChange,
  postgresError,
  setQueryDBName,
  handlepostgresqueryDataBaseName,
  PostgresSQLIngestions,
  postgresselectedOption,
  taskId,
  handlepostgresSelectChange,
  postgresoptionsArray,
  postgresServerdataQueries,
  handlepostgresQueriesTableNameChange,
  postgresqueries,
  handlepostgresQueriesChange,
  postgrescheckboxValues,
  handlepostgresCheckboxChange,
  postgrespartitonColValue,
  handlepostgresQueryPartitionChange,
  addMorepostgresServerQueries,
  postgrestableHead,
  postgrestableData,
  handlepostgresDatabaseNameChange,
  postgresdatabaseNameLoader,
  postgresdatabaseName,
  handlepostgresSchemaNameChange,
  postgresschemas,
  handlepostgresTableNameChange,
  postgrestables,
  handlepostgresTableIngestModeOption,
  postgrestableOptions,
  handlepostgresDeltaColumnChange,
  postgresdeltaColumn,
  handlepostgresPartionChange,
  handlepostgresPartitioningChange,
  postgrespartitionColumn,
  postgresaddRow,
  onClose,
}) => {
  const { t } = useTranslation();

  const useSchemas = true;
  const useTableformat = true;
  return (
    <StyledDashboardContentFlex column>
      <ConnectorScreens
        HeaderText={t(
          "jobs.form.inputs.task.modal.connectors.server.postgres.title",
        )}
        inputData={inputData}
        data={postgresDataValues}
        handleChange={handlepostgresServerValuesChange}
        errors={postgresError}
        setQueryDBName={setQueryDBName}
        handlequeryDataBaseName={handlepostgresqueryDataBaseName}
        ingestions={PostgresSQLIngestions}
        htmlFor={"Ingestions"}
        selectTitle={"Ingestions"}
        selectedOption={postgresselectedOption}
        handleSelected={handlepostgresSelectChange}
        options={postgresoptionsArray}
        dataQueries={postgresServerdataQueries}
        handleQueriesTableNameChange={handlepostgresQueriesTableNameChange}
        queryValues={postgresqueries}
        taskId={taskId}
        handleQueriesChange={handlepostgresQueriesChange}
        checkboxValues={postgrescheckboxValues}
        handleCheckboxChange={handlepostgresCheckboxChange}
        queryPartitionValue={postgrespartitonColValue}
        handleQueryPartitionChange={handlepostgresQueryPartitionChange}
        handleAddMore={addMorepostgresServerQueries}
        tableHead={postgrestableHead}
        tableData={postgrestableData}
        handleDatabaseNameChange={handlepostgresDatabaseNameChange}
        databaseNameLoader={postgresdatabaseNameLoader}
        databaseName={postgresdatabaseName}
        handleSchemaNameChange={handlepostgresSchemaNameChange}
        useSchemas={useSchemas}
        schemas={postgresschemas}
        handleTableNameChange={handlepostgresTableNameChange}
        tables={postgrestables}
        handleTableIngestModeOption={handlepostgresTableIngestModeOption}
        tableOptions={postgrestableOptions}
        handleDeltaColumnChange={handlepostgresDeltaColumnChange}
        deltaColumn={postgresdeltaColumn}
        handlePartionChange={handlepostgresPartionChange}
        handlePartitioningChange={handlepostgresPartitioningChange}
        partitionColumn={postgrespartitionColumn}
        useTableformat={useTableformat}
        addRow={postgresaddRow}
        btnOnClick={onClose}
        buttonText={t("jobs.form.inputs.task.modal.button")}
      />
    </StyledDashboardContentFlex>
  );
};

export default PostgressSQLConnector;
