import { Grid, SignUpForm, SideBar } from "../index";

const SignUp = () => {
  return (
    <Grid>
      <SignUpForm />
      <SideBar />
    </Grid>
  );
};

export default SignUp;
