import React, { useState } from "react";
import {
  Stack,
  TextField,
  InputLabel,
  Tooltip,
  tooltipClasses,
  Checkbox,
  Tab,
  Typography,
  InputAdornment,
  IconButton,
  Box,
  CircularProgress,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { styled } from "@mui/material/styles";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useTranslation } from "react-i18next";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
    maxWidth: "fit-content",
  },
}));

const CustomTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  "&.Mui-selected": {
    color: theme.colors.modalHeaderBgColor,
  },
}));

const CustomCheckbox = ({
  text,
  checked,
  handleChecked,
  expanded,
  handleExpand,
}) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Checkbox
        checked={checked}
        onChange={handleChecked}
        sx={(theme) => ({
          color: theme.colors.modalHeaderBgColor,
          "&.Mui-checked": {
            color: theme.colors.modalHeaderBgColor,
          },
        })}
      />
      {expanded !== undefined ? (
        <Checkbox
          checked={expanded}
          onChange={handleExpand}
          icon={<KeyboardArrowRightIcon />}
          checkedIcon={<KeyboardArrowDownIcon />}
          sx={(theme) => ({
            color: theme.colors.teritaryTextColor,
            "&.Mui-checked": {
              color: theme.colors.modalHeaderBgColor,
            },
          })}
        />
      ) : null}
      <Typography>{text}</Typography>
    </Stack>
  );
};

const ObjectItem = ({
  object,
  expanded,
  handleExpand,
  checked,
  handleChecked,
  parent,
}) => {
  const currentPath = `${parent ? `${parent}.` : ""}${object.name}`;

  return (
    <>
      <CustomCheckbox
        text={object.name}
        expanded={expanded?.has(object.name)}
        handleExpand={() => handleExpand(object.name)}
        checked={checked?.has(currentPath)}
        handleChecked={() => handleChecked(object, parent)}
      />

      {expanded?.has(object.name) && (
        <Stack ml={4}>
          {object.attrs?.map((attr) =>
            attr.attrs?.length ? (
              <ObjectItem
                key={attr.name}
                object={attr}
                expanded={expanded}
                handleExpand={handleExpand}
                checked={checked}
                handleChecked={handleChecked}
                parent={currentPath}
              />
            ) : (
              <CustomCheckbox
                text={attr.name}
                key={attr.name}
                checked={checked?.has(`${currentPath}.${attr.name}`)}
                handleChecked={() => handleChecked(attr, currentPath)}
              />
            ),
          )}
        </Stack>
      )}
    </>
  );
};

const ActiveCampaignConnector = ({
  onClose,
  objects,
  apiKey,
  setApiKey,
  search,
  handleSearch,
  selected,
  handleAttrSelection,
  expanded,
  handleExpand,
  handleAdd,
  url,
  setUrl,
}) => {
  const [tab, setTab] = useState("1");
  const { t } = useTranslation();

  const handleClose = () => {
    handleAdd();
    onClose();
  };

  return (
    <Stack p={4}>
      <Stack spacing={4}>
        <Stack spacing={1}>
          <Stack direction="row" spacing={1}>
            <InputLabel htmlFor="apiKey">
              Inform the API Key from Personal Preferences
            </InputLabel>

            <HtmlTooltip
              title={
                <img
                  src="../../../../images/activecampaing-api-key-print.jpeg"
                  height={400}
                  alt="Active Campaign API Key"
                />
              }
            >
              <InfoIcon
                sx={(theme) => ({ color: theme.colors.modalHeaderBgColor })}
              />
            </HtmlTooltip>
          </Stack>

          <TextField
            id="apiKey"
            placeholder="Enter your API Key"
            type="text"
            variant="outlined"
            fullWidth
            onChange={(e) => setApiKey(e.target.value)}
            value={apiKey}
          />
        </Stack>

        <Stack spacing={1}>
          <Stack direction="row" spacing={1}>
            <InputLabel htmlFor="url">
              Inform the URL from Personal Preferences
            </InputLabel>

            <HtmlTooltip
              title={
                <img
                  src="../../../../images/activecampaing-api-key-print.jpeg"
                  height={400}
                  alt="Active Campaign URL"
                />
              }
            >
              <InfoIcon
                sx={(theme) => ({ color: theme.colors.modalHeaderBgColor })}
              />
            </HtmlTooltip>
          </Stack>

          <TextField
            id="url"
            placeholder="Enter your URL"
            type="text"
            variant="outlined"
            fullWidth
            onChange={(e) => setUrl(e.target.value)}
            value={url}
          />
        </Stack>
      </Stack>

      <Typography
        variant="h5"
        mt={6}
        sx={(theme) => ({ color: theme.colors.modalHeaderBgColor })}
      >
        Object
      </Typography>

      <TextField
        placeholder="Search object by name"
        type="text"
        variant="outlined"
        fullWidth
        size="small"
        onChange={(e) => handleSearch(e.target.value)}
        value={search}
        sx={{ mt: 2 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),

          endAdornment: search && (
            <IconButton onClick={() => handleSearch("")}>
              <CancelIcon />
            </IconButton>
          ),
        }}
      />

      <Stack spacing={2} mt={2}>
        <TabContext value={tab}>
          <TabList
            value={tab}
            onChange={(e, newValue) => setTab(newValue)}
            TabIndicatorProps={{ style: { backgroundColor: "#003352" } }}
          >
            <CustomTab label="All" value="1" />
            <CustomTab label="Enabled Objects" value="2" />
            <CustomTab label="Disabled Objects" value="3" />
          </TabList>

          <TabPanel value="1" sx={{ p: 0, mt: "0 !important" }}>
            {objects ? (
              objects.length ? (
                objects?.map((object) => (
                  <ObjectItem
                    key={object.name}
                    object={object}
                    expanded={expanded}
                    handleExpand={handleExpand}
                    checked={selected}
                    handleChecked={handleAttrSelection}
                  />
                ))
              ) : (
                <Box display="flex" justifyContent="center" my={10}>
                  <Typography>No objects found</Typography>
                </Box>
              )
            ) : (
              <Box display="flex" justifyContent="center" my={10}>
                <CircularProgress />
              </Box>
            )}
          </TabPanel>

          <TabPanel value="2" sx={{ p: 0, mt: "0 !important" }}>
            {objects ? (
              objects.filter((object) => selected?.has(object.name)).length ? (
                objects
                  .filter((object) => selected?.has(object.name))
                  .map((object) => (
                    <ObjectItem
                      key={object.name}
                      object={object}
                      expanded={expanded}
                      handleExpand={handleExpand}
                      checked={selected}
                      handleChecked={handleAttrSelection}
                    />
                  ))
              ) : (
                <Box display="flex" justifyContent="center" my={10}>
                  <Typography>No objects found</Typography>
                </Box>
              )
            ) : (
              <Box display="flex" justifyContent="center" my={10}>
                <CircularProgress />
              </Box>
            )}
          </TabPanel>

          <TabPanel value="3" sx={{ p: 0, mt: "0 !important" }}>
            {objects ? (
              objects.filter((object) => !selected?.has(object.name)).length ? (
                objects
                  .filter((object) => !selected?.has(object.name))
                  .map((object) => (
                    <ObjectItem
                      key={object.name}
                      object={object}
                      expanded={expanded}
                      handleExpand={handleExpand}
                      checked={selected}
                      handleChecked={handleAttrSelection}
                    />
                  ))
              ) : (
                <Box display="flex" justifyContent="center" my={10}>
                  <Typography>No objects found</Typography>
                </Box>
              )
            ) : (
              <Box display="flex" justifyContent="center" my={10}>
                <CircularProgress />
              </Box>
            )}
          </TabPanel>
        </TabContext>

        <div>
          <button className={"next_btn save_btn"} onClick={handleClose}>
            {t("jobs.form.inputs.task.modal.button")}
          </button>
        </div>
      </Stack>
    </Stack>
  );
};

export default ActiveCampaignConnector;
