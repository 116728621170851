export const apiDataMapperBasedOnTask = (apiData, task) => {
    return apiData.map(val => {
        return {
            ...val,
            defaultValue: task.params[val.name === 'api_table_name' ? 'table_name' : val.name] || ''
        }
    })
}

export const s3DataMapperBasedOnTask = (s3Data, task) => {
    return s3Data.map(val => {
        switch (val.name) {
            case 'fileFormat':
                return {...val, defaultValue: task?.params?.file_format}
            case 'header':
                return {...val, defaultValue: task?.params?.header}
            case 'seperator':
                return {...val, defaultValue: task?.params?.separator}
            case 'charset':
                return {...val, defaultValue: task?.params?.charset}
            case 'aws_access_key_id':
                return {...val, defaultValue: task?.params?.credentials_json?.aws_access_key_id}
            case 'aws_secret_access_key':
                return {...val, defaultValue: task?.params?.credentials_json?.aws_secret_access_key}
            case 'aws_session_token':
                return {...val, defaultValue: task?.params?.credentials_json?.aws_session_token}
            default:
                return val;
        }
    })
}

export const oracleDataMapperBasedOnTask = (oracleData, task) => {
    return oracleData.map(val => {
        switch (val.name) {
            case 'host':
                return {...val, defaultValue: task?.params?.input_db_host}
            case 'port':
                return {...val, defaultValue: task?.params?.input_db_port}
            case 'user':
                return {...val, defaultValue: task?.params?.input_db_user}
            case 'password':
                return {...val, defaultValue: task?.params?.input_db_pass}
            case 'service_name':
                return {...val, defaultValue: task?.params?.query_db_name}
            default:
                return val;
        }
    })
}
export const gcsDataMapperBasedOnTask = (gcsData, task) => {
    return gcsData.map(val => {
        switch (val.name) {
            case 'fileFormat':
                return {...val, defaultValue: task?.params?.file_format}
            case 'header':
                return {...val, defaultValue: task?.params?.header}
            case 'seperator':
                return {...val, defaultValue: task?.params?.separator}
            case 'charset':
                return {...val, defaultValue: task?.params?.charset}
            default:
                return val;
        }
    })
}

export const exactDataMapperBasedOnTask = (s3Data, task) => {
    return s3Data.map(val => {
        switch (val.name) {
            case 'aws_access_key_id':
                return {...val, defaultValue: task?.params?.credentials_json?.aws_access_key_id}
            case 'aws_secret_access_key':
                return {...val, defaultValue: task?.params?.credentials_json?.aws_secret_access_key}
            case 'aws_session_token':
                return {...val, defaultValue: task?.params?.credentials_json?.aws_session_token}
            case 'division_var':
                return {...val, defaultValue: task?.params?.division_var}
            default:
                return val;
        }
    })
}

export const mappedTaskSourceType = {
    'Postgres SQL': 'postgresql',
    'PipeDrive': 'pipedrive',
    'ActiveCampaign': 'activecampaign',
    'Big Query': 'bq',
    'S3': 'csv',
    'Oracle': 'oracleql',
    'Exact Online': 'excat',
    'GCS': 'gcs'
}
