import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import {
  postAllJobs,
  warmUpFetchDatabaseName,
} from "../../../redux/actions/usersActions";
import useS3 from "../../connectors/customHook/useS3";
import useSQL from "../../connectors/customHook/useSQL";
import useAPI from "../../connectors/customHook/useAPI";
import useMYSQL from "../../connectors/customHook/useMYSQL";
import usePostgress from "../../connectors/customHook/usePostgress";
import useGCS from "../../connectors/customHook/useGCS";
import useBigQuery from "../../connectors/customHook/useBigQuery";
import { useTranslation } from "react-i18next";
import useOracle from "../../connectors/customHook/useOracle";
import useExcat from "../../connectors/customHook/useExact";
import useGetToken from "../../../cognito/useGetToken";
import usePipedrive from "../../connectors/customHook/usePipeDrive";
import { useActiveCampaing } from "../../connectors/customHook/useActiveCampaign";

const useCreateJobHook = () => {
  const group = localStorage.getItem("group");
  const userEmail = localStorage.getItem("created");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { environmentVariables } = useSelector((state) => state.users);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [startDate, setStartDate] = useState("");
  const [frequencyOption, setFrequencyOption] = useState("");
  const [startHourMinutes, setStartHourMinutes] = useState("");
  const [dailyTime, setDailyTime] = useState("");
  const [startWeeklyTime, setStartWeeklyTime] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedText, setSelectedText] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [reversedModalOpen, setReversedModalOpen] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [jobName, setJobName] = useState("");
  const [isConnectorModalOpen, setIsConnectorModalOpen] = useState(false);
  const [isReversedConnectorOpen, setIsReversedConnectorOpen] = useState(false);
  const [alertValue, setAlertValue] = useState("");
  const [tags, setTags] = useState("");
  const [emailto, setEmailto] = useState("");
  const [emailcc, setEmailcc] = useState("");
  const [emailmessage, setEmailMessage] = useState("");
  const [slackURL, setSlackURL] = useState("");
  const [teamsURL, setTeamsURL] = useState("");
  const [emailsubject, setEmailSubject] = useState("");
  const setQueryDBName = useState(true);
  const inputData = useState(true);
  const [mysqlIngestions, setMYSQLIngestions] = useState(false);
  const [sqlIngestions, setSQLIngestions] = useState(false);
  const [PostgresSQLIngestions, setPostgresSQLIngestions] = useState(false);
  const [OracleIngestions, setOracleIngestions] = useState(false);
  const { token } = useGetToken();
  const { t } = useTranslation();
  const label = t("jobs.form.inputs.frequency.select.label");
  const hourly = t("jobs.form.inputs.frequency.select.hourly");
  const daily = t("jobs.form.inputs.frequency.select.daily");
  const weekly = t("jobs.form.inputs.frequency.select.weekly");

  const { userScript } = useSelector((state) => state.customers);

  const handleJobNameChange = (event) => {
    setJobName(event.target.value);
  };

  //start date
  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleTimeChange = (event) => {
    const timeChange = event.target.value;
    // Check if the input value matches the time format "HH:mm"
    const timeRegex = /^(?:[01]\d|2[0-3]):[0-5]\d$/;
    if (timeRegex.test(timeChange) || timeChange === "") {
      setTime(timeChange);
    }
  };

  useEffect(() => {
    if (date && time) {
      const combinedDateTime = new Date(`${date}T${time}`);
      setStartDate(combinedDateTime.toISOString());
    } else {
      setStartDate("");
    }
  }, [date, time]);

  const handleStartHourMinutesChange = (e) => {
    setStartHourMinutes(e.target.value);
  };

  const handleDailyTimeChange = (e) => {
    setDailyTime(e.target.value);
  };

  const handleWeeklyStartTimeChange = (e) => {
    setStartWeeklyTime(e.target.value);
  };

  const handleDayToggle = (day) => {
    const isSelected = selectedDays.includes(day);

    if (isSelected) {
      setSelectedDays(
        selectedDays.filter((selectedDay) => selectedDay !== day),
      );
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };
  //------- ends here

  //frequency
  const handleFrequencyOption = (e) => {
    const selected = e.target.value;
    setFrequencyOption(selected);
  };
  const frequencydata = [
    { id: 1, title: label, option: "" },
    { id: 2, title: hourly, option: "Hourly" },
    { id: 3, title: daily, option: "Daily" },
    { id: 4, title: weekly, option: "Weekly" },
  ];

  let schedulerResult = "";
  if (date) {
    if (frequencyOption === "Hourly") {
      const cronExpression = format(
        new Date(`${date}T00:00`),
        `${startHourMinutes} * * * *`,
      );
      schedulerResult = cronExpression;
    } else if (frequencyOption === "Daily") {
      const [hours, minutes] = dailyTime ? dailyTime.split(":") : ["00", "00"];
      const cronExpression = `${minutes} ${hours} * * *`;
      schedulerResult = cronExpression;
    } else if (frequencyOption === "Weekly") {
      const cronExpression = format(
        new Date(`${date}T${startWeeklyTime ? startWeeklyTime : "00:00"}`),
        "m H * *",
      );
      const selectedDaysExpression = selectedDays
        .map((day) => day.substring(0, 3))
        .join(",");
      schedulerResult = `${cronExpression} ${selectedDaysExpression}`;
    }
  }
  // --------- ends here

  //task
  const handleTaskNameChange = (event, id, data) => {
    const selected = event.target.value;
    const updatedData = taskData.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          task_name: selected,
        };
      }
      return data;
    });
    setTaskData(updatedData);
  };
  const handleTaskTypeChange = (event, id) => {
    const selected = event.target.value;
    const updatedData = taskData.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          task_type: selected,
          source_type: selected === "transformation" && "sql",
          ...(selected === "ingestion" && { source: "" }),
          ...(selected === "ingestion" && { code_path: "" }),
        };
      }
      return data;
    });
    setTaskData(updatedData);
    setIsConnectorModalOpen(selected === "ingestion");
    setIsReversedConnectorOpen(selected === "reversed_sync_pipedrive");
  };
  const handleScriptNameChange = (id, newValue) => {
    if (!newValue) {
      return;
    }
    const [folderName, scriptName] = newValue.label.split("/");

    let scriptValue = null;

    userScript.forEach((item) => {
      if (item.folderName === folderName) {
        const foundScript = item.scripts.find(
          (script) => script.scriptName === scriptName,
        );
        if (foundScript) {
          scriptValue = foundScript.script;
        }
      }
    });

    const updatedData = taskData.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          scriptName: newValue.label ? `${userEmail}/${newValue.label}` : "",
          script: scriptValue,
        };
      }
      return data;
    });
    setTaskData(updatedData);
  };

  const handleSourceFieldChange = (event, id) => {
    const selected = event.target.value;
    const updatedData = taskData.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          source: selected,
        };
      }
      return data;
    });
    setTaskData(updatedData);
  };

  const handleCodePathChange = (fileName, id) => {
    const updatedData = taskData.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          code_path: fileName,
        };
      }
      return data;
    });
    setTaskData(updatedData);
  };

  const handleParametersTypeChange = (event, id) => {
    const selected = event.target.value;
    const updatedData = taskData.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          parameters_type: selected,
        };
      }
      return data;
    });
    setTaskData(updatedData);
  };

  //UI FORMAT
  const handleKeyChange = (event, id) => {
    const selected = event.target.value;
    const updatedTaskData = taskData.map((data) => {
      if (data.id === id) {
        data.parameters.map((item) => {
          item.key = selected;
          return item;
        });
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };
  const handleValueChange = (event, id) => {
    const selected = event.target.value;
    const updatedTaskData = taskData.map((data) => {
      if (data.id === id) {
        data.parameters.map((item) => {
          item.value = selected;
          return item;
        });
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };
  //--------ENDS HERE
  //JSON FORMAT

  const [jsonData, setJsonData] = useState({
    key: "",
    value: "",
  });

  const handleJsonDataChange = (event, id) => {
    const newText = event.target.value;
    try {
      const parsedData = JSON.parse(newText);
      const updatedTaskData = taskData.map((data) => {
        if (data.id === id) {
          data.parameters.map((item) => {
            item.key = jsonData.key;
            item.value = jsonData.value;
            return item;
          });
        }
        return data;
      });
      setJsonData(parsedData);
      setTaskData(updatedTaskData);
    } catch (error) {
      console.error("Invalid JSON:", error);
    }
  };

  const formattedJson = JSON.stringify(jsonData, undefined, 2);
  //---------ENDS HERE

  const handleTagsChange = (event) => {
    setTags(event.target.value);
  };
  const handleAlertValueChange = (event) => {
    setAlertValue(event.target.value);
  };
  const handleEmailccChange = (event) => {
    setEmailcc(event.target.value);
  };
  const handleEmailToChange = (event) => {
    setEmailto(event.target.value);
  };
  const handleEmailMessageChange = (event) => {
    setEmailMessage(event.target.value);
  };
  const handleEmailSubjectChange = (event) => {
    setEmailSubject(event.target.value);
  };
  const handleSlackURLChange = (event) => {
    setSlackURL(event.target.value);
  };
  const handleTeamsURLChange = (event) => {
    setTeamsURL(event.target.value);
  };
  // --------- ends here

  //open connector modals
  const openModal = (component, text) => {
    setSelectedText(text);
    setSelectedComponent(component);
    if (text === "MySQL" || text === "SQL Server" || text === "Postgres SQL") {
      dispatch(
        warmUpFetchDatabaseName(
          environmentVariables.REACT_APP_TABLE_API,
          token,
        ),
      );
    }
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedComponent(null);
    setModalOpen(false);
    setIsConnectorModalOpen(false);
  };

  const onClose = () => {
    setIsConnectorModalOpen(false);
    setIsReversedConnectorOpen(false);
    setModalOpen(false);
    setSelectedComponent(null);
  };
  const openReversedModal = (component, text, taskId) => {
    setSelectedText(text);
    setSelectedComponent(component);
    setReversedModalOpen(true);
    localStorage.setItem("currentTaskId", taskId);
  };
  const closeReversedModal = () => {
    setSelectedComponent(null);
    setReversedModalOpen(false);
    setIsReversedConnectorOpen(false);
    localStorage.removeItem("currentTaskId");
  };

  const onReversedClose = () => {
    setIsReversedConnectorOpen(false);
    setReversedModalOpen(false);
    setSelectedComponent(null);
    localStorage.removeItem("currentTaskId");
  };

  const closeReversedConnector = () => {
    setIsReversedConnectorOpen(false);
  };
  // --------- ends here
  //-----taskData
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const preFilledScriptData = {
    taskName: searchParams.get("taskName"),
    taskType: searchParams.get("taskType"),
    script: searchParams.get("script"),
    sourceType: searchParams.get("sourceType"),
  };
  // eslint-disable-next-line no-unused-vars
  const { taskName, taskType, script, sourceType } = preFilledScriptData;
  const [taskData, setTaskData] = useState(() => [
    {
      id: 1,
      task_name: taskName !== null ? taskName : "",
      task_execution_status: "",
      depends_on: [],
      parallel: "",
      source_type: sourceType !== null ? sourceType : "",
      scriptName: taskName !== null ? taskName : "",
      script: script !== null ? script : "",
      parameters: [
        {
          key: "",
          value: "",
        },
      ],
      parameters_type: "",
      task_type: taskType !== null ? taskType : "",
      engine_type: "",
      params: {
        input_db_host: "",
        input_db_port: "",
        input_db_user: "",
        input_db_pass: "",
        query_db_name: "",
        selected: "",
        queries: [],
        tables: [],
        file_format: "",
        is_zipped: "",
        header: "",
        separator: "",
        charset: "",
        destination_bucket: "",
        source_bucket: "",
        api_url: "",
        api_client_id: "",
        api_client_secret: "",
        api_header_param: { "": "" },
        api_oauth_flag: "",
        api_oauth_server: "",
        api_http_method: "",
        table_name: "",
        partition_columns: null,
        credentials_json: null,
        location: "",
        bq_tables: [],
      },
    },
  ]);
  const handleAddMoreTask = () => {
    setTaskData((previousTaskData) => {
      return [
        ...previousTaskData,
        {
          id: previousTaskData.length + 1,
          task_name: "",
          task_execution_status: "",
          depends_on: [],
          parallel: "",
          source_type: "",
          scriptName: "",
          script: "",
          parameters: [
            {
              key: "",
              value: "",
            },
          ],
          parameters_type: "",
          task_type: "",
          engine_type: "",
          params: {
            input_db_host: "",
            input_db_port: "",
            input_db_user: "",
            input_db_pass: "",
            query_db_name: "",
            selectedOption: "",
            queries: [],
            tables: [],
            file_format: "",
            is_zipped: "",
            header: "",
            separator: "",
            charset: "",
            destination_bucket: "",
            source_bucket: "",
            api_url: "",
            api_client_id: "",
            api_client_secret: "",
            api_header_param: { "": "" },
            api_oauth_flag: "",
            api_oauth_server: "",
            api_http_method: "",
            table_name: "",
            partition_columns: null,
            credentials_json: null,
            location: "",
            bq_tables: [],
          },
        },
      ];
    });

    setInputValue((previousInputValue) => {
      return [
        ...previousInputValue,
        {
          sourceBucket: "",
          header: false,
          fileFormat: "",
          seperator: "",
          charset: "",
          id: previousInputValue.length + 1,
        },
      ];
    });
    setDBParamsValue((previousSQLServerValue) => {
      return [
        ...previousSQLServerValue,
        {
          host: "",
          port: "",
          user: "",
          password: "",
          id: previousSQLServerValue.length + 1,
        },
      ];
    });
    setmySQLParamsValue((previousmySQLServerValue) => {
      return [
        ...previousmySQLServerValue,
        {
          host: "",
          port: "",
          user: "",
          password: "",
          id: previousmySQLServerValue.length + 1,
        },
      ];
    });
    setAPIInputValue((previousAPIInputValue) => {
      return [
        ...previousAPIInputValue,
        {
          api_url: "",
          api_client_id: "",
          api_client_secret: "",
          api_header_param: { "": "" },
          api_oauth_flag: "",
          id: previousAPIInputValue.length + 1,
        },
      ];
    });
    setpostgresParamsValue((previouspostgresServerValue) => {
      return [
        ...previouspostgresServerValue,
        {
          host: "",
          port: "",
          user: "",
          password: "",
          id: previouspostgresServerValue.length + 1,
        },
      ];
    });
    setoracleParamsValue((previousoracleServerValue) => {
      return [
        ...previousoracleServerValue,
        {
          host: "",
          port: "",
          user: "",
          password: "",
          id: previousoracleServerValue.length + 1,
        },
      ];
    });
    setGCSValue((previousGCSValue) => {
      return [
        ...previousGCSValue,
        {
          sourceBucket: "",
          header: false,
          fileFormat: "",
          seperator: "",
          charset: "",
          id: previousGCSValue.length + 1,
        },
      ];
    });
    setBigQueryInputValue((previousValue) => {
      return [
        ...previousValue,
        {
          location: "",
          id: previousValue.length + 1,
        },
      ];
    });
    setExcatValue((previousExcatValue) => {
      return [
        ...previousExcatValue,
        {
          division_var: "",
          client_id: "",
          aws_secret_access_key: "",
          aws_session_token: "",
          id: previousExcatValue.length + 1,
        },
      ];
    });
    setSQLIngestions(false);
    setMYSQLIngestions(false);
    setPostgresSQLIngestions(false);
    setOracleIngestions(false);
  };

  //-----S3
  const [
    setInputValue,
    S3Data,
    handleChange,
    handleS3SourceBucketChange,
    handleS3SourceBucketBlur,
    s3fileNameError,
    handleS3TaskPartitionColumn,
  ] = useS3(taskData, setTaskData);
  //---ENDS HERE ------------------
  //-----DataBase starts-------------------------------------------------------------------

  //---------SQLSERVER
  const [
    dbDataValues,
    setDBParamsValue,
    handleSQLServerValuesChange,
    sqlError,
    handlequeryDataBaseName,
    selectedOption,
    handleSelectChange,
    optionsArray,
    sqlServerdataQueries,
    handleQueriesTableNameChange,
    queries,
    handleQueriesChange,
    checkboxValues,
    handleCheckboxChange,
    partitonColValue,
    handleQueryPartitionChange,
    addMoreSQLServerQueries,
    tableHead,
    tableData,
    handleDatabaseNameChange,
    databaseNameLoader,
    databaseName,
    handleSchemaNameChange,
    schemas,
    handleTableNameChange,
    tables,
    handleTableIngestModeOption,
    tableOptions,
    handleDeltaColumnChange,
    deltaColumn,
    handlePartionChange,
    handlePartitioningChange,
    partitionColumn,
    addRow,
  ] = useSQL(token, taskData, setTaskData, setSQLIngestions);

  ///----------------------------------------------MySQL Server
  const [
    mySQLDataValues,
    setmySQLParamsValue,
    handlemySQLServerValuesChange,
    mySQLError,
    handlemySQLqueryDataBaseName,
    mySQLselectedOption,
    handlemySQLSelectChange,
    mySQLoptionsArray,
    mySQLServerdataQueries,
    handlemySQLQueriesTableNameChange,
    mySQLqueries,
    handlemySQLQueriesChange,
    mySQLcheckboxValues,
    handlemySQLCheckboxChange,
    mySQLpartitonColValue,
    handlemySQLQueryPartitionChange,
    addMoremySQLServerQueries,
    mySQLtableHead,
    mySQLtableData,
    handlemySQLDatabaseNameChange,
    mySQLdatabaseNameLoader,
    mySQLdatabaseName,
    handlemySQLSchemaNameChange,
    mySQLschemas,
    handlemySQLTableNameChange,
    mySQLtables,
    handlemySQLTableIngestModeOption,
    mySQLtableOptions,
    handlemySQLDeltaColumnChange,
    mySQLdeltaColumn,
    handlemySQLPartionChange,
    handlemySQLPartitioningChange,
    mySQLpartitionColumn,
    mySQLaddRow,
  ] = useMYSQL(token, taskData, setTaskData, setMYSQLIngestions);
  ///----------------------------------------------POSTGRES Server
  const [
    postgresDataValues,
    setpostgresParamsValue,
    handlepostgresServerValuesChange,
    postgresError,
    handlepostgresqueryDataBaseName,
    postgresselectedOption,
    handlepostgresSelectChange,
    postgresoptionsArray,
    postgresServerdataQueries,
    handlepostgresQueriesTableNameChange,
    postgresqueries,
    handlepostgresQueriesChange,
    postgrescheckboxValues,
    handlepostgresCheckboxChange,
    postgrespartitonColValue,
    handlepostgresQueryPartitionChange,
    addMorepostgresServerQueries,
    postgrestableHead,
    postgrestableData,
    handlepostgresDatabaseNameChange,
    postgresdatabaseNameLoader,
    postgresdatabaseName,
    handlepostgresSchemaNameChange,
    postgresschemas,
    handlepostgresTableNameChange,
    postgrestables,
    handlepostgresTableIngestModeOption,
    postgrestableOptions,
    handlepostgresDeltaColumnChange,
    postgresdeltaColumn,
    handlepostgresPartionChange,
    handlepostgresPartitioningChange,
    postgrespartitionColumn,
    postgresaddRow,
  ] = usePostgress(token, taskData, setTaskData, setPostgresSQLIngestions);
  ///----------------------------------------------Oracle Server
  const [
    oracleDataValues,
    setoracleParamsValue,
    handleoracleServerValuesChange,
    oracleError,
    oracleselectedOption,
    handleoracleSelectChange,
    oracleoptionsArray,
    oracleServerdataQueries,
    handleoracleQueriesTableNameChange,
    oraclequeries,
    handleoracleQueriesChange,
    oraclecheckboxValues,
    handleoracleCheckboxChange,
    oraclepartitonColValue,
    handleoracleQueryPartitionChange,
    addMoreoracleServerQueries,
    oracletableHead,
    oracletableData,
    handleoracleSchemaNameChange,
    oracleschemas,
    handleoracleTableNameChange,
    oracletables,
    handleoracleTableIngestModeOption,
    oracletableOptions,
    handleoracleDeltaColumnChange,
    oracledeltaColumn,
    handleoraclePartionChange,
    handleoraclePartitioningChange,
    oraclepartitionColumn,
    oracleaddRow,
  ] = useOracle(token, taskData, setTaskData, setOracleIngestions);
  //------API
  const [
    ApiData,
    setAPIInputValue,
    handleAPIChange,
    apiHttp,
    httpData,
    httpvalue,
    handleHttpChange,
    renderKeyValue,
    apihandleKeyChange,
    apihandleValueChange,
  ] = useAPI(taskData, setTaskData);
  const [
    bigQueryData,
    setBigQueryInputValue,
    handleBigQueryValueChange,
    credential,
    credentialError,
    handleCredentialJsonChange,
    bqTables,
    bigQueryTableHead,
    addBqTablesRow,
    handleProjectIdChange,
    datasets,
    handleDataSetIdChange,
    allTables,
    handleTableIdChange,
    isTableId,
    handleBigQueryLoadTypeChange,
    allDeltaColumn,
    handleBigQueryDeltaColumnChange,
    handleBigQueryPartionChange,
    allPartitionColumn,
    handleBigQueryPartitioningChange,
    handleOutputTableName,
  ] = useBigQuery(token, taskData, setTaskData);
  //-----ENDS HERE-------------------------------------------------------------------------
  //GCS----------------------------------
  const [
    setGCSValue,
    GCSData,
    handleGCSChange,
    handleGCSSourceBucketChange,
    handleGCSSourceBucketBlur,
    gcsfileNameError,
    handleGCSTaskPartitionColumn,
  ] = useGCS(taskData, setTaskData);
  //---------excat
  const [
    setExcatValue,
    excatData,
    handleExcatChange,
    isRequiredFieldFilled,
    excatTableHead,
    myExcatloadType,
    excatTableData,
    excatTableOptions,
    handleSwitchChange,
    handleSyncModeChange,
    handlePrimaryKeyChange,
  ] = useExcat(taskData, setTaskData);
  //-----Ends here

  //-----ActiveCampaign
  const [
    activeCampaignUrl,
    activeCampaignSetUrl,
    activeCampaignApiKey,
    activeCampaignSetApiKey,
    activeCampaignData,
    activeCampaignSearch,
    activeCampaignHandleSearch,
    activeCampaignSelected,
    activeCampaignHandleAttrSelection,
    activeCampaignExpanded,
    activeCampaignHandleExpand,
    activeCampaignHandleAdd,
  ] = useActiveCampaing(token, taskData, setTaskData);
  //-----Ends Here

  const handleRemoveFromUI = (id) => {
    const updatedWords = taskData.filter((item) => item.id !== id);
    setTaskData(updatedWords);
  };

  const [
    pipeDriveApiKey,
    pipeDriveSetApiKey,
    pipeDriveObjects,
    pipeDriveHandleObjectSelection,
    pipeDriveHandleAttrSelection,
    pipeDriveFetchEndpoints,
    pipeDriveConvertSelectedToApi,
  ] = usePipedrive(token, taskData);

  const handlePostJobs = () => {
    taskData.forEach((item) => {
      if (item.task_type === "ingestion") {
        const curatedTask = {
          params: {
            fileset: [
              {
                source_bucket: null,
                destination_bucket: null,
                partition_columns: null,
              },
            ],
          },
          depends_on: [],
        };
        curatedTask.parallel = "false";
        curatedTask.source_type = "transformation-curated";
        curatedTask.task_type = "ingestion";
        curatedTask.depends_on.push(item.task_name);
        curatedTask.task_name = `${item.task_name}_to_curated`;

        //for gcs,s3
        const uriParts = item.params.source_bucket.split("/");
        const fileNameWithExtension = uriParts[uriParts.length - 1];
        const fileName = fileNameWithExtension.split(".")[0];
        curatedTask.params.fileset[0].source_bucket = `databoat-raw-${group}/${fileName}`;
        curatedTask.params.fileset[0].destination_bucket = `databoat-curated-${group}/${fileName}`;
        curatedTask.params.fileset[0].partition_columns =
          item.params.partition_columns;
        //for api connector
        if (item.params.table_name !== "") {
          curatedTask.params.fileset[0].source_bucket = `databoat-raw-${group}/${item.params.table_name}`;
          curatedTask.params.fileset[0].destination_bucket = `databoat-curated-${group}/${item.params.table_name}`;
        }

        //for db connectors
        if (!!item.params.queries[0]?.table_name) {
          item.params.queries.forEach((query, index) => {
            curatedTask.params.fileset[index] = {
              source_bucket: `databoat-raw-${group}/${query.table_name}`,
              destination_bucket: `databoat-curated-${group}/${query.table_name}`,
              partition_columns: query.partition_columns,
            };
          });
        }

        if (!!item.params.tables[0]?.input_table_name) {
          item.params.tables.forEach((row, index) => {
            curatedTask.params.fileset[index] = {
              source_bucket: `databoat-raw-${group}/${row.input_table_name}`,
              destination_bucket: `databoat-curated-${group}/${row.input_table_name}`,
              partition_columns: row.partition_columns || null,
            };
          });
        }
        // for bigquery
        if (!!item.params.bq_tables[0]?.output_table_name) {
          item.params.bq_tables.forEach((row, index) => {
            curatedTask.params.fileset[index] = {
              source_bucket: `databoat-raw-${group}/${row.output_table_name}`,
              destination_bucket: `databoat-curated-${group}/${row.output_table_name}`,
              partition_columns: row.partition_columns || null,
            };
          });
        }
        taskData.push(curatedTask);
      }
    });
    const newTaskData = taskData.map((item) => {
      const { parameters_type, selected, engine_type, id, ...rest } = item;
      return {
        ...rest,
        params: {
          ...item.params,
          queries: item.params.queries?.length > 0 ? item.params.queries : null,
          tables: item.params.tables?.length > 0 ? item.params.tables : null,
          bq_tables:
            item.params.bq_tables?.length > 0 ? item.params.bq_tables : null,
        },
      };
    });
    const webhookUrl =
      alertValue === "Slack"
        ? slackURL
        : alertValue === "Teams"
        ? teamsURL
        : "";
    const formData = {
      job_name: jobName,
      group_name: group,
      start_date: startDate,
      schedule_interval: schedulerResult,
      last_execution_date: "",
      last_execution_status: "",
      created_by: userEmail,
      tags: tags,
      tasks: newTaskData,
      alerts: {
        type: alertValue,
        params: {
          to_email_addresses: emailto,
          cc_email_addresses: emailcc,
          subject_email: emailsubject,
          webhook_url: webhookUrl,
        },
      },
    };
    dispatch(
      postAllJobs(
        group,
        formData,
        token,
        environmentVariables.REACT_APP_BASE_URL_API_CORE,
      ),
    );
    navigate("/yedaflow");
  };

  return {
    jobName,
    handleJobNameChange,
    date,
    handleDateChange,
    time,
    handleTimeChange,
    frequencyOption,
    handleFrequencyOption,
    frequencydata,
    startHourMinutes,
    handleStartHourMinutesChange,
    dailyTime,
    handleDailyTimeChange,
    selectedDays,
    handleDayToggle,
    startWeeklyTime,
    handleWeeklyStartTimeChange,
    //task
    taskData,
    setTaskData,
    handleTaskNameChange,
    handleParametersTypeChange,
    handleKeyChange,
    handleValueChange,
    formattedJson,
    handleJsonDataChange,
    handleTaskTypeChange,
    handleCodePathChange,
    handleSourceFieldChange,
    handleScriptNameChange,

    //CONNECTORS
    isConnectorModalOpen,
    onClose,
    openModal,
    closeModal,
    modalOpen,
    selectedComponent,
    selectedText,
    inputData,
    //s3
    S3Data,
    handleChange,
    handleS3SourceBucketChange,
    handleS3SourceBucketBlur,
    s3fileNameError,
    handleS3TaskPartitionColumn,
    //----------SQL SERVER ----------------------------------------------------
    dbDataValues,
    handleSQLServerValuesChange,
    sqlError,
    setQueryDBName,
    handlequeryDataBaseName,
    sqlIngestions,
    selectedOption,
    handleSelectChange,
    optionsArray,
    sqlServerdataQueries,
    handleQueriesTableNameChange,
    queries,
    handleQueriesChange,
    checkboxValues,
    handleCheckboxChange,
    partitonColValue,
    handleQueryPartitionChange,
    addMoreSQLServerQueries,
    tableHead,
    tableData,
    handleDatabaseNameChange,
    databaseNameLoader,
    databaseName,
    handleSchemaNameChange,
    schemas,
    handleTableNameChange,
    tables,
    handleTableIngestModeOption,
    tableOptions,
    handleDeltaColumnChange,
    deltaColumn,
    handlePartionChange,
    handlePartitioningChange,
    partitionColumn,
    addRow,
    //-----------------MYSQL
    mySQLDataValues,
    handlemySQLServerValuesChange,
    mySQLError,
    handlemySQLqueryDataBaseName,
    mysqlIngestions,
    mySQLselectedOption,
    handlemySQLSelectChange,
    mySQLoptionsArray,
    mySQLServerdataQueries,
    handlemySQLQueriesTableNameChange,
    mySQLqueries,
    handlemySQLQueriesChange,
    mySQLcheckboxValues,
    handlemySQLCheckboxChange,
    mySQLpartitonColValue,
    handlemySQLQueryPartitionChange,
    addMoremySQLServerQueries,
    mySQLtableHead,
    mySQLtableData,
    handlemySQLDatabaseNameChange,
    mySQLdatabaseNameLoader,
    mySQLdatabaseName,
    handlemySQLSchemaNameChange,
    mySQLschemas,
    handlemySQLTableNameChange,
    mySQLtables,
    handlemySQLTableIngestModeOption,
    mySQLtableOptions,
    handlemySQLDeltaColumnChange,
    mySQLdeltaColumn,
    handlemySQLPartionChange,
    handlemySQLPartitioningChange,
    mySQLpartitionColumn,
    mySQLaddRow,
    //--------postgress
    //------------------POSTGRESS
    postgresDataValues,
    handlepostgresServerValuesChange,
    postgresError,
    handlepostgresqueryDataBaseName,
    PostgresSQLIngestions,
    postgresselectedOption,
    handlepostgresSelectChange,
    postgresoptionsArray,
    postgresServerdataQueries,
    handlepostgresQueriesTableNameChange,
    postgresqueries,
    handlepostgresQueriesChange,
    postgrescheckboxValues,
    handlepostgresCheckboxChange,
    postgrespartitonColValue,
    handlepostgresQueryPartitionChange,
    addMorepostgresServerQueries,
    postgrestableHead,
    postgrestableData,
    handlepostgresDatabaseNameChange,
    postgresdatabaseNameLoader,
    postgresdatabaseName,
    handlepostgresSchemaNameChange,
    postgresschemas,
    handlepostgresTableNameChange,
    postgrestables,
    handlepostgresTableIngestModeOption,
    postgrestableOptions,
    handlepostgresDeltaColumnChange,
    postgresdeltaColumn,
    handlepostgresPartionChange,
    handlepostgresPartitioningChange,
    postgrespartitionColumn,
    postgresaddRow,
    // ------------------ Oracle Server
    oracleDataValues,
    handleoracleServerValuesChange,
    oracleError,
    OracleIngestions,
    oracleselectedOption,
    handleoracleSelectChange,
    oracleoptionsArray,
    oracleServerdataQueries,
    handleoracleQueriesTableNameChange,
    oraclequeries,
    handleoracleQueriesChange,
    oraclecheckboxValues,
    handleoracleCheckboxChange,
    oraclepartitonColValue,
    handleoracleQueryPartitionChange,
    addMoreoracleServerQueries,
    oracletableHead,
    oracletableData,
    handleoracleSchemaNameChange,
    oracleschemas,
    handleoracleTableNameChange,
    oracletables,
    handleoracleTableIngestModeOption,
    oracletableOptions,
    handleoracleDeltaColumnChange,
    oracledeltaColumn,
    handleoraclePartionChange,
    handleoraclePartitioningChange,
    oraclepartitionColumn,
    oracleaddRow,
    //csv
    //API
    ApiData,
    handleAPIChange,
    apiHttp,
    httpData,
    httpvalue,
    handleHttpChange,
    renderKeyValue,
    apihandleKeyChange,
    apihandleValueChange,
    //-------GCS
    GCSData,
    handleGCSChange,
    handleGCSSourceBucketChange,
    gcsfileNameError,
    handleGCSSourceBucketBlur,
    handleGCSTaskPartitionColumn,
    //--------BigQuery
    bigQueryData,
    handleBigQueryValueChange,
    credential,
    credentialError,
    handleCredentialJsonChange,
    bqTables,
    bigQueryTableHead,
    addBqTablesRow,
    handleProjectIdChange,
    datasets,
    handleDataSetIdChange,
    allTables,
    handleTableIdChange,
    isTableId,
    handleBigQueryLoadTypeChange,
    allDeltaColumn,
    handleBigQueryDeltaColumnChange,
    handleBigQueryPartionChange,
    allPartitionColumn,
    handleBigQueryPartitioningChange,
    handleOutputTableName,
    //------excat
    excatData,
    handleExcatChange,
    isRequiredFieldFilled,
    excatTableHead,
    myExcatloadType,
    excatTableData,
    excatTableOptions,
    handleSwitchChange,
    handleSyncModeChange,
    handlePrimaryKeyChange,
    //----------------ENDS ---------
    // pipedrive
    pipeDriveApiKey,
    pipeDriveSetApiKey,
    pipeDriveObjects,
    pipeDriveHandleObjectSelection,
    pipeDriveHandleAttrSelection,
    pipeDriveFetchEndpoints,
    pipeDriveConvertSelectedToApi,
    //reversed
    isReversedConnectorOpen,
    closeReversedConnector,
    // handleEngineType,
    handleAddMoreTask,
    handleRemoveFromUI,
    tags,
    handleTagsChange,
    alertValue,
    handleAlertValueChange,
    emailto,
    handleEmailToChange,
    emailcc,
    handleEmailccChange,
    emailsubject,
    handleEmailSubjectChange,
    emailmessage,
    handleEmailMessageChange,
    slackURL,
    handleSlackURLChange,
    teamsURL,
    handleTeamsURLChange,
    handlePostJobs,
    onReversedClose,
    closeReversedModal,
    openReversedModal,
    reversedModalOpen,

    // Active Campaign
    activeCampaignUrl,
    activeCampaignSetUrl,
    activeCampaignApiKey,
    activeCampaignSetApiKey,
    activeCampaignData,
    activeCampaignSearch,
    activeCampaignHandleSearch,
    activeCampaignSelected,
    activeCampaignHandleAttrSelection,
    activeCampaignExpanded,
    activeCampaignHandleExpand,
    activeCampaignHandleAdd,
  };
};

export default useCreateJobHook;
