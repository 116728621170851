import { useState, useEffect } from "react";
import useGetToken from "../../../../cognito/useGetToken";
import { useSelector } from "react-redux";
import axios from "axios";

const useReversedSync = () => {
  const { token } = useGetToken();
  const { environmentVariables } = useSelector((state) => state.users);
  const [pipeDriveObjects, setPipeDriveObjects] = useState();
  const [selectedPipeDriveObject, setSelectedPipeDriveObject] = useState();
  const [pipeDriveObjectsLoader, setPipeDriveObjectsLoader] = useState(false);
  const [error, setError] = useState();
  const getPipeDrivedObjects = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(
        `${environmentVariables.REACT_APP_BASE_URL_API_CORE}/connectors/pipedrive/list-endpoints?endpoint_method=POST`,
        { headers },
      );
      setPipeDriveObjects(response.data);
      setPipeDriveObjectsLoader(true);
    } catch (error) {
      setPipeDriveObjectsLoader(false);
      setError(error);
    }
  };
  useEffect(() => {
    if (environmentVariables && token) {
      getPipeDrivedObjects();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, environmentVariables]);
  const handleSelectPipeDrive = (
    event,
    taskId,
    selectedValue,
    taskData,
    setTaskData,
  ) => {
    const selected = selectedValue;

    setSelectedPipeDriveObject(selected);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        return {
          ...data,
          task_type: "reversed_sync_pipedrive",
          parameters: data.parameters.map((param) => {
            return {
              key: "endpoint",
              value: selected,
            };
          }),
        };
      }
      return data;
    });

    setTaskData(updatedTaskData);
  };
  const handlePipeDriveAPIChange = (event, taskId, taskData, setTaskData) => {
    const selected = event.target.value;
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        return {
          ...data,
          task_type: "reversed_sync_pipedrive",
          params: {
            ...data.params,
            api_key: selected,
          },
        };
      }
      return data;
    });

    setTaskData(updatedTaskData);
  };
  return {
    pipeDriveObjects,
    pipeDriveObjectsLoader,
    error,
    selectedPipeDriveObject,
    setSelectedPipeDriveObject,
    getPipeDrivedObjects,
    handlePipeDriveAPIChange,
    handleSelectPipeDrive,
  };
};

export default useReversedSync;
