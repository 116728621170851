import React, { useState } from "react";
import { UploadContainer, UploadInput } from "../styles/CustomerLogo.Styled";
import { Avatar } from "@mui/material";

const CustomerLogo = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setSelectedFile(file);
  };

  return (
    <UploadContainer>
      <Avatar
        src={selectedFile ? URL.createObjectURL(selectedFile) : ""}
        sx={{ width: "100%", height: "100%" }}
      />
      <UploadInput type="file" accept="image/*" onChange={handleFileChange} />
    </UploadContainer>
  );
};

export default CustomerLogo;
