/* eslint-disable no-unused-vars */
import { useState } from "react";
import { axiosGetTable } from "../../../../clientRequest/axiosRequest";
import { fetchDatabaseName } from "../../../redux/actions/usersActions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const usePostgress = (
  token,
  taskData,
  setTaskData,
  setPostgresSQLIngestions,
) => {
  const dispatch = useDispatch();
  const { environmentVariables } = useSelector((state) => state.users);
  const baseURL = `${environmentVariables.REACT_APP_TABLE_API}/yeda/database-metadata/postgresql`;
  const [postgresqueries, setpostgresQueries] = useState([]);
  const [postgrescheckboxValues, setpostgresCheckboxValues] = useState([false]);
  const [postgresParams, setpostgresParamsValue] = useState([
    {
      host: "",
      port: "",
      user: "",
      password: "",
      id: 1,
    },
  ]);

  const { t } = useTranslation();

  const postgresDataValues = [
    {
      id: 1,
      name: "host",
      title: "Host",
      CheckBox: false,
      defaultValue: "",
      required: true,
    },
    {
      id: 2,
      name: "port",
      title: "Port",
      CheckBox: false,
      defaultValue: "",
      required: true,
    },
    {
      id: 3,
      name: "user",
      title: t(
        "jobs.form.inputs.task.modal.connectors.server.hook.api_data.three",
      ),
      CheckBox: false,
      defaultValue: "",
      required: true,
    },
    {
      id: 4,
      name: "password",
      title: t(
        "jobs.form.inputs.task.modal.connectors.server.hook.api_data.four",
      ),
      CheckBox: false,
      password: true,
      defaultValue: "",
      required: true,
    },
  ];
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const group = localStorage.getItem("group");
  const [postgresselectedOption, setpostgresSelectedOption] = useState("");
  const postgresoptionsArray = [
    {
      id: 1,
      options: "",
      title: "Select",
    },
    {
      id: 2,
      options: "query",
      title: "Query",
    },
    {
      id: 3,
      options: "tables",
      title: "Table(s)",
    },
  ];
  const handlepostgresServerValuesChange = (event, id) => {
    const { name, value } = event.target;
    postgresParams.map((data) => {
      if (data.id === id) {
        switch (name) {
          case "host":
            data.host = value;
            break;
          case "port":
            data.port = value;
            break;
          case "user":
            data.user = value;
            break;
          case "password":
            data.password = value;
            break;
          default:
            console.log("error");
        }
      }
      return data;
    });
    // Check if all values in postgresSQLParams are filled
    const allValuesFilled = postgresParams.every(
      (data) => data.host && data.port && data.user && data.password,
    );

    setPostgresSQLIngestions(allValuesFilled);
    const currentpostgresServerInput = postgresParams.find(
      (input) => input.id === id,
    );
    taskData.map((data) => {
      if (data.id === id) {
        data.params.input_db_host = currentpostgresServerInput.host;
        data.params.input_db_port = currentpostgresServerInput.port;
        data.params.input_db_user = currentpostgresServerInput.user;
        data.params.input_db_pass = currentpostgresServerInput.password;
        data.params.destination_bucket = `databoat-raw-${group}`;
        data.source_type = "postgresql";
      }
      return data;
    });
  };
  const handlepostgresqueryDataBaseName = (event, id) => {
    const selected = event.target.value;
    const updatedData = taskData.map((data) => {
      if (data.id === id) {
        data.params.query_db_name = selected;
      }
      return data;
    });
    setTaskData(updatedData);
  };
  const [postgresError, setpostgresError] = useState("");
  const handlepostgresSelectChange = (event, taskId) => {
    const selected = event.target.value;
    const updatedData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.selected = selected;
      }
      return data;
    });

    setTaskData(updatedData);
    setpostgresSelectedOption(selected);
    const currentDbParam = postgresParams.find((data) => data.id === taskId);
    const validateForm = () => {
      const errors = [];
      if (!currentDbParam.host.trim()) {
        errors.host = "Host is required";
      }
      if (!currentDbParam.user.trim()) {
        errors.user = "User is required";
      }
      if (!currentDbParam.password.trim()) {
        errors.password = "Password is required";
      }

      setpostgresError(errors);
      return Object.keys(errors).length === 0;
    };
    if (validateForm()) {
      setpostgresError("");
      if (selected === "tables") {
        const formData = {
          user: currentDbParam.user,
          password: currentDbParam.password,
          server: currentDbParam.host,
        };
        dispatch(fetchDatabaseName(formData, baseURL, token));
      }
    } else {
      setpostgresError("Please fill all required fields");
    }
  };
  const [postgresServerdataQueries, setpostgresServerDataQueries] = useState(
    () => [
      {
        id: 1,
        query: null,
        table_name: null,
        partitioning: false,
        partition_columns: null,
      },
    ],
  );

  // Function to handle adding more rows
  const addMorepostgresServerQueries = (id) => {
    setpostgresServerDataQueries((previousQueryData) => {
      return [
        ...previousQueryData,
        {
          id: previousQueryData.length + 1,
          query: null,
          table_name: null,
          partitioning: false,
          partition_columns: null,
        },
      ];
    });
  };

  const handlepostgresQueriesChange = (event, id, taskId) => {
    const selected = event.target.value;
    const updatedData = postgresServerdataQueries.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          query: selected,
        };
      }
      return data;
    });

    setpostgresServerDataQueries(updatedData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.queries = updatedData;
      }
      return data;
    });

    setTaskData(updatedTaskData);
  };
  const handlepostgresQueriesTableNameChange = (event, id, taskId) => {
    const selected = event.target.value;
    const updatedData = postgresServerdataQueries.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          table_name: selected,
        };
      }
      return data;
    });

    setpostgresServerDataQueries(updatedData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.queries.map((item) => {
          if (item.id === id) {
            item.table_name = selected;
            return item;
          }
          return item;
        });
      }
      return data;
    });

    setTaskData(updatedTaskData);
  };
  const handlepostgresCheckboxChange = (event, id, taskId) => {
    let selected = event.target.value;
    if (selected === "on") {
      selected = true;
    } else {
      selected = false;
    }
    const updatedData = postgresServerdataQueries.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          partitioning: selected,
        };
      }
      return data;
    });
    setpostgresServerDataQueries(updatedData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.queries.map((item) => {
          item.partitioning = selected;
          return item;
        });
      }
      return data;
    });

    setTaskData(updatedTaskData);
  };
  const handlepostgresQueryPartitionChange = (event, id, taskId) => {
    const selected = event.target.value.split(",");
    const updatedQuery = postgresServerdataQueries.map((data) => {
      if (data.id === id) {
        data.partition_columns = selected;
      }
      return data;
    });
    setpostgresServerDataQueries(updatedQuery);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.queries.map((item) => {
          if (item.id === id) {
            item.partition_columns = selected;
            return item;
          }
          return item;
        });
      }
      return data;
    });

    setTaskData(updatedTaskData);
  };
  //-----------------------------------------POSTGRESSERVER TABLES
  const [postgresisChecked, setpostgresIsChecked] = useState();
  const [postgresloadType, setpostgresLoadType] = useState("");
  const postgresdatabase = useSelector((state) => state.users.databaseName);
  const postgresdatabaseNameLoader = useSelector(
    (state) => state.users.databaseNameLoader,
  );
  const postgrestableHead = [
    { id: 1, title: "Database Name" },
    { id: 2, title: "Schema" },
    { id: 3, title: "Table" },
    { id: 4, title: "Ingestion Mode" },
    postgresloadType === "delta" && { id: 5, title: "Delta_Column" },
    { id: 6, title: "Partition" },
    postgresisChecked && { id: 7, title: "Partition_Column" },
  ].filter(Boolean);
  const [postgrestableData, setpostgresTableData] = useState(() => [
    {
      id: 1,
      input_db_name: null,
      input_db_schema: null,
      input_table_name: null,
      load_type: null,
      partitioning: false,
      isDelta: false,
      delta_column: null,
      partition_columns: null,
      tableSchemasLoader: false,
      listTablesLoader: false,
      deltaColumnDataLoader: false,
      partitionColumnDataLoader: false,
    },
  ]);

  // Function to handle adding more rows
  const postgresaddRow = () => {
    setpostgresTableData((previousTableData) => {
      return [
        ...previousTableData,
        {
          id: previousTableData.length + 1,
          input_db_name: null,
          input_db_schema: null,
          input_table_name: null,
          load_type: null,
          partitioning: false,
          isDelta: false,
          delta_column: null,
          partition_columns: null,
          tableSchemasLoader: false,
          listTablesLoader: false,
          deltaColumnDataLoader: false,
          partitionColumnDataLoader: false,
        },
      ];
    });
    setpostgresSchemas((previousSchemaData) => {
      return [
        ...previousSchemaData,
        {
          id: previousSchemaData.length + 1,
          schema_names: [],
        },
      ];
    });
    setpostgresTables((previousTableNameData) => {
      return [
        ...previousTableNameData,
        {
          id: previousTableNameData.length + 1,
          table_names: [],
        },
      ];
    });
    setpostgresDeltaColumn((previousdeltaData) => {
      return [
        ...previousdeltaData,
        {
          id: previousdeltaData.length + 1,
          COLUMN_NAMES: [],
        },
      ];
    });
    setpostgresPartitionColumn((previousPartitioneData) => {
      return [
        ...previousPartitioneData,
        {
          id: previousPartitioneData.length + 1,
          COLUMN_NAMES: [],
        },
      ];
    });
  };
  const postgrestableOptions = [
    { id: 1, option: "", title: "Select" },
    { id: 2, option: "full", title: "Full" },
    { id: 3, option: "delta", title: "Delta" },
  ];

  const [postgresschemas, setpostgresSchemas] = useState(() => [
    {
      id: 1,
      schema_names: [],
    },
  ]);
  const [postgrestables, setpostgresTables] = useState(() => [
    {
      id: 1,
      table_names: [],
    },
  ]);
  const [postgresdeltaColumn, setpostgresDeltaColumn] = useState(() => [
    {
      id: 1,
      COLUMN_NAMES: [],
    },
  ]);
  const [postgrespartitionColumn, setpostgresPartitionColumn] = useState(() => [
    {
      id: 1,
      COLUMN_NAMES: [],
    },
  ]);
  const postgresdatabaseName = [
    { value: "", label: "Select" },
    ...postgresdatabase.map((item) => {
      return {
        value: item.database_name,
        label: item.database_name,
      };
    }),
  ];

  const handlepostgresDatabaseNameChange = async (event, id, taskId) => {
    const selected = event.target.value;
    if (selected) {
      const currentDbParam = postgresParams.find((data) => data.id === taskId);
      const formData = {
        user: currentDbParam.user,
        password: currentDbParam.password,
        server: currentDbParam.host,
        database: selected,
      };
      await fetchpostgresSchemas(selected, id, formData);
    }
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        const existingTableIndex = data.params.tables.findIndex(
          (item) => item.id === id,
        );

        if (existingTableIndex !== -1) {
          // Table with the given id exists, update it
          data.params.tables[existingTableIndex].input_db_name = selected;
        } else {
          // Table with the given id doesn't exist, create a new one
          data.params.tables.push({
            id: id,
            input_db_name: selected,
          });
        }
      }
      return data;
    });

    setTaskData(updatedTaskData);
  };
  const handlepostgresSchemaNameChange = async (event, id, taskId) => {
    const selected = event.target.value;
    if (selected) {
      const currentDbParam = postgresParams.find((data) => data.id === taskId);
      await fetchpostgresTableName(
        selected,
        id,
        currentDbParam.user,
        currentDbParam.password,
        currentDbParam.host,
      );
    }
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        const existingTableIndex = data.params.tables.findIndex(
          (item) => item.id === id,
        );

        if (existingTableIndex !== -1) {
          // Table with the given id exists, update it
          data.params.tables[existingTableIndex].input_db_schema = selected;
        } else {
          // Table with the given id doesn't exist, create a new one
          data.params.tables.push({
            id: id,
            input_db_schema: selected,
          });
        }
      }
      return data;
    });

    setTaskData(updatedTaskData);
  };

  const handlepostgresTableNameChange = (event, id, taskId) => {
    const selected = event.target.value;
    const updatedData = postgrestableData.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          input_table_name: selected,
        };
      }
      return data;
    });
    setpostgresTableData(updatedData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        const existingTableIndex = data.params.tables.findIndex(
          (item) => item.id === id,
        );

        if (existingTableIndex !== -1) {
          // Table with the given id exists, update it
          data.params.tables[existingTableIndex].input_table_name = selected;
        } else {
          // Table with the given id doesn't exist, create a new one
          data.params.tables.push({
            id: id,
            input_table_name: selected,
          });
        }
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };
  const handlepostgresTableIngestModeOption = async (event, id, taskId) => {
    const selectedValue = event.target.value;
    if (selectedValue) {
      const currentDbParam = postgresParams.find((data) => data.id === taskId);
      await fetchpostgresDeltaColumn(
        selectedValue,
        id,
        currentDbParam.user,
        currentDbParam.password,
        currentDbParam.host,
      );
    }
    setpostgresLoadType(selectedValue);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        const existingTableIndex = data.params.tables.findIndex(
          (item) => item.id === id,
        );

        if (existingTableIndex !== -1) {
          // Table with the given id exists, update it
          data.params.tables[existingTableIndex].load_type = selectedValue;
          data.params.tables[existingTableIndex].isDelta =
            selectedValue === "delta";
        } else {
          // Table with the given id doesn't exist, create a new one
          data.params.tables.push({
            id: id,
            load_type: selectedValue,
            isDelta: selectedValue === "delta",
          });
        }
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };
  const handlepostgresDeltaColumnChange = (event, id, taskId) => {
    const updatedData = postgrestableData.map((data) => {
      if (data.id === id) {
        return { ...data, delta_column: event.target.value };
      }
      return data;
    });
    setpostgresTableData(updatedData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        const existingTableIndex = data.params.tables.findIndex(
          (item) => item.id === id,
        );

        if (existingTableIndex !== -1) {
          // Table with the given id exists, update it
          data.params.tables[existingTableIndex].delta_column =
            event.target.value;
        } else {
          // Table with the given id doesn't exist, create a new one
          data.params.tables.push({
            id: id,
            delta_column: event.target.value,
          });
        }
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };
  const handlepostgresPartionChange = async (event, id, taskId) => {
    let selected = event.target.value;
    if (selected === "on") {
      selected = true;
    }
    if (selected) {
      const currentDbParam = postgresParams.find((data) => data.id === taskId);
      await fetchpostgresPartitionColumn(
        selected,
        id,
        currentDbParam.user,
        currentDbParam.password,
        currentDbParam.host,
      );
    }
    setpostgresIsChecked(selected);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        const existingTableIndex = data.params.tables.findIndex(
          (item) => item.id === id,
        );

        if (existingTableIndex !== -1) {
          // Table with the given id exists, update it
          data.params.tables[existingTableIndex].partitioning = selected;
        } else {
          // Table with the given id doesn't exist, create a new one
          data.params.tables.push({
            id: id,
            partitioning: selected,
          });
        }
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };

  const handlepostgresPartitioningChange = (options, id, taskId) => {
    const partion_column = options.map((value) => value.value);
    const updatedData = postgrestableData.map((data) => {
      if (data.id === id) {
        return { ...data, partition_columns: partion_column };
      }
      return data;
    });
    setpostgresTableData(updatedData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        const existingTableIndex = data.params.tables.findIndex(
          (item) => item.id === id,
        );

        if (existingTableIndex !== -1) {
          // Table with the given id exists, update it
          data.params.tables[existingTableIndex].partition_columns =
            partion_column;
        } else {
          // Table with the given id doesn't exist, create a new one
          data.params.tables.push({
            id: id,
            partition_columns: partion_column,
          });
        }
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };

  const fetchpostgresSchemas = async (name, id, formData) => {
    const response = await axiosGetTable(baseURL).post(
      "/list-schemas",
      formData,
      { headers },
    );
    const updatedData = postgrestableData.map((data) => {
      if (data.id === id)
        return { ...data, input_db_name: name, tableSchemasLoader: true };
      return data;
    });
    setpostgresTableData(updatedData);

    const updatedSchema = postgresschemas.map((schemas) => {
      if (schemas.id === id) return { ...schemas, schema_names: response.data };
      return schemas;
    });
    setpostgresSchemas(updatedSchema);
  };
  const fetchpostgresTableName = async (
    selectedSchemaName,
    id,
    user,
    password,
    host,
  ) => {
    let selectedDbName = "";

    postgrestableData.map((data) => {
      if (data.id === id) {
        selectedDbName = data.input_db_name;
      }
      return data;
    });
    const formData = {
      user: user,
      password: password,
      server: host,
      database: selectedDbName,
      schema: selectedSchemaName,
    };
    const response = await axiosGetTable(baseURL).post(
      "/list-tables",
      formData,
      { headers },
    );
    const updatedData = postgrestableData.map((data) => {
      if (data.id === id)
        return {
          ...data,
          input_db_schema: selectedSchemaName,
          listTablesLoader: true,
        };
      return data;
    });

    setpostgresTableData(updatedData);
    const updatedTable = postgrestables.map((tables) => {
      if (tables.id === id) return { ...tables, table_names: response.data };
      return tables;
    });

    setpostgresTables(updatedTable);
  };
  const fetchpostgresDeltaColumn = async (
    selectedValue,
    id,
    user,
    password,
    host,
  ) => {
    let selectedDbName = "";
    let selectedSchemaName = "";
    let selectedTableName = "";

    postgrestableData.map((data) => {
      if (data.id === id) {
        selectedDbName = data.input_db_name;
        selectedSchemaName = data.input_db_schema;
        selectedTableName = data.input_table_name;
      }
      return data;
    });
    const formData = {
      user: user,
      password: password,
      server: host,
      database: selectedDbName,
      schema: selectedSchemaName,
      table: selectedTableName,
    };
    const response = await axiosGetTable(baseURL).post(
      "/list-columns-date",
      formData,
      { headers },
    );
    const updatedData = postgrestableData.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          load_type: selectedValue,
          isDelta: selectedValue === "delta",
          deltaColumnDataLoader: true,
        };
      }
      return data;
    });
    setpostgresTableData(updatedData);
    const updatedDeltaColumn = postgresdeltaColumn.map((columns) => {
      if (columns.id === id) return { ...columns, COLUMN_NAMES: response.data };
      return columns;
    });
    setpostgresDeltaColumn(updatedDeltaColumn);
  };
  const fetchpostgresPartitionColumn = async (
    selected,
    id,
    user,
    password,
    host,
  ) => {
    let selectedDbName = "";
    let selectedSchemaName = "";
    let selectedTableName = "";

    postgrestableData.map((data) => {
      if (data.id === id) {
        selectedDbName = data.input_db_name;
        selectedSchemaName = data.input_db_schema;
        selectedTableName = data.input_table_name;
      }
      return data;
    });
    const formData = {
      user: user,
      password: password,
      server: host,
      database: selectedDbName,
      schema: selectedSchemaName,
      table: selectedTableName,
    };
    const response = await axiosGetTable(baseURL).post(
      "/list-columns",
      formData,
      { headers },
    );
    const updatedData = postgrestableData.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          partitioning: selected,
          partitionColumnDataLoader: true,
        };
      }
      return data;
    });
    setpostgresTableData(updatedData);

    const updatedPartitonColumn = postgrespartitionColumn.map((columns) => {
      if (columns.id === id) return { ...columns, COLUMN_NAMES: response.data };
      return columns;
    });
    setpostgresPartitionColumn(updatedPartitonColumn);
  };
  const [postgrespartitonColValue, setpostgresPartitonColValue] = useState("");
  //  ------------------------ends
  return [
    postgresDataValues,
    setpostgresParamsValue,
    handlepostgresServerValuesChange,
    postgresError,
    handlepostgresqueryDataBaseName,
    postgresselectedOption,
    handlepostgresSelectChange,
    postgresoptionsArray,
    postgresServerdataQueries,
    handlepostgresQueriesTableNameChange,
    postgresqueries,
    handlepostgresQueriesChange,
    postgrescheckboxValues,
    handlepostgresCheckboxChange,
    postgrespartitonColValue,
    handlepostgresQueryPartitionChange,
    addMorepostgresServerQueries,
    postgrestableHead,
    postgrestableData,
    handlepostgresDatabaseNameChange,
    postgresdatabaseNameLoader,
    postgresdatabaseName,
    handlepostgresSchemaNameChange,
    postgresschemas,
    handlepostgresTableNameChange,
    postgrestables,
    handlepostgresTableIngestModeOption,
    postgrestableOptions,
    handlepostgresDeltaColumnChange,
    postgresdeltaColumn,
    handlepostgresPartionChange,
    handlepostgresPartitioningChange,
    postgrespartitionColumn,
    postgresaddRow,
  ];
};

export default usePostgress;
