import { useState, useRef } from "react";
import { useDropzone } from "react-dropzone";

const UseCSV = () => {
  const [errors, setErrors] = useState([]);
  const [csvValue, setCsvValue] = useState({
    sourcePath: "",
    header: false,
    fileFormat: "",
    seperator: "",
  });
  const inputData = useState(true);
  const upload = useState(true);
  const CSVData = [
    {
      id: 1,
      name: "sourcePath",
      title: "Destination Bucket*",
      checkbox: false,
      value: csvValue.sourcePath,
      required: true,
    },
    {
      id: 2,
      name: "fileFormat",
      title: "File Format*",
      checkbox: false,
      value: csvValue.fileFormat,
      required: true,
    },
    {
      id: 3,
      name: "header",
      title: "Header*",
      checkbox: true,
      value: csvValue.header,
      required: true,
    },
    {
      id: 4,
      name: "seperator",
      title: "Seperator*",
      checkbox: false,
      value: csvValue.seperator,
      required: true,
    },
  ];
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (type === "checkbox") {
      setCsvValue((prevCsvValue) => ({
        ...prevCsvValue,
        [name]: checked,
      }));
    } else {
      setCsvValue((prevCsvValue) => ({
        ...prevCsvValue,
        [name]: value,
      }));
    }
  };
  const [files, setFiles] = useState([]);
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");
  const [progress, setProgress] = useState(0);

  // dropZone
  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      accept: {
        "text/csv": [".csv"],
      },
      maxFilesize: 2,
      maxFiles: 3,
      maxSize: 1000 * 1024 * 1024, // 1GB
      onDrop: (acceptedFiles) => {
        setFiles(acceptedFiles.map((file) => Object.assign(file)));
      },
    });
  // delete from the ui
  const handleRemove = (name) => {
    setFiles(files.filter((file) => file.name !== name));
  };

  const handlePostCSVData = (e) => {
    e.preventDefault();
    setErrors("");
    setErrMsg("");
    setProgress("completed");
  };
  return [
    inputData,
    CSVData,
    errors,
    handleChange,
    upload,
    files,
    progress,
    handleRemove,
    fileRejections,
    getRootProps,
    isDragActive,
    getInputProps,
    errMsg,
    errRef,
    handlePostCSVData,
  ];
};

export default UseCSV;
