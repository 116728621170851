import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import databoatLogo from "../../images/databoat.png";
import { StyledPortalHeader, StyledDashboardContentFlex, Flex } from "../index";
import { useGetPoolData } from "../../clientRequest/env-variable";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import Search from "../reusableComponents/Search";
import Avatar from "./Avatar";
import { FlexDocs } from "../styles/Login.Styled";
import { Tooltip } from "react-tooltip";
import ArticleIcon from "@mui/icons-material/Article";

const PortalHeader = () => {
  // eslint-disable-next-line no-unused-vars
  const [notification, setNotification] = useState(0);
  const [currentUser, setCurrentUser] = useState("");
  const getPoolData = useGetPoolData();

  const getCurrentUsername = () => {
    const UserPool = new CognitoUserPool(getPoolData);
    const cognitoUser = UserPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          console.error("Error getting user session:", err);
        } else {
          const userEmail = cognitoUser.storage.created;
          setCurrentUser(userEmail);
        }
      });
    }
  };
  useEffect(() => {
    if (getPoolData.ClientId) {
      getCurrentUsername();
    }
  });
  return (
    <StyledPortalHeader>
      <StyledDashboardContentFlex justifyContent>
        <Flex>
          <Link to="/dataManagement">
            <img src={databoatLogo} alt="Data boat logo" />
          </Link>
          <Search />
        </Flex>

        <FlexDocs>
          <Link to="https://docs.databoat.nl/">
            <div className={"my-source-bucket"}>
              <Tooltip
                anchorSelect=".my-source-bucket"
                place="top"
                className="hover-info-container"
              >
                <span>Documentation</span>
              </Tooltip>

              <ArticleIcon
                style={{
                  fill: "white",
                  fontSize: "1.5em",
                  transform: "scale(2.60)",
                  padding: "8px 0 0 0 ",
                }}
              />
            </div>
          </Link>
        </FlexDocs>
        <Avatar name={currentUser} />
      </StyledDashboardContentFlex>
    </StyledPortalHeader>
  );
};

export default PortalHeader;
