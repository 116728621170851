import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import DocumentPageLayout from "../DocumentPageLayout";

const Connectors = () => {
  const { t } = useTranslation();
  const contentArr = [
    {
      name: t("general.connectors"),
      link: "#connectors",
    },
  ];

  return (
    <DocumentPageLayout breadcrumbs={contentArr}>
      <Grid container>
        <Grid item>
          <h1 id="connectors">{t("general.connectors")}</h1>

          <p>
            DataBoat supports integration with a variety of data connectors:
          </p>

          <Grid
            container
            alignItems="flex-start"
            sx={{ paddingBottom: "20px" }}
          >
            <Grid item xs>
              <ul>
                <li>S3</li>
                <li>MySQL</li>
                <li>SQL Server</li>
                <li>Postgres SQL</li>
                <li>GCS</li>
                <li>API</li>
                <li>Oracle</li>
                <li>BigQuery</li>
                <li>CSV files</li>
                <li>Kafka</li>
              </ul>
            </Grid>

            <Grid item sx={{ maxWidth: "400px" }}>
              <img
                src="https://lirp.cdn-website.com/b29d841e/dms3rep/multi/opt/Screenshot+2024-04-22+at+15.34.44+%281%29-1920w.png"
                alt=""
                width="400px"
              />
            </Grid>
          </Grid>
          <p>
            Allowing you to leverage your existing data sources. If you have a
            specific need, discuss with our technical team the possibility of
            integrating it into DataBoat as well.
          </p>
        </Grid>
      </Grid>
    </DocumentPageLayout>
  );
};

export default Connectors;
