import {
  Stack,
  TextField,
  InputLabel,
  Tooltip,
  tooltipClasses,
  Autocomplete,
  InputAdornment,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { PreLoader, Theme } from "../../../index";
import InfoIcon from "@mui/icons-material/Info";
import SearchIcon from "@mui/icons-material/Search";
import ReversedSideBar from "./ReversedSideBar";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  fetchMetadataColumns,
  fetchMetadataTables,
  fetchMetadataDataset,
} from "../../../redux/actions/metaDataAction";
import useGetToken from "../../../cognito/useGetToken";
import { updateOverElementIndex } from "../../../redux/reducers/elementsDndReducer";
import useReversedSync from "./hooks/useReversedSync";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
    maxWidth: "fit-content",
  },
}));

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: "1em",
    border: "1px solid rgb(0, 0, 0)",
    color: "rgb(66, 71, 78)",
    font: "400 16px / 24px Poppins, sans-serif",
    transition: "all 0.5s ease 0s",
    outline: "none",
    opacity: "0.6",
    borderRadius: "4px",
    "&:focus": {
      border: "2.5px solid #006399",
      color: "#006399",
    },
  },
  input: {
    border: "none",
    outline: "none",
    width: "100%",
    color: "#42474E",
    font: "normal 400 16px/24px 'Poppins',sans-serif",
    WebkitTransition: "0.5s",
    "&:focus": {
      border: "2.5px solid #006399",
      color: "#006399",
    },
  },
};
const PipedriveConnector = ({ onClose, taskId, taskData, setTaskData }) => {
  const [isDbSideBarOpen, setIsDbSideBarOpen] = useState(true);
  const {
    pipeDriveObjects,
    selectedPipeDriveObject,
    pipeDriveObjectsLoader,
    handlePipeDriveAPIChange,
    handleSelectPipeDrive,
  } = useReversedSync();
  const [dragAndDropQuery, setDragAndDropQuery] = useState("");
  const { environmentVariables } = useSelector((state) => state.users);
  const { metaDataTablesLoader } = useSelector((state) => state.metaData);
  const [dndScale, setDndScale] = useState(0.6);
  const dispatch = useDispatch();
  const groupName = localStorage.getItem("group");
  const { token } = useGetToken();

  const getColumns = (table_name) => {
    if (environmentVariables && token && groupName) {
      dispatch(
        fetchMetadataColumns(
          table_name,
          token,
          groupName,
          environmentVariables.REACT_APP_BASE_URL_API_METADATA,
        ),
      );
    }
  };

  const { elements } = useSelector((state) => state.elementsDnd);
  const [overDragArea, setOverDragArea] = useState(false);
  const [overDraggableTable, setOverDraggableTable] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [, setElements] = useState([]);
  const [, setDragElementIndex] = useState(null);
  const draggableTableProps = {
    x: mousePosition.x,
    y: mousePosition.y,
    index: elements.length,
    setDragElement: setDragElementIndex,
    setElements,
    overDragArea,
    setOverDragArea,
    overDraggableTable,
    setOverDraggableTable,
  };

  useEffect(() => {
    if (environmentVariables && token && groupName) {
      dispatch(
        fetchMetadataDataset(
          token,
          groupName,
          environmentVariables.REACT_APP_BASE_URL_API_METADATA,
        ),
      );
      dispatch(
        fetchMetadataTables(
          token,
          groupName,
          environmentVariables.REACT_APP_BASE_URL_API_METADATA,
        ),
      );
    }
  }, [dispatch, token, environmentVariables, groupName]);

  return pipeDriveObjectsLoader ? (
    <Stack p={4} spacing={3}>
      <Stack spacing={2}>
        <Stack direction="row" spacing={1}>
          <InputLabel
            htmlFor="api-key"
            sx={{ font: Theme.fonts.fontSecondaryBold }}
          >
            Inform the API Key from Personal Preferences
          </InputLabel>

          <HtmlTooltip
            title={
              <img
                src="../../../../images/pipedrive-apikey-print.jpeg"
                alt="PipeDrive API Key"
                height={400}
                width={550}
              />
            }
            placement="right"
          >
            <InfoIcon />
          </HtmlTooltip>
        </Stack>
        <TextField
          id="api-key"
          variant="outlined"
          placeholder="Enter your API Key"
          sx={styles.input}
          defaultValue={""}
          onChange={(event) =>
            handlePipeDriveAPIChange(event, taskId, taskData, setTaskData)
          }
        />
      </Stack>

      {/* objects starts here */}
      <Stack spacing={1} direction={"column"}>
        <InputLabel
          htmlFor="api-key"
          sx={{ font: Theme.fonts.fontSecondaryBold }}
        >
          PipeDrive Object
        </InputLabel>

        <Autocomplete
          disablePortal
          value={selectedPipeDriveObject}
          onChange={(event, newValue) => {
            handleSelectPipeDrive(
              event,
              taskId,
              newValue ? newValue.label : "",
              taskData,
              setTaskData,
            ); // Pass the selected label
          }}
          freeSolo
          id="select-folder"
          options={
            pipeDriveObjects
              ? pipeDriveObjects[0].attributes.map((object) => ({
                  label: object,
                }))
              : []
          }
          renderInput={(params) => (
            <Stack spacing={2}>
              <TextField
                {...params}
                placeholder="Search object by name"
                variant="outlined"
                sx={styles.input}
                InputProps={{
                  ...params.InputProps, // Merging the essential InputProps from params
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          )}
        />
      </Stack>
      {/* objects ends here */}

      {/* Drag and drop mechnaism starts here */}
      <Stack direction={"row"} gap={2}>
        <div className="dataBaseListContainer">
          <ReversedSideBar
            dbLoader={metaDataTablesLoader}
            getColumns={getColumns}
            draggableTableProps={draggableTableProps}
            dragAndDropQuery={dragAndDropQuery}
            setDragAndDropQuery={setDragAndDropQuery}
            isDbSideBarOpen={isDbSideBarOpen}
            setIsDbSideBarOpen={setIsDbSideBarOpen}
            setTaskData={setTaskData}
          />
        </div>
        <div
          onDragOver={(e) => {
            const rect = e.target.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;
            setMousePosition({ x, y });
            setOverDragArea(true);
            setOverDraggableTable(false);
            dispatch(updateOverElementIndex({ index: -1, x, y }));
          }}
          style={{
            width: "100%",
            position: "relative",
            overflow: "scroll",
            scrollbarWidth: "thin",
            scrollbarColor: "#888 #f1f1f1",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: 20,
              top: 20,
              right: 20,
              display: "flex",
              flexDirection: "column",
              gap: 4,
              zIndex: 2,
            }}
          >
            <button
              onClick={() => {
                setDndScale((prev) => prev + 0.1);
              }}
              style={{
                padding: 4,
                width: 24,
                height: 24,
                border: "0px",
                borderRadius: 4,
                backgroundColor: "rgb(0, 99, 153)",
                color: "white",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "red",
                },
              }}
            >
              +
            </button>
            <button
              style={{
                padding: 4,
                width: 24,
                height: 24,
                border: "0px",
                borderRadius: 4,
                backgroundColor: "rgb(0, 99, 153)",
                color: "white",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "red",
                },
              }}
              onClick={() => setDndScale((prev) => prev - 0.1)}
            >
              -
            </button>
          </div>
          <div
            style={{
              transform: `scale(${dndScale})`,
              transformOrigin: "0% 0%",
              position: "relative",
              zIndex: 5,
            }}
          >
            {elements.length > 0 &&
              elements.map((element, index) => (
                <div key={index}>
                  <div
                    style={{
                      position: "absolute",
                      left: index * 400,
                      top: element.props.y,
                      width: "350px",
                      padding: "0.5em",
                      height: "350px",
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      overflow: "scroll",
                      zIndex: 10,
                      cursor: "-moz-grab",
                    }}
                    draggable={true}
                  >
                    {element}
                  </div>
                  {index > 0 && (
                    <Divider
                      orientation="horizontal"
                      sx={{
                        position: "absolute",
                        top: element.props.y + 150,
                        left: (index - 1) * 400 + 350,
                        width: "50px",
                        height: "3px",
                        backgroundColor: "black",
                        cursor: "pointer",
                        zIndex: 5,
                      }}
                    />
                  )}
                </div>
              ))}
          </div>
        </div>
      </Stack>
      {/* Drag and drop mechnaism ends here */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <button className={"next_btn save_btn"} onClick={onClose}>
          Save
        </button>
      </div>
    </Stack>
  ) : (
    <PreLoader />
  );
};

export default PipedriveConnector;
