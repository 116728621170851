/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import useQuery from "../hooks/useQuery";
import SchedulerModal from "../Transformation/SchedulerModal";
import ButtonFilledIcon from "../../reusableComponents/UIComponents/ButtonFilledIcon";
import ButtonOutlineIcon from "../../reusableComponents/UIComponents/ButtonOutlineIcon";
import { HeaderTabButton } from "../Transformation/Styles/TransformationStyle";
import { StyledDashboardContentFlex, Theme } from "../../index";
import axios from "axios";
import { Stack, Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import PreLoader from "../../reusableComponents/PreLoader";
import { fetchUserScripts } from "../../redux/actions/customerAction";
import { clearQeuryCatch } from "../../redux/actions/usersActions";
import {
  updateOverElementIndex,
  clearElements,
} from "../../redux/reducers/elementsDndReducer";

import { LuDownload } from "react-icons/lu";
import {
  PiCaretDownLight,
  PiCaretRightLight,
  PiPlayLight,
  PiDatabaseLight,
} from "react-icons/pi";
import { useTranslation } from "react-i18next";
import DragAndDropResultTable from "./DragAndDropResultTable";
import RelationshipModal from "./RelationshipModal";
import SaveResultModel from "./SaveResultModel";
import SideBar from "./SideBar";
import { useNavigate } from "react-router-dom";
import CreateTableModal from "./CreateTableModal";
import useGetToken from "../../cognito/useGetToken";
import SQLError from "../Transformation/Components/SQLError";

const DragAndDropModal = ({
  getColumns,
  draggableTableProps,
  setOverDragArea,
  setOverDraggableTable,
  setMousePosition,
}) => {
  const { elements } = useSelector((state) => state.elementsDnd);
  const { metaDataTablesLoader } = useSelector((state) => state.metaData);
  const navigate = useNavigate();
  const [dragAndDropQuery, setDragAndDropQuery] = useState("");
  const { token } = useGetToken();
  const dispatch = useDispatch();
  const [isRunActive, setIsRunActive] = React.useState(false);
  const [isOpenResults, setIsOpenResults] = React.useState(false);
  const [saveDrag, setSaveDrag] = React.useState(false);
  const bundleRef = React.useRef();
  const groupName = localStorage.getItem("group");
  const user = localStorage.getItem("created");
  const handleOpenResults = () => {
    setIsOpenResults(!isOpenResults);
  };

  const [joinQuery, setJoinQuery] = React.useState("");
  const { t } = useTranslation();
  const [dndScale, setDndScale] = React.useState(0.6);
  const {
    environmentVariables,
    postDRAGQuerySuccess,
    postDRAGQueryError,
    getDRAGQueryResultSuccess,
    postDRAGQueryLoader,
    getDRAGQueryResultLoader,
    getQueryResultError,
    getDRAGQueryResultError,
  } = useSelector((state) => state.users);
  const { runDRAGQuery, getDRAGQueryResult, handleExportCSV } = useQuery();
  const { allCustomers, allCustomersLoader } = useSelector(
    (state) => state.customers,
  );
  const handleRunSQL = () => {
    if (allCustomers !== null) {
      runDRAGQuery(
        allCustomers[0],
        joinQuery ? joinQuery : dragAndDropQuery,
        token,
        environmentVariables.REACT_APP_BASE_URL_API_CORE,
      );
    }
    setIsOpenResults(true);
  };
  React.useEffect(() => {
    if (allCustomersLoader) {
      bundleRef.current = allCustomers[0].bundle;
    }
  }, [allCustomersLoader]);
  React.useEffect(() => {
    if (postDRAGQuerySuccess !== undefined && token) {
      if (allCustomers !== null) {
        getDRAGQueryResult(
          allCustomers[0],
          token,
          environmentVariables.REACT_APP_BASE_URL_API_CORE,
          postDRAGQuerySuccess.jobId,
          dispatch(clearQeuryCatch()),
        );
        dispatch(clearQeuryCatch());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    postDRAGQuerySuccess,
    allCustomers,
    environmentVariables.REACT_APP_BASE_URL_API_CORE,
    token,
    dispatch,
  ]);
  React.useEffect(() => {
    if (dragAndDropQuery !== "") {
      setIsRunActive(true);
    }
  }, [dragAndDropQuery]);

  const [openRelationshipModel, setOpenRelationshipModel] =
    React.useState(false);

  const handleCloseRelationshipModel = () => {
    setOpenRelationshipModel(false);
  };

  const handleClick = () => {
    setOpenRelationshipModel(true);
  };
  const [newQuery, setNewQuery] = React.useState([
    { id: "", result: null, query: "", tableName: "", datasetName: "" },
  ]);
  const [currentQueryIndex, setCurrentQueryIndex] = useState(0);

  // Initialize newQuery state based on elements
  useEffect(() => {
    if (elements.length > 0) {
      const queries = elements.map((item) => ({
        query: `select * from ${item.props.databaseName}.${item.props.tableName} limit 10`,
        tableName: item.props.tableName,
        id: "", // Initialize with empty id
        result: null, // Initialize with null result
        datasetName: item.props.databaseName,
      }));
      setNewQuery(queries);
      setCurrentQueryIndex(0); // Reset current query index
    }
  }, [elements, setNewQuery]);

  // Effect to process each query and get an ID
  useEffect(() => {
    const processQuery = async (queryObj, index) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const formData = {
        ssmParameterName: allCustomers[0].cloudCredential[0].ssmPathParam,
        query: queryObj.query,
      };

      try {
        const res = await axios.post(
          `${environmentVariables.REACT_APP_BASE_URL_API_CORE}/transformation/query`,
          formData,
          { headers },
        );
        // Update newQuery with the response data
        setNewQuery((prevQueries) =>
          prevQueries.map((prevQueryObj, idx) =>
            idx === index
              ? { ...prevQueryObj, id: res.data.jobId }
              : prevQueryObj,
          ),
        );
        setCurrentQueryIndex((prevIndex) => prevIndex + 1); // Move to the next query
      } catch (error) {
        console.error("Error executing query:", error);
      }
    };
    if (
      elements.length > 1 &&
      allCustomers !== null &&
      currentQueryIndex < newQuery.length
    ) {
      const currentQueryObj = newQuery[currentQueryIndex];
      if (currentQueryObj.id === "" && token) {
        processQuery(currentQueryObj, currentQueryIndex);
      }
    }
  }, [
    elements,
    allCustomers,
    token,
    environmentVariables,
    newQuery,
    currentQueryIndex,
    setNewQuery,
  ]);
  // Function to get query result
  const getQueryResults = async (jobId) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const formData = {
      ssmParameterName: allCustomers[0].cloudCredential[0].ssmPathParam,
    };
    try {
      const res = await axios.post(
        `${environmentVariables.REACT_APP_BASE_URL_API_CORE}/transformation/result?jobId=${jobId}`,
        formData,
        { headers },
      );
      return res.data;
    } catch (error) {
      console.error("Error fetching query result:", error);
      return null;
    }
  };
  const [tableName, setTableName] = useState("");
  const [datasetName, setDatasetName] = useState("");
  const [tableError, setTableError] = useState("");
  const [createTableModal, setCreateTableModal] = useState(false);
  const handleOpenCreateTableModal = () => {
    if (joinQuery !== "" || dragAndDropQuery !== "") {
      setCreateTableModal(true);
    }
  };
  const handleSaveAsTable = () => {
    const query = joinQuery ? joinQuery : dragAndDropQuery;
    if (tableName && datasetName) {
      const createTableQuery = `
        CREATE TABLE ${datasetName}.${tableName} AS ${query};
      `;
      setJoinQuery(createTableQuery);
    } else {
      setTableError("Fields cannot be Empty");
    }

    setCreateTableModal(false);
  };

  // Effect to fetch results for each query with a non-empty ID
  useEffect(() => {
    const fetchResults = async () => {
      for (let i = 0; i < newQuery.length; i++) {
        if (newQuery[i].id !== "" && newQuery[i].result === null) {
          const result = await getQueryResults(newQuery[i].id);
          setNewQuery((prevQueries) =>
            prevQueries.map((prevQueryObj, index) =>
              index === i ? { ...prevQueryObj, result } : prevQueryObj,
            ),
          );
        }
      }
    };

    if (allCustomers !== null && newQuery.length > 0 && token) {
      fetchResults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    elements,
    allCustomers,
    token,
    environmentVariables,
    newQuery,
    currentQueryIndex,
    setNewQuery,
  ]);
  useEffect(() => {
    if (token) {
      dispatch(
        fetchUserScripts(
          token,
          groupName,
          user,
          environmentVariables.REACT_APP_BASE_URL_API_CUSTOMER,
        ),
      );
    }
  }, [token, dispatch]);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);

  const [existingJob, setExistingJob] = useState("");
  const [scriptName, setScriptName] = useState("");

  const [newJob, setNewJob] = useState(true);
  const proceedButtonDisabled = () => {
    if (newJob === "") {
      return true;
    }
    if (!newJob && existingJob === "") {
      return true;
    }

    return false;
  };
  const handleScheduleModal = () => {
    setIsScheduleModalOpen((prev) => !prev);
  };
  const handleScriptNameChange = (e) => {
    setScriptName(e.target.value);
  };
  const proceed = proceedButtonDisabled();
  function formatSqlQuery(query) {
    return query
      .split("\n")
      .map((line) => line.trim())
      .join(" ");
  }
  const proceedOnScheduleModal = () => {
    const value = formatSqlQuery(joinQuery ? joinQuery : dragAndDropQuery);
    if (newJob) {
      navigate(
        `/create-job?taskName=${scriptName}&taskType=transformation&script=${value}&sourceType=${"sql"}`,
      );
    }

    if (!newJob && existingJob !== "") {
      navigate(
        `/edit-job/${existingJob}?taskName=${scriptName}&taskType=transformation&script=${value}`,
      );
    }
  };
  const [isDbSideBarOpen, setIsDbSideBarOpen] = useState(true);
  useEffect(() => {
    // This effect runs when component unmounts or navigates away
    return () => {
      // Dispatch action to clear elements when component unmounts
      dispatch(clearElements());
    };
  }, [dispatch]);
  return (
    <div>
      {metaDataTablesLoader ? (
        <>
          <Stack direction={"row"} gap={2}>
            <div className="dataBaseListContainer">
              <SideBar
                dbLoader={metaDataTablesLoader}
                getColumns={getColumns}
                draggableTableProps={draggableTableProps}
                dragAndDropQuery={dragAndDropQuery}
                setDragAndDropQuery={setDragAndDropQuery}
                isDbSideBarOpen={isDbSideBarOpen}
                setIsDbSideBarOpen={setIsDbSideBarOpen}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "3em",
                width: isDbSideBarOpen ? "calc(100% - 360px)" : "93%",
              }}
            >
              <Stack direction={"row"} spacing={2}>
                <ButtonFilledIcon
                  label={"Run"}
                  disabled={!isRunActive}
                  style={{ cursor: !isRunActive ? "not-allowed" : "pointer" }}
                  handleOnClick={() => {
                    handleRunSQL();
                  }}
                  icon={
                    <PiPlayLight
                      size={"18px"}
                      color={`${Theme.colors.whiteColor}`}
                    />
                  }
                />
                <ButtonFilledIcon
                  label={"Save"}
                  disabled={!isRunActive}
                  style={{ cursor: !isRunActive ? "not-allowed" : "pointer" }}
                  handleOnClick={() => {
                    setSaveDrag(true);
                  }}
                  icon={
                    <PiPlayLight
                      size={"18px"}
                      color={`${Theme.colors.whiteColor}`}
                    />
                  }
                />
                <ButtonFilledIcon
                  label={"Create Table"}
                  disabled={!isRunActive}
                  style={{ cursor: !isRunActive ? "not-allowed" : "pointer" }}
                  handleOnClick={handleOpenCreateTableModal}
                  icon={<PiDatabaseLight size={"18px"} color={"white"} />}
                />
                <ButtonOutlineIcon
                  label={t(
                    "data_management.transformation.presenter.buttons.schedule",
                  )}
                  disabled={scriptName === ""}
                  style={{
                    cursor: scriptName === "" ? "not-allowed" : "pointer",
                  }}
                  handleOnClick={handleScheduleModal}
                />
              </Stack>
              <Stack>
                <div
                  onDragOver={(e) => {
                    const rect = e.target.getBoundingClientRect();
                    const x = e.clientX - rect.left;
                    const y = e.clientY - rect.top;
                    setMousePosition({ x, y });
                    setOverDragArea(true);
                    setOverDraggableTable(false);
                    dispatch(updateOverElementIndex({ index: -1, x, y }));
                  }}
                  style={{
                    height: isOpenResults ? "40vh" : "50vh",
                    width: "100%",
                    position: "relative",
                    overflow: "scroll",
                    scrollbarWidth: "thin",
                    scrollbarColor: "#888 #f1f1f1",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      width: 20,
                      top: 20,
                      right: 20,
                      display: "flex",
                      flexDirection: "column",
                      gap: 4,
                      zIndex: 2,
                    }}
                  >
                    <button
                      onClick={() => {
                        setDndScale((prev) => prev + 0.1);
                      }}
                      style={{
                        padding: 4,
                        width: 24,
                        height: 24,
                        border: "0px",
                        borderRadius: 4,
                        backgroundColor: "rgb(0, 99, 153)",
                        color: "white",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "red",
                        },
                      }}
                    >
                      +
                    </button>
                    <button
                      style={{
                        padding: 4,
                        width: 24,
                        height: 24,
                        border: "0px",
                        borderRadius: 4,
                        backgroundColor: "rgb(0, 99, 153)",
                        color: "white",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "red",
                        },
                      }}
                      onClick={() => setDndScale((prev) => prev - 0.1)}
                    >
                      -
                    </button>
                  </div>
                  <div
                    style={{
                      transform: `scale(${dndScale})`,
                      transformOrigin: "0% 0%",
                      position: "relative",
                      zIndex: 5,
                    }}
                  >
                    {elements.length > 0 &&
                      elements.map((element, index) => (
                        <div key={index}>
                          <div
                            style={{
                              position: "absolute",
                              // left:element.props.x ,
                              left: index * 400, // // Adjust the spacing here
                              top: element.props.y,
                              width: "350px",
                              padding: "0.5em",
                              height: "350px",
                              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                              overflow: "scroll",
                              zIndex: 10,
                              cursor: "-moz-grab",
                            }}
                            draggable={true}
                            // onDragEnd={() =>
                            //   handleDragEnd(
                            //     index,
                            //     element.props.x,
                            //     element.props.y,
                            //   )
                            // }
                          >
                            {element}
                          </div>
                          {index > 0 && (
                            <Divider
                              orientation="horizontal"
                              sx={{
                                position: "absolute",
                                top: element.props.y + 150,
                                left: (index - 1) * 400 + 350,
                                width: "50px", // Adjust the length as needed
                                height: "3px", // Adjust the thickness as needed
                                backgroundColor: "black",
                                cursor: "pointer",
                                zIndex: 5,
                              }}
                              onClick={handleClick}
                            />
                          )}
                        </div>
                      ))}
                  </div>
                </div>
                <StyledDashboardContentFlex
                  justifyContent
                  style={{ padding: "1.5em 0" }}
                >
                  <div className="plusResultContainer">
                    <HeaderTabButton
                      onClick={() => {
                        handleOpenResults();
                      }}
                      selected={isOpenResults}
                    >
                      {isOpenResults ? (
                        <PiCaretDownLight size={24} />
                      ) : (
                        <PiCaretRightLight size={24} />
                      )}
                      {t(
                        "data_management.transformation.presenter.footer.results",
                      )}
                    </HeaderTabButton>
                  </div>
                  <HeaderTabButton
                    style={{
                      textTransform: "uppercase",
                      cursor:
                        getDRAGQueryResultSuccess === null
                          ? "not-allowed"
                          : "pointer",
                    }}
                    disabled={getDRAGQueryResultSuccess === null}
                    onClick={() =>
                      handleExportCSV(
                        getDRAGQueryResultSuccess,
                        "drag and drop query",
                      )
                    }
                  >
                    <LuDownload />
                    {t(
                      "data_management.transformation.presenter.footer.save_results",
                    )}
                  </HeaderTabButton>
                </StyledDashboardContentFlex>
                {isOpenResults && (
                  <DragAndDropResultTable
                    queryResults={getDRAGQueryResultSuccess}
                    postDRAGQuerySuccess={postDRAGQuerySuccess}
                    postDRAGQueryError={postDRAGQueryError}
                    postDRAGQueryLoader={postDRAGQueryLoader}
                    isDbSideBarOpen={isDbSideBarOpen}
                    getDRAGQueryResultLoader={getDRAGQueryResultLoader}
                  />
                )}
                {(postDRAGQueryError ||
                  getQueryResultError ||
                  getDRAGQueryResultError) && (
                  <SQLError
                    value={
                      postDRAGQueryError ||
                      getQueryResultError ||
                      getDRAGQueryResultError
                    }
                  />
                )}
              </Stack>
            </div>
          </Stack>
          {isScheduleModalOpen && (
            <SchedulerModal
              isScheduleModalOpen={isScheduleModalOpen}
              setIsScheduleModalOpen={setIsScheduleModalOpen}
              newJob={newJob}
              setNewJob={setNewJob}
              existingJob={existingJob}
              proceed={proceed}
              proceedOnScheduleModal={proceedOnScheduleModal}
              setExistingJob={setExistingJob}
            />
          )}
          {openRelationshipModel && (
            <RelationshipModal
              runQuery={runDRAGQuery}
              environmentVariables={environmentVariables}
              postQuerySuccess={postDRAGQuerySuccess}
              getQueryResult={getDRAGQueryResultSuccess}
              openRelationshipModel={openRelationshipModel}
              handleCloseRelationshipModel={handleCloseRelationshipModel}
              elements={elements}
              setJoinQuery={setJoinQuery}
              newQuery={newQuery}
              setNewQuery={setNewQuery}
            />
          )}
          {saveDrag && (
            <SaveResultModel
              scriptName={scriptName}
              joinQuery={joinQuery}
              setSaveDrag={setSaveDrag}
              saveDrag={saveDrag}
              dragAndDropQuery={dragAndDropQuery}
              handleScriptNameChange={handleScriptNameChange}
              bundleRef={bundleRef}
            />
          )}

          {createTableModal && (
            <CreateTableModal
              tableName={tableName}
              setTableName={setTableName}
              datasetName={datasetName}
              setDatasetName={setDatasetName}
              createTableModal={createTableModal}
              setCreateTableModal={setCreateTableModal}
              tableError={tableError}
              handleSaveAsTable={handleSaveAsTable}
            />
          )}
        </>
      ) : (
        <PreLoader />
      )}
    </div>
  );
};
export default DragAndDropModal;
