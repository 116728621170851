import React from "react";
import {
  StyledDashboardContentFlex,
  Flex,
  StyledP,
  StyledFileImports,
  Theme,
} from "../index";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const DragandDrop = ({
  fileRejections,
  getRootProps,
  isDragActive,
  getInputProps,
  errMsg,
  errRef,
}) => {
  const { t } = useTranslation();
  return (
    <StyledDashboardContentFlex column>
      <p
        style={{
          color: Theme.colors.blueColor,
          font: Theme.fonts.fontPrimary,
          letterspacing: Theme.fonts.primaryletterSpacing,
        }}
      >
        {t("data_management.upload.drag_drop.title")}
      </p>
      <StyledFileImports {...getRootProps()}>
        <Flex column gap center centerAlign>
          <AiOutlineCloudUpload size={"4em"} />
          <div>
            <label htmlFor="file-input">
              <StyledDashboardContentFlex
                gap
                justifyContent
                style={{ padding: "1em" }}
              >
                {isDragActive ? (
                  <StyledP>
                    {t("data_management.upload.drag_drop.active")}
                  </StyledP>
                ) : (
                  <Flex column gap>
                    <StyledP>
                      {t("data_management.upload.drag_drop.description_one")}
                    </StyledP>
                    <StyledP className="browse">
                      {t("data_management.upload.drag_drop.description_two")}
                    </StyledP>
                    <StyledP>
                      {t("data_management.upload.drag_drop.description_three")}{" "}
                      <span {...getRootProps()} className="browse">
                        browse
                      </span>
                    </StyledP>
                    <StyledP>
                      {t("data_management.upload.drag_drop.description_four")}
                    </StyledP>
                  </Flex>
                )}
              </StyledDashboardContentFlex>
            </label>
            <input
              {...getInputProps()}
              id="file-input"
              type="file"
              className="upload_input"
              name="csv"
              accept="application/json, application/vnd.ms-excel, text/csv"
            />
          </div>
        </Flex>
      </StyledFileImports>
      <div>
        {fileRejections.length !== 0 ? (
          <StyledDashboardContentFlex style={{ gap: "0.5em" }}>
            <StyledP style={{ color: "red", fontStyle: "italic" }}>
              {fileRejections[0].errors[0].code},
            </StyledP>
            <StyledP style={{ color: "red", fontStyle: "italic" }}>
              {fileRejections[0].errors[0].message}.
            </StyledP>
          </StyledDashboardContentFlex>
        ) : null}
        <div>
          {errMsg && (
            <section className="error">
              <p ref={errRef} aria-live="assertive">
                {errMsg}
              </p>
            </section>
          )}
        </div>
      </div>
    </StyledDashboardContentFlex>
  );
};

export default DragandDrop;
