import React, { useState } from "react";
import {
  JobDetailsTable,
  JobDetailsTableHeader,
  StatusSquare,
  TaskNameCell,
  TaskOccurrenceCell,
} from "../../styles/Yedaflow.Styled";
import moment from "moment";

const JobRunsTable = ({ lastItems, handleTaskClick }) => {
  const uniqueTaskNames = lastItems
    ? lastItems[0].task.map((task) => task.task_name)
    : [];
  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoveredColumn, setHoveredColumn] = useState(null);

  const handleHover = (rowIndex, columnIndex) => {
    setHoveredRow(rowIndex);
    setHoveredColumn(columnIndex);
  };
  return (
    <div
      style={{
        overflow: "auto",
      }}
    >
      <JobDetailsTable>
        <colgroup>
          <col width={200} />
        </colgroup>
        <thead>
          <tr>
            <th
              style={{
                border: "0 solid black",
                position: "sticky",
                left: 0,
                zIndex: 2,
                backgroundColor: "#fcfcff",
                height: 120,
                borderBottom: "1px solid #ddd",
              }}
            >
              <p style={{ width: "200px" }}> Task Name</p>
            </th>
            {lastItems !== null &&
              lastItems.map((item, columnIndex) => (
                <JobDetailsTableHeader
                  key={item.execution_date}
                  hoveredColumn={hoveredColumn}
                  columnIndex={columnIndex}
                  onMouseEnter={() => setHoveredColumn(columnIndex)}
                  onMouseLeave={() => setHoveredColumn(null)}
                >
                  <p
                    style={{
                      whiteSpace: "nowrap",
                      margin: "0",
                      transform: "rotate(-60deg)",
                      textAlign: "center",
                    }}
                  >
                    {moment(item.execution_date).format("MMM D hh:mm")}
                  </p>
                </JobDetailsTableHeader>
              ))}
          </tr>
        </thead>
        <tbody>
          {uniqueTaskNames.map((taskName, rowIndex) => (
            <tr key={rowIndex} style={{ height: "100%" }}>
              <TaskNameCell
                style={{
                  backgroundColor:
                    hoveredRow === rowIndex ? "lightblue" : "#fcfcff",
                }}
                onMouseEnter={() => handleHover(rowIndex, null)}
                onMouseLeave={() => handleHover(null, null)}
              >
                {taskName}
              </TaskNameCell>
              {lastItems !== null &&
                lastItems.map((item, columnIndex) => {
                  const task = item.task.find(
                    (task) => task.task_name === taskName,
                  );
                  return (
                    <TaskOccurrenceCell
                      key={columnIndex}
                      style={{
                        backgroundColor:
                          hoveredRow === rowIndex ||
                          hoveredColumn === columnIndex
                            ? "lightblue"
                            : "#fcfcff",
                      }}
                      onMouseEnter={() => handleHover(rowIndex, columnIndex)}
                      onMouseLeave={() => handleHover(null, null)}
                      onClick={() =>
                        item &&
                        handleTaskClick(
                          task,
                          item.execution_id,
                          item.execution_date,
                        )
                      }
                    >
                      <StatusSquare
                        style={{
                          backgroundColor: "pink",
                          ...(!!task && {
                            backgroundColor:
                              task.task_status === "Succeeded" ||
                              task.task_status === "OK"
                                ? "#377E22"
                                : task.task_status === "Running"
                                ? "#75FB4C"
                                : task.task_status === "Pending"
                                ? "grey"
                                : task.task_status === "Failed"
                                ? "red"
                                : task.task_status === null
                                ? "#ED72B2"
                                : "#ED72B2",
                          }),
                        }}
                      />
                    </TaskOccurrenceCell>
                  );
                })}
            </tr>
          ))}
        </tbody>
      </JobDetailsTable>
    </div>
  );
};

export default JobRunsTable;
