/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import useQuery from "../hooks/useQuery";
import { LuDownload } from "react-icons/lu";
import { PiCaretDownLight, PiCaretRightLight } from "react-icons/pi";
import SQLError from "../Transformation/Components/SQLError";
import { StyledButton } from "../../reusableComponents/UIComponents/ButtonTonalIcon";
import ButtonOutlineIcon from "../../reusableComponents/UIComponents/ButtonOutlineIcon";
import BoatGPTLogo from "../../../images/boat_GPT.png";
import {
  HeaderTabButton,
  TransformationResultContainer,
} from "../Transformation/Styles/TransformationStyle";
import { MdSend } from "react-icons/md";
import { FormControl, InputAdornment, TextField, Box } from "@mui/material";
import { PreLoader } from "../../index";
import GPTResult from "./GPTResult";
import { fetchJobs } from "../../redux/actions/usersActions";
import { fetchCustomers } from "../../redux/actions/customerAction";
import SchedulerModal from "../Transformation/SchedulerModal";
import {
  postGPTLlmQueryRequest,
  clearGPTQueryResultSuccess,
} from "../../redux/actions/usersActions";
import {
  HomeSvg,
  StyledDashboardContentFlex,
  StyledPortalContent,
  Tabs,
  Theme,
  TransformationSvg,
} from "../../index";
import { useTranslation } from "react-i18next";
import GPTModal from "./GPTModal";
import useGetToken from "../../cognito/useGetToken";

const BoatGPTContainer = () => {
  const { token } = useGetToken();
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [existingJob, setExistingJob] = useState("");
  const [newJob, setNewJob] = useState(true);
  const groupName = localStorage.getItem("group");
  const [isOpenResults, setIsOpenResults] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const group = localStorage.getItem("group");
  const { t } = useTranslation();
  const { getGPTQueryResult, runGPTQuery, handleExportCSV } = useQuery();
  const { allCustomers, allCustomersLoader } = useSelector(
    (state) => state.customers,
  );
  const bundleRef = useRef(null);
  const {
    environmentVariables,
    postGPTLlmQuestion,
    postGPTQuerySuccess,
    postGPTLlmQuestionError,
    getGPTQueryResultSuccess,
    postGPTLlmQuestionLoader,
    postGPTQueryError,
    postGPTLlmResultLoader,
  } = useSelector((state) => state.users);
  const navigate = useNavigate();
  const fill = Theme.colors.blueColor;
  const size = 18;
  const tabData = [
    {
      to: "/dataManagement",
      icon: <HomeSvg fill={fill} width={size} height={size} />,
      label: t("data_management.transformation.header.home"),
    },
    {
      to: "/transformation",
      icon: <TransformationSvg fill={fill} width={size} height={size} />,
      label: t("data_management.transformation.header.transformation"),
    },
  ];
  const [scriptName, setScriptName] = useState("");
  const handleScriptNameChange = (e) => {
    e.preventDefault();
    setScriptName(e.target.value);
  };
  const ref = useRef("");
  function submitGptForm(e) {
    dispatch(
      postGPTLlmQueryRequest(
        {
          input: { question: ref.current.value },
          index_name: `${group}-catalog`,
          customer_name: group,
        },
        token,
        environmentVariables.REACT_APP_BASE_URL_API_CORE,
      ),
    );
  }
  useEffect(() => {
    if (postGPTQuerySuccess !== undefined && token) {
      if (allCustomers !== null) {
        getGPTQueryResult(
          allCustomers[0],
          token,
          environmentVariables.REACT_APP_BASE_URL_API_CORE,
          postGPTQuerySuccess.jobId,
        );
      }
    }
    //eslint-disable-next-line
  }, [
    postGPTQuerySuccess,
    allCustomers,
    environmentVariables.REACT_APP_BASE_URL_API_CORE,
    token,
  ]);
  const handleRunSQL = (optionalQuery) => {
    dispatch(clearGPTQueryResultSuccess());
    if (allCustomers !== null) {
      runGPTQuery(
        allCustomers[0],
        optionalQuery,
        token,
        environmentVariables.REACT_APP_BASE_URL_API_CORE,
      );
    }
    setIsOpenResults(true);
  };
  useEffect(() => {
    if (
      postGPTLlmQuestion !== null &&
      postGPTLlmQuestion !== "SQL not found."
    ) {
      setIsSaveDisabled(true);
      handleRunSQL(postGPTLlmQuestion);
    }
    //eslint-disable-next-line
  }, [postGPTLlmQuestion]);

  useEffect(() => {
    if (token) {
      dispatch(
        fetchJobs(
          token,
          environmentVariables.REACT_APP_BASE_URL_API_CORE,
          groupName,
        ),
      );
      dispatch(
        fetchCustomers(
          token,
          group,
          environmentVariables.REACT_APP_BASE_URL_API_CUSTOMER,
        ),
      );
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (allCustomersLoader) {
      bundleRef.current = allCustomers[0].bundle;
    }
  }, [allCustomersLoader]);
  const handleOpenResults = () => {
    setIsOpenResults(!isOpenResults);
  };
  const proceedButtonDisabled = () => {
    if (newJob === "") {
      return true;
    }
    if (!newJob && existingJob === "") {
      return true;
    }
    return false;
  };
  const proceed = proceedButtonDisabled();
  function formatSqlQuery(query) {
    return query
      .split("\n")
      .map((line) => line.trim())
      .join(" ");
  }
  const proceedOnScheduleModal = () => {
    const value = formatSqlQuery(postGPTLlmQuestion);
    if (newJob) {
      navigate(
        `/create-job?taskName=${scriptName}&taskType=transformation&script=${value}&sourceType=${"sql"}`,
      );
    }
    if (!newJob && existingJob !== "") {
      navigate(
        `/edit-job/${existingJob}?taskName=${scriptName}&taskType=transformation&script=${value}`,
      );
    }
  };
  const handleClickOpen = () => {
    setOpenModal(true);
  };
  const handleClickClosed = () => {
    setOpenModal(false);
  };
  const handleScheduleModal = () => {
    setIsScheduleModalOpen((prev) => !prev);
  };

  useEffect(() => {
    dispatch(clearGPTQueryResultSuccess());
  }, [dispatch]);

  return (
    <StyledPortalContent>
      <div
        style={{
          padding: "1em",
          width: "100%",
          height: "90vh",
          overflow: "auto",
        }}
      >
        <StyledDashboardContentFlex column>
          <StyledDashboardContentFlex justifyContent>
            <Tabs tabs={tabData} />
          </StyledDashboardContentFlex>
          <StyledDashboardContentFlex justifyContent>
            <div></div>
            <img
              src={BoatGPTLogo}
              alt="boat_gpt"
              style={{
                width: "100px",
                height: "80px",
              }}
            />
            <div
              className="queryOptionsDivider"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <StyledButton
                onClick={handleClickOpen}
                disabled={!isSaveDisabled}
                style={{ cursor: !isSaveDisabled ? "not-allowed" : "pointer" }}
              >
                {t("data_management.transformation.presenter.buttons.save")}
              </StyledButton>
              {openModal && (
                <GPTModal
                  openModal={openModal}
                  handleClickClosed={handleClickClosed}
                  bundleRef={bundleRef}
                  postLlmQuestion={postGPTLlmQuestion}
                  scriptName={scriptName}
                  handleScriptNameChange={handleScriptNameChange}
                />
              )}
              <ButtonOutlineIcon
                label={t(
                  "data_management.transformation.presenter.buttons.schedule",
                )}
                disabled={scriptName === ""}
                handleOnClick={handleScheduleModal}
              />
            </div>
          </StyledDashboardContentFlex>

          <FormControl
            sx={{
              paddingY: 1,
            }}
            fullWidth
            onSubmit={submitGptForm}
          >
            <TextField
              inputRef={ref}
              id="outlined-textarea"
              placeholder={t(
                "data_management.transformation.presenter.inputs.placeholder",
              )}
              multiline
              fullWidth
              InputProps={{
                sx: { borderRadius: "1rem" },
                endAdornment: (
                  <InputAdornment position="end">
                    <MdSend
                      onClick={(e) => {
                        e.preventDefault();
                        submitGptForm(e);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </InputAdornment>
                ),
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  submitGptForm(e);
                }
              }}
            />
          </FormControl>
          <TransformationResultContainer id="bottomDiv">
            <div className="plusResultContainer">
              <HeaderTabButton
                onClick={() => {
                  handleOpenResults();
                }}
                selected={isOpenResults}
              >
                {isOpenResults ? (
                  <PiCaretDownLight size={24} />
                ) : (
                  <PiCaretRightLight size={24} />
                )}
                {t("data_management.transformation.presenter.footer.results")}
              </HeaderTabButton>
              <HeaderTabButton
                style={{
                  textTransform: "uppercase",
                  cursor:
                    getGPTQueryResultSuccess === null
                      ? "not-allowed"
                      : "pointer",
                }}
                disabled={getGPTQueryResultSuccess === null}
                onClick={() =>
                  handleExportCSV(getGPTQueryResultSuccess, "GPTResult")
                }
              >
                <LuDownload />
                {t(
                  "data_management.transformation.presenter.footer.save_results",
                )}
              </HeaderTabButton>
            </div>
          </TransformationResultContainer>
          {(postGPTLlmQuestionError !== null || postGPTQueryError !== null) && (
            <SQLError
              value={
                "🚤 BoatGPT hit some rough waters while navigating your data🚤"
              }
              value2={
                "To help us steer back on course, try rephrasing your question with more context or details about your data ⚓"
              }
              value3={"More fuel means a smoother journey to your insights! "}
            />
          )}
          {postGPTLlmQuestionLoader || postGPTLlmResultLoader ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <PreLoader />
            </Box>
          ) : postGPTLlmQuestion === "SQL not found." ? (
            <SQLError
              value={
                "🚤 BoatGPT hit some rough waters while navigating your data🚤"
              }
              value2={
                "To help us steer back on course, try rephrasing your question with more context or details about your data ⚓"
              }
              value3={"More fuel means a smoother journey to your insights! "}
            />
          ) : (
            <GPTResult
              GPTQueryResults={getGPTQueryResultSuccess}
              postLlmQuestion={postGPTLlmQuestion}
            />
          )}
        </StyledDashboardContentFlex>
        {isScheduleModalOpen && (
          <SchedulerModal
            isScheduleModalOpen={isScheduleModalOpen}
            setIsScheduleModalOpen={setIsScheduleModalOpen}
            newJob={newJob}
            setNewJob={setNewJob}
            existingJob={existingJob}
            proceed={proceed}
            proceedOnScheduleModal={proceedOnScheduleModal}
            setExistingJob={setExistingJob}
          />
        )}
      </div>
    </StyledPortalContent>
  );
};
export default BoatGPTContainer;
