import React from "react";

import ExpiredImage from "../../images/databoat-solution-detail - customer.png";
import {
  StyledButton,
  StyledButtonContainer,
  StyledTrialHasEndedContainer,
} from "./Upgrade.styles";
import { useNavigate } from "react-router-dom";

const Upgrade = () => {
  const navigate = useNavigate();
  const supportLink = "https://www.dataopshouse.com/contact";

  const handleNavigateTonBundle = () => {
    navigate("/bundle");
  };
  const handleContactSupport = () => {
    window.open(supportLink, "_blank");
  };
  const handleOpenEmail = () => {
    window.open(
      "mailto:support@dataopshouse.com?subject=Customer Support",
      "_blank",
    );
  };

  return (
    <StyledTrialHasEndedContainer>
      <h1>Your trial period has ended!</h1>
      <p>To save your work and keep sailing the boat, choose a plan now</p>
      <img src={ExpiredImage} alt="Trial Expired" style={{ width: 400 }} />
      <StyledButtonContainer>
        <StyledButton
          variant="outlined"
          color="primary"
          size="large"
          onClick={handleContactSupport}
        >
          Book a demo
        </StyledButton>
        <StyledButton
          variant="contained"
          color="primary"
          size="large"
          onClick={handleNavigateTonBundle}
        >
          Choose Your Plan
        </StyledButton>
      </StyledButtonContainer>
      <p className="questions">
        Have questions? Get in touch.{" "}
        <a
          href="mailto:support@dataopshouse.com?subject=Customer Support"
          onClick={handleOpenEmail}
        >
          Contact Support
        </a>
      </p>
    </StyledTrialHasEndedContainer>
  );
};

export default Upgrade;
