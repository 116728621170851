import PropTypes from "prop-types";

const Input = ({
  className,
  type,
  name,
  placeholder,
  onClick,
  defaultValue,
  value,
  onChange,
  accept,
  id,
  onBlur,
}) => {
  return (
    <input
      id={id}
      type={type}
      name={name}
      defaultValue={defaultValue}
      value={value}
      className={className}
      placeholder={placeholder}
      onClick={onClick}
      onBlur={onBlur}
      onChange={onChange}
      accept={accept}
    />
  );
};
Input.defaultProp = {
  width: "100%",
};
Input.proptype = {
  className: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  accept: PropTypes.string,
  placeholder: PropTypes.string,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
};
export default Input;
