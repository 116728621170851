import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { LinkableImageCard } from "../dashboardContent/Card";
import DocumentPageLayout from "./DocumentPageLayout";

import BoatGptImage from "../../images/boat_GPT.png";

const Home = () => {
  const { t } = useTranslation();
  const breadcrumbs = [
    {
      name: t("general.documentation"),
      link: "#documentation",
    },
    {
      name: t("general.data_onboarding"),
      link: "#data-onboarding",
    },
    {
      name: t("general.workflow_management"),
      link: "#workflow-management",
    },
    {
      name: t("general.data_transformation"),
      link: "#data-transformation",
    },
    {
      name: t("general.faq"),
      link: "#faq",
    },
    {
      name: "Choose Databoat",
      link: "#choose-databoat",
    },
  ];

  const displayImageGrid = () => {
    // samle images w/ content
    const imageItems = [
      {
        image: BoatGptImage,
        title: t("general.quickstart"),
        body: "Learn how to create, configure, and naviagte your Databoat account.",
        url: "overview",
      },
      {
        image: BoatGptImage,
        title: t("general.setup_a_job"),
        body: "Move large volumes of data with our enterprise-level solution.",
        url: "overview",
      },
      {
        image: BoatGptImage,
        title: t("general.ingest_using_connectors"),
        body: "DataBoat supports integration with a variety of data connectors.",
        url: "overview",
      },
    ];
    return (
      <Grid container spacing={2} columns={9} className="content-image-grid">
        {imageItems.map((item) => (
          <Grid item xs={12} sm={6} md={3} sx={{ p: 0 }}>
            <LinkableImageCard {...item} url={`/docs/${item.url}`} />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <DocumentPageLayout breadcrumbs={breadcrumbs}>
      <Grid container rowSpacing={4} sx={{ width: "98%" }}>
        <Grid item>
          <h1 id="documentation" style={{ paddingBottom: "20px" }}>
            {t("general.documentation")}
          </h1>
          <p>
            Welcome to the DataBoat Knowledge Base. Guidance and resources for
            who are addressing in new challenges, techniques and tools for
            growth in data analytics and AI. All information you need to
            accelerate your boat
          </p>
        </Grid>

        <Grid item>{displayImageGrid()}</Grid>

        <Grid item>
          <h2 id="data-onboarding" style={{ paddingBottom: "20px" }}>
            {t("general.data_onboarding")}
          </h2>
          <p>
            Seamlessly synchronize data across a diverse range of systems and
            platforms with DataBoat's adaptable data synchronization
            capabilities. Featuring plug-ins for connecting to multiple systems
            including databases, social media platforms, SaaS applications,
            ERPs, and Salesforce, DataBoat ensures seamless data integration and
            consistency, facilitating streamlined data management across the
            enterprise.
          </p>
        </Grid>

        <Grid item>
          <h2 id="workflow-management" style={{ paddingBottom: "20px" }}>
            {t("general.workflow_management")}
          </h2>
          <p>
            Simplify the orchestration of data workflows and automate routine
            tasks with DataBoat's robust job scheduler management capabilities.
            From scheduling data ingestion to transformation and synchronization
            tasks, DataBoat offers flexible scheduling options and comprehensive
            monitoring tools to optimize workflow management and boost
            operational efficiency.
          </p>
        </Grid>

        <Grid item>
          <h2 id="data-transformation" style={{ paddingBottom: "20px" }}>
            {t("general.data_transformation")}
          </h2>
          <p>
            Empower users to unlock the full potential of their data with
            DataBoat's versatile data transformation features. From traditional
            SQL queries to intuitive drag-and-drop interfaces and advanced
            AI-powered query generation capabilities, DataBoat offers flexible
            tools for data preparation, cleansing, and transformation, enabling
            users to derive actionable insights effortlessly.
          </p>
        </Grid>

        <Grid
          item
          className="content-icon-grid"
          direction={{
            sm: "column",
            md: "row",
          }}
        >
          <Link to="www.youtube.com/@dataopshouse">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="currentColor"
                class="bi bi-youtube"
                viewBox="0 0 16 16"
              >
                <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z" />
              </svg>
              <div>
                <h4>Youtube channel</h4>
                <p>www.youtube.com/@dataopshouse</p>
              </div>
            </div>
          </Link>
          <Link to="https://www.dataopshouse.com/cases">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="currentColor"
                class="bi bi-person-circle"
                viewBox="0 0 16 16"
              >
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                <path
                  fill-rule="evenodd"
                  d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
                />
              </svg>
              <div>
                <h4>Use Cases</h4>
                <p>
                  Real experiences: See how DataOps House solutions have been
                  helping the biggest names in the market.
                </p>
              </div>
            </div>
          </Link>

          <Link to="https://www.dataopshouse.com/news_blog">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="currentColor"
                class="bi bi-newspaper"
                viewBox="0 0 16 16"
              >
                <path d="M0 2.5A1.5 1.5 0 0 1 1.5 1h11A1.5 1.5 0 0 1 14 2.5v10.528c0 .3-.05.654-.238.972h.738a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 1 1 0v9a1.5 1.5 0 0 1-1.5 1.5H1.497A1.497 1.497 0 0 1 0 13.5zM12 14c.37 0 .654-.211.853-.441.092-.106.147-.279.147-.531V2.5a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0-.5.5v11c0 .278.223.5.497.5z" />
                <path d="M2 3h10v2H2zm0 3h4v3H2zm0 4h4v1H2zm0 2h4v1H2zm5-6h2v1H7zm3 0h2v1h-2zM7 8h2v1H7zm3 0h2v1h-2zm-3 2h2v1H7zm3 0h2v1h-2zm-3 2h2v1H7zm3 0h2v1h-2z" />
              </svg>
              <div>
                <h4>Blogs & Articles</h4>
                <p>Articles, news, and updates.</p>
              </div>
            </div>
          </Link>
        </Grid>

        <Grid item>
          <h2 id="faq">{t("general.faq")}</h2>
          <h4>Got a question? We’re here to help.</h4>

          <div>
            <div>
              <strong>What is DataBoat?</strong>
            </div>
            <div>
              DataBoat is a plug-and-play data platform that creates a single
              source of truth for Business Intelligence (BI) and Artificial
              Intelligence (AI). It automates engineering work to accelerate
              your operations and reduce costs.
            </div>
            <br />
            <div>
              <strong>What is a data platform and why do I need one?</strong>
            </div>
            <div>
              A data platform is a technological infrastructure that enables the
              collection, storage, processing, and analysis of large volumes of
              data from various sources. You need a data platform to centralise
              and efficiently manage your data, facilitating data-driven
              decision-making and leveraging valuable insights for your
              business.
            </div>
            <br />
            <div>
              <strong>What are the key features of DataBoat?</strong>
            </div>
            <div>
              DataBoat utilises a winning combination of open-source tools,
              selected for their intended purposes. It features a modular
              framework that combines the best of application development for
              data ingestion with operational capabilities to automate
              end-to-end solutions in a parameterizable and scalable manner.
            </div>
            <br />
            <div>
              <strong>How can DataBoat help my company?</strong>
            </div>
            <div>
              DataBoat saves time and money by automating data engineering
              tasks, allowing your team to focus on business problems rather
              than technical issues. It offers adaptability in any existing
              cloud environment, enabling code reuse and incorporation into the
              framework.
            </div>
            <br />
            <div>
              <strong>Is DataBoat suitable for companies of any size?</strong>
            </div>
            <div>
              Yes, DataBoat is designed for companies of all sizes seeking to
              centralise and optimise their data operations, from startups to
              large corporations.
            </div>
            <br />
            <div>
              <strong>
                How does DataBoat differ from other data platforms?
              </strong>
            </div>
            <div>
              DataBoat stands out by fully automating the data engineering
              process, a modular framework its easily integrated into any
              existing stack, offering a plug-and-play approach that accelerates
              data ingestion and transformation while ensuring interoperability
              with various cloud environments.
            </div>
            <br />
            <div>
              <strong>
                How can I integrate DataBoat into my existing infrastructure?
              </strong>
            </div>
            <div>
              DataBoat is designed to be highly adaptable and can be easily
              integrated into any existing technology stack, allowing for code
              reuse and a seamless transition.
            </div>
            <br />
            <div>
              <strong>Is DataBoat secure?</strong>
            </div>
            <div>
              Yes, data security is a top priority for DataBoat. We implement
              robust security measures, including user authentication, access
              control, and compliance with industry security standards, to
              protect your data from unauthorised access and ensure its privacy
              and integrity.
            </div>
            <br />
            <div>
              <strong>What types of data sources does DataBoat support?</strong>
            </div>
            <div>
              DataBoat can handle a variety of data sources, including
              traditional databases, cloud sources, APIs, and other data
              systems, ensuring flexibility and scalability.
            </div>
            <br />
            <div>
              <strong>Can I use my own connectors with DataBoat?</strong>
            </div>
            <div>
              Yes, DataBoat supports integration with a variety of data
              connectors, such as S3, MySQL, SQL Server, Postgresql, GCS, API,
              Oracle, BigQuery, CSV files, Kafka, allowing you to leverage your
              existing data sources. If you have a specific need, discuss with
              our technical team the possibility of integrating it into DataBoat
              as well.
            </div>
            <br />
            <div>
              <strong>What is DataBoat's pricing model?</strong>
            </div>
            <div>
              With DataBoat, you pay a flat fee to access all features,
              including: Scheduler &amp; Workflow Management, Source Sink
              Connectors, Data Transformation using different methods: SQL
              Queries, Drag &amp; Drop or via BoatGPT (LLM). By combining your
              own cloud usage with the fixed monthly fee, you avoid any unwanted
              surprises at the end of the month. Contact us for detailed
              information on available pricing plans and subscription options.
            </div>
            <br />
            <div>
              <strong>Where is the DataBoat office located?</strong>
            </div>
            <div>
              Our main office is located in Amsterdam, NL, at
              Oostenburgermiddenstraat 06.
            </div>
            <br />
            <div>
              <strong>Title or question</strong>
            </div>
            <div>
              Describe the item or answer the question so that site visitors who
              are interested get more information. You can emphasize this text
              with bullets, italics or bold, and add links.
            </div>
          </div>
        </Grid>

        <Grid item>
          <h2 id="choose-databoat">
            Data-driven organizations choose DataBoat
          </h2>
        </Grid>
      </Grid>
    </DocumentPageLayout>
  );
};

export default Home;
