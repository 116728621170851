import { useState } from "react";
import { Outlet } from "react-router-dom";
import { Grid } from "@mui/material";
import { DocumentHeader } from "../index";
import DocumentNavigation from "./DocumentNavigation";
import CookieModal from "./CookieModal";

const Documentation = () => {
  const [openModal, setOpenModal] = useState(true);

  return (
    <div style={{ overflow: "hidden", maxHeight: "100vh" }}>
      <Grid
        xs={12}
        style={{ position: "sticky", top: 0, margin: 0, zIndex: 1 }}
      >
        <DocumentHeader />
      </Grid>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        style={{ position: "fixed" }}
      >
        <Grid item xs={3} md={3}>
          <DocumentNavigation />
        </Grid>
        <Grid item xs={9} md={9}>
          <Outlet />
        </Grid>
      </Grid>
      <CookieModal openModal={openModal} setOpenModal={setOpenModal} />
    </div>
  );
};

export default Documentation;
