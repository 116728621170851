import React from "react";
import {
  StyledDashboardContentFlex,
  ModalHeader,
  Button,
  StyledModal,
  Flex,
} from "../../index";

import { Dialog } from "@mui/material";
import CloseSvg from "../../reusableComponents/svg/CloseSvg";

const DeleteFolderModal = ({
  open,
  handleClose,
  handleDeleteFolder,
  folder,
}) => {
  return (
    <StyledModal>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        PaperProps={{ sx: { borderRadius: "28px" } }}
      >
        <ModalHeader>
          <p>Delete Script Folder</p>
          <div onClick={handleClose}>
            <CloseSvg />
          </div>
        </ModalHeader>
        <StyledDashboardContentFlex column style={{ padding: "2em" }}>
          <p>
            Are you sure you want to delete{" "}
            <span style={{ color: "red" }}>{folder.current}</span> folder?
          </p>

          <Flex style={{ flexDirection: "row-reverse" }}>
            <Button
              onClick={handleDeleteFolder}
              text={"Yes"}
              className={"next_btn save_btn"}
              style={{ backgroundColor: "red", color: "white" }}
            />
            <Button
              onClick={handleClose}
              text={"No"}
              className={"next_btn save_btn"}
            />
          </Flex>
        </StyledDashboardContentFlex>
      </Dialog>
    </StyledModal>
  );
};
export default DeleteFolderModal;
