import { Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import NextInsightImage from "../../images/Next insights.jpg";
import SchemaAndOwnershipImage from "../../images/Schema & Ownership.jpg";
import SteeringWheel from "../../images/boat-steering.png";
import IngestDataImage from "../../images/ingest Data.jpg";

import { Flex, StyledTitle, Theme, StyledDashboardContentFlex } from "../index";
import { CardWithoutButton, RoutableCard } from "./Card";
import { useTranslation } from "react-i18next";

const DashboardHome = ({ greeting, currentUser }) => {
  const { getCountAnalytics } = useSelector((state) => state.users);
  const { t } = useTranslation();
  const now = new Date();
  return (
    <StyledDashboardContentFlex column style={{ gap: "2em" }}>
      <p style={{ font: Theme.fonts.fontMediumBold }}>
        {greeting}, {currentUser}!
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1em",
          padding: "20px",
          maxWidth: "100%",
          backgroundColor: "#fff",
          boxShadow:
            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
        }}
      >
        <Flex
          gap
          style={{
            alignItems: "center",
          }}
        >
          <StyledTitle>{t("dashboard.home.title")}</StyledTitle>
          <img
            src={SteeringWheel}
            alt="boat steering wheel"
            style={{ width: 40, height: 32 }}
          />
        </Flex>
        <Grid justifyContent="center" container spacing={2}>
          <Grid item xs={12} sm={6} md={3} sx={{ p: 0, borderColor: "#ddd" }}>
            <RoutableCard
              imageTitle={"Ingest Data"}
              image={IngestDataImage}
              header={t("dashboard.home.cards.routable.header.ingest_data")}
              body={t("dashboard.home.cards.routable.body.ingest_data")}
              url={"/create-job"}
              linkTitle={t(
                "dashboard.home.cards.routable.link_title.ingest_data",
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={{ p: 0, borderColor: "#ddd" }}>
            <RoutableCard
              image={SchemaAndOwnershipImage}
              header={t(
                "dashboard.home.cards.routable.header.schema_ownership",
              )}
              body={t("dashboard.home.cards.routable.body.schema_ownership")}
              url={"/catalog"}
              linkTitle={t(
                "dashboard.home.cards.routable.link_title.schema_ownership",
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={{ p: 0, borderColor: "#ddd" }}>
            <RoutableCard
              header={t("dashboard.home.cards.routable.header.predict_insigth")}
              image={NextInsightImage}
              body={t("dashboard.home.cards.routable.body.predict_insigth")}
              url={"/boatGPT"}
              linkTitle={t(
                "dashboard.home.cards.routable.link_title.predict_insigth",
              )}
            />
          </Grid>
        </Grid>
      </div>
      <Grid justifyContent="center" container spacing={2}>
        <Grid item xs={12} sm={6} md={3} sx={{ p: 0, borderColor: "#ddd" }}>
          <CardWithoutButton
            header={t(
              "dashboard.home.cards.without_button.total_datasets.header",
            )}
            title={t(
              "dashboard.home.cards.without_button.total_datasets.title",
            )}
            amount={
              Object.keys(getCountAnalytics).length > 0
                ? getCountAnalytics.totalObjects
                : 0
            }
            date={now.toLocaleString()} //add date and time
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ p: 0, borderColor: "#ddd" }}>
          <CardWithoutButton
            header={t("dashboard.home.cards.without_button.total_jobs.header")}
            title={t("dashboard.home.cards.without_button.total_jobs.title")}
            amount={0}
            date={now.toLocaleString()}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ p: 0, borderColor: "#ddd" }}>
          <CardWithoutButton
            header={t(
              "dashboard.home.cards.without_button.storage_space.header",
            )}
            title={t("dashboard.home.cards.without_button.storage_space.title")}
            amount={0}
            date={now.toLocaleString()}
          />
        </Grid>
      </Grid>
    </StyledDashboardContentFlex>
  );
};

export default DashboardHome;
