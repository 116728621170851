/* eslint-disable */
import React, { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { StyledPortalContent, H3, CreateJobButton } from "../index";
import { Stack } from "@mui/material";
import SupportTable from "./SupportTable";
import { useNavigate } from "react-router-dom";
import {
  fetchSupportRequest,
  fetchSupportOpenCase,
} from "../redux/actions/usersActions";
import useGetToken from "../cognito/useGetToken";

const SupportOverview = () => {
  const dispatch = useDispatch();
  const created_by = localStorage.getItem("group");
  const [status, setStatus] = useState("work in Progress");
  const { token } = useGetToken();
  const { environmentVariables } = useSelector((state) => state.users);
  const navigate = useNavigate();

  const handleFilterCase = (event) => {
    const selected = event.target.value;

    if (selected === "all status") {
      dispatch(
        fetchSupportRequest(
          created_by,
          token,
          environmentVariables.REACT_APP_BASE_URL_API_CORE,
        ),
      );
    }
    setStatus(selected);
  };

  useEffect(() => {
    if (token) {
      dispatch(
        fetchSupportOpenCase(
          created_by,
          token,
          environmentVariables.REACT_APP_BASE_URL_API_CORE,
        ),
      );
    }
  }, [token, dispatch, status]);

  return (
    <StyledPortalContent>
      <div style={{ padding: "1em", height: "90vh", overflow: "scroll" }}>
        <Stack spacing={3}>
          <H3>Support Center</H3>

          <Stack direction={"row"} justifyContent={"space-between"}>
            <FormControl>
              <InputLabel id="Filter by status">Filter by status</InputLabel>
              <Select
                labelId="Filter by status"
                id="Filter by status"
                value={status}
                label="Filter by status"
                onChange={handleFilterCase}
              >
                <MenuItem value={"all status"}>All status</MenuItem>
                <MenuItem value={"work in Progress"}>Work in Progress</MenuItem>
              </Select>
            </FormControl>
            <CreateJobButton
              text="Create case"
              type={"button"}
              onClick={() => navigate("/create-case")}
            />
          </Stack>
          <SupportTable status={status} />
        </Stack>
      </div>
    </StyledPortalContent>
  );
};

export default SupportOverview;
