import React from "react";
import styled from "styled-components";

export const StyledButton = styled.button`
  display: inline-flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  gap: 8px;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.sysSurfaceActive : theme.colors.secondaryColor};
  border: ${({ theme, selected }) =>
    selected ? "none" : `1px solid ${theme.colors.borderColor}`};
  color: ${({ theme, selected }) =>
    selected
      ? theme.colors.sysSecondaryContainer
      : theme.colors.placeholderText};
  text-align: center;
  letter-spacing: ${({ theme }) => theme.fonts.primaryLetterSpacing};
  font: ${({ theme }) => theme.fonts.fontPrimary};
  padding: ${({ icon }) => (icon ? "6px 16px 6px 8px" : "6px 12px")};
`;

const ChipInputLeadingIconLabel = ({
  label = "button",
  handleOnClick,
  selected = false,
  leadingIcon = null,
}) => {
  return (
    <StyledButton
      selected={selected}
      onClick={handleOnClick}
      icon={leadingIcon}
    >
      {leadingIcon}
      {label}
    </StyledButton>
  );
};

export default ChipInputLeadingIconLabel;
