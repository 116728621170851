//https://something.amazonaws.com/dev/list-table-details/databoat-consumption-catalog/gdp?page=1&pageSize=10&orderBy=maxNullCount&orderDirection=asc
export const mockTableDetailsDataWithPaginationAndOrder = {
  res: {
    data: [
      {
        table: "Customer",
        columnName: "Title1",
        dataType: "text",
        maxDistinctCount: "2",
        maxNullCount: {
          type: "very good",
          amount: "0",
        },
        min: "JR",
        averageValue: "0",
        max: "JR",
      },
      {
        table: "Customer",
        columnName: "Title2",
        dataType: "string",
        maxDistinctCount: "2",
        maxNullCount: {
          type: "good",
          amount: "0",
        },
        min: "JR",
        averageValue: "0",
        max: "JR",
      },
      {
        table: "Customer",
        columnName: "Title3",
        dataType: "number",
        maxDistinctCount: "2",
        maxNullCount: {
          type: "ok",
          amount: "0",
        },
        min: "JR",
        averageValue: "0",
        max: "JR",
      },
      {
        table: "Customer",
        columnName: "Title4",
        dataType: "text",
        maxDistinctCount: "2",
        maxNullCount: {
          type: "bad",
          amount: "0",
        },
        min: "JR",
        averageValue: "0",
        max: "JR",
      },
      {
        table: "Customer",
        columnName: "Title5",
        dataType: "text",
        maxDistinctCount: "2",
        maxNullCount: {
          type: "very bad",
          amount: "0",
        },
        min: "JR",
        averageValue: "0",
        max: "JR",
      },
      {
        table: "Customer",
        columnName: "Title6",
        dataType: "text",
        maxDistinctCount: "2",
        maxNullCount: {
          type: "very bad",
          amount: "0",
        },
        min: "JR",
        averageValue: "0",
        max: "JR",
      },
      {
        table: "Customer",
        columnName: "Title7",
        dataType: "text",
        maxDistinctCount: "2",
        maxNullCount: {
          type: "bad",
          amount: "0",
        },
        min: "JR",
        averageValue: "0",
        max: "JR",
      },
      {
        table: "Customer",
        columnName: "Title8",
        dataType: "text",
        maxDistinctCount: "2",
        maxNullCount: {
          type: "very bad",
          amount: "0",
        },
        min: "JR",
        averageValue: "0",
        max: "JR",
      },
      {
        table: "Customer",
        columnName: "Title9",
        dataType: "text",
        maxDistinctCount: "2",
        maxNullCount: {
          type: "very bad",
          amount: "0",
        },
        min: "JR",
        averageValue: "0",
        max: "JR",
      },
      {
        table: "Customer0",
        columnName: "Title10",
        dataType: "text",
        maxDistinctCount: "2",
        maxNullCount: {
          type: "bad",
          amount: "0",
        },
        min: "JR",
        averageValue: "0",
        max: "JR",
      },
    ],
    currentPage: 1,
    pageSize: 10,
    totalItems: 20,
    totalPages: 2,
  },
};

//https://something.amazonaws.com/dev/list-table-filters/databoat-consumption-catalog/gdp
export const mockTableFilters = {
  res: ["text", "string", "number"],
};

//https://something.amazonaws.com/dev/list-table-infos/databoat-consumption-catalog/gdp
export const mockTableInfos = {
  res: { rowCount: "18,482", maxNullCount: "18,481" },
};
