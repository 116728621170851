import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import DocumentPageLayout from "../DocumentPageLayout";

const WorkflowManagement = () => {
  const { t } = useTranslation();

  const contentArr = [
    {
      name: t("general.workflow_management"),
      link: "#workflow-management",
    },
    {
      name: t("general.creating_a_job"),
      link: "#creating-a-job",
    },
    {
      name: t("general.viewing_jobs"),
      link: "#viewing-job",
    },
    {
      name: t("general.searching_for_jobs"),
      link: "#searching-for-jobs",
    },
    {
      name: t("general.editing_a_job"),
      link: "#editing-a-job",
    },
    {
      name: t("general.running_a_job_manually"),
      link: "#running-a-job-manually",
    },
    {
      name: t("general.deleting_a_job"),
      link: "#deleting-a-job",
    },
    {
      name: t("general.tracking_and_analyzing_job_executions"),
      link: "#tracking-and-analyzing-job-executions",
    },
  ];

  return (
    <DocumentPageLayout
      breadcrumbs={contentArr.map(({ name, link }) => ({ name, link }))}
    >
      <Grid container>
        <Grid item gap={4}>
          <h1 id="workflow-management"> {t("general.workflow_management")} </h1>

          <p>
            Welcome to the Workflow Management section of our platform. This
            guide will help you navigate through the workflow management
            features, enabling you to efficiently manage jobs related to data
            ingestion and transformation. Here, you will learn how to create,
            manage, and manipulate jobs on our platform.
          </p>

          <div id="creating-a-job">
            <div>
              <div>
                <div data-element-type="paragraph" data-version="5">
                  <h2>
                    <a href="https://docs.databoat.nl/" type="page">
                      3.1 Creating a Job
                    </a>
                  </h2>
                  <p>
                    &nbsp;To start using the workflow management system, you'll
                    begin by creating a job:
                  </p>
                  <ol>
                    <li>
                      Access the Workflow Management Interface: Click the Create
                      Job button. This action will direct you to the job
                      creation page.
                    </li>
                    <li>
                      Specify Job Details: Here, you can define the job's name
                      and configure its tasks, such as data ingestion and
                      transformation specifics.
                    </li>
                    <li>
                      Save the Job: Once all the details are correctly filled
                      out, save the job to add it to your list of jobs
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div id="viewing-jobs">
            <div>
              <div>
                <div data-element-type="paragraph" data-version="5">
                  <h2>
                    <a href="https://docs.databoat.nl/" type="page">
                      3.2 Viewing Jobs
                    </a>
                  </h2>
                  <p>To view and monitor your jobs:</p>
                  <ol>
                    <li>
                      Navigate to the Job List: On the main dashboard, you will
                      see the list of jobs created.
                    </li>
                    <li>
                      Job Information: In the job list, you will see each job's
                      creator, the status of the last run (e.g., success,
                      failure), and the last run time. This helps in quickly
                      assessing the operational status of your jobs.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div id="searching-for-jobs">
            <div>
              <div>
                <div data-element-type="paragraph" data-version="5">
                  <h2>3.3 Searching for Jobs</h2>
                  <p>To find a specific job or set of jobs:</p>
                  <ol>
                    <li>
                      Use the Search Bar: Enter a keyword related to the job you
                      are looking for in the search bar. The system will display
                      all jobs matching your query, making it easier to locate
                      specific jobs.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div id="editing-a-job">
            <div>
              <div>
                <div data-element-type="paragraph" data-version="5">
                  <h2>3.4 Editing a Job</h2>
                  <p>If you need to update or modify a job:</p>
                  <ol>
                    <li>
                      Find the Job: Use the search function or find the job in
                      your list.
                    </li>
                    <li>
                      Initiate Editing: Click on the Edit button located in the
                      actions column next to the job you wish to edit.
                    </li>
                    <li>
                      Modify Job Details: Change the job&rsquo;s name,
                      scheduling, and task details. You can also add different
                      tasks as required.
                    </li>
                    <li>
                      Save Changes: Ensure all changes are correct and save them
                      to update the job.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div id="Running a Job Manually">
            <div>
              <div>
                <div data-element-type="paragraph" data-version="5">
                  <h2>3.5 Running a Job Manually</h2>
                  <p>For immediate execution of a job:</p>
                  <p>
                    Locate the Job: Identify the job you want to run in your job
                    list.
                  </p>
                  <p>
                    Manual Trigger: Click the Run button in the actions column
                    for the desired job. The job will execute immediately based
                    on the latest configurations.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="Deleting a Job">
            <div>
              <div>
                <div data-element-type="paragraph" data-version="5">
                  <h2>3.6 Deleting a Job</h2>
                  <p>To remove a job from the system:</p>
                  <ol>
                    <li>Select the Job: Find the job you wish to delete.</li>
                    <li>
                      Delete the Job: Click the Delete button in the actions
                      column. Confirm the deletion when prompted to permanently
                      remove the job.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div id="Tracking and Analyzing Job Executions">
            <div>
              <div>
                <div data-element-type="paragraph" data-version="5">
                  <h2>3.7 Tracking and Analyzing Job Executions</h2>
                  <p>
                    Our platform also offers execution dashboard that help you
                    monitor and analyze the performance and efficiency of your
                    jobs:
                  </p>
                  <ol>
                    <li>
                      Access the Analytics Dashboard: Click on the job in the
                      workflow management page to view the execution dashboard.
                    </li>
                    <li>
                      Execution Timeline: Each job will have an execution
                      timeline that shows the history of executions over a
                      specified period. This timeline will help you track the
                      progression of job executions and task statuses.
                    </li>
                    <li>Status Indicators:</li>
                  </ol>
                  <ul>
                    <li>Green: A task that has been completed successfully.</li>
                    <li>Red: A task that has failed during execution.</li>
                    <li>Yellow: A task that is currently running.</li>
                    <li>Grey: A task that is pending execution.</li>
                  </ul>
                  <ol>
                    <li>
                      View Logs: Detailed logs are available for each task,
                      providing insights into the execution processes and
                      outcomes. Logs can be accessed by clicking on the
                      respective task in the timeline.
                    </li>
                    <li>
                      Rerun Tasks: If a task fails or you need to rerun it for
                      any other reason, simply click on the task in the timeline
                      and press the Rerun button. This allows you to rerun tasks
                      directly from the execution timeline, whether they have
                      succeeded or failed, ensuring flexibility and control in
                      managing job executions.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div>
                <div>
                  <div>
                    <div>
                      <div data-element-type="paragraph" data-version="5">
                        <p>3.8.1 Enabling and Disabling Jobs</p>
                        <p>
                          An upcoming feature that we are excited about is the
                          ability to enable or disable jobs. This will provide
                          you with greater control over the operational status
                          of your jobs without having to delete or recreate
                          them.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </DocumentPageLayout>
  );
};

export default WorkflowManagement;
