import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  FormControl,
  Autocomplete,
  TextField,
} from "@mui/material";
import React, { Suspense, useState, lazy, useEffect } from "react";
import { AiFillFolder, AiOutlineFileText } from "react-icons/ai";
import { BiGitRepoForked } from "react-icons/bi";
import { FaUsers } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { RiFolderUserFill } from "react-icons/ri";
import {
  FieldSet,
  Flex,
  IoIosAddCircleOutline,
  IoIosRemoveCircleOutline,
  // JSONFormat,
  StyledDashboardContentFlex,
  StyledIconContainer,
  StyledModal,
  StyledTask,
  StyledTaskTitle,
  // UIFormat,
} from "../../../index";
import {
  ReusableFieldSet,
  SelectFieldSet,
} from "../../../reusableComponents/FieldSet";
import {
  JobDetailsButton,
  TransformationFileModal,
} from "../../../styles/Yedaflow.Styled";
import { useTranslation } from "react-i18next";

import { fetchUserScripts } from "../../../redux/actions/customerAction";
import { useDispatch, useSelector } from "react-redux";
import useGetToken from "../../../cognito/useGetToken";
import ReversedConnectorModal from "../../connectors/reversedSync/ReversedConnectorModal";

const transformationFileColumn = {
  gitRepo: "Git repo",
  shared: "Shared",
  users: "Users",
};

const mockedUsers = ["user@email.com", "user2@email2.com"];

const mockedFiles = {
  1: ["file_1.sql", "file_2.sql", "file_3.sql"],
  2: ["file_4.sql", "file_5.sql", "file_6.sql"],
};
const ConnectorsModal = lazy(() =>
  import("../../connectors/allconnectors/ConnectorsModal"),
);

const Task = ({
  onReversedClose,
  closeReversedModal,
  openReversedModal,
  reversedModalOpen,
  taskData,
  setTaskData,
  handleTaskNameChange,
  handleParametersTypeChange,
  handleKeyChange,
  handleValueChange,
  formattedJson,
  handleJsonDataChange,
  handleTaskTypeChange,
  isConnectorModalOpen,
  onClose,
  openModal,
  closeModal,
  modalOpen,
  selectedComponent,
  selectedText,
  inputData,
  S3Data,
  handleChange,
  handleS3SourceBucketChange,
  handleS3SourceBucketBlur,
  s3fileNameError,
  handleS3TaskPartitionColumn,
  dbDataValues,
  handleSQLServerValuesChange,
  sqlError,
  setQueryDBName,
  handlequeryDataBaseName,
  sqlIngestions,
  handleSelectChange,
  optionsArray,
  sqlServerdataQueries,
  handleQueriesTableNameChange,
  queries,
  handleQueriesChange,
  checkboxValues,
  handleCheckboxChange,
  partitonColValue,
  handleQueryPartitionChange,
  addMoreSQLServerQueries,
  tableHead,
  tableData,
  handleDatabaseNameChange,
  databaseNameLoader,
  databaseName,
  handleSchemaNameChange,
  schemas,
  handleTableNameChange,
  tables,
  handleTableIngestModeOption,
  tableOptions,
  handleDeltaColumnChange,
  deltaColumn,
  handlePartionChange,
  handlePartitioningChange,
  partitionColumn,
  addRow,
  //
  mySQLDataValues,
  handlemySQLServerValuesChange,
  mySQLError,
  handlemySQLqueryDataBaseName,
  mysqlIngestions,
  handlemySQLSelectChange,
  mySQLoptionsArray,
  mySQLServerdataQueries,
  handlemySQLQueriesTableNameChange,
  mySQLqueries,
  handlemySQLQueriesChange,
  mySQLcheckboxValues,
  handlemySQLCheckboxChange,
  mySQLpartitonColValue,
  handlemySQLQueryPartitionChange,
  addMoremySQLServerQueries,
  mySQLtableHead,
  mySQLtableData,
  handlemySQLDatabaseNameChange,
  mySQLdatabaseNameLoader,
  mySQLdatabaseName,
  handlemySQLSchemaNameChange,
  mySQLschemas,
  handlemySQLTableNameChange,
  mySQLtables,
  handlemySQLTableIngestModeOption,
  mySQLtableOptions,
  handlemySQLDeltaColumnChange,
  mySQLdeltaColumn,
  handlemySQLPartionChange,
  handlemySQLPartitioningChange,
  mySQLpartitionColumn,
  mySQLaddRow,
  //-------------------------------
  postgresDataValues,
  handlepostgresServerValuesChange,
  postgresError,
  handlepostgresqueryDataBaseName,
  PostgresSQLIngestions,
  handlepostgresSelectChange,
  postgresoptionsArray,
  postgresServerdataQueries,
  handlepostgresQueriesTableNameChange,
  postgresqueries,
  handlepostgresQueriesChange,
  postgrescheckboxValues,
  handlepostgresCheckboxChange,
  postgrespartitonColValue,
  handlepostgresQueryPartitionChange,
  addMorepostgresServerQueries,
  postgrestableHead,
  postgrestableData,
  handlepostgresDatabaseNameChange,
  postgresdatabaseNameLoader,
  postgresdatabaseName,
  handlepostgresSchemaNameChange,
  postgresschemas,
  handlepostgresTableNameChange,
  postgrestables,
  handlepostgresTableIngestModeOption,
  postgrestableOptions,
  handlepostgresDeltaColumnChange,
  postgresdeltaColumn,
  handlepostgresPartionChange,
  handlepostgresPartitioningChange,
  postgrespartitionColumn,
  postgresaddRow,
  //------------------------------
  oracleDataValues,
  handleoracleServerValuesChange,
  oracleError,
  OracleIngestions,
  oracleselectedOption,
  handleoracleSelectChange,
  oracleoptionsArray,
  oracleServerdataQueries,
  handleoracleQueriesTableNameChange,
  oraclequeries,
  handleoracleQueriesChange,
  oraclecheckboxValues,
  handleoracleCheckboxChange,
  oraclepartitonColValue,
  handleoracleQueryPartitionChange,
  addMoreoracleServerQueries,
  oracletableHead,
  oracletableData,
  handleoracleSchemaNameChange,
  oracleschemas,
  handleoracleTableNameChange,
  oracletables,
  handleoracleTableIngestModeOption,
  oracletableOptions,
  handleoracleDeltaColumnChange,
  oracledeltaColumn,
  handleoraclePartionChange,
  handleoraclePartitioningChange,
  oraclepartitionColumn,
  oracleaddRow,
  // ---------
  ApiData,
  handleAPIChange,
  apiHttp,
  httpData,
  httpvalue,
  handleHttpChange,
  renderKeyValue,
  apihandleKeyChange,
  apihandleValueChange,
  //---
  GCSData,
  handleGCSChange,
  handleGCSSourceBucketChange,
  handleGCSSourceBucketBlur,
  gcsfileNameError,
  handleGCSTaskPartitionColumn,
  //---
  bigQueryData,
  handleBigQueryValueChange,
  credential,
  credentialError,
  handleCredentialJsonChange,
  bqTables,
  bigQueryTableHead,
  addBqTablesRow,
  handleProjectIdChange,
  datasets,
  handleDataSetIdChange,
  allTables,
  handleTableIdChange,
  isTableId,
  handleBigQueryLoadTypeChange,
  allDeltaColumn,
  handleBigQueryDeltaColumnChange,
  handleBigQueryPartionChange,
  allPartitionColumn,
  handleBigQueryPartitioningChange,
  handleOutputTableName,
  //-------excat
  excatData,
  handleExcatChange,
  isRequiredFieldFilled,
  excatTableHead,
  myExcatloadType,
  excatTableData,
  excatTableOptions,
  handleSwitchChange,
  handleSyncModeChange,
  handlePrimaryKeyChange,
  //-----
  //---- reversed connector
  isReversedConnectorOpen,
  closeReversedConnector,
  ///
  handleScriptNameChange,
  handleAddMoreTask,
  handleRemoveFromUI,
  tags,
  handleTagsChange,
  alertValue,
  handleAlertValueChange,
  emailto,
  handleEmailToChange,
  emailcc,
  handleEmailccChange,
  emailsubject,
  handleEmailSubjectChange,
  emailmessage,
  handleEmailMessageChange,
  slackURL,
  handleSlackURLChange,
  teamsURL,
  handleTeamsURLChange,
  handlePostJobs,
  handleCodePathChange,
  handleSourceFieldChange,
  // pipedrive
  pipeDriveApiKey,
  pipeDriveSetApiKey,
  pipeDriveObjects,
  pipeDriveHandleObjectSelection,
  pipeDriveHandleAttrSelection,
  pipeDriveFetchEndpoints,
  pipeDriveConvertSelectedToApi,

  // Active Campaign
  activeCampaignApiKey,
  activeCampaignSetApiKey,
  activeCampaignData,
  activeCampaignSearch,
  activeCampaignHandleSearch,
  activeCampaignSelected,
  activeCampaignHandleAttrSelection,
  activeCampaignExpanded,
  activeCampaignHandleExpand,
  activeCampaignHandleAdd,
  activeCampaignUrl,
  activeCampaignSetUrl,
}) => {
  const [isCodePathModalOpen, setIsCodePathModalOpen] = useState(false);
  const getId = taskData.map((item) => item.id);
  const [transformationFileFirstColumn, setTransformationFileFirstColumn] =
    React.useState(null);
  const [transformationFileSecondColumn, setTransformationFileSecondColumn] =
    React.useState(null);
  const [transformationFile, setTransformationFile] = useState(null);

  const { t } = useTranslation();

  const handleFirstColumnItemClick = (event, index) => {
    setTransformationFileFirstColumn(index);
    setTransformationFileSecondColumn(null);
    setTransformationFile(null);
  };

  const handleSecondColumnItemClick = (event, index) => {
    setTransformationFileSecondColumn(index);
    setTransformationFile(null);
  };

  const handleSelectFileButtonClick = (id) => {
    handleCodePathChange(transformationFile, id);
    setIsCodePathModalOpen(false);
    setTransformationFileFirstColumn(null);
    setTransformationFileSecondColumn(null);
  };

  const closeCodePathModalOpen = () => {
    setIsCodePathModalOpen(false);
    setTransformationFileFirstColumn(null);
    setTransformationFileSecondColumn(null);
    setTransformationFile(null);
  };
  const alertData = [
    { id: 1, option: "", title: t("jobs.form.inputs.task.alert.select.label") },
    {
      id: 2,
      title: t("jobs.form.inputs.task.alert.select.email"),
      option: "Email",
    },
    {
      id: 3,
      title: t("jobs.form.inputs.task.alert.select.slack"),
      option: "Slack",
    },
    {
      id: 4,
      title: t("jobs.form.inputs.task.alert.select.teams"),
      option: "Teams",
    },
  ];
  const [isTagFocused, setIsTagFocused] = useState(false);
  const [isAlertValueFocused, setIsAlertValueFocused] = useState(false);
  const [isToFocused, setIsToFocused] = useState(false);
  const [isCCFocused, setIsCCFocused] = useState(false);
  const [isteamsURLFocused, setIsteamsURLFocused] = useState(false);
  const [isSubjectFocused, setIsSubjectFocused] = useState(false);
  const [isEmailMessageFocused, setIsEmailMessageFocused] = useState(false);
  const [isSlackFocused, setIsSlackFocused] = useState(false);
  const [isTaskTypeFocused, setIsTaskTypeFocused] = useState(false);
  // const [isParametersFocused, setIsParametersFocused] = useState(false);
  const [isTaskNameFocused, setIsTaskNameFocused] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isCodePathFocused, setIsCodePathFocused] = useState(false);
  const dispatch = useDispatch();
  const { environmentVariables } = useSelector((state) => state.users);
  const user = localStorage.getItem("created");
  const groupName = localStorage.getItem("group");
  const { token } = useGetToken();
  const { userScript, userScriptLoader } = useSelector(
    (state) => state.customers,
  );
  useEffect(() => {
    if (token) {
      dispatch(
        fetchUserScripts(
          token,
          groupName,
          user,
          environmentVariables.REACT_APP_BASE_URL_API_CUSTOMER,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, dispatch]);

  const formattedOptions =
    userScript !== null && userScriptLoader
      ? userScript.flatMap((item) =>
          item.scripts.map((script) => ({
            label: `${item.folderName}/${script.scriptName}`,
          })),
        )
      : [];
  return (
    <div>
      <StyledDashboardContentFlex column>
        <Flex column>
          {taskData.map((data) => (
            <StyledTask key={data.id}>
              <StyledTaskTitle>
                {t("jobs.form.inputs.task.title")}
              </StyledTaskTitle>
              <StyledDashboardContentFlex column>
                <FieldSet
                  legendTitle={t("jobs.form.inputs.task.label")}
                  name={"TaskName"}
                  id={"TaskName"}
                  type={"text"}
                  value={data.task_name}
                  onChange={(event) => handleTaskNameChange(event, data.id)}
                  isInputFocused={isTaskNameFocused}
                  onFocus={() => setIsTaskNameFocused(true)}
                  onBlur={() => setIsTaskNameFocused(false)}
                />
                {/* <ReusableFieldSet
                  title={"Parameters"}
                  isFocus={isParametersFocused}
                  children={
                    <select
                      name="add"
                      id="add-select"
                      className={"paddingClass"}
                      value={data.parameters_type}
                      onChange={(event) =>
                        handleParametersTypeChange(event, data.id)
                      }
                      onFocus={() => setIsParametersFocused(true)}
                      onBlur={() => setIsParametersFocused(false)}
                    >
                      <option value="" className={"signin_input_focus"}>
                        Select
                      </option>
                      <option value="UI" className={"signin_input_focus"}>
                        UI
                      </option>
                      <option value="JSON" className={"signin_input_focus"}>
                        JSON
                      </option>
                    </select>
                  }
                /> */}
                {/* {data.parameters_type === "UI" ? (
                  <UIFormat
                    paramKey={data.parmeters}
                    handleKeyChange={(event) => handleKeyChange(event, data.id)}
                    keyPlaceholder={"Enter your parameter key"}
                    paramValue={data.parmeters}
                    valuePlaceholder={"Enter your parameter value"}
                    handleValueChange={(event) =>
                      handleValueChange(event, data.id)
                    }
                  />
                ) : data.parameters_type === "JSON" ? (
                  <JSONFormat
                    formattedJson={formattedJson}
                    handleJsonDataChange={(event) =>
                      handleJsonDataChange(event, data.id)
                    }
                  />
                ) : null} */}
                <ReusableFieldSet
                  title={t("jobs.form.inputs.task_type")}
                  isFocus={isTaskTypeFocused}
                  children={
                    <select
                      name="ingestion"
                      id="ingestion-select"
                      value={data.task_type}
                      onChange={(event) => handleTaskTypeChange(event, data.id)}
                      onFocus={() => setIsTaskTypeFocused(true)}
                      onBlur={() => setIsTaskTypeFocused(false)}
                      className={"paddingClass"}
                    >
                      <option value="" className={"signin_input_focus"}>
                        {t("jobs.form.inputs.task.alert.select.label")}
                      </option>
                      <option
                        value="ingestion"
                        className={"signin_input_focus"}
                      >
                        {t("jobs.form.inputs.task.ingestion")}
                      </option>
                      <option
                        value="transformation"
                        className={"signin_input_focus"}
                      >
                        {t("jobs.form.inputs.task.transformation.title")}
                      </option>
                      <option
                        value="reversed_sync_pipedrive"
                        className={"signin_input_focus"}
                      >
                        Reversed Sync
                      </option>
                    </select>
                  }
                />
                <Suspense fallback={<div>Loading... </div>}>
                  {data.task_type === "ingestion" && (
                    <ConnectorsModal
                      isConnectorModalOpen={isConnectorModalOpen}
                      onClose={onClose}
                      openModal={openModal}
                      closeModal={closeModal}
                      modalOpen={modalOpen}
                      selectedComponent={selectedComponent}
                      selectedText={selectedText}
                      inputData={inputData}
                      S3Data={S3Data}
                      handleChange={(e) => handleChange(e, data.id)}
                      handleS3SourceBucketChange={(e) =>
                        handleS3SourceBucketChange(e, data.id)
                      }
                      handleS3SourceBucketBlur={(e) =>
                        handleS3SourceBucketBlur(e, data.id)
                      }
                      s3fileNameError={s3fileNameError}
                      s3taskPartitionColumn={data.partition_column}
                      handleS3TaskPartitionColumn={(e) =>
                        handleS3TaskPartitionColumn(e, data.id)
                      }
                      dbDataValues={dbDataValues}
                      handleSQLServerValuesChange={(e) =>
                        handleSQLServerValuesChange(e, data.id)
                      }
                      sqlError={sqlError}
                      setQueryDBName={setQueryDBName}
                      handlequeryDataBaseName={(e) =>
                        handlequeryDataBaseName(e, data.id)
                      }
                      sqlIngestions={sqlIngestions}
                      selectedOption={data.params.selected}
                      handleSelectChange={(e) => handleSelectChange(e, data.id)}
                      optionsArray={optionsArray}
                      sqlServerdataQueries={sqlServerdataQueries}
                      handleQueriesTableNameChange={
                        handleQueriesTableNameChange
                      }
                      queries={queries}
                      handleQueriesChange={handleQueriesChange}
                      checkboxValues={checkboxValues}
                      handleCheckboxChange={handleCheckboxChange}
                      partitonColValue={partitonColValue}
                      handleQueryPartitionChange={handleQueryPartitionChange}
                      taskId={data.id}
                      addMoreSQLServerQueries={(e) =>
                        addMoreSQLServerQueries(e, data.id)
                      }
                      tableHead={tableHead}
                      tableData={tableData}
                      handleDatabaseNameChange={handleDatabaseNameChange}
                      databaseNameLoader={databaseNameLoader}
                      databaseName={databaseName}
                      handleSchemaNameChange={handleSchemaNameChange}
                      schemas={schemas}
                      handleTableNameChange={handleTableNameChange}
                      tables={tables}
                      handleTableIngestModeOption={handleTableIngestModeOption}
                      tableOptions={tableOptions}
                      handleDeltaColumnChange={handleDeltaColumnChange}
                      deltaColumn={deltaColumn}
                      handlePartionChange={handlePartionChange}
                      handlePartitioningChange={handlePartitioningChange}
                      partitionColumn={partitionColumn}
                      addRow={addRow}
                      //----------------------------
                      mySQLDataValues={mySQLDataValues}
                      handlemySQLServerValuesChange={(e) =>
                        handlemySQLServerValuesChange(e, data.id)
                      }
                      mySQLError={mySQLError}
                      handlemySQLqueryDataBaseName={(e) =>
                        handlemySQLqueryDataBaseName(e, data.id)
                      }
                      mysqlIngestions={mysqlIngestions}
                      mySQLselectedOption={data.params.selected}
                      handlemySQLSelectChange={(e) =>
                        handlemySQLSelectChange(e, data.id)
                      }
                      mySQLoptionsArray={mySQLoptionsArray}
                      mySQLServerdataQueries={mySQLServerdataQueries}
                      handlemySQLQueriesTableNameChange={
                        handlemySQLQueriesTableNameChange
                      }
                      mySQLqueries={mySQLqueries}
                      handlemySQLQueriesChange={handlemySQLQueriesChange}
                      mySQLcheckboxValues={mySQLcheckboxValues}
                      handlemySQLCheckboxChange={handlemySQLCheckboxChange}
                      mySQLpartitonColValue={mySQLpartitonColValue}
                      handlemySQLQueryPartitionChange={
                        handlemySQLQueryPartitionChange
                      }
                      addMoremySQLServerQueries={(e) =>
                        addMoremySQLServerQueries(e, data.id)
                      }
                      mySQLtableHead={mySQLtableHead}
                      mySQLtableData={mySQLtableData}
                      handlemySQLDatabaseNameChange={
                        handlemySQLDatabaseNameChange
                      }
                      mySQLdatabaseNameLoader={mySQLdatabaseNameLoader}
                      mySQLdatabaseName={mySQLdatabaseName}
                      handlemySQLSchemaNameChange={handlemySQLSchemaNameChange}
                      mySQLschemas={mySQLschemas}
                      handlemySQLTableNameChange={handlemySQLTableNameChange}
                      mySQLtables={mySQLtables}
                      handlemySQLTableIngestModeOption={
                        handlemySQLTableIngestModeOption
                      }
                      mySQLtableOptions={mySQLtableOptions}
                      handlemySQLDeltaColumnChange={
                        handlemySQLDeltaColumnChange
                      }
                      mySQLdeltaColumn={mySQLdeltaColumn}
                      handlemySQLPartionChange={handlemySQLPartionChange}
                      handlemySQLPartitioningChange={
                        handlemySQLPartitioningChange
                      }
                      mySQLpartitionColumn={mySQLpartitionColumn}
                      mySQLaddRow={mySQLaddRow}
                      //----------------------------------
                      postgresDataValues={postgresDataValues}
                      handlepostgresServerValuesChange={(e) =>
                        handlepostgresServerValuesChange(e, data.id)
                      }
                      postgresError={postgresError}
                      handlepostgresqueryDataBaseName={(e) =>
                        handlepostgresqueryDataBaseName(e, data.id)
                      }
                      PostgresSQLIngestions={PostgresSQLIngestions}
                      postgresselectedOption={data.params.selected}
                      handlepostgresSelectChange={(e) =>
                        handlepostgresSelectChange(e, data.id)
                      }
                      postgresoptionsArray={postgresoptionsArray}
                      postgresServerdataQueries={postgresServerdataQueries}
                      handlepostgresQueriesTableNameChange={
                        handlepostgresQueriesTableNameChange
                      }
                      postgresqueries={postgresqueries}
                      handlepostgresQueriesChange={handlepostgresQueriesChange}
                      postgrescheckboxValues={postgrescheckboxValues}
                      handlepostgresCheckboxChange={
                        handlepostgresCheckboxChange
                      }
                      postgrespartitonColValue={postgrespartitonColValue}
                      handlepostgresQueryPartitionChange={
                        handlepostgresQueryPartitionChange
                      }
                      addMorepostgresServerQueries={(e) =>
                        addMorepostgresServerQueries(e, data.id)
                      }
                      postgrestableHead={postgrestableHead}
                      postgrestableData={postgrestableData}
                      handlepostgresDatabaseNameChange={
                        handlepostgresDatabaseNameChange
                      }
                      postgresdatabaseNameLoader={postgresdatabaseNameLoader}
                      postgresdatabaseName={postgresdatabaseName}
                      handlepostgresSchemaNameChange={
                        handlepostgresSchemaNameChange
                      }
                      postgresschemas={postgresschemas}
                      handlepostgresTableNameChange={
                        handlepostgresTableNameChange
                      }
                      postgrestables={postgrestables}
                      handlepostgresTableIngestModeOption={
                        handlepostgresTableIngestModeOption
                      }
                      postgrestableOptions={postgrestableOptions}
                      handlepostgresDeltaColumnChange={
                        handlepostgresDeltaColumnChange
                      }
                      postgresdeltaColumn={postgresdeltaColumn}
                      handlepostgresPartionChange={handlepostgresPartionChange}
                      handlepostgresPartitioningChange={
                        handlepostgresPartitioningChange
                      }
                      postgrespartitionColumn={postgrespartitionColumn}
                      postgresaddRow={postgresaddRow}
                      //-------------------------------------
                      oracleDataValues={oracleDataValues}
                      handleoracleServerValuesChange={(e) =>
                        handleoracleServerValuesChange(e, data.id)
                      }
                      oracleError={oracleError}
                      OracleIngestions={OracleIngestions}
                      oracleselectedOption={oracleselectedOption}
                      handleoracleSelectChange={(e) =>
                        handleoracleSelectChange(e, data.id)
                      }
                      oracleoptionsArray={oracleoptionsArray}
                      oracleServerdataQueries={oracleServerdataQueries}
                      handleoracleQueriesTableNameChange={
                        handleoracleQueriesTableNameChange
                      }
                      oraclequeries={oraclequeries}
                      handleoracleQueriesChange={handleoracleQueriesChange}
                      oraclecheckboxValues={oraclecheckboxValues}
                      handleoracleCheckboxChange={handleoracleCheckboxChange}
                      oraclepartitonColValue={oraclepartitonColValue}
                      handleoracleQueryPartitionChange={
                        handleoracleQueryPartitionChange
                      }
                      addMoreoracleServerQueries={(e) =>
                        addMoreoracleServerQueries(e, data.id)
                      }
                      oracletableHead={oracletableHead}
                      oracletableData={oracletableData}
                      handleoracleSchemaNameChange={
                        handleoracleSchemaNameChange
                      }
                      oracleschemas={oracleschemas}
                      handleoracleTableNameChange={handleoracleTableNameChange}
                      oracletables={oracletables}
                      handleoracleTableIngestModeOption={
                        handleoracleTableIngestModeOption
                      }
                      oracletableOptions={oracletableOptions}
                      handleoracleDeltaColumnChange={
                        handleoracleDeltaColumnChange
                      }
                      oracledeltaColumn={oracledeltaColumn}
                      handleoraclePartionChange={handleoraclePartionChange}
                      handleoraclePartitioningChange={
                        handleoraclePartitioningChange
                      }
                      oraclepartitionColumn={oraclepartitionColumn}
                      oracleaddRow={oracleaddRow}
                      // ----
                      ApiData={ApiData}
                      handleAPIChange={(e) => handleAPIChange(e, data.id)}
                      apiHttp={apiHttp}
                      httpData={httpData}
                      httpvalue={httpvalue}
                      handleHttpChange={(e) => handleHttpChange(e, data.id)}
                      renderKeyValue={renderKeyValue}
                      apiparamKey={""}
                      apihandleKeyChange={(event) =>
                        apihandleKeyChange(event, data.id)
                      }
                      apiparamValue={""}
                      apihandleValueChange={(event) =>
                        apihandleValueChange(event, data.id)
                      }
                      GCSData={GCSData}
                      handleGCSChange={(event) =>
                        handleGCSChange(event, data.id)
                      }
                      handleGCSSourceBucketChange={(e) =>
                        handleGCSSourceBucketChange(e, data.id)
                      }
                      handleGCSSourceBucketBlur={(e) =>
                        handleGCSSourceBucketBlur(e, data.id)
                      }
                      gcsfileNameError={gcsfileNameError}
                      gcstaskPartitionColumn={data.partition_column}
                      handleGCSTaskPartitionColumn={(e) =>
                        handleGCSTaskPartitionColumn(e, data.id)
                      }
                      bigQueryData={bigQueryData}
                      handleBigQueryValueChange={(event) =>
                        handleBigQueryValueChange(event, data.id)
                      }
                      credential={credential}
                      credentialError={credentialError}
                      handleCredentialJsonChange={(event) =>
                        handleCredentialJsonChange(event, data.id)
                      }
                      bqTables={bqTables}
                      bigQueryTableHead={bigQueryTableHead}
                      addBqTablesRow={addBqTablesRow}
                      handleProjectIdChange={handleProjectIdChange}
                      datasets={datasets}
                      handleDataSetIdChange={handleDataSetIdChange}
                      allTables={allTables}
                      handleTableIdChange={handleTableIdChange}
                      isTableId={isTableId}
                      handleBigQueryLoadTypeChange={
                        handleBigQueryLoadTypeChange
                      }
                      allDeltaColumn={allDeltaColumn}
                      handleBigQueryDeltaColumnChange={
                        handleBigQueryDeltaColumnChange
                      }
                      handleBigQueryPartionChange={handleBigQueryPartionChange}
                      allPartitionColumn={allPartitionColumn}
                      handleBigQueryPartitioningChange={
                        handleBigQueryPartitioningChange
                      }
                      handleOutputTableName={handleOutputTableName}
                      //---excat
                      excatData={excatData}
                      handleExcatChange={(e) => handleExcatChange(e, data.id)}
                      isRequiredFieldFilled={isRequiredFieldFilled}
                      excatTableHead={excatTableHead}
                      myExcatloadType={myExcatloadType}
                      excatTableData={excatTableData}
                      excatTableOptions={excatTableOptions}
                      handleSwitchChange={handleSwitchChange}
                      handleSyncModeChange={handleSyncModeChange}
                      handlePrimaryKeyChange={handlePrimaryKeyChange}
                      // pipedrive
                      pipeDriveApiKey={pipeDriveApiKey}
                      pipeDriveSetApiKey={pipeDriveSetApiKey}
                      pipeDriveObjects={pipeDriveObjects}
                      pipeDriveHandleObjectSelection={
                        pipeDriveHandleObjectSelection
                      }
                      pipeDriveHandleAttrSelection={
                        pipeDriveHandleAttrSelection
                      }
                      pipeDriveFetchEndpoints={pipeDriveFetchEndpoints}
                      pipeDriveConvertSelectedToApi={
                        pipeDriveConvertSelectedToApi
                      }
                      // Active Campaign
                      activeCampaignApiKey={activeCampaignApiKey}
                      activeCampaignSetApiKey={activeCampaignSetApiKey}
                      activeCampaignData={activeCampaignData}
                      activeCampaignSearch={activeCampaignSearch}
                      activeCampaignHandleSearch={activeCampaignHandleSearch}
                      activeCampaignSelected={activeCampaignSelected}
                      activeCampaignHandleAttrSelection={
                        activeCampaignHandleAttrSelection
                      }
                      activeCampaignExpanded={activeCampaignExpanded}
                      activeCampaignHandleExpand={activeCampaignHandleExpand}
                      activeCampaignHandleAdd={activeCampaignHandleAdd}
                      activeCampaignUrl={activeCampaignUrl}
                      activeCampaignSetUrl={activeCampaignSetUrl}
                    />
                  )}
                </Suspense>

                {data.task_type === "transformation" && (
                  <>
                    <FormControl>
                      <Autocomplete
                        disablePortal
                        onFocus={() => setIsCodePathFocused(true)}
                        onBlur={() => setIsCodePathFocused(false)}
                        value={
                          data.scriptName
                            ? data.scriptName.split("/").slice(1).join("/")
                            : "" // This ensures only the scriptName is shown
                        }
                        id="select-script-name"
                        options={formattedOptions}
                        onChange={(_event, newValue) =>
                          handleScriptNameChange(data.id, newValue)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t(
                              "jobs.form.inputs.task.transformation.label",
                            )}
                          />
                        )}
                      />
                    </FormControl>
                    {isCodePathModalOpen && (
                      <StyledModal style={{ zIndex: 2 }}>
                        <TransformationFileModal>
                          <StyledDashboardContentFlex column gap>
                            <StyledDashboardContentFlex justifyContent>
                              <p
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "1.5em",
                                }}
                              >
                                Select Transformation File
                              </p>
                              <MdClose
                                style={{ cursor: "pointer" }}
                                onClick={closeCodePathModalOpen}
                              />
                            </StyledDashboardContentFlex>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  padding: "0 8px",
                                }}
                              >
                                <List component="nav">
                                  <ListItemButton
                                    selected={
                                      transformationFileFirstColumn ===
                                      transformationFileColumn.gitRepo
                                    }
                                    onClick={(event) =>
                                      handleFirstColumnItemClick(
                                        event,
                                        transformationFileColumn.gitRepo,
                                      )
                                    }
                                  >
                                    <ListItemIcon>
                                      <BiGitRepoForked />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={transformationFileColumn.gitRepo}
                                    />
                                  </ListItemButton>
                                  <ListItemButton
                                    selected={
                                      transformationFileFirstColumn ===
                                      transformationFileColumn.shared
                                    }
                                    onClick={(event) =>
                                      handleFirstColumnItemClick(
                                        event,
                                        transformationFileColumn.shared,
                                      )
                                    }
                                  >
                                    <ListItemIcon>
                                      <RiFolderUserFill />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={transformationFileColumn.shared}
                                    />
                                  </ListItemButton>
                                  <ListItemButton
                                    selected={
                                      transformationFileFirstColumn ===
                                      transformationFileColumn.users
                                    }
                                    onClick={(event) =>
                                      handleFirstColumnItemClick(
                                        event,
                                        transformationFileColumn.users,
                                      )
                                    }
                                  >
                                    <ListItemIcon>
                                      <FaUsers />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={transformationFileColumn.users}
                                    />
                                  </ListItemButton>
                                </List>
                              </div>
                              {transformationFileFirstColumn ===
                                transformationFileColumn.users && (
                                <div
                                  style={{
                                    padding: "0 8px",
                                    borderLeft: "1px solid #ddd",
                                  }}
                                >
                                  <List component="nav">
                                    {mockedUsers.map((email, index) => (
                                      <ListItemButton
                                        selected={
                                          transformationFileSecondColumn ===
                                          index + 1
                                        }
                                        onClick={(event) =>
                                          handleSecondColumnItemClick(
                                            event,
                                            index + 1,
                                          )
                                        }
                                      >
                                        <ListItemIcon>
                                          <AiFillFolder />
                                        </ListItemIcon>
                                        <ListItemText primary={email} />
                                      </ListItemButton>
                                    ))}
                                  </List>
                                </div>
                              )}
                              {transformationFileSecondColumn && (
                                <div
                                  style={{
                                    padding: "0 8px",
                                    borderLeft: "1px solid #ddd",
                                  }}
                                >
                                  <List component="nav">
                                    {mockedFiles[
                                      transformationFileSecondColumn
                                    ].map((file) => (
                                      <ListItemButton
                                        selected={transformationFile === file}
                                        onClick={() =>
                                          setTransformationFile(file)
                                        }
                                      >
                                        <ListItemIcon>
                                          <AiOutlineFileText />
                                        </ListItemIcon>
                                        <ListItemText primary={file} />
                                      </ListItemButton>
                                    ))}
                                  </List>
                                </div>
                              )}
                            </div>
                            <div>
                              <JobDetailsButton
                                text={"Select"}
                                onClick={() =>
                                  handleSelectFileButtonClick(data.id)
                                }
                              />
                            </div>
                          </StyledDashboardContentFlex>
                        </TransformationFileModal>
                      </StyledModal>
                    )}
                  </>
                )}
                {data.task_type === "reversed_sync_pipedrive" && (
                  <ReversedConnectorModal
                    selectedText={selectedText}
                    isReversedConnectorOpen={isReversedConnectorOpen}
                    closeReversedConnector={closeReversedConnector}
                    onReversedClose={onReversedClose}
                    closeReversedModal={closeReversedModal}
                    openReversedModal={openReversedModal}
                    reversedModalOpen={reversedModalOpen}
                    taskId={data.id}
                    taskData={taskData}
                    setTaskData={setTaskData}
                    onClose={onClose}
                  />
                )}
                {/* //new tast_type */}
              </StyledDashboardContentFlex>
            </StyledTask>
          ))}
          <StyledIconContainer>
            <IoIosAddCircleOutline
              onClick={handleAddMoreTask}
              className="add_remove"
            />
            <IoIosRemoveCircleOutline
              onClick={() => getId.forEach((id) => handleRemoveFromUI(id))}
              className="add_remove"
            />
          </StyledIconContainer>
        </Flex>
        <StyledDashboardContentFlex column>
          <FieldSet
            legendTitle={t("jobs.form.inputs.task.tags.label")}
            type={"text"}
            name={"Tags"}
            id={"Tags"}
            value={tags}
            onChange={handleTagsChange}
            placeholder={t("jobs.form.inputs.task.tags.placeholder")}
            isInputFocused={isTagFocused}
            onFocus={() => setIsTagFocused(true)}
            onBlur={() => setIsTagFocused(false)}
          />
          <SelectFieldSet
            selectTitle={t("jobs.form.inputs.task.alert.label")}
            onBlur={() => setIsAlertValueFocused(false)}
            onFocus={() => setIsAlertValueFocused(true)}
            selectValue={alertValue}
            handleSelectValueChange={handleAlertValueChange}
            selectValuedata={alertData}
            isSelectFocus={isAlertValueFocused}
          />
          {alertValue === "Email" && (
            <>
              <ReusableFieldSet
                title={t("jobs.form.inputs.task.alert.email.to")}
                isFocus={isToFocused}
                children={
                  <input
                    type={"email"}
                    value={emailto}
                    name="emailto"
                    id="emailto"
                    onChange={handleEmailToChange}
                    placeholder={t("jobs.form.inputs.task.tags.placeholder")}
                    multiple
                    onFocus={() => setIsToFocused(true)}
                    onBlur={() => setIsToFocused(false)}
                    className={"signin_input_focus, paddingClass"}
                  />
                }
              />
              <ReusableFieldSet
                title={t("jobs.form.inputs.task.alert.email.cc")}
                isFocus={isCCFocused}
                children={
                  <input
                    type={"email"}
                    value={emailcc}
                    name="emailcc"
                    id="emailcc"
                    onChange={handleEmailccChange}
                    onFocus={() => setIsCCFocused(true)}
                    onBlur={() => setIsCCFocused(false)}
                    className={"signin_input_focus, paddingClass"}
                    placeholder={t("jobs.form.inputs.task.tags.placeholder")}
                    multiple
                  />
                }
              />
              <ReusableFieldSet
                title={t("jobs.form.inputs.task.alert.email.subject")}
                isFocus={isSubjectFocused}
                children={
                  <input
                    type={"text"}
                    value={emailsubject}
                    name="emailsubject"
                    id="emailsubject"
                    onChange={handleEmailSubjectChange}
                    onFocus={() => setIsSubjectFocused(true)}
                    onBlur={() => setIsSubjectFocused(false)}
                    className={"signin_input_focus, paddingClass"}
                  />
                }
              />
              <ReusableFieldSet
                title={t("jobs.form.inputs.task.alert.email.message")}
                isFocus={isEmailMessageFocused}
                children={
                  <textarea
                    value={emailmessage}
                    onChange={handleEmailMessageChange}
                    name="message"
                    id="message"
                    rows={4}
                    cols={40}
                    onFocus={() => setIsEmailMessageFocused(true)}
                    onBlur={() => setIsEmailMessageFocused(false)}
                    className={"signin_input_focus, paddingClass"}
                  />
                }
              />
            </>
          )}
          {alertValue === "Slack" && (
            <FieldSet
              legendTitle={"Slack URL"}
              type={"text"}
              name={"Slack URL"}
              id={"Slack URL"}
              value={slackURL}
              onChange={handleSlackURLChange}
              placeholder={t("jobs.form.inputs.task.alert.slack.placeholder")}
              isInputFocused={isSlackFocused}
              onFocus={() => setIsSlackFocused(true)}
              onBlur={() => setIsSlackFocused(false)}
            />
          )}
          {alertValue === "Teams" && (
            <FieldSet
              legendTitle={"MS Teams URL"}
              type={"text"}
              name={"Tags"}
              id={"Tags"}
              value={teamsURL}
              onChange={handleTeamsURLChange}
              placeholder={t("jobs.form.inputs.task.alert.teams.placeholder")}
              isInputFocused={isteamsURLFocused}
              onFocus={() => setIsteamsURLFocused(true)}
              onBlur={() => setIsteamsURLFocused(false)}
            />
          )}
        </StyledDashboardContentFlex>
        <div>
          <button
            className={"next_btn save_btn"}
            type="submit"
            onClick={handlePostJobs}
          >
            {t("reusable_components.buttons.labels.save")}
          </button>
        </div>
      </StyledDashboardContentFlex>
    </div>
  );
};

export default Task;
