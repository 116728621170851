/* eslint-disable no-unused-vars */
import { useState } from "react";

import { axiosGetTable } from "../../../../clientRequest/axiosRequest";
import { fetchDatabaseName } from "../../../redux/actions/usersActions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const useMYSQL = (token, taskData, setTaskData, setMYSQLIngestions) => {
  const dispatch = useDispatch();
  const { environmentVariables } = useSelector((state) => state.users);
  const baseURL = `${environmentVariables.REACT_APP_TABLE_API}/yeda/database-metadata/mysql`;
  const [mySQLselectedOption, setmySQLSelectedOption] = useState("");
  const [mySQLqueries, setmySQLQueries] = useState([]);
  const [mySQLcheckboxValues, setmySQLCheckboxValues] = useState([false]);
  const [mySQLParams, setmySQLParamsValue] = useState([
    {
      host: "",
      port: "",
      user: "",
      password: "",
      id: 1,
    },
  ]);

  const { t } = useTranslation();

  const mySQLDataValues = [
    {
      id: 1,
      name: "host",
      title: "Host",
      CheckBox: false,
      defaultValue: "",
      required: true,
    },
    {
      id: 2,
      name: "port",
      title: "Port",
      CheckBox: false,
      defaultValue: "",
      required: true,
    },
    {
      id: 3,
      name: "user",
      title: t(
        "jobs.form.inputs.task.modal.connectors.server.hook.api_data.three",
      ),
      CheckBox: false,
      defaultValue: "",
      required: true,
    },
    {
      id: 4,
      name: "password",
      title: t(
        "jobs.form.inputs.task.modal.connectors.server.hook.api_data.four",
      ),
      CheckBox: false,
      password: true,
      defaultValue: "",
      required: true,
    },
  ];
  const group = localStorage.getItem("group");
  const mySQLoptionsArray = [
    {
      id: 1,
      options: "",
      title: "Select",
    },
    {
      id: 2,
      options: "query",
      title: "Query",
    },
    {
      id: 3,
      options: "tables",
      title: "Table(s)",
    },
  ];
  const handlemySQLServerValuesChange = (event, id) => {
    const { name, value } = event.target;
    mySQLParams.map((data) => {
      if (data.id === id) {
        switch (name) {
          case "host":
            data.host = value;
            break;
          case "port":
            data.port = value;
            break;
          case "user":
            data.user = value;
            break;
          case "password":
            data.password = value;
            break;
          default:
            console.log("error");
        }
      }
      return data;
    });
    const allValuesFilled = mySQLParams.every(
      (params) => params.host && params.port && params.user && params.password,
    );
    setMYSQLIngestions(allValuesFilled);
    const currentmySQLServerInput = mySQLParams.find(
      (input) => input.id === id,
    );
    taskData.map((data) => {
      if (data.id === id) {
        data.params.input_db_host = currentmySQLServerInput.host;
        data.params.input_db_port = currentmySQLServerInput.port;
        data.params.input_db_user = currentmySQLServerInput.user;
        data.params.input_db_pass = currentmySQLServerInput.password;
        data.params.destination_bucket = `databoat-raw-${group}`;
        data.source_type = "mysql";
      }
      return data;
    });
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const handlemySQLqueryDataBaseName = (event, id) => {
    const selected = event.target.value;
    const updatedData = taskData.map((data) => {
      if (data.id === id) {
        data.params.query_db_name = selected;
      }
      return data;
    });
    setTaskData(updatedData);
  };
  const [mySQLError, setmySQLError] = useState("");

  const handlemySQLSelectChange = (event, taskId) => {
    const selected = event.target.value;
    const updatedData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.selected = selected;
      }
      return data;
    });

    setTaskData(updatedData);
    setmySQLSelectedOption(selected);
    const currentDbParam = mySQLParams.find((data) => data.id === taskId);
    const validateForm = () => {
      const errors = [];
      if (!currentDbParam.host.trim()) {
        errors.host = "Host is required";
      }
      if (!currentDbParam.user.trim()) {
        errors.user = "User is required";
      }
      if (!currentDbParam.password.trim()) {
        errors.password = "Password is required";
      }

      setmySQLError(errors);
      return Object.keys(errors).length === 0;
    };
    if (validateForm()) {
      setmySQLError("");
      if (selected === "tables") {
        const formData = {
          user: currentDbParam.user,
          password: currentDbParam.password,
          server: currentDbParam.host,
        };
        dispatch(fetchDatabaseName(formData, baseURL, token));
      }
    } else {
      setmySQLError("Please fill all required fields");
    }
  };

  const [mySQLServerdataQueries, setmySQLServerDataQueries] = useState(() => [
    {
      id: 1,
      query: null,
      table_name: null,
      partitioning: false,
      partition_columns: null,
    },
  ]);
  // Function to handle adding more rows
  const addMoremySQLServerQueries = (id) => {
    setmySQLServerDataQueries((previousQueryData) => {
      return [
        ...previousQueryData,
        {
          id: previousQueryData.length + 1,
          query: null,
          table_name: null,
          partitioning: false,
          partition_columns: null,
        },
      ];
    });
  };
  const handlemySQLQueriesTableNameChange = (event, id, taskId) => {
    const selected = event.target.value;
    const updatedData = mySQLServerdataQueries.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          table_name: selected,
        };
      }
      return data;
    });

    setmySQLServerDataQueries(updatedData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.queries.map((item) => {
          if (item.id === id) {
            item.table_name = selected;
            return item;
          }
          return item;
        });
      }
      return data;
    });

    setTaskData(updatedTaskData);
  };
  const handlemySQLQueriesChange = (event, id, taskId) => {
    const selected = event.target.value;
    const updatedData = mySQLServerdataQueries.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          query: selected,
        };
      }
      return data;
    });

    setmySQLServerDataQueries(updatedData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.queries = updatedData;
      }
      return data;
    });

    setTaskData(updatedTaskData);
  };

  const handlemySQLCheckboxChange = (event, id, taskId) => {
    let selected = event.target.value;
    if (selected === "on") {
      selected = true;
    } else {
      selected = false;
    }
    const updatedData = mySQLServerdataQueries.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          partitioning: selected,
        };
      }
      return data;
    });
    setmySQLServerDataQueries(updatedData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.queries.map((item) => {
          item.partitioning = selected;
          return item;
        });
      }
      return data;
    });

    setTaskData(updatedTaskData);
  };
  const handlemySQLQueryPartitionChange = (event, id, taskId) => {
    const selected = event.target.value.split(",");
    const updatedQuery = mySQLServerdataQueries.map((data) => {
      if (data.id === id) {
        data.partition_columns = selected;
      }
      return data;
    });
    setmySQLServerDataQueries(updatedQuery);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.queries.map((item) => {
          if (item.id === id) {
            item.partition_columns = selected;
            return item;
          }
          return item;
        });
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };
  //-----------------------------------------SQLSERVER TABLES
  const [mySQLisChecked, setmySQLIsChecked] = useState();
  const [mySQLloadType, setmySQLLoadType] = useState("");
  const mySQLdatabase = useSelector((state) => state.users.databaseName);

  const mySQLdatabaseNameLoader = useSelector(
    (state) => state.users.databaseNameLoader,
  );
  const mySQLtableHead = [
    { id: 1, title: "Database Name" },
    // { id: 2, title: "Schema" },
    { id: 3, title: "Table" },
    { id: 4, title: "Ingestion Mode" },
    mySQLloadType === "delta" && { id: 5, title: "Delta_Column" },
    { id: 6, title: "Partition" },
    mySQLisChecked && { id: 7, title: "Partition_Column" },
  ].filter(Boolean);
  const [mySQLtableData, setmySQLTableData] = useState(() => [
    {
      id: 1,
      input_db_name: null,
      input_db_schema: null,
      input_table_name: null,
      load_type: null,
      partitioning: false,
      isDelta: false,
      delta_column: null,
      partition_columns: null,
      tableSchemasLoader: false,
      listTablesLoader: false,
      deltaColumnDataLoader: false,
      partitionColumnDataLoader: false,
    },
  ]);

  // Function to handle adding more rows
  const mySQLaddRow = () => {
    setmySQLTableData((previousTableData) => {
      return [
        ...previousTableData,
        {
          id: previousTableData.length + 1,
          input_db_name: "",
          input_db_schema: "",
          input_table_name: "",
          load_type: "",
          partitioning: false,
          isDelta: false,
          delta_column: null,
          partition_columns: null,
          tableSchemasLoader: false,
          listTablesLoader: false,
          deltaColumnDataLoader: false,
          partitionColumnDataLoader: false,
        },
      ];
    });
    setmySQLSchemas((previousSchemaData) => {
      return [
        ...previousSchemaData,
        {
          id: previousSchemaData.length + 1,
          schema_names: [],
        },
      ];
    });
    setmySQLTables((previousTableNameData) => {
      return [
        ...previousTableNameData,
        {
          id: previousTableNameData.length + 1,
          table_names: [],
        },
      ];
    });
    setmySQLDeltaColumn((previousdeltaData) => {
      return [
        ...previousdeltaData,
        {
          id: previousdeltaData.length + 1,
          COLUMN_NAMES: [],
        },
      ];
    });
    setmySQLPartitionColumn((previousPartitioneData) => {
      return [
        ...previousPartitioneData,
        {
          id: previousPartitioneData.length + 1,
          COLUMN_NAMES: [],
        },
      ];
    });
  };
  const mySQLtableOptions = [
    { id: 1, option: "", title: "Select" },
    { id: 2, option: "full", title: "Full" },
    { id: 3, option: "delta", title: "Delta" },
  ];
  const [mySQLschemas, setmySQLSchemas] = useState(() =>
    mySQLdatabase.map((item) => ({
      id: 1,
      schema_names: item.database_name,
    })),
  );

  const [mySQLtables, setmySQLTables] = useState(() => [
    {
      id: 1,
      table_names: [],
    },
  ]);
  const [mySQLdeltaColumn, setmySQLDeltaColumn] = useState(() => [
    {
      id: 1,
      COLUMN_NAMES: [],
    },
  ]);
  const [mySQLpartitionColumn, setmySQLPartitionColumn] = useState(() => [
    {
      id: 1,
      COLUMN_NAMES: [],
    },
  ]);

  const mySQLdatabaseName = [
    { value: "", label: "Select" },
    ...mySQLdatabase.map((item) => {
      return {
        value: item.database_name,
        label: item.database_name,
      };
    }),
  ];

  const handlemySQLDatabaseNameChange = async (event, id, taskId) => {
    const selected = event.target.value;
    if (selected) {
      const currentDbParam = mySQLParams.find((data) => data.id === taskId);
      //table name
      await fetchmySQLTableName(
        currentDbParam.user,
        currentDbParam.password,
        currentDbParam.host,
        selected,
        id,
      );
    }
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        const existingTableIndex = data.params.tables.findIndex(
          (item) => item.id === id,
        );

        if (existingTableIndex !== -1) {
          // Table with the given id exists, update it
          data.params.tables[existingTableIndex].input_db_name = selected;
        } else {
          // Table with the given id doesn't exist, create a new one
          data.params.tables.push({
            id: id,
            input_db_name: selected,
          });
        }
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };
  const handlemySQLSchemaNameChange = async (event, id, taskId) => {
    const selected = event.target.value;
  };

  const handlemySQLTableNameChange = (event, id, taskId) => {
    const selected = event.target.value;
    const updatedData = mySQLtableData.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          input_table_name: selected,
        };
      }
      return data;
    });
    setmySQLTableData(updatedData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        const existingTableIndex = data.params.tables.findIndex(
          (item) => item.id === id,
        );

        if (existingTableIndex !== -1) {
          // Table with the given id exists, update it
          data.params.tables[existingTableIndex].input_table_name = selected;
        } else {
          // Table with the given id doesn't exist, create a new one
          data.params.tables.push({
            id: id,
            input_table_name: selected,
          });
        }
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };
  const handlemySQLTableIngestModeOption = async (event, id, taskId) => {
    let selectedDbName = "";
    let selectedTableName = "";
    const selectedValue = event.target.value;
    if (selectedValue) {
      const currentDbParam = mySQLParams.find((data) => data.id === taskId);
      mySQLtableData.map((data) => {
        if (data.id === id)
          return {
            ...data,
            selectedDbName: data.input_db_name,
            selectedTableName: data.input_table_name,
          };
        return data;
      });
      await fetchmySQLDeltaColumn(
        currentDbParam.user,
        currentDbParam.password,
        currentDbParam.host,
        selectedDbName,
        selectedTableName,
        id,
        selectedValue,
      );
    }
    setmySQLLoadType(selectedValue);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        const existingTableIndex = data.params.tables.findIndex(
          (item) => item.id === id,
        );

        if (existingTableIndex !== -1) {
          // Table with the given id exists, update it
          data.params.tables[existingTableIndex].load_type = selectedValue;
          data.params.tables[existingTableIndex].isDelta =
            selectedValue === "delta";
        } else {
          // Table with the given id doesn't exist, create a new one
          data.params.tables.push({
            id: id,
            load_type: selectedValue,
            isDelta: selectedValue === "delta",
          });
        }
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };
  const handlemySQLDeltaColumnChange = (event, id, taskId) => {
    const updatedData = mySQLtableData.map((data) => {
      if (data.id === id) {
        return { ...data, delta_column: event.target.value };
      }
      return data;
    });
    setmySQLTableData(updatedData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        const existingTableIndex = data.params.tables.findIndex(
          (item) => item.id === id,
        );

        if (existingTableIndex !== -1) {
          // Table with the given id exists, update it
          data.params.tables[existingTableIndex].delta_column =
            event.target.value;
        } else {
          // Table with the given id doesn't exist, create a new one
          data.params.tables.push({
            id: id,
            delta_column: event.target.value,
          });
        }
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };

  const handlemySQLPartionChange = async (event, id, taskId) => {
    let selected = event.target.value === "on";
    if (selected) {
      const currentDbParam = mySQLParams.find((data) => data.id === taskId);
      let selectedDbName = "";
      let selectedTableName = "";

      mySQLtableData.forEach((data) => {
        if (data.id === id) {
          selectedDbName = data.input_db_name;
          selectedTableName = data.input_table_name;
        }
      });

      if (selectedDbName && selectedTableName) {
        await fetchmySQLPartitionColumn(
          currentDbParam.user,
          currentDbParam.password,
          currentDbParam.host,
          selectedDbName,
          selectedTableName,
          id,
          selected,
        );
      } else {
        console.error("Database name or table name is missing");
      }
    }
    setmySQLIsChecked(selected);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        const existingTableIndex = data.params.tables.findIndex(
          (item) => item.id === id,
        );

        if (existingTableIndex !== -1) {
          // Table with the given id exists, update it
          data.params.tables[existingTableIndex].partitioning = selected;
        } else {
          // Table with the given id doesn't exist, create a new one
          data.params.tables.push({
            id: id,
            partitioning: selected,
          });
        }
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };

  const handlemySQLPartitioningChange = (options, id, taskId) => {
    const partion_column = options.map((value) => value.value);
    const updatedData = mySQLtableData.map((data) => {
      if (data.id === id) {
        return { ...data, partition_columns: partion_column };
      }
      return data;
    });
    setmySQLTableData(updatedData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        const existingTableIndex = data.params.tables.findIndex(
          (item) => item.id === id,
        );

        if (existingTableIndex !== -1) {
          // Table with the given id exists, update it
          data.params.tables[existingTableIndex].partition_columns =
            partion_column;
        } else {
          // Table with the given id doesn't exist, create a new one
          data.params.tables.push({
            id: id,
            partition_columns: partion_column,
          });
        }
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };
  const fetchmySQLTableName = async (
    user,
    password,
    host,
    selectedDbName,
    id,
  ) => {
    const formData = {
      user: user,
      password: password,
      server: host,
      database: selectedDbName,
    };
    const response = await axiosGetTable(baseURL).post(
      "/list-tables",
      formData,
      { headers },
    );
    const updatedData = mySQLtableData.map((data) => {
      if (data.id === id)
        return {
          ...data,
          input_db_name: selectedDbName,
          listTablesLoader: true,
        };
      return data;
    });

    setmySQLTableData(updatedData);
    const updatedTable = mySQLtables.map((tables) => {
      if (tables.id === id) return { ...tables, table_names: response.data };
      return tables;
    });

    setmySQLTables(updatedTable);
  };
  const fetchmySQLDeltaColumn = async (
    user,
    password,
    host,
    selectedDbName,
    selectedTableName,
    id,
    selectedValue,
  ) => {
    const formData = {
      user: user,
      password: password,
      server: host,
      database: selectedDbName,
      table: selectedTableName,
    };
    const response = await axiosGetTable(baseURL).post(
      "/list-columns-date",
      formData,
      { headers },
    ); //[]
    const updatedData = mySQLtableData.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          load_type: selectedValue,
          isDelta: selectedValue === "delta",
          deltaColumnDataLoader: true,
        };
      }
      return data;
    });
    setmySQLTableData(updatedData);
    const updatedDeltaColumn = mySQLdeltaColumn.map((columns) => {
      if (columns.id === id) return { ...columns, COLUMN_NAMES: response.data };
      return columns;
    });
    setmySQLDeltaColumn(updatedDeltaColumn);
  };
  const fetchmySQLPartitionColumn = async (
    user,
    password,
    host,
    selectedDbName,
    selectedTableName,
    id,
    selected,
  ) => {
    const formData = {
      user: user,
      password: password,
      server: host,
      database: selectedDbName,
      table: selectedTableName,
    };
    const response = await axiosGetTable(baseURL).post(
      "/list-columns",
      formData,
      { headers },
    );
    const updatedData = mySQLtableData.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          partitioning: selected,
          partitionColumnDataLoader: true,
        };
      }
      return data;
    });
    setmySQLTableData(updatedData);

    const updatedPartitonColumn = mySQLpartitionColumn.map((columns) => {
      if (columns.id === id) return { ...columns, COLUMN_NAMES: response.data };
      return columns;
    });
    setmySQLPartitionColumn(updatedPartitonColumn);
  };
  const [mySQLpartitonColValue, setmySQLpartitonColValue] = useState("");
  //------------------------ends

  return [
    mySQLDataValues,
    setmySQLParamsValue,
    handlemySQLServerValuesChange,
    mySQLError,
    handlemySQLqueryDataBaseName,
    mySQLselectedOption,
    handlemySQLSelectChange,
    mySQLoptionsArray,
    mySQLServerdataQueries,
    handlemySQLQueriesTableNameChange,
    mySQLqueries,
    handlemySQLQueriesChange,
    mySQLcheckboxValues,
    handlemySQLCheckboxChange,
    mySQLpartitonColValue,
    handlemySQLQueryPartitionChange,
    addMoremySQLServerQueries,
    mySQLtableHead,
    mySQLtableData,
    handlemySQLDatabaseNameChange,
    mySQLdatabaseNameLoader,
    mySQLdatabaseName,
    handlemySQLSchemaNameChange,
    mySQLschemas,
    handlemySQLTableNameChange,
    mySQLtables,
    handlemySQLTableIngestModeOption,
    mySQLtableOptions,
    handlemySQLDeltaColumnChange,
    mySQLdeltaColumn,
    handlemySQLPartionChange,
    handlemySQLPartitioningChange,
    mySQLpartitionColumn,
    mySQLaddRow,
  ];
};

export default useMYSQL;
