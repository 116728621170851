import React from "react";
import {
  StyledDashboardContentFlex,
  RenderDataItems,
  Select,
  RenderIngestion,
  RenderQuery,
  RenderTable,
  DragandDrop,
  Flex,
  UIFormat,
  StyledConnectorInput,
} from "../index";
import { Tooltip } from "react-tooltip";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ConnectorScreens = ({
  HeaderText,
  inputData,
  data,
  handleChange,
  errors,
  useSourceBucket,
  formatType,
  sourceBucketFormat,
  bucketFileName,
  handleSourceBucketChange,
  sourceBucketPlaceholder,
  handleSourceBucketBlur,
  sourceBucketError,
  useTaskPartition,
  taskPartitionColumn,
  handleTaskPartitionColumn,
  http,
  apiPartitionText,
  apiHttp,
  httpvalue,
  handleHttpChange,
  httpData,
  apiPartition,
  apiPartitionValue,
  handleapiPartitionValueChange,
  apiPartitionValueData,
  sourcePath,
  sourcePathText,
  sourcePathValue,
  handlesourcePathValueChange,
  sourcePathValueData,
  setQueryDBName,
  handlequeryDataBaseName,
  ingestions,
  htmlFor,
  selectTitle,
  selectedOption,
  handleSelected,
  options,
  dataQueries,
  handleQueriesTableNameChange,
  queryValues,
  taskId,
  handleQueriesChange,
  checkboxValues,
  handleCheckboxChange,
  queryPartitionValue,
  handleQueryPartitionChange,
  handleAddMore,
  tableHead,
  tableData,
  handleDatabaseNameChange,
  databaseNameLoader,
  databaseName,
  handleSchemaNameChange,
  useSchemas,
  schemas,
  usesql,
  handleTableNameChange,
  tables,
  handleTableIngestModeOption,
  tableOptions,
  handleDeltaColumnChange,
  deltaColumn,
  handlePartionChange,
  handlePartitioningChange,
  partitionColumn,
  useTableformat,
  addRow,
  upload,
  files,
  progress,
  handleRemove,
  fileRejections,
  getRootProps,
  isDragActive,
  getInputProps,
  errMsg,
  errRef,
  renderKeyValue,
  formtitle,
  apiHeaderText,
  paramKey,
  handleKeyChange,
  keyPlaceholder,
  valuePlaceholder,
  paramValue,
  handleValueChange,
  btnOnClick,
  buttonText,
}) => {
  const styles = {
    margin: 0,
    width: "50%",
  };
  const styled = {
    container: {
      display: "flex",
      width: "100%",
      padding: "1em",
      border: "1px solid rgb(0, 0, 0)",
      font: "400 16px / 24px Poppins, sans-serif",
      transition: "all 0.5s ease 0s",
      outline: "none",
      opacity: "0.6",
      borderRadius: "4px",
    },
    input: {
      border: "none",
      outline: "none",
      width: "100%",
      color: "#42474E",
      font: "normal 400 16px/24px 'Poppins',sans-serif",
      WebkitTransition: "0.5s",
    },
  };

  const { databaseNameError } = useSelector((state) => state.users);

  const { t } = useTranslation();

  return (
    <StyledDashboardContentFlex column style={{ padding: "2em" }}>
      <p>{HeaderText}</p>
      <StyledDashboardContentFlex column>
        {useSourceBucket && (
          <div
            style={styled.container}
            onBlur={handleSourceBucketBlur}
            className={"my-source-bucket"}
          >
            <Tooltip
              anchorSelect=".my-source-bucket"
              place="top"
              className="hover-info-container"
            >
              <Flex column>
                <span>{formatType}</span>
              </Flex>
            </Tooltip>
            <p>{sourceBucketFormat} </p>
            <input
              style={styled.input}
              type="text"
              className="sourceStyle"
              name="tasklevelPartitionColumn"
              defaultValue={bucketFileName}
              onChange={handleSourceBucketChange}
              placeholder={`${t(
                "jobs.form.inputs.task.modal.connectors.bucket.inputs.placeholder.bucket_ph",
              )}${sourceBucketPlaceholder}`}
              onBlur={handleSourceBucketBlur}
            />
          </div>
        )}
        {inputData && (
          <RenderDataItems
            data={data}
            handleChange={handleChange}
            errors={errors}
          />
        )}

        {useTaskPartition && (
          <div style={styled.container} className={"my-partition-column"}>
            <Tooltip
              anchorSelect=".my-partition-column"
              place="top"
              className="hover-info-container"
            >
              <Flex column>
                <span>
                  Column used to improve the performance to read and write data
                </span>
              </Flex>
            </Tooltip>
            <input
              style={styled.input}
              type="text"
              name="tasklevelPartitionColumn"
              value={taskPartitionColumn}
              onChange={handleTaskPartitionColumn}
              placeholder={t(
                "jobs.form.inputs.task.modal.connectors.bucket.inputs.placeholder.task_partition",
              )}
            />
          </div>
        )}
        {apiHttp && (
          <Select
            selectText={http}
            value={httpvalue}
            handleSelectChange={handleHttpChange}
            selectData={httpData}
          />
        )}
        {apiPartition && (
          <Select
            selectText={apiPartitionText}
            value={apiPartitionValue}
            handleSelectChange={handleapiPartitionValueChange}
            selectData={apiPartitionValueData}
          />
        )}
        {sourcePath && (
          <Select
            selectText={sourcePathText}
            value={sourcePathValue}
            handleSelectChange={handlesourcePathValueChange}
            selectData={sourcePathValueData}
          />
        )}
        {setQueryDBName && (
          <StyledConnectorInput
            type="text"
            defaultValue={""}
            onChange={handlequeryDataBaseName}
            placeholder={t(
              "jobs.form.inputs.task.modal.connectors.server.placeholder.db_name",
            )}
          />
        )}
        {ingestions ? (
          <>
            <RenderIngestion
              htmlFor={htmlFor}
              selectTitle={selectTitle}
              selectedOption={selectedOption}
              handleSelected={handleSelected}
              options={options}
            />

            <div>
              {databaseNameError !== null ? (
                <p className="error">{databaseNameError}</p>
              ) : selectedOption === "query" ? (
                <RenderQuery
                  dataQueries={dataQueries}
                  handleQueriesTableNameChange={handleQueriesTableNameChange}
                  queryValues={queryValues}
                  taskId={taskId}
                  handleQueriesChange={handleQueriesChange}
                  checkboxValues={checkboxValues}
                  handleCheckboxChange={handleCheckboxChange}
                  queryPartitionValue={queryPartitionValue}
                  handleQueryPartitionChange={handleQueryPartitionChange}
                  handleAddMore={handleAddMore}
                />
              ) : selectedOption === "tables" ? (
                <RenderTable
                  taskId={taskId}
                  tableHead={tableHead}
                  tableData={tableData}
                  databaseNameLoader={databaseNameLoader}
                  handleDatabaseNameChange={handleDatabaseNameChange}
                  databaseName={databaseName}
                  handleSchemaNameChange={handleSchemaNameChange}
                  useSchemas={useSchemas}
                  schemas={schemas}
                  usesql={usesql}
                  handleTableNameChange={handleTableNameChange}
                  tables={tables}
                  handleTableIngestModeOption={handleTableIngestModeOption}
                  tableOptions={tableOptions}
                  handleDeltaColumnChange={handleDeltaColumnChange}
                  deltaColumn={deltaColumn}
                  handlePartionChange={handlePartionChange}
                  handlePartitioningChange={handlePartitioningChange}
                  partitionColumn={partitionColumn}
                  useTableformat={useTableformat}
                  addRow={addRow}
                />
              ) : null}
            </div>
          </>
        ) : null}
        {upload && (
          <DragandDrop
            files={files}
            progress={progress}
            handleRemove={handleRemove}
            fileRejections={fileRejections}
            getRootProps={getRootProps}
            isDragActive={isDragActive}
            getInputProps={getInputProps}
            errMsg={errMsg}
            errRef={errRef}
          />
        )}
        <p>{apiHeaderText}</p>
        {renderKeyValue && (
          <UIFormat
            widthStyled={styles}
            formtitle={"API_Header_Params"}
            paramKey={paramKey}
            keyPlaceholder={keyPlaceholder}
            handleKeyChange={handleKeyChange}
            paramValue={paramValue}
            valuePlaceholder={valuePlaceholder}
            handleValueChange={handleValueChange}
          />
        )}
        <div>
          <button className={"next_btn save_btn"} onClick={btnOnClick}>
            {buttonText}
          </button>
        </div>
      </StyledDashboardContentFlex>
    </StyledDashboardContentFlex>
  );
};
export default ConnectorScreens;
