import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import Button from "../reusableComponents/Button";
import { StyledCenteredModal } from "./Modal.Styled";

export const StyledYedaflowContainer = styled.div`
  padding: 1.5em 3em;
  width: 80%;
`;

export const TabsContainer = styled.div`
  width: 100%;
  border-style: solid;
  border-width: 0;
  border-bottom-width: 1px;
  border-color: #e6e7e6;
  margin-bottom: 16px;
`;
export const StyledTabLink = styled(Link)`
  color: ${({ theme }) => theme.colors.blueColor};
  font: ${({ theme }) => theme.fonts.fontPrimary};
  letter-spacing: ${({ theme }) => theme.fonts.primaryLetterSpacing};
  display: flex;
  text-decoration: none;
  gap: 0.5em;
  &:hover {
    cursor: pointer;
  }
`;
export const StyledTabParagraph = styled.p`
  color: ${({ theme }) => theme.colors.blueColor};
  font: ${({ theme }) => theme.fonts.fontPrimary};
  letter-spacing: ${({ theme }) => theme.fonts.primaryLetterSpacing};
  display: flex;
  text-decoration: none;
  gap: 0.5em;
  &:hover {
    cursor: pointer;
  }
`;
export const StyledSlash = styled.div`
  color: ${({ theme }) => theme.colors.borderColor};
  text-align: center;
  font: ${({ theme }) => theme.fonts.fontMedium};
`;

const YedaButton = css`
  background-color: #4a77b3;
  color: white;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 14px;
  border: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #1b529b;
  }
`;

export const CreateJobButton = styled(Button)`
  ${YedaButton}
  border-radius: ${({ theme }) => theme.radius.secondaryBorderRadius};
  background: ${({ theme }) => theme.colors.blueColor};
  color: ${({ theme }) => theme.colors.whiteColor};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  padding: 10px 44px;
  font: ${({ theme }) => theme.fonts.fontPrimary};
  letter-spacing: ${({ theme }) => theme.fonts.primaryLetterSpacing};
`;
export const JobDetailsButton = styled(Button)`
  ${YedaButton}
`;
export const Table = styled.table`
  border: 1px solid ${({ theme }) => theme.colors.transparentBlack};
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;

  tr {
    border: 1px solid ${({ theme }) => theme.colors.lightGrey};
    height: 70px;
    padding: 5em;
    &:hover {
      background-color: ${({ theme }) => theme.colors.lightGrey};
    }
  }
  th,
  td {
    border: none;
    padding: 1em 3em 1em 1em;
    text-align: left;
  }
  td {
    width: calc(100% / 3);
  }
`;

export const CSVTable = styled.table`
  ${Table}
  th,
  td {
    padding: 0;
    white-space: normal;
  }
  th {
    color: ${({ theme }) => theme.colors.sysSurfaceTextColor};
    font: ${({ theme }) => theme.fonts.fontPrimary};
    letter-spacing: 0.1px;
  }
  td {
    color: ${({ theme }) => theme.colors.sysSurfaceTextColor};
    font: ${({ theme }) => theme.fonts.fontSecondary};
    letter-spacing: 0.25px;
  }
`;

export const ScrollableTable = styled.table`
  border: 1px solid ${({ theme }) => theme.colors.transparentBlack};
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  tr {
    border: 1px solid ${({ theme }) => theme.colors.lightGrey};
    height: 70px;
    padding: 5em;
    &:hover {
      background-color: ${({ theme }) => theme.colors.lightGrey};
    }
  }
  th,
  td {
    border: none;
    padding: 1em 3em 1em 1em;
    white-space: nowrap;
    text-align: left;
  }
  th:first-child,
  td:first-child {
    width: 0;
  }
`;
export const Thead = styled.thead`
  background: ${({ theme }) => theme.colors.lightBlue};
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightBlue};
  }
`;
export const TableHeaderCell = styled.th`
  color: ${({ theme }) => theme.colors.sysSurfaceTextColor};
  font: ${({ theme }) => theme.fonts.fontPrimary};
  letter-spacing: 0.1px;
`;

export const TableDataCell = styled.td`
  color: ${({ theme }) => theme.colors.sysSurfaceTextColor};
  font: ${({ theme }) => theme.fonts.fontSecondary};
  letter-spacing: 0.25px;
`;

export const JobName = styled.span`
  color: ${({ theme }) => theme.colors.blueColor};
  font: ${({ theme }) => theme.fonts.fontSecondaryBold};
  letter-spacing: 0.15px;
  &:hover {
    cursor: pointer;
  }
`;
export const StyledJobPaginationResult = styled.p`
  color: ${({ theme }) => theme.colors.sysSurfaceTextColor};
  font: ${({ theme }) => theme.fonts.smallFont};
  letter-spacing: 0.25px;
`;
export const BreadcrumbsWrapper = styled.div``;

export const BreadcrumbsLink = styled(Link)`
  color: #4a77b3;
  text-decoration: none;
  transition: 0.3s;
  font-weight: 600;

  &:visited {
    color: #4a77b3;
  }

  &:hover {
    color: #003c89;
  }
`;

export const TaskModal = styled(StyledCenteredModal)`
  width: clamp(600px, 620px, 70%);
`;

export const TransformationFileModal = styled(StyledCenteredModal)`
  width: clamp(800px, 820px, 70%);
`;

export const JobNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const JobDetailsTopHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const JobDetailsCheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
`;

export const JobDetailsCheckbox = styled.input`
  display: none;
`;

export const JobDetailsCustomCheckbox = styled.div`
  width: 18px;
  height: 18px;
  border: 2px solid #1b529b;
  border-radius: 3px;
  margin: 0;
  margin-right: 8px;
  position: relative;
  background-color: ${(props) => (props.autoRefresh ? "#1b529b" : "white")};
`;

export const JobDetailsCheckmark = styled.span`
  content: "";
  position: absolute;
  display: ${(props) => (props.autoRefresh ? "block" : "none")};
  left: 5px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
`;

export const JobDetailsTable = styled.table`
  /* margin-top: 40px; */
  border-radius: 8px;
  border-collapse: collapse;
  border: unset;
  table-layout: fixed;
  border: 0px solid transparent;
  border-bottom: 1px solid #ddd;
  width: 100%;
  margin-bottom: 8px;
`;

export const JobDetailsTableHeader = styled.th`
  display: "flex";
  flex-direction: "column";
  gap: "2em";
  flex-wrap: wrap;
  border: 0 solid black;
  transition: 0.3s;
  width: 36px;
  max-width: 36px;
  position: relative;
  top: 20px;
  padding: 0;
  cursor: pointer;
  border: 0px solid #ddd;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
`;

export const TaskNameCell = styled.td`
  border-style: solid;
  transition: 0.3s;
  cursor: pointer;
  position: sticky;
  left: 0;
  z-index: 1;
  border: 0px solid #ddd;
  border-bottom: 1px solid #ddd;
`;

export const TaskOccurrenceCell = styled.td`
  border-style: solid;
  padding: 8px;
  width: 36px;
  max-width: 36px;
  min-width: 36px;
  position: relative;
  transition: 0.3s;
  cursor: pointer;
  padding: 0;
  border: 0px solid #ddd;
  border-bottom: 1px solid #ddd;
`;

export const StatusSquare = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 1px solid #ddd;
  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    height: 60%;
  }
`;
