import React from "react";

export const Book = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      cursor={"pointer"}
    >
      <path
        d="M7.3125 2.25H4.5C4.20163 2.25 3.91548 2.36853 3.7045 2.5795C3.49353 2.79048 3.375 3.07663 3.375 3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H7.3125C7.61087 15.75 7.89702 15.6315 8.108 15.4205C8.31897 15.2095 8.4375 14.9234 8.4375 14.625V3.375C8.4375 3.07663 8.31897 2.79048 8.108 2.5795C7.89702 2.36853 7.61087 2.25 7.3125 2.25ZM7.3125 14.625H4.5V3.375H7.3125V14.625ZM13.5 2.25H10.6875C10.3891 2.25 10.103 2.36853 9.892 2.5795C9.68103 2.79048 9.5625 3.07663 9.5625 3.375V14.625C9.5625 14.9234 9.68103 15.2095 9.892 15.4205C10.103 15.6315 10.3891 15.75 10.6875 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V3.375C14.625 3.07663 14.5065 2.79048 14.2955 2.5795C14.0845 2.36853 13.7984 2.25 13.5 2.25ZM13.5 14.625H10.6875V3.375H13.5V14.625Z"
        fill="#006399"
      />
    </svg>
  );
};
export const DownPaddedArrow = ({ onClick }) => {
  return (
    <span onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        cursor={"pointer"}
      >
        <path
          d="M15.0229 7.14797L9.39792 12.773C9.34568 12.8253 9.28365 12.8668 9.21536 12.8951C9.14707 12.9234 9.07388 12.9379 8.99996 12.9379C8.92603 12.9379 8.85284 12.9234 8.78455 12.8951C8.71627 12.8668 8.65423 12.8253 8.60199 12.773L2.97699 7.14797C2.89823 7.0693 2.84459 6.96903 2.82284 6.85986C2.8011 6.75069 2.81224 6.63752 2.85485 6.53468C2.89747 6.43184 2.96963 6.34396 3.06221 6.28216C3.1548 6.22036 3.26364 6.18741 3.37495 6.1875H14.625C14.7363 6.18741 14.8451 6.22036 14.9377 6.28216C15.0303 6.34396 15.1024 6.43184 15.1451 6.53468C15.1877 6.63752 15.1988 6.75069 15.1771 6.85986C15.1553 6.96903 15.1017 7.0693 15.0229 7.14797Z"
          fill="#001F25"
        />
      </svg>
    </span>
  );
};
export const PlaySvg = ({ onClick }) => {
  return (
    <span onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        cursor={"pointer"}
      >
        <path
          d="M21.7875 10.7334L8.28 2.47029C8.05227 2.33084 7.79144 2.25471 7.52445 2.24977C7.25746 2.24482 6.99399 2.31124 6.76125 2.44217C6.53073 2.57106 6.3387 2.75902 6.2049 2.98673C6.07111 3.21445 6.00039 3.47368 6 3.73779V20.2622C6.00174 20.6583 6.16072 21.0376 6.442 21.3166C6.72328 21.5956 7.10383 21.7515 7.5 21.75C7.77652 21.7499 8.04767 21.6736 8.28375 21.5297L21.7875 13.2665C22.0046 13.1342 22.184 12.9483 22.3084 12.7267C22.4329 12.5051 22.4983 12.2551 22.4983 12.0009C22.4983 11.7467 22.4329 11.4968 22.3084 11.2751C22.184 11.0535 22.0046 10.8676 21.7875 10.7353V10.7334ZM7.5 20.2444V3.74998L20.9841 12L7.5 20.2444Z"
          fill="#72777F"
        />
      </svg>
    </span>
  );
};

export const DeleteSvg = ({ onClick }) => {
  return (
    <span onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        cursor={"pointer"}
      >
        <path
          d="M20.25 4.5H16.5V3.75C16.5 3.15326 16.2629 2.58097 15.841 2.15901C15.419 1.73705 14.8467 1.5 14.25 1.5H9.75C9.15326 1.5 8.58097 1.73705 8.15901 2.15901C7.73705 2.58097 7.5 3.15326 7.5 3.75V4.5H3.75C3.55109 4.5 3.36032 4.57902 3.21967 4.71967C3.07902 4.86032 3 5.05109 3 5.25C3 5.44891 3.07902 5.63968 3.21967 5.78033C3.36032 5.92098 3.55109 6 3.75 6H4.5V19.5C4.5 19.8978 4.65804 20.2794 4.93934 20.5607C5.22064 20.842 5.60218 21 6 21H18C18.3978 21 18.7794 20.842 19.0607 20.5607C19.342 20.2794 19.5 19.8978 19.5 19.5V6H20.25C20.4489 6 20.6397 5.92098 20.7803 5.78033C20.921 5.63968 21 5.44891 21 5.25C21 5.05109 20.921 4.86032 20.7803 4.71967C20.6397 4.57902 20.4489 4.5 20.25 4.5ZM9 3.75C9 3.55109 9.07902 3.36032 9.21967 3.21967C9.36032 3.07902 9.55109 3 9.75 3H14.25C14.4489 3 14.6397 3.07902 14.7803 3.21967C14.921 3.36032 15 3.55109 15 3.75V4.5H9V3.75ZM18 19.5H6V6H18V19.5ZM10.5 9.75V15.75C10.5 15.9489 10.421 16.1397 10.2803 16.2803C10.1397 16.421 9.94891 16.5 9.75 16.5C9.55109 16.5 9.36032 16.421 9.21967 16.2803C9.07902 16.1397 9 15.9489 9 15.75V9.75C9 9.55109 9.07902 9.36032 9.21967 9.21967C9.36032 9.07902 9.55109 9 9.75 9C9.94891 9 10.1397 9.07902 10.2803 9.21967C10.421 9.36032 10.5 9.55109 10.5 9.75ZM15 9.75V15.75C15 15.9489 14.921 16.1397 14.7803 16.2803C14.6397 16.421 14.4489 16.5 14.25 16.5C14.0511 16.5 13.8603 16.421 13.7197 16.2803C13.579 16.1397 13.5 15.9489 13.5 15.75V9.75C13.5 9.55109 13.579 9.36032 13.7197 9.21967C13.8603 9.07902 14.0511 9 14.25 9C14.4489 9 14.6397 9.07902 14.7803 9.21967C14.921 9.36032 15 9.55109 15 9.75Z"
          fill="#72777F"
        />
      </svg>
    </span>
  );
};
