import React, { useState, useEffect } from "react";
import ChipLabelIcon from "./ChipLabelIcon";
import styled from "styled-components";

export const ListContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.secondaryColor};
  border: 1px solid ${({ theme }) => theme.colors.transparentBlack};
  position: absolute;
  display: flex;
  width: auto;
  flex-direction: column;
  align-items: flex-start;
  max-height: 200px;
  overflow: hidden;
  box-shadow:
    0px 2px 6px 2px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
`;

export const ListButton = styled.button`
  background-color: ${({ theme }) => theme.colors.secondaryColor};
  padding: 8px 16px 8px 16px;
  height: 48px;
  width: auto;
  max-width: 200px;
  color: ${({ theme }) => theme.colors.sysSurfaceTextColor};
  letter-spacing: ${({ theme }) => theme.fonts.secondaryLetterSpacing};
  font: ${({ theme }) => theme.fonts.fontSecondary};
  border: none;
  min-width: 100px;
`;

const Dropdown = ({ label, options, selected, handleChange }) => {
  const [open, setOpen] = useState(false);

  const handleOnClick = () => {
    setOpen(!open);
  };

  const handleOptionClick = (option) => {
    handleChange(option);
    setOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (open && !event.target.closest(".dropdown")) {
        setOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [open]);

  return (
    <div className="dropdown">
      <ChipLabelIcon
        label={selected ? selected : label}
        handleOnClick={handleOnClick}
        open={open}
        selected={selected}
      />
      {open && (
        <ListContainer>
          {options.map((option, index) => (
            <ListButton key={index} onClick={() => handleOptionClick(option)}>
              {option}
            </ListButton>
          ))}
        </ListContainer>
      )}
    </div>
  );
};

export default Dropdown;
