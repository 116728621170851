import React from "react";
import Loader from "../../images/wave.gif";

const PreLoader = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={Loader} alt="Loading..." style={{ width: "80%" }} />
    </div>
  );
};

export default PreLoader;
