import PipedriveReversedSyncConnector from "./PipedriveConnector";

const SelectedComponent = ({
  selectedText,
  closeReversedModal,
  onReversedClose,
  openReversedModal,
  reversedModalOpen,
  isReversedConnectorOpen,
  closeReversedConnector,
  taskId,
  taskData,
  setTaskData,
  onClose,
}) => {
  return (
    <div>
      {selectedText === "PipeDrive" ? (
        <PipedriveReversedSyncConnector
          selectedText={selectedText}
          closeReversedModal={closeReversedModal}
          onReversedClose={onReversedClose}
          openReversedModal={openReversedModal}
          reversedModalOpen={reversedModalOpen}
          isReversedConnectorOpen={isReversedConnectorOpen}
          closeReversedConnector={closeReversedConnector}
          taskId={taskId}
          taskData={taskData}
          setTaskData={setTaskData}
          onClose={onClose}
        />
      ) : null}
    </div>
  );
};

export default SelectedComponent;
