import React from "react";
import { Link } from "react-router-dom";
import { Flex, H3, PortalHeader, StyledPortalContainer } from "./index";

const IsNotLoggedIn = () => {
  const styles = {
    flex: {
      display: "flex",
      justifyContent: "center",
      alignItem: "center",
      flexDirection: "column",
      width: "50%",
      margin: "auto",
      gap: "1em",
    },
    text: {
      textAlign: "center",
    },
    buttonLink: {
      padding: "0.75em 1em",
      borderRadius: "8px",
      backgroundColor: "#222",
      color: "#fff",
      textAlign: "center",
      textDecoration: "none",
      width: "50%",
      margin: "auto",
    },
  };
  return (
    <StyledPortalContainer column>
      <PortalHeader />
      <div style={styles.flex}>
        <Flex column style={styles.text}>
          <H3>Please, Login.</H3>
          <p>Seems you're not logged in</p>
        </Flex>
        <Link to={"/"} style={styles.buttonLink}>
          Log in
        </Link>
      </div>
    </StyledPortalContainer>
  );
};

export default IsNotLoggedIn;
