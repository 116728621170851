import styled from "styled-components";

export const DeleteTableContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: relative;
`;

export const DraggableTableHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid #e8e8e8;
  padding: 8px;
`;

export const DraggableTableRow = styled.div`
  display: flex;
`;

export const WhereClausesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const WhereClauses = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
`;

export const Line = styled.div`
  width: 40px;
  height: 1px;
  background-color: #5e5e5e;
`;
