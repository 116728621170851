/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useState, lazy, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/material";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import PersonAddAlt1RoundedIcon from "@mui/icons-material/PersonAddAlt1Rounded";

import {
  StyledPortalContent,
  H3,
  CustomerLogo,
  JobName,
  TableDataCell,
  TableOverview,
  Theme,
  PreLoader,
} from "../index";
import {
  fetchCustomers,
  deleteUserFunction,
} from "../redux/actions/customerAction";
import useGetToken from "../cognito/useGetToken";

const Edituser = lazy(() => import("./userGroup/EditUser"));
const AddUserModal = lazy(() => import("./userGroup/AddUserModal"));
const RemoveUser = lazy(() => import("./userGroup/RemoveUser"));

const ControlOverview = () => {
  const dispatch = useDispatch();
  const { token } = useGetToken();
  const group = localStorage.getItem("group");
  const created = localStorage.getItem("created");
  const { environmentVariables } = useSelector((state) => state.users);
  const { allCustomersLoader, allCustomers } = useSelector(
    (state) => state.customers,
  );
  const bundleRef = useRef(null);
  useEffect(() => {
    if (token) {
      dispatch(
        fetchCustomers(
          token,
          group,
          environmentVariables.REACT_APP_BASE_URL_API_CUSTOMER,
        ),
      );
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (allCustomersLoader) {
      bundleRef.current = allCustomers[0].bundle;
    }
  }, [allCustomersLoader]);
  //modals actions
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [user, setUser] = useState(null);
  const closeDeleteModal = () => {
    setIsModalDeleteOpen(false);
  };
  const handleModalOpen = (user) => {
    setUser(user);
    setIsModalDeleteOpen(true);
  };
  const handleDeleteActionClick = (user) => {
    dispatch(
      deleteUserFunction(
        token,
        group,
        bundleRef.current,
        user,
        environmentVariables.REACT_APP_BASE_URL_API_CUSTOMER,
      ),
    );
  };
  const [newUserModal, setNewUserModal] = useState(false);
  const closeNewUserModal = () => {
    setNewUserModal(false);
  };
  const [editUserModal, setEditUserModal] = useState(false);
  const handleOpenEditUserModal = (user) => {
    setUser(user);
    setEditUserModal(true);
  };
  const closeEditUserModal = () => {
    setEditUserModal(false);
  };

  const styledBorder = {
    border: "1px solid black",
    font: Theme.fonts.fontPrimary,
    padding: "0.25em",
  };

  return (
    <StyledPortalContent>
      {allCustomersLoader ? (
        <Stack spacing={3}>
          <H3>User Management</H3>
          <Stack justifyContent={"space-between"} direction={"row"}>
            <CustomerLogo />
            <Stack direction={"row"} spacing={2}>
              <Stack direction={"column"} spacing={3}>
                <p style={{ font: Theme.fonts.fontSecondaryBold }}>User:</p>
                <p style={{ font: Theme.fonts.fontSecondaryBold }}>Group:</p>
              </Stack>
              <Stack direction={"column"} spacing={2}>
                <p style={styledBorder}>{created}</p>
                <p style={styledBorder}>{group}</p>
              </Stack>
            </Stack>
          </Stack>
          <div style={{ height: "600px", overflow: "scroll" }}>
            <TableOverview
              headers={["Users", "Last Login", "Group", "Actions"]}
              rows={allCustomers[0].users}
              width={"150px"}
              renderRow={(user, index) => (
                <tr key={index}>
                  <TableDataCell>
                    <JobName onClick={() => handleOpenEditUserModal(user)}>
                      {user.user}
                    </JobName>
                  </TableDataCell>
                  <TableDataCell>{user.status}</TableDataCell>
                  <TableDataCell>{user.group}</TableDataCell>
                  <TableDataCell style={{ display: "flex", gap: "0.75em" }}>
                    <div
                      onClick={() => {
                        handleOpenEditUserModal(user);
                      }}
                    >
                      <svg
                        fill="none"
                        cursor={"pointer"}
                        viewBox="0 0 24 24"
                        height="24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill="#72777F"
                          d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
                        ></path>
                      </svg>
                    </div>
                    <CancelRoundedIcon
                      onClick={() => handleModalOpen(user.user)}
                      sx={{ cursor: "pointer" }}
                    />
                  </TableDataCell>
                </tr>
              )}
            />
          </div>

          <Stack alignItems={"flex-end"}>
            <PersonAddAlt1RoundedIcon
              sx={{ cursor: "pointer", fontSize: "2em" }}
              onClick={() => setNewUserModal(true)}
            />
          </Stack>
          <Suspense fallback={<div>Loading ...</div>}>
            {isModalDeleteOpen && (
              <RemoveUser
                isModalDeleteOpen={isModalDeleteOpen}
                closeDeleteModal={closeDeleteModal}
                handleDeleteActionClick={handleDeleteActionClick}
                user={user}
              />
            )}
          </Suspense>

          <Suspense fallback={<div>Loading ...</div>}>
            {newUserModal && (
              <AddUserModal
                newUserModal={newUserModal}
                closeNewUserModal={closeNewUserModal}
                bundleRef={bundleRef}
              />
            )}
          </Suspense>

          <Suspense fallback={<div>Loading ...</div>}>
            {editUserModal && (
              <Edituser
                editUserModal={editUserModal}
                closeEditUserModal={closeEditUserModal}
                user={user}
                bundleRef={bundleRef}
              />
            )}
          </Suspense>
        </Stack>
      ) : (
        <PreLoader />
      )}
    </StyledPortalContent>
  );
};

export default ControlOverview;
